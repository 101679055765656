import { useState } from 'react';

import './styles.scss';
import { useResponsive } from '@hooks';
import { UIButton, UILoaderWrap } from '@ui';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ReportsFormGeneral from './FormGeneral';
import ReportsFormResponsible from './FormResponsible';

const Reports = () => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();

  const { loading } = useSelector((state) => state.reports);

  const [tab, setTab] = useState(true);

  return (
    <div className="reports-wrap">
      <div className="tabs">
        <UIButton
          defaultClassName=""
          handler={() => setTab(true)}
          className={cn({ active: tab })}
        >
          {t('REPORT.GENERAL_REPORT')}
        </UIButton>

        <UIButton
          defaultClassName=""
          handler={() => setTab(false)}
          className={cn({ active: !tab })}
        >
          {isMobile
            ? t('REPORT.BY_RESPONSIVE')
            : t('REPORT.REPORT_RESPONSIBLE_PERSON')}
        </UIButton>
      </div>

      <UILoaderWrap loading={loading} className="tabs-wrap">
        {tab ? <ReportsFormGeneral /> : <ReportsFormResponsible />}
      </UILoaderWrap>
    </div>
  );
};

export default Reports;
