import { useMemo } from 'react';

import cn from 'classnames';

const ProjectAddEquipmentTab = ({ bookingList, setBookIdx, bookIdx }) => {
  const renderTabs = useMemo(
    () =>
      bookingList?.reservations?.map((item, index) => (
        <div
          key={index}
          className={cn('h6 fw700 addEvent-tabs__item', {
            active: bookIdx === index,
          })}
          onClick={() => setBookIdx(index)}
        >
          {item.name}
        </div>
      )),
    [bookIdx, bookingList.reservations, setBookIdx],
  );

  return (
    <div className="addEvent-tabs__list">
      <div>{renderTabs}</div>
    </div>
  );
};

export default ProjectAddEquipmentTab;
