import { useEffect, useState } from 'react';

import { useAppDispatch } from '@store/';
import { createOfficesRequest } from '@store/Users';
import { UIButton, UIColor, UIInput } from '@ui';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const UsersFormOffices = ({ loading, refresh }) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const [isOpenColor, setIsOpenColor] = useState(false);

  const handlerSubmit = (values) => {
    dispatch(createOfficesRequest(values));
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  useEffect(() => {
    if (refresh) {
      form.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <form onSubmit={form.handleSubmit} className="users-new">
      <div className="users-new__title fw600 h3">
        {t('USERS.ADD_OFFICE')}

        <UIColor
          setColor={(color) => form.setFieldValue('color', color)}
          color={form.values.color}
          setIsOpenColor={setIsOpenColor}
          isOpenColor={isOpenColor}
        />
      </div>

      <div className="users-new__items">
        <UIInput
          label={t('COMMON.TITLE')}
          name="name"
          placeholder={t('COMMON.TITLE')}
          classLabel="fw600"
          value={form.values.name}
          onChange={form.handleChange}
          isValid={!(form.errors.name && form.touched.name)}
          validateText={form.errors.name}
        />

        <div className="users-btn">
          <UIButton
            loading={loading}
            className="btn outline__blue"
            type={Button.SUBMIT}
          >
            <span>{t('COMMON.SAVE')}</span>
          </UIButton>

          <UIButton
            handler={() => form.resetForm()}
            className="btn__gray addEvent-remove__employee"
          >
            {t('COMMON.CANCEL')}
          </UIButton>
        </div>
      </div>
    </form>
  );
};

export default UsersFormOffices;
