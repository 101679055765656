import i18n from '@i18n';
import { CategoriesHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { put, takeLatest, fork, call } from 'redux-saga/effects';

import {
  addCategoriesFailure,
  addCategoriesSuccess,
  addGroupsFailure,
  addGroupsSuccess,
  addPackagesFailure,
  addPackagesSuccess,
  deleteCategoriesFailure,
  deleteCategoriesSuccess,
  deleteGroupsFailure,
  deleteGroupsSuccess,
  deletePackagesFailure,
  deletePackagesSuccess,
  getCategoriesFailure,
  getCategoriesSuccess,
  getGroupsFailure,
  getGroupsSuccess,
  getPackagesFailure,
  getPackagesSuccess,
  setCategoriesFailure,
  setCategoriesSuccess,
  setGroupsFailure,
  setGroupsSuccess,
  setPackagesFailure,
  setPackagesSuccess,
} from './Categories.action';
import {
  ADD_CATEGORIES_REQUEST,
  ADD_GROUPS_REQUEST,
  ADD_PACKAGES_REQUEST,
  DELETE_CATEGORIES_REQUEST,
  DELETE_GROUPS_REQUEST,
  DELETE_PACKAGES_REQUEST,
  GET_CATEGORIES_REQUEST,
  GET_GROUPS_REQUEST,
  GET_PACKAGES_REQUEST,
  SET_CATEGORIES_REQUEST,
  SET_GROUPS_REQUEST,
  SET_PACKAGES_REQUEST,
} from './Categories.constant';

function* workerGetCategories({ payload }) {
  try {
    const data = yield call(CategoriesHttp.getCategories, payload);

    yield put(getCategoriesSuccess(data));
  } catch (error) {
    yield put(getCategoriesFailure());
    yield put(alertError());
  }
}
function* watchGetCategories() {
  yield takeLatest(GET_CATEGORIES_REQUEST, workerGetCategories);
}

function* workerAddCategories({ payload }) {
  try {
    const data = yield call(CategoriesHttp.addCategories, payload);

    yield put(addCategoriesSuccess(data));
    yield put(
      alertSuccess({ message: i18n.t('ALERT.SUCCESSFULLY_ADDED_CATEGORY') }),
    );
  } catch (error) {
    yield put(addCategoriesFailure());
    yield put(alertError());
  }
}
function* watchAddCategories() {
  yield takeLatest(ADD_CATEGORIES_REQUEST, workerAddCategories);
}

function* workerSetCategories({ payload }) {
  try {
    const data = yield call(CategoriesHttp.setCategories, payload);

    yield put(setCategoriesSuccess(data));
    yield put(
      alertSuccess({
        message: i18n.t('ALERT.SUCCESSFULLY_CHANGED_CATEGORY'),
      }),
    );
  } catch (error) {
    yield put(setCategoriesFailure());
    yield put(alertError());
  }
}
function* watchSetCategories() {
  yield takeLatest(SET_CATEGORIES_REQUEST, workerSetCategories);
}

function* workerDeleteCategories({ payload }) {
  try {
    const data = yield call(CategoriesHttp.deleteCategories, payload);

    yield put(deleteCategoriesSuccess(data));
    yield put(
      alertSuccess({
        message: i18n.t('ALERT.SUCCESSFULLY_DELETE_CATEGORY'),
      }),
    );
  } catch (error) {
    yield put(deleteCategoriesFailure());
    yield put(alertError());
  }
}
function* watchDeleteCategories() {
  yield takeLatest(DELETE_CATEGORIES_REQUEST, workerDeleteCategories);
}

function* workerGetPackages({ payload }) {
  try {
    const data = yield call(CategoriesHttp.getPackages, payload);

    yield put(getPackagesSuccess(data));
  } catch (error) {
    yield put(getPackagesFailure());
    yield put(alertError());
  }
}
function* watchGetPackages() {
  yield takeLatest(GET_PACKAGES_REQUEST, workerGetPackages);
}

function* workerAddPackages({ payload }) {
  try {
    const data = yield call(CategoriesHttp.addPackages, payload);

    yield put(addPackagesSuccess(data));
    yield put(
      alertSuccess({ message: i18n.t('ALERT.SUCCESSFULLY_ADDED_KIT') }),
    );
  } catch (error) {
    yield put(addPackagesFailure());
    yield put(alertError());
  }
}
function* watchAddPackages() {
  yield takeLatest(ADD_PACKAGES_REQUEST, workerAddPackages);
}

function* workerSetPackages({ payload }) {
  try {
    const data = yield call(CategoriesHttp.setPackages, payload);

    yield put(setPackagesSuccess(data));
    yield put(
      alertSuccess({ message: i18n.t('ALERT.SUCCESSFULLY_CHANGED_KIT') }),
    );
  } catch (error) {
    yield put(setPackagesFailure());
    yield put(alertError());
  }
}
function* watchSetPackages() {
  yield takeLatest(SET_PACKAGES_REQUEST, workerSetPackages);
}

function* workerDeletePackages({ payload }) {
  try {
    const data = yield call(CategoriesHttp.deletePackages, payload);

    yield put(deletePackagesSuccess(data));
    yield put(
      alertSuccess({ message: i18n.t('ALERT.SUCCESSFULLY_DELETE_KIT') }),
    );
  } catch (error) {
    yield put(deletePackagesFailure());
    yield put(alertError());
  }
}
function* watchDeletePackages() {
  yield takeLatest(DELETE_PACKAGES_REQUEST, workerDeletePackages);
}

function* workerGetGroups({ payload }) {
  try {
    const data = yield call(CategoriesHttp.getGroups, payload);

    yield put(getGroupsSuccess(data));
  } catch (error) {
    yield put(getGroupsFailure());
    yield put(alertError());
  }
}
function* watchGetGroups() {
  yield takeLatest(GET_GROUPS_REQUEST, workerGetGroups);
}

function* workerAddGroups({ payload }) {
  try {
    const data = yield call(CategoriesHttp.addGroups, payload);

    yield put(addGroupsSuccess(data));
    yield put(
      alertSuccess({
        message: i18n.t('ALERT.SUCCESSFULLY_ADDED_WAREHOUSE'),
      }),
    );
  } catch (error) {
    yield put(addGroupsFailure());
    yield put(alertError());
  }
}
function* watchAddGroups() {
  yield takeLatest(ADD_GROUPS_REQUEST, workerAddGroups);
}

function* workerSetGroups({ payload }) {
  try {
    const data = yield call(CategoriesHttp.setGroups, payload);

    yield put(setGroupsSuccess(data));
    yield put(
      alertSuccess({
        message: i18n.t('ALERT.SUCCESSFULLY_CHANGED_WAREHOUSE'),
      }),
    );
  } catch (error) {
    yield put(setGroupsFailure());
    yield put(alertError());
  }
}
function* watchSetGroups() {
  yield takeLatest(SET_GROUPS_REQUEST, workerSetGroups);
}

function* workerDeleteGroups({ payload }) {
  try {
    const data = yield call(CategoriesHttp.deleteGroups, payload);

    yield put(deleteGroupsSuccess(data));
    yield put(
      alertSuccess({
        message: i18n.t('ALERT.SUCCESSFULLY_DELETE_WAREHOUSE'),
      }),
    );
  } catch (error) {
    yield put(deleteGroupsFailure());
    yield put(alertError());
  }
}
function* watchDeleteGroups() {
  yield takeLatest(DELETE_GROUPS_REQUEST, workerDeleteGroups);
}

export const categoriesWatchers = [
  fork(watchGetCategories),
  fork(watchAddCategories),
  fork(watchSetCategories),
  fork(watchDeleteCategories),
  fork(watchGetPackages),
  fork(watchAddPackages),
  fork(watchSetPackages),
  fork(watchDeletePackages),
  fork(watchGetGroups),
  fork(watchAddGroups),
  fork(watchSetGroups),
  fork(watchDeleteGroups),
];
