export const SEARCH_EQUIPMENTS_REQUEST =
  '@equipments/SEARCH_EQUIPMENTS_REQUEST';
export const SEARCH_EQUIPMENTS_FAILURE =
  '@equipments/SEARCH_EQUIPMENTS_FAILURE';
export const SEARCH_EQUIPMENTS_SUCCESS =
  '@equipments/SEARCH_EQUIPMENTS_SUCCESS';

export const ADD_EQUIPMENTS_REQUEST = '@equipments/ADD_EQUIPMENTS_REQUEST';
export const ADD_EQUIPMENTS_FAILURE = '@equipments/ADD_EQUIPMENTS_FAILURE';
export const ADD_EQUIPMENTS_SUCCESS = '@equipments/ADD_EQUIPMENTS_SUCCESS';

export const SET_EQUIPMENTS_REQUEST = '@equipments/SET_EQUIPMENTS_REQUEST';
export const SET_EQUIPMENTS_FAILURE = '@equipments/SET_EQUIPMENTS_FAILURE';
export const SET_EQUIPMENTS_SUCCESS = '@equipments/SET_EQUIPMENTS_SUCCESS';

export const DELETE_EQUIPMENTS_REQUEST =
  '@equipments/DELETE_EQUIPMENTS_REQUEST';
export const DELETE_EQUIPMENTS_FAILURE =
  '@equipments/DELETE_EQUIPMENTS_FAILURE';
export const DELETE_EQUIPMENTS_SUCCESS =
  '@equipments/DELETE_EQUIPMENTS_SUCCESS';

export const TRANSFER_EQUIPMENTS_REQUEST =
  '@equipments/TRANSFER_EQUIPMENTS_REQUEST';
export const TRANSFER_EQUIPMENTS_FAILURE =
  '@equipments/TRANSFER_EQUIPMENTS_FAILURE';
export const TRANSFER_EQUIPMENTS_SUCCESS =
  '@equipments/TRANSFER_EQUIPMENTS_SUCCESS';

export const CLEAR_EQUIPMENTS_DATA = '@equipments/CLEAR_EQUIPMENTS_DATA';
