import { LayoutAccount } from '@layouts';
import Users from '@modules/Users';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

const ViewUsers = () => {
  const { t } = useTranslation();

  return (
    <LayoutAccount title={t('USERS.USERS')} className="users">
      <Routes>
        <Route path="/" element={<Users />} />
      </Routes>
    </LayoutAccount>
  );
};

export default ViewUsers;
