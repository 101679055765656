import { FORMAT_DATE_QUERY } from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { useTranslation } from 'react-i18next';

const ProjectAddInfoCard = ({ title, isProject, equipmentData }) => {
  const { t } = useTranslation();

  const startDate = DateHelper.toFormat(
    equipmentData?.date_start_event * 1000,
    FORMAT_DATE_QUERY,
  );
  const endDate = DateHelper.toFormat(
    equipmentData?.date_finish_event * 1000,
    FORMAT_DATE_QUERY,
  );

  const renderEquipmentTable = () => {
    return (
      <>
        <tr>
          <td>{t('COMMON.BEGINNING')}</td>
          <td>{startDate}</td>
        </tr>
        <tr>
          <td>{t('COMMON.ENDING')}</td>
          <td>{endDate}</td>
        </tr>
        <tr>
          <td>{t('PROJECT.MANAGER_NAME')}</td>
          <td>{equipmentData?.created_by?.name} </td>
        </tr>
        <tr>
          <td>{t('COMMON.EMAIL')}</td>
          <td>{equipmentData?.created_by?.email}</td>
        </tr>
      </>
    );
  };

  const renderProjectTable = () => {
    return (
      <>
        <tr>
          <td>{t('COMMON.PROJECT_NAME')}</td>
          <td>{equipmentData?.name}</td>
        </tr>
        <tr>
          <td>{t('PROJECT.PROJECT_SITE')}</td>
          <td>{equipmentData?.place} </td>
        </tr>
        <tr>
          <td>{t('COMMON.ORGANIZER')}</td>
          <td>{equipmentData?.organizer?.name} </td>
        </tr>
      </>
    );
  };

  return (
    <div>
      <div className="project_info-table_title">{title}</div>
      <table className="custom-table">
        <tbody>
          {isProject ? renderProjectTable() : renderEquipmentTable()}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectAddInfoCard;
