import http from '@utils/http';
import qs from 'qs';

const basePath = '/api';

export const getOrganizerEvent = async () => {
  const data = await http.get(`${basePath}/organizers-event`);

  return data.data.data;
};

export const getUserEvent = async () => {
  const data = await http.get(`${basePath}/users-event`);

  return data.data.data;
};

export const createClient = async (body) => {
  const data = await http.post(`${basePath}/organizers`, body);

  return data.data.data;
};

export const createProject = async (body) => {
  const data = await http.post(`${basePath}/events`, body);

  return data.data.data;
};

export const createEmployee = async (body) => {
  const data = await http.post(`${basePath}/users`, body);

  return data.data.data;
};

export const getOfficeEvent = async () => {
  const data = await http.get(`${basePath}/offices`);

  return data.data.data;
};

export const getLegacyEvent = async () => {
  const data = await http.get(`${basePath}/legacy-event`);

  return data.data.data;
};

export const getTransport = async () => {
  const data = await http.get(`${basePath}/transports-event`);

  return data.data.data;
};

export const getUsers = async () => {
  const data = await http.get(`${basePath}/users-event`);

  return data.data.data;
};

export const createResponsible = async (body) => {
  const data = await http.post(`${basePath}/legacy`, body);

  return data.data.data;
};

export const createTransport = async (body) => {
  const data = await http.post(`${basePath}/transports`, body);

  return data.data.data;
};

export const createStaff = async (body) => {
  const data = await http.post(`${basePath}/users`, body);

  return data.data.data;
};

export const createManager = async (body) => {
  const data = await http.put(`${basePath}/events/${body.id}/step2`, body);

  return data.data.data;
};

export const getEquipmentEvent = async () => {
  const data = await http.get(`${basePath}/categories-event`);

  return data.data.data;
};

export const getWarehouseEvent = async () => {
  const data = await http.get(`${basePath}/groups-event `);

  return data.data.data;
};

export const getListBooking = async (body) => {
  const data = await http.get(`${basePath}/events/${body}/edit`);

  return data.data.data;
};

export const getListEquipmentSelect = async (body) => {
  const data = await http.get(`${basePath}/equipments-event`, {
    params: body,
    paramsSerializer: (...data) => {
      return qs.stringify(...data, {
        encodeValuesOnly: true,
        skipNulls: true,
      });
    },
  });

  return data.data.data;
};

export const createEquipment = async (body) => {
  const data = await http.put(`${basePath}/events/${body.id}/step3`, body);

  return data.data.data;
};

export const createProjectFinish = async (body) => {
  const data = await http.put(`${basePath}/events/${body.id}/finish`, body);

  return data.data.data;
};

export const projectDelete = async (body) => {
  const data = await http.delete(`${basePath}/events/${body}`);

  return data.data.data;
};

export const projectValidation = async (body) => {
  const data = await http.post(`${basePath}/events/validation`, body);

  return data.data;
};
