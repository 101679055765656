import { useMemo } from 'react';

const UITableRowHeader = ({ header }) => {
  const renderHeader = useMemo(
    () => header?.map((title, index) => <th key={index}>{title}</th>),
    [header],
  );

  return <tr>{renderHeader}</tr>;
};

export default UITableRowHeader;
