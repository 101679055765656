import {
  CLERA_STORE,
  CREATE_CLIENT_FAILURE,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  CREATE_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_REQUEST,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EQUIPMENT_FAILURE,
  CREATE_EQUIPMENT_REQUEST,
  CREATE_EQUIPMENT_SUCCESS,
  CREATE_MANAGER_FAILURE,
  CREATE_MANAGER_REQUEST,
  CREATE_MANAGER_SUCCESS,
  CREATE_PROJECT_FAILURE,
  CREATE_PROJECT_FINISH_FAILURE,
  CREATE_PROJECT_FINISH_REQUEST,
  CREATE_PROJECT_FINISH_SUCCESS,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_RESPONSIBLE_FAILURE,
  CREATE_RESPONSIBLE_REQUEST,
  CREATE_RESPONSIBLE_SUCCESS,
  CREATE_STAFF_FAILURE,
  CREATE_STAFF_REQUEST,
  CREATE_STAFF_SUCCESS,
  CREATE_TRANSPORT_FAILURE,
  CREATE_TRANSPORT_REQUEST,
  CREATE_TRANSPORT_SUCCESS,
  GET_EQUIPMENT_EVENT_FAILURE,
  GET_EQUIPMENT_EVENT_REQUEST,
  GET_EQUIPMENT_EVENT_SUCCESS,
  GET_LEGACY_EVENT_FAILURE,
  GET_LEGACY_EVENT_REQUEST,
  GET_LEGACY_EVENT_SUCCESS,
  GET_LIST_BOOKING_FAILURE,
  GET_LIST_BOOKING_REQUEST,
  GET_LIST_BOOKING_SUCCESS,
  GET_LIST_EQUIPMENT_SELECT_FAILURE,
  GET_LIST_EQUIPMENT_SELECT_REQUEST,
  GET_LIST_EQUIPMENT_SELECT_SUCCESS,
  GET_OFFICE_EVENT_FAILURE,
  GET_OFFICE_EVENT_REQUEST,
  GET_OFFICE_EVENT_SUCCESS,
  GET_ORGANIZERS_EVENT_FAILURE,
  GET_ORGANIZERS_EVENT_REQUEST,
  GET_ORGANIZERS_EVENT_SUCCESS,
  GET_TRANSPORT_LIST_FAILURE,
  GET_TRANSPORT_LIST_REQUEST,
  GET_TRANSPORT_LIST_SUCCESS,
  GET_USER_EVENT_FAILURE,
  GET_USER_EVENT_REQUEST,
  GET_USER_EVENT_SUCCESS,
  GET_USER_LIST_FAILURE,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_WAREHOUSE_EVENT_FAILURE,
  GET_WAREHOUSE_EVENT_REQUEST,
  GET_WAREHOUSE_EVENT_SUCCESS,
  IS_LOADED_EQUIPMENTS_EVENT,
  PROJECT_DELETE_FAILURE,
  PROJECT_DELETE_REQUEST,
  PROJECT_DELETE_SUCCESS,
  PROJECT_VALIDATION_FAILURE,
  PROJECT_VALIDATION_REQUEST,
  PROJECT_VALIDATION_SUCCESS,
  RESET_STORE,
  SET_STEP,
  SET_STEP1_FORM,
  SET_STEP2_FORM,
  SET_STEP3_FORM,
  RESET_ACTION,
} from './ProjectAdd.constant';

export function projectDeleteRequest(payload) {
  return {
    type: PROJECT_DELETE_REQUEST,
    payload,
  };
}
export function projectDeleteSuccess(payload) {
  return {
    type: PROJECT_DELETE_SUCCESS,
    payload,
  };
}
export function projectDeleteFailure() {
  return {
    type: PROJECT_DELETE_FAILURE,
  };
}

export function projectValidationRequest(payload, step) {
  return {
    type: PROJECT_VALIDATION_REQUEST,
    payload,
    step,
  };
}
export function projectValidationSuccess(payload) {
  return {
    type: PROJECT_VALIDATION_SUCCESS,
    payload,
  };
}
export function projectValidationFailure() {
  return {
    type: PROJECT_VALIDATION_FAILURE,
  };
}

export function getOrganizersEventRequest() {
  return {
    type: GET_ORGANIZERS_EVENT_REQUEST,
  };
}
export function getOrganizersEventSuccess(payload) {
  return {
    type: GET_ORGANIZERS_EVENT_SUCCESS,
    payload,
  };
}
export function getOrganizersEventFailure() {
  return {
    type: GET_ORGANIZERS_EVENT_FAILURE,
  };
}

export function getUserEventRequest() {
  return {
    type: GET_USER_EVENT_REQUEST,
  };
}

export function getUserEventSuccess(payload) {
  return {
    type: GET_USER_EVENT_SUCCESS,
    payload,
  };
}

export function getUserEventFailure() {
  return {
    type: GET_USER_EVENT_FAILURE,
  };
}

export function createClientRequest(payload) {
  return {
    type: CREATE_CLIENT_REQUEST,
    payload,
  };
}

export function createClientSuccess(payload) {
  return {
    type: CREATE_CLIENT_SUCCESS,
    payload,
  };
}

export function createClientFailure() {
  return {
    type: CREATE_CLIENT_FAILURE,
  };
}

export function createProjectRequest(payload, step) {
  return {
    type: CREATE_PROJECT_REQUEST,
    payload,
    step,
  };
}

export function createProjectSuccess(payload) {
  return {
    type: CREATE_PROJECT_SUCCESS,
    payload,
  };
}

export function createProjectFailure() {
  return {
    type: CREATE_PROJECT_FAILURE,
  };
}

export function createEmployeeRequest(payload) {
  return {
    type: CREATE_EMPLOYEE_REQUEST,
    payload,
  };
}

export function createEmployeeSuccess(payload) {
  return {
    type: CREATE_EMPLOYEE_SUCCESS,
    payload,
  };
}

export function createEmployeeFailure() {
  return {
    type: CREATE_EMPLOYEE_FAILURE,
  };
}

export function getOfficeEventRequest() {
  return {
    type: GET_OFFICE_EVENT_REQUEST,
  };
}

export function getOfficeEventSuccess(payload) {
  return {
    type: GET_OFFICE_EVENT_SUCCESS,
    payload,
  };
}

export function getOfficeEventFailure() {
  return {
    type: GET_OFFICE_EVENT_FAILURE,
  };
}

export function getLegacyEventRequest() {
  return {
    type: GET_LEGACY_EVENT_REQUEST,
  };
}

export function getLegacyEventSuccess(payload) {
  return {
    type: GET_LEGACY_EVENT_SUCCESS,
    payload,
  };
}

export function getLegacyEventFailure() {
  return {
    type: GET_LEGACY_EVENT_FAILURE,
  };
}

export function getTransportListRequest() {
  return {
    type: GET_TRANSPORT_LIST_REQUEST,
  };
}

export function getTransportListSuccess(payload) {
  return {
    type: GET_TRANSPORT_LIST_SUCCESS,
    payload,
  };
}

export function getTransportListFailure() {
  return {
    type: GET_TRANSPORT_LIST_FAILURE,
  };
}

export function getUserListRequest() {
  return {
    type: GET_USER_LIST_REQUEST,
  };
}

export function getUserListSuccess(payload) {
  return {
    type: GET_USER_LIST_SUCCESS,
    payload,
  };
}

export function getUserListFailure() {
  return {
    type: GET_USER_LIST_FAILURE,
  };
}

export function createResponsibleRequest(payload) {
  return {
    type: CREATE_RESPONSIBLE_REQUEST,
    payload,
  };
}

export function createResponsibleSuccess(payload) {
  return {
    type: CREATE_RESPONSIBLE_SUCCESS,
    payload,
  };
}

export function createResponsibleFailure() {
  return {
    type: CREATE_RESPONSIBLE_FAILURE,
  };
}

export function createTransportRequest(payload) {
  return {
    type: CREATE_TRANSPORT_REQUEST,
    payload,
  };
}

export function createTransportSuccess(payload) {
  return {
    type: CREATE_TRANSPORT_SUCCESS,
    payload,
  };
}

export function createTransportFailure() {
  return {
    type: CREATE_TRANSPORT_FAILURE,
  };
}

export function createStaffRequest(payload) {
  return {
    type: CREATE_STAFF_REQUEST,
    payload,
  };
}

export function createStaffSuccess(payload) {
  return {
    type: CREATE_STAFF_SUCCESS,
    payload,
  };
}

export function createStaffFailure() {
  return {
    type: CREATE_STAFF_FAILURE,
  };
}

export function createManagerRequest(payload, step) {
  return {
    type: CREATE_MANAGER_REQUEST,
    payload,
    step,
  };
}

export function createManagerSuccess(payload) {
  return {
    type: CREATE_MANAGER_SUCCESS,
    payload,
  };
}

export function createManagerFailure() {
  return {
    type: CREATE_MANAGER_FAILURE,
  };
}

export function getEquipmentEventRequest() {
  return {
    type: GET_EQUIPMENT_EVENT_REQUEST,
  };
}

export function getEquipmentEventSuccess(payload) {
  return {
    type: GET_EQUIPMENT_EVENT_SUCCESS,
    payload,
  };
}

export function getEquipmentEventFailure() {
  return {
    type: GET_EQUIPMENT_EVENT_FAILURE,
  };
}

export function getWarehouseEventRequest() {
  return {
    type: GET_WAREHOUSE_EVENT_REQUEST,
  };
}

export function getWarehouseEventSuccess(payload) {
  return {
    type: GET_WAREHOUSE_EVENT_SUCCESS,
    payload,
  };
}

export function getWarehouseEventFailure() {
  return {
    type: GET_WAREHOUSE_EVENT_FAILURE,
  };
}

export function getListBookingRequest(payload) {
  return {
    type: GET_LIST_BOOKING_REQUEST,
    payload,
  };
}

export function getListBookingSuccess(payload) {
  return {
    type: GET_LIST_BOOKING_SUCCESS,
    payload,
  };
}

export function getListBookingFailure() {
  return {
    type: GET_LIST_BOOKING_FAILURE,
  };
}

export function getListEquipmentSelectRequest(payload) {
  return {
    type: GET_LIST_EQUIPMENT_SELECT_REQUEST,
    payload,
  };
}

export function getListEquipmentSelectSuccess(payload) {
  return {
    type: GET_LIST_EQUIPMENT_SELECT_SUCCESS,
    payload,
  };
}

export function getListEquipmentSelectFailure() {
  return {
    type: GET_LIST_EQUIPMENT_SELECT_FAILURE,
  };
}

export function createEquipmentRequest(payload, step) {
  return {
    type: CREATE_EQUIPMENT_REQUEST,
    payload,
    step,
  };
}

export function createEquipmentSuccess(payload) {
  return {
    type: CREATE_EQUIPMENT_SUCCESS,
    payload,
  };
}

export function createEquipmentFailure() {
  return {
    type: CREATE_EQUIPMENT_FAILURE,
  };
}

export function createProjectFinishRequest(payload) {
  return {
    type: CREATE_PROJECT_FINISH_REQUEST,
    payload,
  };
}

export function createProjectFinishSuccess(payload) {
  return {
    type: CREATE_PROJECT_FINISH_SUCCESS,
    payload,
  };
}

export function createProjectFinishFailure() {
  return {
    type: CREATE_PROJECT_FINISH_FAILURE,
  };
}

export function clearStore(payload) {
  return {
    type: CLERA_STORE,
    payload,
  };
}

export function setStep(payload) {
  return {
    type: SET_STEP,
    payload,
  };
}

export function setStep1Form(payload) {
  return {
    type: SET_STEP1_FORM,
    payload,
  };
}

export function setStep2Form(payload) {
  return {
    type: SET_STEP2_FORM,
    payload,
  };
}

export function setStep3Fom(payload) {
  return {
    type: SET_STEP3_FORM,
    payload,
  };
}

export function isLoadedEquipmentsEvent(payload) {
  return {
    type: IS_LOADED_EQUIPMENTS_EVENT,
    payload,
  };
}

export function resetStore() {
  return {
    type: RESET_STORE,
  };
}

export function resetAction() {
  return {
    type: RESET_ACTION,
  };
}
