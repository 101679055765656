import { useMemo } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const { PlusSVG, DeleteSVG } = require('@assets/img/icons');
const { UISelect, UIButton } = require('@ui');

const ProjectAddMainBookingHeader = ({
  setReservations,
  reservations,
  setIdxBookingMenu,
  idxBookingContent,
  openModal,
  setIdxBookingContent,
}) => {
  const { t } = useTranslation();

  const INITIAL_VALUES = {
    place: '',
    office: '',
    place_phone: '',
    legacy_id: '',
    comment_light: '',
    comment: '',
    traffic_lights: [],
    reservations: [
      {
        name: t('COMMON.BOOKING', 1),
        start: '',
        finish: '',
        loading: '',
        time: '',
        dateBooking: '',
      },
    ],
    step: '2',
  };

  const addBooking = () => {
    setReservations('reservations', [
      ...reservations,
      {
        ...INITIAL_VALUES.reservations[0],
        name: `${t('COMMON.BOOKING')} ${reservations.length + 1}`,
      },
    ]);
    setIdxBookingMenu(reservations.length);
    setIdxBookingContent(reservations.length);
  };

  const renderBooking = useMemo(
    () =>
      reservations.map((item, index) => (
        <div className="addEvent-booking__wrapp-btn" key={index}>
          <div
            onClick={() => setIdxBookingMenu(index)}
            className={cn('btn pd__small', {
              error: !(
                reservations?.[index]?.dateBooking ||
                reservations?.[index]?.loading ||
                reservations?.[index]?.time
              ),
              'btn__gray color_grey': index !== idxBookingContent,
              'btn-delete':
                reservations?.length > 1 && index === idxBookingContent,
            })}
          >
            <span>
              {reservations[index]?.name?.length
                ? item.name
                : `${t('COMMON.BOOKING')} ${index + 1}`}
            </span>
          </div>
          {index !== idxBookingContent ||
            (reservations.length > 1 && (
              <span onClick={() => openModal(index)}>
                <DeleteSVG />
              </span>
            ))}
        </div>
      )),
    [idxBookingContent, openModal, reservations, setIdxBookingMenu, t],
  );

  return (
    <>
      <div className="addEvent-booking__tabs hide-mobile">
        <div className="addEvent-booking__tabl-list">{renderBooking}</div>

        <UIButton className="btn outline__blue" handler={() => addBooking()}>
          <PlusSVG />
          <span className="hide-tablet">{t('PROJECT.ADD_BOOKING')}</span>
        </UIButton>
      </div>

      <div className="custom-select__wrap hide-desktop show-mobile">
        <UISelect
          isClearable={false}
          value={{
            id: idxBookingContent,
            name: reservations[idxBookingContent].name,
          }}
          options={reservations?.map((option, index) => ({
            id: index,
            name: option.name,
          }))}
          onChange={(value) => {
            setIdxBookingMenu(value.id);
          }}
        />
        <div>
          <UIButton handler={() => addBooking()} className="btn outline__blue">
            <PlusSVG />
          </UIButton>
        </div>
      </div>
    </>
  );
};

export default ProjectAddMainBookingHeader;
