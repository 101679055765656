import { LayoutAccount } from '@layouts';
import Project from '@modules/Project';
import ProjectAll from '@modules/Project/All';
import ProjectDetail from '@modules/Project/Detail';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

const ViewProject = () => {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <LayoutAccount
            title={t('PROJECT.PROJECT')}
            className="project_addEvent"
          >
            <Project />
          </LayoutAccount>
        }
      />
      <Route
        path="/:id"
        element={
          <LayoutAccount idNotTitle className="project_detail">
            <ProjectDetail />
          </LayoutAccount>
        }
      />
      <Route
        path="/all"
        element={
          <LayoutAccount idNotTitle className="page__event project_all">
            <ProjectAll />
          </LayoutAccount>
        }
      />
    </Routes>
  );
};

export default ViewProject;
