import { useCallback, useEffect, useState } from 'react';

import { MoveBackBigSVG, MoveToBigSVG } from '@assets/img/icons';
import { useAppDispatch } from '@store/';
import {
  getEquipmentEventRequest,
  getListBookingRequest,
  getListEquipmentSelectRequest,
  getWarehouseEventRequest,
  projectValidationRequest,
  setStep3Fom,
} from '@store/ProjectAdd';
import { UIButton, UISelect } from '@ui';
import { FORMAT_DATE_QUERY } from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ProjectAddEquipmentHeader from './Header';
import './style.scss';
import ProjectAddEquipmentTab from './Tab';
import ProjectAddEquipmentTable from './Table';
import ProjectAddEquipmentTime from './Time';
import { UIProjectAddStep } from '../ui';

const ProjectAddEquipment = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    categoriesEvent,
    loadingCategories,
    werehouseEvent,
    loadingWerehouse,
    bookingList,
    equipmentSelectData,
    loadingEquipment,
    loading,
    projectData,
    step3Form,
    isLoadedEquipments,
  } = useSelector((state) => state.projectAdd);
  const { editData } = useSelector((state) => state.project);

  const [bookIdx, setBookIdx] = useState(0);
  const [isSelectValue, setSelectValue] = useState(false);
  const [isChangeWarehouse, setChangeWarehouse] = useState(false);
  const [groupId, setGroupId] = useState();
  const [categoryId, setCategoryId] = useState();
  const [tableData, setTableData] = useState(equipmentSelectData);

  const isEditReservations =
    new Date(bookingList?.reservations?.[bookIdx]?.finish * 1000) <
    new Date(DateHelper.roundingDayToUnix(new Date()));

  useEffect(() => setTableData(equipmentSelectData), [equipmentSelectData]);
  useEffect(() => {
    dispatch(getEquipmentEventRequest());
    dispatch(getWarehouseEventRequest());
    dispatch(getListBookingRequest(projectData?.id || editData?.id));
  }, [dispatch, editData?.id, projectData?.id]);

  useEffect(() => {
    if (
      (editData?.id || projectData?.id) &&
      bookingList?.reservations?.length !== step3Form?.length
    ) {
      dispatch(
        setStep3Fom(
          bookingList?.reservations?.map((item) => ({
            data: item?.equipments,
          })),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingList?.reservations, dispatch, editData?.id, projectData?.id]);

  const { reservations } = bookingList;
  const startDate = DateHelper.toFormat(
    reservations?.[bookIdx].start * 1000,
    FORMAT_DATE_QUERY,
  );
  const endDate = DateHelper.toFormat(
    reservations?.[bookIdx].finish * 1000,
    FORMAT_DATE_QUERY,
  );

  const onSubmit = useCallback(
    (red, type) => {
      const filteredArray = step3Form?.map((item, index) => ({
        ...item,
        data: {
          ...item.data?.map((i) => {
            return {
              count: i.reserved,
              group_id: i.group_id || i.group[0].id,
              equipment_id: i.id,
            };
          }),
        },
        id: bookingList.reservations[index].id,
      }));
      const objToRequest = {
        id: bookingList.id,
        reservations: filteredArray,
        step: '3',
      };

      const allSelectEquipment = step3Form
        ?.map((item, index) => [
          ...item.data.map((item) => ({
            ...item,
            reservation_id: bookingList?.reservations?.[index]?.id,
            start: bookingList?.reservations?.[index]?.start,
            finish: bookingList?.reservations?.[index]?.finish,
          })),
        ])
        .flat();

      const objToValidation = allSelectEquipment?.map((i) => ({
        event_id: bookingList.id,
        reservation_id: i.reservation_id,
        start: i.start,
        finish: i.finish,
        id: i.id,
        name: i.name,
        group: i.group_id || i.group[0].id,
        count: i.reserved,
      }));

      dispatch(
        projectValidationRequest(
          {
            validation: objToValidation,
            request: objToRequest,
            red,
          },
          type,
        ),
      );
    },
    [bookingList.id, bookingList.reservations, dispatch, step3Form],
  );

  const onWerehouseSelectChange = (value) => {
    dispatch(
      getListEquipmentSelectRequest({
        page: 1,
        reservation_id: reservations[bookIdx]?.id,
        date: [startDate, endDate],
        filter: {
          'group.id': value?.id || '',
        },
      }),
    );
    setGroupId(value?.id || '');
    setSelectValue(!!value);
    setChangeWarehouse(true);
  };

  const onCategorySelectChange = (value) => {
    dispatch(
      getListEquipmentSelectRequest({
        page: 1,
        reservation_id: reservations[bookIdx].id || '',
        date: [startDate, endDate],
        filter: {
          category_id: value?.id || '',
          'group.id': groupId,
        },
      }),
    );
    setCategoryId(value?.id || '');
    setChangeWarehouse(false);
  };

  // const isBtnDisabled = !(
  //   step3Form &&
  //   step3Form?.map((i) => i.data.length).filter((i) => i).length ===
  //     step3Form?.length
  // );

  useEffect(() => {
    if (step3Form?.map((i) => i.data.length).filter((i) => i).length > -1) {
      onSubmit();
    }
    // if (step3Form?.map((i) => i.data.length).filter((i) => i).length) {
    // onSubmit();
    // } else if (groupId && categoryId) {
    //   dispatch(
    //     getListEquipmentSelectRequest({
    //       page: 1,
    //       reservation_id: reservations[bookIdx].id,
    //       date: [startDate, endDate],
    //       filter: {
    //         category_id: categoryId,
    //         'group.id': groupId,
    //       },
    //     }),
    //   );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookIdx]);

  useEffect(() => {
    if (isLoadedEquipments && groupId && categoryId) {
      dispatch(
        getListEquipmentSelectRequest({
          page: 1,
          reservation_id: reservations[bookIdx].id,
          date: [startDate, endDate],
          filter: {
            category_id: categoryId,
            'group.id': groupId,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedEquipments]);

  return (
    <>
      <UIProjectAddStep handleSave={(type) => onSubmit(true, type)} />
      <div className="addEvent-wrap__step3 add_event">
        <div className="addEvent-wrap">
          <div className="addEvent-wrap__top">
            <div className="h3 fw600 mb-30">{t('COMMON.EQUIPMENT')}</div>

            <ProjectAddEquipmentHeader
              name={bookingList.name}
              dateStart={bookingList.date_start_event}
              dateEnd={bookingList.date_finish_event}
            />

            <div className="addEvent-top__select">
              <span className="h6 fw700">{t('COMMON.WAREHOUSE')}</span>
              <UISelect
                disabled={isEditReservations}
                options={werehouseEvent}
                loading={loadingWerehouse}
                onChange={onWerehouseSelectChange}
              />
            </div>
          </div>
        </div>
        <div className="addEvent-wrap__table">
          <div className="addEvent-table__left">
            <div className="h3 fw600">{t('PROJECT.AVAILABLE_EQUIPMENT')}</div>

            <div className="addEvent-top__select">
              <UISelect
                disabled={!isSelectValue}
                loading={loadingCategories}
                options={categoriesEvent}
                {...(isChangeWarehouse && { value: null })}
                onChange={onCategorySelectChange}
              />
            </div>

            <ProjectAddEquipmentTable
              icon={<MoveToBigSVG />}
              loadingEquipment={loadingEquipment}
              step3Form={step3Form}
              setTableData={setTableData}
              tableData={tableData}
              bookIdx={bookIdx}
              disabled={isEditReservations}
              disabledText={t('PROJECT.THIS_BOOKING_ALREADY_OVER')}
            />
          </div>

          <div className="addEvent-table__right">
            <div className="h3 fw600">{t('PROJECT.SELECT_EQUIPMENT')}</div>
            <ProjectAddEquipmentTab
              bookingList={bookingList}
              setBookIdx={setBookIdx}
              bookIdx={bookIdx}
            />

            <ProjectAddEquipmentTime
              startDate={reservations?.[bookIdx].start * 1000}
              endDate={reservations?.[bookIdx].finish * 1000}
            />

            <ProjectAddEquipmentTable
              disabled={isEditReservations}
              disabledText={t('PROJECT.THIS_BOOKING_ALREADY_OVER')}
              icon={<MoveBackBigSVG />}
              step3Form={step3Form}
              setTableData={setTableData}
              tableData={tableData}
              bookIdx={bookIdx}
              isSelected
            />
          </div>
        </div>

        <UIButton
          // disabled={isBtnDisabled}
          loading={loading}
          handler={() => {
            onSubmit(true);
          }}
          className="mt-30 addEvent-submit"
        >
          {t('COMMON.SAVE')}
        </UIButton>
      </div>
    </>
  );
};

export default ProjectAddEquipment;
