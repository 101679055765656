import http from '@utils/http';

const basePath = '/api/media';

export const upload = async (payload) => {
  const formData = new FormData();

  formData.append(payload.fileName, payload.file);
  formData.append('name', payload.file.name);
  formData.append('type', payload.file.type);

  const { data } = await http.post(basePath, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data.data;
};
