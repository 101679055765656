/* eslint-disable indent */
import { useState } from 'react';

import { LayoutModal } from '@layouts';
import { useAppDispatch } from '@store/';
import { hideModal } from '@store/Modal';
import { UIButton, UICheckBox } from '@ui';
import { FORMAT_DATE_TEXT_ALT } from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './style.scss';

const ProjectPrint = () => {
  const { project } = useSelector((state) => state.project);

  const [indexReservation, setIndexReservation] = useState([]);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handler = () => {
    const dataToFormat = (data) =>
      DateHelper.toFormat(data * 1000, FORMAT_DATE_TEXT_ALT);
    const dateStart = dataToFormat(project.date_start_event);
    const dateFinish = dataToFormat(project.date_finish_event);
    let popupWin;

    // eslint-disable-next-line prefer-const
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();

    let printedPage = `
      <html>
        <head>
          <title>${t(
            'COMMON.EVENT',
          )}: ${`${project.name}(${dateStart}`} - ${dateFinish})</title>
          <style>
          table {border-collapse: collapse;}
          table th { text-align: left;}
          table td,table th {border: 1px solid #8c8c8c;padding: 5px}
          </style>
        </head>
    <body onload="window.print();window.close()">`;

    printedPage += `<h1>${project?.name}</h1><hr>`;

    printedPage += `<div class="date-event"><strong>${t(
      'CALENDAR.DATE',
    )}</strong>: ${dateStart} - ${dateFinish}</div>`;

    if (project.person_charge)
      printedPage += `<div><strong>${t('PROJECT.MANAGER_NAME')}</strong>: ${
        project.person_charge.name
      }</div>`;

    if (project.person_charge.email)
      printedPage += `<div><strong>E-mail</strong>: ${project.person_charge.email}</div>`;

    if (project.comment)
      printedPage += `<div><strong>${t('PROJECT.COMMENT')}</strong>: ${
        project.comment
      }</div>`;

    if (project.comment_light)
      printedPage += `<div><strong>${t(
        'PROJECT.CONTRACTORS_COMMENT',
      )}</strong>: ${project.comment_light}</div>`;

    if (project.place || project.legacy_id) {
      printedPage += `<hr><h2>${t('PROJECT.INFO_ABOUT_SITE')}:</h2>`;

      if (project.place)
        printedPage += `<div><strong>${t('PROJECT.EVENT_VENUE')}</strong>: ${
          project.place
        }</div>`;

      if (project?.legacy_id?.organisation)
        printedPage += `<div><strong>${t('COMMON.ORGANIZATION')}</strong>: ${
          project.legacy_id.organisation
        }</div>`;

      if (project?.legacy_id?.person)
        printedPage += `<div><strong>${t('PROJECT.RESPONSIBLE')}</strong>: ${
          project.legacy_id.person
        }</div>`;

      if (project?.legacy_id?.phone)
        printedPage += `<div><strong>${t('COMMON.PHONE')}</strong>: ${
          project.legacy_id.phone
        }</div>`;

      if (project?.legacy_id?.email)
        printedPage += `<div><strong>${t('COMMON.MAIL')}</strong>: ${
          project.legacy_id.email
        }</div>`;
    }

    printedPage += `<hr></h4><h2>${t('PROJECT.BOOKING')}</h2>`;
    indexReservation.forEach((item, index) => {
      if (project?.reservations?.[index]) {
        const nameReservation =
          project.reservations[index]?.name ||
          `${t('COMMON.BOOKING')} ${index + 1}`;
        const currentReservation = project.reservations[index];

        printedPage += `<div class="print-block"><h3 class="print-block-title">${nameReservation}</h3>`;

        printedPage += `<div><strong>${t(
          'PROJECT.BOOKING_DATE',
        )}</strong>: ${dataToFormat(currentReservation.start)} - ${dataToFormat(
          currentReservation.finish,
        )}</div>`;
        printedPage += `<div><strong>${t(
          'PROJECT.LOADING_DATE',
        )}</strong>: ${dataToFormat(currentReservation.loading)}`;

        if (currentReservation.personals) {
          printedPage += `<div><strong>${t('PROJECT.STAFF')}</strong>: `;

          currentReservation.personals.forEach((item, index) => {
            if (index + 1 === +currentReservation.personals.length) {
              printedPage += item.name;
            } else {
              printedPage += `${item.name}, `;
            }
          });
          printedPage += '</div>';
        }

        if (currentReservation.transports) {
          printedPage += `<div><strong>${t('PROJECT.TRANSPORT')}</strong>: `;

          currentReservation.transports.forEach((item, index) => {
            if (index + 1 === +currentReservation.transports.length) {
              printedPage += item.name;
            } else {
              printedPage += `${item.name}, `;
            }
          });
          printedPage += '</div>';
        }

        if (currentReservation.equipments) {
          printedPage += `<h4>${t(
            'EQUIPMENTS.EQUIPMENT_LIST',
          )}:</h4><div class="ant-table-body"> <table class="table-equipment"> <thead class="ant-table-thead"> <tr class="table-filter"> <th width="40%">${t(
            'COMMON.TYPE',
          )}</th> <th width="40%">${t(
            'COMMON.EQUIPMENT',
          )}</th> <th width="15%">${t(
            'COMMON.WAREHOUSE',
          )}</th> <th width="5%">${t(
            'COMMON.QUANTITY',
          )}</th> </tr> </thead> <tbody>`;

          currentReservation.equipments.forEach((item) => {
            item.group.forEach((subitem) => {
              if (+item.group_id === +subitem.id)
                printedPage += `<tr><td>${item.category.name}</td><td>${item.name}</td><td>${subitem.name}</td><td>${item.reserved}</td></tr>`;
            });
          });
          printedPage += '</tbody> </table> </div>';
        }
      }
    });

    printedPage += `</body>
        </html>`;

    popupWin.document.write(printedPage);
    popupWin.document.close();
  };

  const handlerChange = (val) => {
    setIndexReservation((prev) =>
      prev.includes(val) ? [...prev].filter((i) => i !== val) : [...prev, val],
    );
  };

  return (
    <LayoutModal
      title={t('COMMON.PRINT_OUTPUT', ':')}
      className="modal__booking"
    >
      <div className="modal-booking__checkbox">
        {project?.reservations?.map((item, index) => {
          return (
            <UICheckBox
              key={index}
              // eslint-disable-next-line prefer-template
              id={'print_' + index}
              onChange={() => handlerChange(index)}
              // eslint-disable-next-line prefer-template
              label={item?.name || t('COMMON.NO_NAME', ', id -') + item?.id}
            />
          );
        })}
      </div>
      <div className="modal__booking__btn">
        <UIButton
          handler={() => dispatch(hideModal())}
          className="btn btn__gray hide-desktop show-mobile"
        >
          <span>{t('COMMON.CANCEL')}</span>
        </UIButton>
        <UIButton handler={() => handler()} className="btn fw400">
          {t('COMMON.PRINT')}
        </UIButton>
      </div>
    </LayoutModal>
  );
};

export default ProjectPrint;
