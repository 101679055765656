import { useEffect, useState } from 'react';

import { SentSVG } from '@assets/img/icons';
import { useAppDispatch } from '@store/';
import {
  clearMassages,
  createMassageRequest,
  getListMassageRequest,
} from '@store/Wall';
import './style.scss';
import { UIButton, UICheckBox, UISelect, UITextarea } from '@ui';
import { FORMAT_DATE_QUERY_WITH_TIME } from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import WallList from './List';

const Wall = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    massageList,
    meta,
    loading,
    deleteLoading,
    updateLoading,
    createLoading,
  } = useSelector((state) => state.wall);

  const [tab, setTab] = useState(true);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState('');
  const [vacation, setVacation] = useState(false);
  const [selectId, setSelectId] = useState(0);
  const [massageListReverse, setMassageListReverse] = useState(null);

  const options = [
    { id: 1, name: t('WALL.SHOW_LATEST_LIST') },
    { id: 2, name: t('WALL.SHOW_FIRST_LIST') },
  ];

  useEffect(() => {
    if (massageList) {
      setMassageListReverse([...massageList]?.reverse());
    }
  }, [massageList, selectId]);

  const onSubmitHandler = () => {
    const objToRequest = {
      content: value,
      date: DateHelper.toFormat(new Date(), FORMAT_DATE_QUERY_WITH_TIME, false),
      vacation,
    };

    dispatch(createMassageRequest(objToRequest));
    setValue('');
  };

  useEffect(() => {
    dispatch(
      getListMassageRequest({
        page,
        vacation: tab ? 1 : 0,
      }),
    );
  }, [dispatch, page, tab]);

  useEffect(() => {
    return () => {
      dispatch(clearMassages());
    };
  }, [dispatch]);

  const handlerTabClick = (type) => {
    dispatch(clearMassages());
    setPage(1);
    if (type === 1) {
      setTab(true);
    }

    if (type === 0) {
      setTab(false);
    }
  };

  return (
    <>
      <div className="tabs wall-wrap__tabs">
        <UIButton
          defaultClassName=""
          className={cn({ active: tab })}
          handler={() => handlerTabClick(1)}
        >
          {t('COMMON.VACATIONS')}
        </UIButton>

        <UIButton
          defaultClassName=""
          className={cn({ active: !tab })}
          handler={() => handlerTabClick(0)}
        >
          {t('WALL.CHAT')}
        </UIButton>

        <div className="select-wrap">
          <UISelect
            options={options}
            onChange={(value) => {
              setSelectId(value.id);

              return value.id;
            }}
          />
        </div>
      </div>

      <div className="tabs-wrap">
        <div id="wall-chat" className="wall-chat">
          <WallList
            massageList={selectId === 1 ? massageListReverse : massageList}
            loading={loading}
            deleteLoading={deleteLoading}
            updateLoading={updateLoading}
            createLoading={createLoading}
            meta={meta}
            page={page}
            setPage={setPage}
          />

          <span className="wall-chat__shadow"></span>

          <div className="wall-chat__new">
            <UITextarea
              placeholder={t('WALL.MASSAGE')}
              name="text"
              value={value}
              className="wall-chat__texarea"
              onChange={(value) => setValue(value.target.value)}
            />

            <div>
              <UICheckBox
                onChange={() => setVacation(!vacation)}
                id={1}
                checked={vacation}
                label={t('WALL.REPORT_VACATION')}
                classLabel="color_grey"
              />

              <UIButton
                handler={onSubmitHandler}
                defaultClassName=""
                className="wall-chatNew__btn"
              >
                <SentSVG />

                <span className="h6 fw600 color_darkBlue">
                  {t('COMMON.SEND')}
                </span>
              </UIButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wall;
