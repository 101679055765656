import { LayoutAccount } from '@layouts';
import Reports from '@modules/Reports';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

const ViewReports = () => {
  const { t } = useTranslation();

  return (
    <LayoutAccount
      title={t('REPORT.REPORTS')}
      className="reports page__reports"
    >
      <Routes>
        <Route path="/" element={<Reports />} />
      </Routes>
    </LayoutAccount>
  );
};

export default ViewReports;
