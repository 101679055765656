import {
  UPLOAD_FAILURE,
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS,
} from './Upload.constant';

export function uploadRequest(payload) {
  return {
    type: UPLOAD_REQUEST,
    payload,
  };
}

export function uploadSuccess(payload) {
  return {
    type: UPLOAD_SUCCESS,
    payload,
  };
}

export function uploadFailure() {
  return {
    type: UPLOAD_FAILURE,
  };
}
