import http from '@utils/http';
import qs from 'qs';

const basePath = '/api';

export const getProgect = async (body) => {
  const data = await http.get(`${basePath}/events`, {
    params: body,
    paramsSerializer: (...data) => {
      return qs.stringify(...data, {
        encodeValuesOnly: true,
        skipNulls: true,
      });
    },
  });

  return data.data;
};

export const getOneProgect = async (id) => {
  const data = await http.get(`${basePath}/events/${id}`);

  return data.data.data;
};

export const editProgect = async (id) => {
  const data = await http.get(`${basePath}/events/${id}/edit`);

  return data.data.data;
};

export const getEvents = async (body) => {
  const data = await http.get(`${basePath}/events`, {
    params: body,
    paramsSerializer: (...data) => {
      return qs.stringify(...data, {
        encodeValuesOnly: true,
        skipNulls: true,
      });
    },
  });

  return data.data;
};

export const getOrganizers = async () => {
  const data = await http.get(`${basePath}/organizers`);

  return data.data.data;
};

export const updateEditProgect = async (payload) => {
  const data = await http.put(`${basePath}/events/${payload.id}`, payload);

  return data.data.data;
};
