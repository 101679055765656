import i18n from '@i18n';
import { ProjectAddHttp, UsersHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { hideModal } from '@store/Modal';
import { put, takeLatest, fork, call } from 'redux-saga/effects';

import {
  createOfficesFailure,
  createOfficesSuccess,
  createUsersFailure,
  createUsersSuccess,
  getOfficesFailure,
  getOfficesSuccess,
  getUsersFailure,
  getUsersSuccess,
  setOfficesFailure,
  setOfficesSuccess,
  setUsersFailure,
  setUsersSuccess,
} from './Users.action';
import {
  CREATE_OFFICES_REQUEST,
  CREATE_USERS_REQUEST,
  GET_OFFICES_REQUEST,
  GET_USERS_REQUEST,
  SET_OFFICES_REQUEST,
  SET_USERS_REQUEST,
} from './Users.constant';

function* workerGetUsers({ payload }) {
  try {
    const data = yield call(UsersHttp.getUsers, payload);

    yield put(getUsersSuccess(data));
  } catch (error) {
    yield put(getUsersFailure());
    yield put(alertError());
  }
}
function* watchGetUsers() {
  yield takeLatest(GET_USERS_REQUEST, workerGetUsers);
}

function* workerGetOffices({ payload }) {
  try {
    const data = yield call(UsersHttp.getOffices, payload);

    yield put(getOfficesSuccess(data));
  } catch (error) {
    yield put(getOfficesFailure());
    yield put(alertError());
  }
}
function* watchGetOffices() {
  yield takeLatest(GET_OFFICES_REQUEST, workerGetOffices);
}

function* workerCreateUsers({ payload }) {
  try {
    const data = yield call(ProjectAddHttp.createStaff, payload);

    yield put(createUsersSuccess(data));
    yield put(
      alertSuccess({ message: i18n.t('ALERT.YOU_SUCCESSFULLY_ADDED_USER') }),
    );
    yield put(hideModal());
  } catch (error) {
    yield put(createUsersFailure());
    yield put(alertError(error));
  }
}
function* watchCreateUsers() {
  yield takeLatest(CREATE_USERS_REQUEST, workerCreateUsers);
}

function* workerCreateOffices({ payload }) {
  try {
    const data = yield call(UsersHttp.createOffices, payload);

    yield put(createOfficesSuccess(data));
    yield put(
      alertSuccess({
        message: i18n.t('ALERT.YOU_SUCCESSFULLY_ADDED_OFFICE'),
      }),
    );
    yield put(hideModal());
  } catch (error) {
    yield put(createOfficesFailure());
    yield put(alertError());
  }
}
function* watchCreateOffices() {
  yield takeLatest(CREATE_OFFICES_REQUEST, workerCreateOffices);
}
function* workerSetOffices({ payload }) {
  try {
    const data = yield call(UsersHttp.setOffices, payload);

    yield put(setOfficesSuccess(data));
    yield put(
      alertSuccess({
        message: i18n.t('ALERT.YOU_SUCCESSFULLY_CHANGED_OFFICE'),
      }),
    );
    yield put(hideModal());
  } catch (error) {
    yield put(setOfficesFailure());
    yield put(alertError());
  }
}
function* watchSetOffices() {
  yield takeLatest(SET_OFFICES_REQUEST, workerSetOffices);
}

function* workerSetUsers({ payload }) {
  try {
    const data = yield call(UsersHttp.setUsers, payload);

    yield put(setUsersSuccess(data));
    yield put(
      alertSuccess({
        message: i18n.t('ALERT.YOU_SUCCESSFULLY_CHANGED_USER'),
      }),
    );
    yield put(hideModal());
  } catch (error) {
    yield put(setUsersFailure());
    yield put(alertError());
  }
}
function* watchSetUsers() {
  yield takeLatest(SET_USERS_REQUEST, workerSetUsers);
}
export const usersWatchers = [
  fork(watchGetUsers),
  fork(watchSetUsers),
  fork(watchGetOffices),
  fork(watchCreateUsers),
  fork(watchCreateOffices),
  fork(watchSetOffices),
];
