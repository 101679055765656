import cn from 'classnames';

const LayoutAccount = ({ children, className, title, idNotTitle = false }) => {
  return (
    <>
      <div className={cn('content', className)}>
        {!idNotTitle ? <div className="content__title">{title}</div> : null}
        {children}
      </div>
    </>
  );
};

export default LayoutAccount;
