export const AddCircleSVG = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.041 14.8337V10.5003M10.041 10.5003V6.16699M10.041 10.5003H14.3743M10.041 10.5003H5.70764"
      stroke="#3A36DB"
      strokeLinecap="round"
    />
    <rect
      x="0.5"
      y="0.557617"
      width="19.082"
      height="19.8852"
      rx="9.54098"
      stroke="#3A36DB"
    />
  </svg>
);
