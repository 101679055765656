export const GET_ORGANIZERS_EVENT_REQUEST =
  '@progect_add/GET_ORGANIZERS_EVENT_REQUEST';
export const GET_ORGANIZERS_EVENT_SUCCESS =
  '@progect_add/GET_ORGANIZERS_EVENT_SUCCESS';
export const GET_ORGANIZERS_EVENT_FAILURE =
  '@progect_add/GET_ORGANIZERS_EVENT_FAILURE';

export const GET_USER_EVENT_REQUEST = '@progect_add/GET_USER_EVENT_REQUEST';
export const GET_USER_EVENT_SUCCESS = '@progect_add/GET_USER_EVENT_SUCCESS';
export const GET_USER_EVENT_FAILURE = '@progect_add/GET_USER_EVENT_FAILURE';

export const CREATE_CLIENT_REQUEST = '@progect_add/CREATE_CLIENT_REQUEST';
export const CREATE_CLIENT_SUCCESS = '@progect_add/CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAILURE = '@progect_add/CREATE_CLIENT_FAILURE';

export const CREATE_PROJECT_REQUEST = '@progect_add/CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = '@progect_add/CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = '@progect_add/CREATE_PROJECT_FAILURE';

export const CREATE_EMPLOYEE_REQUEST = '@progect_add/CREATE_EMPLOYEE_REQUEST';
export const CREATE_EMPLOYEE_SUCCESS = '@progect_add/CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAILURE = '@progect_add/CREATE_EMPLOYEE_FAILURE';

export const GET_OFFICE_EVENT_REQUEST = '@progect_add/GET_OFFICE_EVENT_REQUEST';
export const GET_OFFICE_EVENT_SUCCESS = '@progect_add/GET_OFFICE_EVENT_SUCCESS';
export const GET_OFFICE_EVENT_FAILURE = '@progect_add/GET_OFFICE_EVENT_FAILURE';

export const GET_LEGACY_EVENT_REQUEST = '@progect_add/GET_LEGACY_EVENT_REQUEST';
export const GET_LEGACY_EVENT_SUCCESS = '@progect_add/GET_LEGACY_EVENT_SUCCESS';
export const GET_LEGACY_EVENT_FAILURE = '@progect_add/GET_LEGACY_EVENT_FAILURE';

export const GET_TRANSPORT_LIST_REQUEST =
  '@progect_add/GET_TRANSPORT_LIST_REQUEST';
export const GET_TRANSPORT_LIST_SUCCESS =
  '@progect_add/GET_TRANSPORT_LIST_SUCCESS';
export const GET_TRANSPORT_LIST_FAILURE =
  '@progect_add/GET_TRANSPORT_LIST_FAILURE';
export const UPDATE_TRANSPORT_LIST = '@progect_add/UPDATE_TRANSPORT_LIST_';

export const GET_USER_LIST_REQUEST = '@progect_add/GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = '@progect_add/GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILURE = '@progect_add/GET_USER_LIST_FAILURE';

export const CREATE_RESPONSIBLE_REQUEST =
  '@progect_add/CREATE_RESPONSIBLE_REQUEST';
export const CREATE_RESPONSIBLE_SUCCESS =
  '@progect_add/CREATE_RESPONSIBLE_SUCCESS';
export const CREATE_RESPONSIBLE_FAILURE =
  '@progect_add/CREATE_RESPONSIBLE_FAILURE';

export const CREATE_TRANSPORT_REQUEST = '@progect_add/CREATE_TRANSPORT_REQUEST';
export const CREATE_TRANSPORT_SUCCESS = '@progect_add/CREATE_TRANSPORT_SUCCESS';
export const CREATE_TRANSPORT_FAILURE = '@progect_add/CREATE_TRANSPORT_FAILURE';

export const CREATE_STAFF_REQUEST = '@progect_add/CREATE_STAFF_REQUEST';
export const CREATE_STAFF_SUCCESS = '@progect_add/CREATE_STAFF_SUCCESS';
export const CREATE_STAFF_FAILURE = '@progect_add/CREATE_STAFF_FAILURE';

export const CREATE_MANAGER_REQUEST = '@progect_add/CREATE_MANAGER_REQUEST';
export const CREATE_MANAGER_SUCCESS = '@progect_add/CREATE_MANAGER_SUCCESS';
export const CREATE_MANAGER_FAILURE = '@progect_add/CREATE_MANAGER_FAILURE';

export const GET_EQUIPMENT_EVENT_REQUEST =
  '@progect_add/GET_EQUIPMENT_EVENT_REQUEST';
export const GET_EQUIPMENT_EVENT_SUCCESS =
  '@progect_add/GET_EQUIPMENT_EVENT_SUCCESS';
export const GET_EQUIPMENT_EVENT_FAILURE =
  '@progect_add/GET_EQUIPMENT_EVENT_FAILURE';

export const GET_WAREHOUSE_EVENT_REQUEST =
  '@progect_add/GET_WAREHOUSE_EVENT_REQUEST';
export const GET_WAREHOUSE_EVENT_SUCCESS =
  '@progect_add/GET_WAREHOUSE_EVENT_SUCCESS';
export const GET_WAREHOUSE_EVENT_FAILURE =
  '@progect_add/GET_WAREHOUSE_EVENT_FAILURE';

export const GET_LIST_BOOKING_REQUEST = '@progect_add/GET_LIST_BOOKING_REQUEST';
export const GET_LIST_BOOKING_SUCCESS = '@progect_add/GET_LIST_BOOKING_SUCCESS';
export const GET_LIST_BOOKING_FAILURE = '@progect_add/GET_LIST_BOOKING_FAILURE';

export const GET_LIST_EQUIPMENT_SELECT_REQUEST =
  '@progect_add/GET_LIST_EQUIPMENT_SELECT_REQUEST';
export const GET_LIST_EQUIPMENT_SELECT_SUCCESS =
  '@progect_add/GET_LIST_EQUIPMENT_SELECT_SUCCESS';
export const GET_LIST_EQUIPMENT_SELECT_FAILURE =
  '@progect_add/GET_LIST_EQUIPMENT_SELECT_FAILURE';

export const CREATE_EQUIPMENT_REQUEST = '@progect_add/CREATE_EQUIPMENT_REQUEST';
export const CREATE_EQUIPMENT_SUCCESS = '@progect_add/CREATE_EQUIPMENT_SUCCESS';
export const CREATE_EQUIPMENT_FAILURE = '@progect_add/CREATE_EQUIPMENT_FAILURE';

export const CREATE_PROJECT_FINISH_REQUEST =
  '@progect_add/CREATE_PROJECT_FINISH_REQUEST';
export const CREATE_PROJECT_FINISH_SUCCESS =
  '@progect_add/CREATE_PROJECT_FINISH_SUCCESS';
export const CREATE_PROJECT_FINISH_FAILURE =
  '@progect_add/CREATE_PROJECT_FINISH_FAILURE';

export const SET_STEP1_FORM = '@progect_add/SET_STEP1_FORM';
export const SET_STEP2_FORM = '@progect_add/SET_STEP2_FORM';
export const SET_STEP3_FORM = '@progect_add/SET_STEP3_FORM';

export const PROJECT_VALIDATION_REQUEST =
  '@progect_add/PROJECT_VALIDATION_REQUEST';
export const PROJECT_VALIDATION_SUCCESS =
  '@progect_add/PROJECT_VALIDATION_SUCCESS';
export const PROJECT_VALIDATION_FAILURE =
  '@progect_add/PROJECT_VALIDATION_FAILURE';

export const PROJECT_DELETE_REQUEST = '@progect_add/PROJECT_DELETE_REQUEST';
export const PROJECT_DELETE_SUCCESS = '@progect_add/PROJECT_DELETE_SUCCESS';
export const PROJECT_DELETE_FAILURE = '@progect_add/PROJECT_DELETE_FAILURE';

export const CLERA_STORE = '@progect_add/CLERA_STORE';

export const SET_STEP = '@progect_add/SET_STEP';
export const IS_LOADED_EQUIPMENTS_EVENT =
  '@progect_add/IS_LOADED_EQUIPMENTS_EVENT';

export const RESET_STORE = '@progect_add/RESET_STORE';

export const RESET_ACTION = '@progect_add/RESET_ACTION';
