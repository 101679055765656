export const MoveBackBigSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M8.47837 13.528V11.152H15.5404V13.528H8.47837Z"
          fill="#3A36DB"
        />
        <rect
          x="0.7"
          y="0.7"
          width="22.6"
          height="22.6"
          rx="11.3"
          stroke="#3A36DB"
          strokeWidth="1.4"
        />
      </g>
    </svg>
  );
};
