import cn from 'classnames';

import styles from './style.module.scss';

const UILoader = ({ primary, bgTransparent }) => (
  <div
    style={bgTransparent ? { backgroundColor: 'transparent' } : null}
    className={cn(styles['container-lds'], 'loader__item', {
      [styles.primary]: primary,
    })}
  >
    <div className={styles['lds-roller']}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default UILoader;
