/* eslint-disable indent */
/* eslint-disable prefer-template */
import { useEffect } from 'react';

import { ArrLeftLongSVG, ArrLeftSVG, PrinterSVG } from '@assets/img/icons';
import deleteIcon from '@assets/img/icons/delete.svg';
import edditIcon from '@assets/img/icons/edit.svg';
import { useAppDispatch } from '@store/';
import { hideModal, ModalType, showModal } from '@store/Modal';
import { editProjectRequest, getOneProjectRequest } from '@store/Project';
import { getOfficeEventRequest, projectDeleteRequest } from '@store/ProjectAdd';
import { UILoaderWrap, UIBack, UIButton, UITable } from '@ui';
import { FORMAT_DATE_TEXT_ALT } from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { LINK_PROJECTS, LINK_PROJECTS_EDIT } from '@utils/link';
import { Button } from '@utils/utility-property';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './style.scss';
import { useNavigate, useParams } from 'react-router-dom';

import ProjectDetailBooking from './Booking';

const ProjectDetail = () => {
  const { project, loading } = useSelector((state) => state.project);
  const { officeEvents, loading: loadingOffice } = useSelector(
    (state) => state.projectAdd,
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const handleDelete = () => {
    dispatch(
      showModal({
        modalType: ModalType.CONFIRM,
        modalProps: {
          handler: () => {
            dispatch(projectDeleteRequest(id));
            dispatch(hideModal());
            navigate(LINK_PROJECTS);
          },
          title: (
            <>
              {t('ALERT.DO_YOU_REALLY_WANT_TO_DELETE')}
              <span>{project?.name}</span>?
            </>
          ),
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(getOfficeEventRequest());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getOneProjectRequest(id));
    }
  }, [dispatch, id]);

  const header1 = [
    t('COMMON.OFFICE'),
    t('COMMON.PHONE'),
    t('PROJECT.RESPONSIBLE'),
    t('COMMON.BEGINNING'),
    t('COMMON.ENDING'),
    t('PROJECT.MANAGER_NAME'),
    t('COMMON.EMAIL'),
    t('PROJECT.CREATED'),
    t('COMMON.CUSTOMER'),
    t('PROJECT.PARTICIPATION_THIRD_CONTRACTORS'),
  ];

  const header2 = [
    t('PROJECT.EVENT_VENUE'),
    t('PROJECT.SVETOVIKOV_COMMENT'),
    t('PROJECT.GENERAL_COMMENT'),
  ];

  return (
    <UILoaderWrap loading={loading || loadingOffice}>
      <div className="projects-naw">
        <UIBack
          link={LINK_PROJECTS}
          className="projects-naw__title h4 color_blue"
          title={t('PROJECT.EVENTS_ALL')}
        >
          <ArrLeftSVG className="hide-mobile" />
          <ArrLeftLongSVG className="hide-desktop show-mobile" />
        </UIBack>

        {!project?.edit_disabled ? (
          <UIButton
            handler={handleDelete}
            type={Button.BUTTON}
            className="btn btn__red btn_eddit"
          >
            <span className="hide-mobile">{t('PROJECT.DELETE_PROJECT')}</span>

            <img
              className="hide-desktop show-mobile"
              src={deleteIcon}
              alt="icon"
            />
          </UIButton>
        ) : null}

        {!project?.edit_disabled && (
          <UIButton
            handler={() => dispatch(editProjectRequest(id))}
            type={Button.LINK}
            link={`${LINK_PROJECTS_EDIT}/${id}`}
            disabled={project?.edit_disabled}
            className="btn outline__blue btn_eddit"
          >
            <span className="hide-mobile">{t('COMMON.EDIT')}</span>

            <img
              className="hide-desktop show-mobile"
              src={edditIcon}
              alt="icon"
            />
          </UIButton>
        )}

        <UIButton
          handler={() =>
            dispatch(
              showModal({
                modalType: ModalType.PROJECT_PRINT,
              }),
            )
          }
          className="outline__blue projects-naw__print btn_eddit"
        >
          <PrinterSVG />
        </UIButton>
      </div>

      <div className="projects__title h3 fw600">
        <span>{project?.name || 'Loading...'}</span>

        <div>
          <div className="h6">
            <span className="fw600">{t('PROJECT.DATE_CREATION')}</span>

            <span className="color_grey">
              {DateHelper.toFormat(
                project?.created_at * 1000,
                FORMAT_DATE_TEXT_ALT,
              )}
            </span>
          </div>

          <div className="h6">
            <span className="fw600">{t('PROJECT.DATE_UPDATE')}</span>
            <span className="color_grey">
              {DateHelper.toFormat(
                project?.updated_at * 1000,
                FORMAT_DATE_TEXT_ALT,
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="projects-tables projectsTables__wrap">
        <div className="projects-tables__item">
          <div className="projects-table__title h4 fw600 color_blue">
            {t('PROJECT.GENERAL_INFORMATION')}
          </div>

          <UITable
            className="custom-table reversBg oneTdBold"
            vertical
            header={header1}
            content={[
              [
                officeEvents.find((item) => +item?.id === +project?.office)
                  ?.name,
                project?.place_phone,
                project?.legacy_id?.person,
                DateHelper.toFormat(
                  project?.date_start_event * 1000,
                  FORMAT_DATE_TEXT_ALT,
                ),
                DateHelper.toFormat(
                  project?.date_finish_event * 1000,
                  FORMAT_DATE_TEXT_ALT,
                ),
                project?.person_charge?.name,
                project?.person_charge?.email,
                project?.created_by?.name,
                project?.organizer?.name,
              ],
            ]}
          />
        </div>

        <div className="projects-tables__itemWrap">
          <div className="projects-tables__item">
            <div className="projects-table__title h4 fw600 color_blue">
              {t('PROJECT.INFO_ABOUT_SITE')}
            </div>

            <UITable
              className="custom-table reversBg oneTdBold"
              vertical
              header={header2}
              content={[
                [project?.place, project?.comment_light, project?.comment],
              ]}
            />
          </div>
        </div>
      </div>
      <ProjectDetailBooking project={project} />
    </UILoaderWrap>
  );
};

export default ProjectDetail;
