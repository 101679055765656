export const MoveToBigSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3377_9244)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 18.75C15 18.9489 14.921 19.1397 14.7803 19.2803C14.6397 19.421 14.4489 19.5 14.25 19.5H2.25C2.05109 19.5 1.86032 19.421 1.71967 19.2803C1.57902 19.1397 1.5 18.9489 1.5 18.75V5.25C1.5 5.05109 1.57902 4.86032 1.71967 4.71967C1.86032 4.57902 2.05109 4.5 2.25 4.5H14.25C14.4489 4.5 14.6397 4.57902 14.7803 4.71967C14.921 4.86032 15 5.05109 15 5.25V8.25C15 8.44891 15.079 8.63968 15.2197 8.78033C15.3603 8.92098 15.5511 9 15.75 9C15.9489 9 16.1397 8.92098 16.2803 8.78033C16.421 8.63968 16.5 8.44891 16.5 8.25V5.25C16.5 4.65326 16.2629 4.08097 15.841 3.65901C15.419 3.23705 14.8467 3 14.25 3H2.25C1.65326 3 1.08097 3.23705 0.65901 3.65901C0.237053 4.08097 0 4.65326 0 5.25L0 18.75C0 19.3467 0.237053 19.919 0.65901 20.341C1.08097 20.7629 1.65326 21 2.25 21H14.25C14.8467 21 15.419 20.7629 15.841 20.341C16.2629 19.919 16.5 19.3467 16.5 18.75V15.75C16.5 15.5511 16.421 15.3603 16.2803 15.2197C16.1397 15.079 15.9489 15 15.75 15C15.5511 15 15.3603 15.079 15.2197 15.2197C15.079 15.3603 15 15.5511 15 15.75V18.75Z"
          fill="#3A36DB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.781 12.531C23.8508 12.4613 23.9063 12.3785 23.9441 12.2874C23.9819 12.1963 24.0013 12.0986 24.0013 12C24.0013 11.9013 23.9819 11.8036 23.9441 11.7125C23.9063 11.6214 23.8508 11.5386 23.781 11.469L19.281 6.96897C19.1402 6.82814 18.9492 6.74902 18.75 6.74902C18.5508 6.74902 18.3598 6.82814 18.219 6.96897C18.0782 7.1098 17.9991 7.30081 17.9991 7.49997C17.9991 7.69913 18.0782 7.89014 18.219 8.03097L21.4395 11.25H8.25C8.05109 11.25 7.86032 11.329 7.71967 11.4696C7.57902 11.6103 7.5 11.8011 7.5 12C7.5 12.1989 7.57902 12.3896 7.71967 12.5303C7.86032 12.671 8.05109 12.75 8.25 12.75H21.4395L18.219 15.969C18.0782 16.1098 17.9991 16.3008 17.9991 16.5C17.9991 16.6991 18.0782 16.8901 18.219 17.031C18.3598 17.1718 18.5508 17.2509 18.75 17.2509C18.9492 17.2509 19.1402 17.1718 19.281 17.031L23.781 12.531Z"
          fill="#3A36DB"
        />
      </g>
      <defs>
        <clipPath id="clip0_3377_9244">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
