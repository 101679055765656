import { useEffect } from 'react';

import { useAppDispatch } from '@store/';
import { createUsersRequest } from '@store/Users';
import { UIButton, UIInput, UISelect } from '@ui';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const UsersFormUsers = ({ loading, refresh }) => {
  // const [isOpenColor, setIsOpenColor] = useState(false);

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const handlerSubmit = (values) =>
    dispatch(createUsersRequest({ ...values, role: values.role.id }));

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  useEffect(() => {
    if (refresh) {
      form.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <form onSubmit={form.handleSubmit} className="users-new">
      <div className="users-new__title fw600 h3">
        {t('USERS.ADD_USER')}
        {/* <UIColor
          setColor={(color) => form.setFieldValue('color', color)}
          color={form.values.color}
          setIsOpenColor={setIsOpenColor}
          isOpenColor={isOpenColor}
        /> */}
      </div>

      <div className="users-new__items">
        <UIInput
          label={t('COMMON.EMAIL')}
          name="email"
          placeholder={t('COMMON.EMAIL')}
          classLabel="fw600"
          value={form.values.email}
          onChange={form.handleChange}
          isValid={!(form.errors.email && form.touched.email)}
          validateText={form.errors.email}
        />

        <UIInput
          label={t('COMMON.NAME')}
          name="first_name"
          placeholder={t('COMMON.NAME')}
          classLabel="fw600"
          value={form.values.first_name}
          onChange={form.handleChange}
          isValid={!(form.errors.first_name && form.touched.first_name)}
          validateText={form.errors.first_name}
        />

        <UIInput
          label={t('COMMON.SURNAME')}
          name="last_name"
          placeholder={t('COMMON.SURNAME')}
          classLabel="fw600"
          value={form.values.last_name}
          onChange={form.handleChange}
          isValid={!(form.errors.last_name && form.touched.last_name)}
          validateText={form.errors.last_name}
        />

        <UIInput
          label={t('COMMON.PHONE')}
          name="phone"
          placeholder={t('COMMON.PHONE')}
          classLabel="fw600"
          value={form.values.phone}
          onChange={form.handleChange}
          isValid={!(form.errors.phone && form.touched.phone)}
          validateText={form.errors.phone}
        />

        <div className="select-column">
          <UISelect
            id="role"
            isSearchable={false}
            label={t('COMMON.ROLE')}
            classLabel="h6 fw600"
            options={[{ id: 'admin', name: t('COMMON.ADMINISTRATOR') }]}
            name="role"
            value={form.values.role}
            onChange={(value) => {
              form.setFieldValue('role', value || null);
            }}
            placeholder={t('COMMON.ROLE')}
            isValid={!(form.errors.role && form.touched.role)}
            validateText={form.errors.role}
          />
        </div>

        <UIInput
          label={t('COMMON.PASSWORD')}
          name="password"
          type="password"
          className="input input__icon"
          placeholder={t('COMMON.ENTER_PASSWORD')}
          classLabel="fw600"
          value={form.values.password}
          onChange={form.handleChange}
          isValid={!(form.errors.password && form.touched.password)}
          validateText={form.errors.password}
          isShowPassword
        />
        {/* <div className="radio-wrap users-radio">
          <span className="h6 fw700">Статус</span>
          <div>
            <UIRadio
              id="active"
              type="radio"
              name="active"
              checked={form.values?.active === '1'}
              className="custom-radio"
              value="1"
              onChange={form.handleChange}
            >
              Активен
            </UIRadio>
            <UIRadio
              id="inactive"
              type="radio"
              name="active"
              checked={form.values?.active === '0'}
              className="custom-radio"
              value="0"
              onChange={form.handleChange}
            >
              Неактивен
            </UIRadio>
          </div>
          <UIError
            isValid={!(form.errors.active && form.touched.active)}
            text={form.errors.active}
          />
        </div> */}
        <div className="users-btn">
          <UIButton
            loading={loading}
            className="btn outline__blue"
            type={Button.SUBMIT}
          >
            <span>{t('COMMON.SAVE')}</span>
          </UIButton>

          <UIButton
            handler={() => form.resetForm()}
            type={Button.BUTTON}
            className="btn__gray addEvent-remove__employee"
          >
            {t('COMMON.CANCEL')}
          </UIButton>
        </div>
      </div>
    </form>
  );
};

export default UsersFormUsers;
