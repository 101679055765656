import {
  ALERT_CLEAR,
  ALERT_SUCCESS,
  ALERT_ERROR,
  ALERT_ERROR_WRAP,
} from './Alert.constant';

export function alertSuccess({ message, isTitleDefault }) {
  return {
    type: ALERT_SUCCESS,
    payload: {
      message,
      isTitleDefault,
    },
  };
}

export function alertError(message) {
  return {
    type: ALERT_ERROR,
    payload: {
      message,
    },
  };
}

export function alertErrorWrap(message) {
  return {
    type: ALERT_ERROR_WRAP,
    payload: {
      message,
    },
  };
}

export function alertClear() {
  return {
    type: ALERT_CLEAR,
  };
}
