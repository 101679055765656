import { useEffect } from 'react';

import { useAppDispatch } from '@store/';
import { clearData, searchEquipmentsRequest } from '@store/Equipments';
import { UIInput, UISelect } from '@ui';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const EquipmentsFormFilter = ({
  loading,
  page,
  clearPage,
  categories,
  // packages,
  setEdit,
  groups,
}) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const handlerSubmit = (values) => {
    dispatch(clearData());
    if (page === 1) {
      dispatch(
        searchEquipmentsRequest({
          page: 1,
          filter: {
            ...values,
            category_id: values.category_id?.id,
            // package_id: values.package_id?.id,
            'group.id': values['group.id']?.id,
          },
        }),
      );
    }
    clearPage(1);
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  useEffect(() => {
    dispatch(
      searchEquipmentsRequest({
        page,
        filter: {
          ...form.values,
          category_id: form.values.category_id?.id,
          // package_id: form.values.package_id?.id,
          'group.id': form.values['group.id']?.id,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);

  return (
    <form onSubmit={form.handleSubmit} className="equipment-filter">
      <UIInput
        name="name"
        id="name"
        label={t('COMMON.TITLE')}
        classLabel="fw600"
        className="input input__row input-wrap"
        placeholder={t('COMMON.TITLE')}
        value={form.values.name}
        onChange={(e) => {
          form.setFieldValue('name', e.target.value);
          handlerSubmit({ ...form.values, name: e.target.value });
        }}
        isValid={!(form.errors.name && form.touched.name)}
        validateText={form.errors.name}
        onFocus={() => setEdit(null)}
      />

      <UISelect
        name="category_id"
        loading={loading}
        className="select-wrap custom-select-row"
        label={t('COMMON.CATEGORY')}
        classLabel="h6 fw600"
        options={categories}
        placeholder={t('COMMON.CATEGORY')}
        value={form.values.category_id}
        onChange={(value) => {
          form.setFieldValue('category_id', value);
          handlerSubmit({
            ...form.values,
            category_id: value,
          });
        }}
        onFocus={() => setEdit(null)}
      />

      {/* <UISelect
        name="package_id"
        loading={loading}
        className="select-wrap custom-select-row"
        label="Комплект"
        classLabel="h6 fw600"
        options={packages}
        placeholder="Комплект"
        value={form.values.package_id}
        onChange={(value) => {
          form.setFieldValue('package_id', value);
          handlerSubmit({
            ...form.values,
            package_id: value,
          });
        }}
        isValid={!(form.errors.package_id && form.touched.package_id)}
        validateText={form.errors.package_id}
      /> */}

      <UISelect
        name="group.id"
        loading={loading}
        className="select-wrap custom-select-row mb-0"
        label={t('COMMON.WAREHOUSE')}
        classLabel="h6 fw600"
        options={groups}
        placeholder={t('COMMON.WAREHOUSE')}
        value={form.values['group.id']}
        onChange={(value) => {
          form.setFieldValue(['group.id'], value);
          handlerSubmit({
            ...form.values,
            'group.id': value,
          });
        }}
        isValid={!(form.errors['group.id'] && form.touched['group.id'])}
        validateText={form.errors['group.id']}
        onFocus={() => setEdit(null)}
      />

      {/* <UICheckBox
        id="transfer_group"
        value={true}
        onClick={() =>
          handlerSubmit({
            ...form.values,
            transfer_group: !form.values.transfer_group,
          })
        }
        onChange={form.handleChange}
        checked={form.values.transfer_group}
        name="transfer_group"
        label="Перенесенное оборудование"
      /> */}
    </form>
  );
};

export default EquipmentsFormFilter;
