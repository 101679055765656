import { AuthHttp } from '@services/http';
import { alertError } from '@store/Alert';
import { put, takeLatest, fork, call } from 'redux-saga/effects';

import { authLoginSuccess, authLoginFailure } from './Auth.action';
import { AUTH_LOGIN_REQUEST } from './Auth.constant';

function* workerAuthLogin({ payload }) {
  try {
    const data = yield call(AuthHttp.login, payload);

    if (data?.access_token) {
      yield put(authLoginSuccess(data));
    }
  } catch (error) {
    yield put(authLoginFailure());
    yield put(alertError());
  }
}

function* watchAuthLogin() {
  yield takeLatest(AUTH_LOGIN_REQUEST, workerAuthLogin);
}

export const authWatchers = [fork(watchAuthLogin)];
