export default (() => {
  const overwriteArray = (arr, index, value, name) => {
    const newArr = [...arr];

    newArr[index] = {
      ...arr[index],
      [name]: value,
    };

    return newArr;
  };

  return {
    overwriteArray,
  };
})();
