import {
  GET_PROJECT_FAILURE,
  GET_PROJECT_REQUEST,
  GET_PROJECT_SUCCESS,
  GET_ONE_PROJECT_REQUEST,
  GET_ONE_PROJECT_FAILURE,
  GET_ONE_PROJECT_SUCCESS,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_FAILURE,
  EDIT_PROJECT_SUCCESS,
  UPDATE_EDIT_PROJECT_REQUEST,
  UPDATE_EDIT_PROJECT_SUCCESS,
  UPDATE_EDIT_PROJECT_FAILURE,
  RESET_STORE,
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  CLEAR_FILTER,
  GET_ORGANIZERS_REQUEST,
  GET_ORGANIZERS_SUCCESS,
  GET_ORGANIZERS_FAILURE,
} from './Project.constant';

export const clearFilter = () => {
  return {
    type: CLEAR_FILTER,
  };
};

export const getOrganizersRequest = (payload) => {
  return {
    type: GET_ORGANIZERS_REQUEST,
    payload,
  };
};
export const getOrganizersSuccess = (payload) => {
  return {
    type: GET_ORGANIZERS_SUCCESS,
    payload,
  };
};
export const getOrganizersFailure = () => {
  return {
    type: GET_ORGANIZERS_FAILURE,
  };
};

export const getEventsRequest = (payload) => {
  return {
    type: GET_EVENTS_REQUEST,
    payload,
  };
};
export const getEventsSuccess = (payload) => {
  return {
    type: GET_EVENTS_SUCCESS,
    payload,
  };
};
export const getEventsFailure = () => {
  return {
    type: GET_EVENTS_FAILURE,
  };
};

export const getProjectRequest = (payload) => {
  return {
    type: GET_PROJECT_REQUEST,
    payload,
  };
};
export const getProjectSuccess = (payload) => {
  return {
    type: GET_PROJECT_SUCCESS,
    payload,
  };
};
export const getProjectFailure = () => {
  return {
    type: GET_PROJECT_FAILURE,
  };
};

export const getOneProjectRequest = (payload) => {
  return {
    type: GET_ONE_PROJECT_REQUEST,
    payload,
  };
};

export const getOneProjectSuccess = (payload) => {
  return {
    type: GET_ONE_PROJECT_SUCCESS,
    payload,
  };
};

export const getOneProjectFailure = () => {
  return {
    type: GET_ONE_PROJECT_FAILURE,
  };
};

export const editProjectRequest = (payload) => {
  return {
    type: EDIT_PROJECT_REQUEST,
    payload,
  };
};

export const editProjectSuccess = (payload) => {
  return {
    type: EDIT_PROJECT_SUCCESS,
    payload,
  };
};

export const editProjectFailure = () => {
  return {
    type: EDIT_PROJECT_FAILURE,
  };
};

export const updateEditProjectRequest = (payload, step) => {
  return {
    type: UPDATE_EDIT_PROJECT_REQUEST,
    payload,
    step,
  };
};

export const updateEditProjectSuccess = (payload) => {
  return {
    type: UPDATE_EDIT_PROJECT_SUCCESS,
    payload,
  };
};

export const updateEditProjectFailure = () => {
  return {
    type: UPDATE_EDIT_PROJECT_FAILURE,
  };
};

export function resetStore() {
  return {
    type: RESET_STORE,
  };
}
