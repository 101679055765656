export const GET_PROJECT_REQUEST = '@project/GET_PROJECT_REQUEST';
export const GET_PROJECT_FAILURE = '@project/GET_PROJECT_FAILURE';
export const GET_PROJECT_SUCCESS = '@project/GET_PROJECT_SUCCESS';

export const GET_ONE_PROJECT_REQUEST = '@project/GET_ONE_PROJECT_REQUEST';
export const GET_ONE_PROJECT_FAILURE = '@project/GET_ONE_PROJECT_FAILURE';
export const GET_ONE_PROJECT_SUCCESS = '@project/GET_ONE_PROJECT_SUCCESS';

export const EDIT_PROJECT_REQUEST = '@project/EDIT_PROJECT_REQUEST';
export const EDIT_PROJECT_FAILURE = '@project/EDIT_PROJECT_FAILURE';
export const EDIT_PROJECT_SUCCESS = '@project/EDIT_PROJECT_SUCCESS';

export const GET_EVENTS_REQUEST = '@project/GET_EVENTS_REQUEST';
export const GET_EVENTS_FAILURE = '@project/GET_EVENTS_FAILURE';
export const GET_EVENTS_SUCCESS = '@project/GET_EVENTS_SUCCESS';

export const GET_ORGANIZERS_REQUEST = '@project/GET_ORGANIZERS_REQUEST';
export const GET_ORGANIZERS_FAILURE = '@project/GET_ORGANIZERS_FAILURE';
export const GET_ORGANIZERS_SUCCESS = '@project/GET_ORGANIZERS_SUCCESS';

export const UPDATE_EDIT_PROJECT_REQUEST =
  '@project/UPDATE_EDIT_PROJECT_REQUEST';
export const UPDATE_EDIT_PROJECT_FAILURE =
  '@project/UPDATE_EDIT_PROJECT_FAILURE';
export const UPDATE_EDIT_PROJECT_SUCCESS =
  '@project/UPDATE_EDIT_PROJECT_SUCCESS';

export const CLEAR_FILTER = '@project/CLEAR_FILTER';

export const RESET_STORE = '@project/RESET_STORE';
