import Language from '@modules/Language';
import { Provider } from 'react-redux';

import { AppProvider } from './context';
import store from './store';
import ViewRouter from './views';
import { WidgetAlert, WidgetModal } from './widgets';
import './lib/i18n/i18n';

function App() {
  return (
    <Provider store={store}>
      <AppProvider>
        <ViewRouter />
        <WidgetModal />
        <WidgetAlert />
        <Language />
      </AppProvider>
    </Provider>
  );
}

export default App;
