import i18n from '@i18n';
import * as yup from 'yup';

export const validationSchema = () =>
  yup.object({
    name: yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    transport: yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    phone: yup
      .string()
      .matches(/^(\+|)(\d[0-9]{7,16})$/, i18n.t('VALIDATION.PHONE.FORMAT'))
      .required(i18n.t('VALIDATION.REQUIRED')),
  });
