import ProjectAddMainEmployeeForm from './Form';

const ProjectAddMainEmployee = ({ handler }) => {
  return (
    <div className="addEvent-wrap__add ">
      <ProjectAddMainEmployeeForm handler={handler} />
    </div>
  );
};

export default ProjectAddMainEmployee;
