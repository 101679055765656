import { useMemo } from 'react';

import UITableRow from '../Row';

const UITableBody = ({
  classTd,
  header,
  vertical,
  content,
  onClickRows,
  tdSpan,
  onClickCol,
}) => {
  return useMemo(
    () =>
      (vertical ? header : content).map((item, index) => (
        <UITableRow
          {...(onClickRows && { onClickRows })}
          {...(onClickCol && { onClickCol })}
          tdSpan={tdSpan}
          key={index}
          index={index}
          columns={
            vertical ? [item, ...content.map((item2) => item2[index])] : item
          }
          classTd={classTd}
        />
      )),
    [vertical, header, content, onClickRows, onClickCol, tdSpan, classTd],
  );
};

export default UITableBody;
