import { useEffect, useState } from 'react';

import { useResponsive } from '@hooks';
import { useAppDispatch } from '@store/';
import {
  getOrganizersEventRequest,
  getUserEventRequest,
} from '@store/ProjectAdd/ProjectAdd.action';
import { useSelector } from 'react-redux';

import ProjectAddMainEmployee from './Employee';
import ProjectAddMainForm from './Form';
import './style.scss';
import ProjectAddMainClient from './Сlient';

const ProjectAddMain = () => {
  const [isShowClient, setShowClient] = useState(false);
  const [isShowEmployee, setShowEmployee] = useState(false);

  const dispatch = useAppDispatch();

  const { organizerEvents, userEvents } = useSelector(
    (state) => state.projectAdd,
  );

  const { isMobile, isDesktop, isIpad, isTablet } = useResponsive();

  useEffect(() => {
    if (!userEvents.length) {
      dispatch(getUserEventRequest());
    }
  }, [dispatch, userEvents.length]);

  useEffect(() => {
    if (!organizerEvents.length) {
      dispatch(getOrganizersEventRequest());
    }
  }, [dispatch, organizerEvents.length]);

  return (
    <>
      <div className="addEvent-wrap addEvent-wrap__step1">
        <ProjectAddMainForm
          setShowClient={setShowClient}
          setShowEmployee={setShowEmployee}
        />

        {isShowClient && !isDesktop && !isIpad && !isMobile && !isTablet && (
          <ProjectAddMainClient handler={() => setShowClient(false)} />
        )}
        {isShowEmployee && !isDesktop && !isIpad && !isMobile && !isTablet && (
          <ProjectAddMainEmployee handler={() => setShowEmployee(false)} />
        )}
      </div>
    </>
  );
};

export default ProjectAddMain;
