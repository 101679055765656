import { LayoutModal } from '@layouts';
import { LocalStorage } from '@services/storage';
import { UIButton } from '@ui';
import { LINK_PROJECTS_ADD } from '@utils/link';
import { Button } from '@utils/utility-property';
import { useTranslation } from 'react-i18next';

import './style.scss';

const Intersections = ({ data }) => {
  const { t } = useTranslation();

  return (
    <LayoutModal
      title={t('COMMON.INTERSECTIONS')}
      className="modal__intersections"
    >
      <div className="intersections-list">
        {data.map((item, index) => (
          <div key={index} className="intersections-item">
            <div className="intersections-title h5 fw600">
              {item?.equipment_name}
            </div>
            {item?.related?.map((item2, index) => (
              <div
                key={index}
                className="intersections-subItem color_darkBlue h6"
              >
                <UIButton
                  handler={() => {
                    LocalStorage.set('project_id', item2.id);
                  }}
                  type={Button.LINK}
                  target
                  link={LINK_PROJECTS_ADD}
                >
                  {item2.name}
                </UIButton>
                <span className="color_grey">
                  {item2.start} - {item2.finish}
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </LayoutModal>
  );
};

export default Intersections;
