import { CalendarHttp } from '@services/http';
import { alertError } from '@store/Alert';
import {
  FORMAT_DATE_QUERY,
  FORMAT_DATE_QUERY_FULL,
  UNIX_DAY,
} from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { put, takeLatest, fork, call } from 'redux-saga/effects';

import {
  getEventsFailure,
  getEventsSuccess,
  getEventsSuccessYear,
  getOfficesFailure,
  getOfficesSuccess,
  getOneEventFailure,
  getOneEventSuccess,
} from './Calendar.action';
import {
  GET_EVENTS_REQUEST,
  GET_OFFICES_REQUEST,
  GET_ONE_EVENT_REQUEST,
} from './Calendar.constant';

function* workerGetEvents({ payload }) {
  try {
    if (payload.type === 'year') {
      const data = yield call(CalendarHttp.getEventsYear, payload.query);

      yield put(
        getEventsSuccessYear({
          eventsInfo: data,
          events: data.map((item) => ({
            id: item.id,
            start: DateHelper.toFormat(
              item.date_start_event * 1000,
              FORMAT_DATE_QUERY_FULL,
            ),
            end: DateHelper.toFormat(
              item.date_finish_event * 1000,
              FORMAT_DATE_QUERY_FULL,
            ),
          })),
        }),
      );
    } else {
      const data = yield call(CalendarHttp.getEvents, payload.query);

      yield put(
        getEventsSuccess({
          eventsInfo: data,
          events: data.map((item) => {
            const takeDay =
              DateHelper.toFormat(
                item.date_finish_event * 1000,
                FORMAT_DATE_QUERY,
              ) ===
              DateHelper.toFormat(
                item.date_start_event * 1000,
                FORMAT_DATE_QUERY,
              );

            return {
              id: item.id,
              start: DateHelper.toFormat(
                item.date_start_event * 1000,
                FORMAT_DATE_QUERY_FULL,
              ),
              end: DateHelper.toFormat(
                item.date_finish_event * 1000 + (!takeDay ? UNIX_DAY : 0),
                FORMAT_DATE_QUERY_FULL,
              ),
              textColor: item?.office?.color || '#3768B4',
              backgroundColor: item?.office?.color
                ? `#${item?.office?.color?.slice(1)}51`
                : '#3768B451',
              borderColor: 'transparent',
              title: `${item?.name} - ${item.person_charge.last_name} ${item.person_charge.first_name} - ${item.organizer.name} - ${item.place}`,
              allDay: true,
              loading: (item?.first_loading || item?.date_start_event) * 1000,
              sortId: item?.office?.id,
              takeDay,
            };
          }),
        }),
      );
    }
  } catch (error) {
    yield put(getEventsFailure());
    yield put(alertError());
  }
}
function* watchGetEvents() {
  yield takeLatest(GET_EVENTS_REQUEST, workerGetEvents);
}

function* workerGetOneEvent({ payload }) {
  try {
    const data = yield call(CalendarHttp.getOneEvent, payload);

    yield put(getOneEventSuccess(data));
  } catch (error) {
    yield put(getOneEventFailure());
    yield put(alertError());
  }
}
function* watchOneGetEvent() {
  yield takeLatest(GET_ONE_EVENT_REQUEST, workerGetOneEvent);
}

function* workerGetOffices() {
  try {
    const data = yield call(CalendarHttp.getOffices);

    yield put(getOfficesSuccess(data));
  } catch (error) {
    yield put(getOfficesFailure());
    yield put(alertError());
  }
}
function* watchGetOffices() {
  yield takeLatest(GET_OFFICES_REQUEST, workerGetOffices);
}

export const calendarWatchers = [
  fork(watchGetEvents),
  fork(watchOneGetEvent),
  fork(watchGetOffices),
];
