import {
  DELETE_EVENT,
  GET_EVENTS_REQUEST,
  GET_EVENTS_FAILURE,
  GET_EVENTS_SUCCESS,
  GET_ONE_EVENT_REQUEST,
  GET_ONE_EVENT_FAILURE,
  GET_ONE_EVENT_SUCCESS,
  UPDATE_EVENT,
  ADD_ONE_EVENT_INFO,
  GET_OFFICES_REQUEST,
  GET_OFFICES_FAILURE,
  GET_OFFICES_SUCCESS,
  CHANGE_PARAM,
  GET_EVENTS_SUCCESS_YEAR,
} from './Calendar.constant';

export function addOneEventInfo(payload) {
  return {
    type: ADD_ONE_EVENT_INFO,
    payload,
  };
}

export function getOneEventRequest(payload) {
  return {
    type: GET_ONE_EVENT_REQUEST,
    payload,
  };
}
export function getOneEventFailure(payload) {
  return {
    type: GET_ONE_EVENT_FAILURE,
    payload,
  };
}
export function getOneEventSuccess(payload) {
  return {
    type: GET_ONE_EVENT_SUCCESS,
    payload,
  };
}

export function getOfficesRequest() {
  return {
    type: GET_OFFICES_REQUEST,
  };
}
export function getOfficesFailure(payload) {
  return {
    type: GET_OFFICES_FAILURE,
    payload,
  };
}
export function getOfficesSuccess(payload) {
  return {
    type: GET_OFFICES_SUCCESS,
    payload,
  };
}

export function getEventsRequest(payload) {
  return {
    type: GET_EVENTS_REQUEST,
    payload,
  };
}
export function getEventsFailure(payload) {
  return {
    type: GET_EVENTS_FAILURE,
    payload,
  };
}
export function getEventsSuccess(payload) {
  return {
    type: GET_EVENTS_SUCCESS,
    payload,
  };
}
export function getEventsSuccessYear(payload) {
  return {
    type: GET_EVENTS_SUCCESS_YEAR,
    payload,
  };
}

export function updateEvent(payload) {
  return {
    type: UPDATE_EVENT,
    payload,
  };
}

export function deleteEvent(payload) {
  return {
    type: DELETE_EVENT,
    payload,
  };
}

export function changeParam(payload) {
  return {
    type: CHANGE_PARAM,
    payload,
  };
}
