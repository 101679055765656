import alert from './alert.json';
import common from './common.json';
import menu from './menu.json';
import validation from './validation.json';

export default {
  ...common,
  ...validation,
  ...alert,
  ...menu,
};
