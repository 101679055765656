import { useRef, useEffect } from 'react';

const useHideModalOutside = (handler) => {
  const myRef = useRef(null);

  useEffect(() => {
    const listener = (e) => {
      if (!myRef.current || myRef.current.contains(e.target)) {
        return;
      }
      if (handler) {
        handler();
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [myRef, handler]);

  return myRef;
};

export default useHideModalOutside;
