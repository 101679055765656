import { useCallback, useEffect } from 'react';

import { hideModal, ModalType } from '@store/Modal';
import { useDispatch, useSelector } from 'react-redux';

import WidgetsModalAddClient from './AddClient';
import WidgetModalAddEmployee from './AddEmployee';
import WidgetsModalAddEquipment from './AddEquipment';
import WidgetsModalAddResponsible from './AddResponsible';
import WidgetsModalAddStaff from './AddStaff';
import WidgetsModalAddTransport from './AddTransport';
import WidgetsModalCalendarEvent from './CalendarEvent';
import WidgetsModalDeleteBooking from './DeleteBooking';
import WidgetsModalIntersections from './Intersections';
import WidgetsModalMobileDate from './MobileDate';
import WidgetsModalMobileFormCategories from './MobileFormCategories';
import WidgetsModalMobileFormGroups from './MobileFormGroups';
import WidgetsModalMobileFormPackages from './MobileFormPackages';
import WidgetsModalMobileOffices from './MobileOffices';
import WidgetsModalMobileUsers from './MobileUsers';
import WidgetsModalProjectPrint from './ProjectPrint';
import WidgetsModalSettingsOffices from './SettingsOffices';
import WidgetsModalSettingsUsers from './SettingsUsers';
import WidgetsModalTransferAdd from './TransferAdd';
import WidgetsModalTransferEquipments from './TransferEquipments';
import WidgetsModalTransferSettings from './TransferSettings';
import WidgetsModalConfirm from './Сonfirm';

const WidgetModal = () => {
  const { modalType, modalProps } = useSelector((state) => state.modal);

  const dispatch = useDispatch();

  const onModalClose = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('popstate', onModalClose);

    return () => {
      window.removeEventListener('popstate', onModalClose);
    };
  }, [onModalClose]);

  const renderModal = () => {
    switch (modalType) {
      case ModalType.CALENDAR_EVENT:
        return <WidgetsModalCalendarEvent {...modalProps} />;
      case ModalType.ADD_CLIENT:
        return <WidgetsModalAddClient {...modalProps} />;
      case ModalType.ADD_EMPLOYEE:
        return <WidgetModalAddEmployee {...modalProps} />;
      case ModalType.ADD_RESPONSIBLE:
        return <WidgetsModalAddResponsible {...modalProps} />;
      case ModalType.ADD_STAFF:
        return <WidgetsModalAddStaff {...modalProps} />;
      case ModalType.ADD_TRANSPORT:
        return <WidgetsModalAddTransport {...modalProps} />;
      case ModalType.DELETE_BOOKING:
        return <WidgetsModalDeleteBooking {...modalProps} />;
      case ModalType.SETTINGS_USERS:
        return <WidgetsModalSettingsUsers {...modalProps} />;
      case ModalType.SETTINGS_OFFICES:
        return <WidgetsModalSettingsOffices {...modalProps} />;
      case ModalType.MOBILE_USERS:
        return <WidgetsModalMobileUsers />;
      case ModalType.MOBILE_OFFICES:
        return <WidgetsModalMobileOffices />;
      case ModalType.MOBILE_FORM_CATEGORIES:
        return <WidgetsModalMobileFormCategories {...modalProps} />;
      case ModalType.MOBILE_FORM_PACKAGES:
        return <WidgetsModalMobileFormPackages {...modalProps} />;
      case ModalType.MOBILE_FORM_GROUPS:
        return <WidgetsModalMobileFormGroups {...modalProps} />;
      case ModalType.ADD_EQUIPMENT:
        return <WidgetsModalAddEquipment {...modalProps} />;
      case ModalType.TRANSFER_EQUIPMENT:
        return <WidgetsModalTransferEquipments {...modalProps} />;
      case ModalType.INTERSECTIONS:
        return <WidgetsModalIntersections {...modalProps} />;
      case ModalType.PROJECT_PRINT:
        return <WidgetsModalProjectPrint {...modalProps} />;
      case ModalType.TRANSFER__SETTINGS:
        return <WidgetsModalTransferSettings {...modalProps} />;
      case ModalType.TRANSFER__ADD:
        return <WidgetsModalTransferAdd {...modalProps} />;
      case ModalType.MOBILE__DATE:
        return <WidgetsModalMobileDate {...modalProps} />;
      case ModalType.CONFIRM:
        return <WidgetsModalConfirm {...modalProps} />;

      default:
        return null;
    }
  };

  return renderModal();
};

export default WidgetModal;
