const UIRadio = (props) => {
  const {
    id,
    name,
    value,
    checked,
    readOnly = true,
    className = 'custom-radio',
    onChange,
    children,
  } = props;

  const handlerChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className={className}>
      <input
        name={name}
        readOnly={readOnly}
        checked={checked}
        value={value}
        id={id}
        type="radio"
        onChange={handlerChange}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
};

export default UIRadio;
