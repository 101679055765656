/* eslint-disable camelcase */
import { useEffect, useState } from 'react';

import { LayoutModal } from '@layouts';
import { useAppDispatch } from '@store/';
import { hideModal } from '@store/Modal';
import { validationTransferRequest } from '@store/Transfer';
import { UIButton, UIInputNumber, UISelect } from '@ui';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './style.scss';
import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const WidgetsModalTransferAdd = ({
  groups,
  date_start,
  date_finish,
  group_at,
  count,
  officeEvents,
  data,
}) => {
  const { loading, groups: groupsAll } = useSelector(
    (state) => state.categories,
  );
  const { officeEvents: officeEventsAll, loading: officeLoading } = useSelector(
    (state) => state.projectAdd,
  );
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const handlerSubmit = ({
    category_id,
    group_for,
    isTransfered,
    office,
    ...val
  }) => {
    dispatch(
      validationTransferRequest({
        ...val,
        group_for: group_for.id,
        equipment_id: data.id,
        date_start: `${date_start} 00:00:00`,
        date_finish: `${date_finish} 00:00:00`,
        group_at,
        count: isTransfered || 1,
        type: 'CREATE',
      }),
    );
    dispatch(hideModal());
  };

  useEffect(() => {
    if (groups || officeEvents) {
      setInitialValues({
        ...INITIAL_VALUES,
        ...(groups && { group_for: groups }),
        ...(officeEvents && { office_id: officeEvents.id }),
      });
    }
  }, [groups, officeEvents]);

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  return (
    <LayoutModal title={t('TRANSFER.TRANSFER')} className="modal__transferAdd">
      <form onSubmit={form.handleSubmit}>
        <UISelect
          label={t('COMMON.OFFICE')}
          className="select-wrap"
          options={officeEventsAll}
          name="office_id"
          loading={officeLoading}
          classLabel="h6 fw600"
          placeholder={t('COMMON.OFFICE')}
          value={officeEventsAll?.find((i) => i.id === form.values.office_id)}
          onChange={(value) => {
            form.setFieldValue('office_id', value?.id || '');
          }}
          isValid={!(form.errors.office_id && form.touched.office_id)}
          validateText={form.errors.office_id}
        />
        <UISelect
          name="group_for"
          loading={loading}
          className="select-wrap"
          label={t('COMMON.TO_WAREHOUSE')}
          classLabel="h6 fw600"
          options={groupsAll.filter((i) => i.id !== group_at)}
          placeholder={t('COMMON.WAREHOUSE')}
          value={form.values.group_for}
          onChange={(value) => form.setFieldValue('group_for', value || null)}
          isValid={!(form.errors.group_for && form.touched.group_for)}
          validateText={form.errors.group_for}
        />
        <UIInputNumber
          min={1}
          max={count}
          name="isTransfered"
          label={t('COMMON.QUANTITY_MIN')}
          classLabel="h6 fw600"
          value={form.values?.isTransfered}
          onChange={(value) => form.setFieldValue('isTransfered', value)}
          isValid={!(form.errors.isTransfered && form.touched.isTransfered)}
          validateText={form.errors.isTransfered}
        />
        <div className="added-btn">
          <UIButton
            loading={loading}
            className="btn outline__blue"
            type={Button.SUBMIT}
          >
            <span>{t('COMMON.SAVE')}</span>
          </UIButton>
          <UIButton
            handler={() => dispatch(hideModal())}
            className="btn btn__gray"
          >
            {t('COMMON.CANCEL')}
          </UIButton>
        </div>
      </form>
    </LayoutModal>
  );
};

export default WidgetsModalTransferAdd;
