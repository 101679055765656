import { useRef } from 'react';

import { useHidePopupOutside } from '@hooks';
import i18n from '@i18n';
import { colors } from '@utils/utility-property';
import cn from 'classnames';
import { SketchPicker } from 'react-color';

import './style.scss';

const UIColor = ({
  className,
  setColor,
  color = colors.green,
  setIsOpenColor,
  isOpenColor,
  title = i18n.t('COMMON.СOLOR'),
}) => {
  const colorRef = useRef();

  useHidePopupOutside(colorRef, () => setIsOpenColor(false));

  const handlerChange = (color) => {
    setColor(color.hex);
  };

  return (
    <div
      className={cn('h6 wrapper-color', className)}
      onClick={() => setIsOpenColor(true)}
      ref={colorRef}
    >
      {title}
      <span
        style={{ background: color || '#00ba34' }}
        className="span__color"
      ></span>
      {isOpenColor && (
        <div className="picker-wrapper">
          <SketchPicker
            onChange={handlerChange}
            presetColors={Object.values(colors)}
            color={color}
          />
        </div>
      )}
    </div>
  );
};

export default UIColor;
