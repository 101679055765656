import i18n from '@i18n';
import * as yup from 'yup';

export const validationSchema = () =>
  yup.object({
    email: yup
      .string()
      .email(i18n.t('VALIDATION.EMAIL.VALID'))
      .required(i18n.t('VALIDATION.EMAIL.REQUIRED')),
    name: yup.string().required(i18n.t('VALIDATION.FULL_NAME.REQUIRED')),
    phone: yup
      .string()
      .matches(/^(\+|)(\d[0-9]{7,16})$/, i18n.t('VALIDATION.PHONE.FORMAT'))
      .required(i18n.t('VALIDATION.PHONE.REQUIRED')),
  });
