import { combineReducers } from 'redux';

import { AlertReducer } from './Alert';
import { AuthReducer } from './Auth';
import { CalendarReducer } from './Calendar';
import { CategoriesReducer } from './Categories';
import { EquipmentsReducer } from './Equipments';
import { EventsReducer } from './Events';
import { ModalReducer } from './Modal';
import { ProfileReducer } from './Profile';
import { ProjectReducer } from './Project';
import { ProjectAddReducer } from './ProjectAdd';
import { ReportsReducer } from './Reports';
import { TransferReducer } from './Transfer';
import { UploadReducer } from './Upload';
import { UsersReducer } from './Users';
import { WallReducer } from './Wall';

export const rootReducer = combineReducers({
  alert: AlertReducer,
  auth: AuthReducer,
  calendar: CalendarReducer,
  projectAdd: ProjectAddReducer,
  users: UsersReducer,
  reports: ReportsReducer,
  modal: ModalReducer,
  events: EventsReducer,
  upload: UploadReducer,
  wall: WallReducer,
  categories: CategoriesReducer,
  project: ProjectReducer,
  equipments: EquipmentsReducer,
  profile: ProfileReducer,
  transfer: TransferReducer,
});
