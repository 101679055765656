import i18n from '@i18n';
import { EquipmentsHttp } from '@services/http';
import { alertError, alertErrorWrap, alertSuccess } from '@store/Alert';
import { put, takeLatest, fork, call } from 'redux-saga/effects';

import {
  addEquipmentsFailure,
  addEquipmentsSuccess,
  deleteEquipmentsFailure,
  deleteEquipmentsSuccess,
  searchEquipmentsFailure,
  searchEquipmentsSuccess,
  setEquipmentsFailure,
  setEquipmentsSuccess,
  transferEquipmentsFailure,
  transferEquipmentsSuccess,
} from './Equipments.action';
import {
  ADD_EQUIPMENTS_REQUEST,
  DELETE_EQUIPMENTS_REQUEST,
  SEARCH_EQUIPMENTS_REQUEST,
  SET_EQUIPMENTS_REQUEST,
  TRANSFER_EQUIPMENTS_REQUEST,
} from './Equipments.constant';

function* workerSearchEquipments({ payload }) {
  try {
    const data = yield call(EquipmentsHttp.search, payload);

    yield put(searchEquipmentsSuccess(data));
    yield !data.data.length &&
      put(alertErrorWrap(i18n.t('ALERT.EQUIPMENT_NOT_FOUND')));
  } catch (error) {
    yield put(searchEquipmentsFailure());
    yield put(alertError());
  }
}
function* watchSearchEquipments() {
  yield takeLatest(SEARCH_EQUIPMENTS_REQUEST, workerSearchEquipments);
}

function* workerSetEquipments({ payload }) {
  try {
    const data = yield call(EquipmentsHttp.setEquipments, payload);

    yield put(setEquipmentsSuccess(data));
    yield put(alertSuccess({ message: i18n.t('ALERT.EQUIPMENT_CHANGED') }));
  } catch (error) {
    yield put(setEquipmentsFailure());
    yield put(alertError());
  }
}
function* watchSetEquipments() {
  yield takeLatest(SET_EQUIPMENTS_REQUEST, workerSetEquipments);
}

function* workerAddEquipments({ payload }) {
  try {
    const data = yield call(EquipmentsHttp.addEquipments, payload);

    yield put(addEquipmentsSuccess(data));
    yield put(alertSuccess({ message: i18n.t('ALERT.EQUIPMENT_ADDED') }));
  } catch (error) {
    yield put(addEquipmentsFailure());
    yield put(alertError());
  }
}
function* watchAddEquipments() {
  yield takeLatest(ADD_EQUIPMENTS_REQUEST, workerAddEquipments);
}

function* workerDeleteEquipments({ payload }) {
  try {
    const data = yield call(EquipmentsHttp.deleteEquipments, payload);

    yield put(deleteEquipmentsSuccess(data));
    yield put(alertSuccess({ message: i18n.t('ALERT.EQUIPMENT_DELETE') }));
  } catch (error) {
    yield put(deleteEquipmentsFailure());
    yield put(alertError());
  }
}
function* watchDeleteEquipments() {
  yield takeLatest(DELETE_EQUIPMENTS_REQUEST, workerDeleteEquipments);
}

function* workerTransferEquipments({ payload }) {
  try {
    const data = yield call(EquipmentsHttp.transferEquipments, payload);

    yield put(transferEquipmentsSuccess(data));
    yield put(alertSuccess({ message: i18n.t('ALERT.EQUIPMENT_MOVED') }));
  } catch (error) {
    yield put(transferEquipmentsFailure());
    yield put(alertError(error));
  }
}
function* watchTransferEquipments() {
  yield takeLatest(TRANSFER_EQUIPMENTS_REQUEST, workerTransferEquipments);
}

export const equipmentsWatchers = [
  fork(watchSetEquipments),
  fork(watchAddEquipments),
  fork(watchDeleteEquipments),
  fork(watchSearchEquipments),
  fork(watchTransferEquipments),
];
