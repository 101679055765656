export const PlusSVG = () => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="8" y="1.5" width="2" height="18" fill="#3A36DB" />
    <rect
      x="17"
      y="9.5"
      width="2"
      height="16"
      transform="rotate(90 17 9.5)"
      fill="#3A36DB"
    />
  </svg>
);
