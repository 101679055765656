import { useState } from 'react';

import { useAuth } from '@hooks';
import { LINK_LOGIN } from '@utils';
import { WidgetHeader, WidgetMenu } from '@widgets';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const [isMenuMobile, setIsMenuMobile] = useState(false);

  const { accessToken } = useAuth();

  if (accessToken) {
    return (
      <main>
        <WidgetHeader setIsMenu={setIsMenuMobile} isMenuMobile={isMenuMobile} />
        <WidgetMenu
          isMenuMobile={isMenuMobile}
          setIsMenuMobile={setIsMenuMobile}
        />
        {children}
        {/* <WidgetFooter /> */}
      </main>
    );
  }

  return <Navigate to={{ pathname: LINK_LOGIN }} />;
};

export default PrivateRoute;
