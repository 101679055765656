import {
  GET_MASSAGES_LIST_REQUEST,
  GET_MASSAGES_LIST_SUCCESS,
  GET_MASSAGES_LIST_FAILURE,
  CREATE_MASSAGE_REQUEST,
  CREATE_MASSAGE_SUCCESS,
  CREATE_MASSAGE_FAILURE,
  UPDATE_MASSAGE_REQUEST,
  UPDATE_MASSAGE_SUCCESS,
  UPDATE_MASSAGE_FAILURE,
  DELETE_MASSAGE_REQUEST,
  DELETE_MASSAGE_SUCCESS,
  DELETE_MASSAGE_FAILURE,
  CLEAR_MASSAGES,
} from './Wall.constant';

export function getListMassageRequest(payload) {
  return {
    type: GET_MASSAGES_LIST_REQUEST,
    payload,
  };
}

export function getListMassageSuccess(payload) {
  return {
    type: GET_MASSAGES_LIST_SUCCESS,
    payload,
  };
}

export function getListMassageFailure() {
  return {
    type: GET_MASSAGES_LIST_FAILURE,
  };
}

export function createMassageRequest(payload) {
  return {
    type: CREATE_MASSAGE_REQUEST,
    payload,
  };
}

export function createMassageSuccess(payload) {
  return {
    type: CREATE_MASSAGE_SUCCESS,
    payload,
  };
}

export function createMassageFailure() {
  return {
    type: CREATE_MASSAGE_FAILURE,
  };
}

export function updateMassageRequest(payload) {
  return {
    type: UPDATE_MASSAGE_REQUEST,
    payload,
  };
}

export function updateMassageSuccess(payload) {
  return {
    type: UPDATE_MASSAGE_SUCCESS,
    payload,
  };
}

export function updateMassageFailure() {
  return {
    type: UPDATE_MASSAGE_FAILURE,
  };
}

export function deleteMassageRequest(payload) {
  return {
    type: DELETE_MASSAGE_REQUEST,
    payload,
  };
}

export function deleteMassageSuccess(payload) {
  return {
    type: DELETE_MASSAGE_SUCCESS,
    payload,
  };
}

export function deleteMassageFailure() {
  return {
    type: DELETE_MASSAGE_FAILURE,
  };
}

export function clearMassages() {
  return {
    type: CLEAR_MASSAGES,
  };
}
