import { useEffect } from 'react';

import { LocalStorage } from '@services/storage';
import { useAppDispatch } from '@store/';
import { editProjectRequest, resetStore } from '@store/Project';
import { resetStore as projectAddResetStore, setStep } from '@store/ProjectAdd';
import { UILoaderWrap } from '@ui';
import { LINK_PROJECTS_EDIT } from '@utils/link';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import ProjectAddEquipment from './Equipment';
import ProjectAddInfo from './Info';
import ProjectAddMain from './Main';
import ProjectAddManager from './Manager';
// import UIProjectAddStep from './ui/Step';

const ProjectAdd = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { id } = useParams();

  const { step, projectData } = useSelector((state) => state.projectAdd);
  const { editData, loading } = useSelector((state) => state.project);

  const localId = LocalStorage.get('project_id');

  useEffect(() => {
    if (localId && +LocalStorage.get('project_step') === 0) {
      dispatch(editProjectRequest(localId));
      LocalStorage.removeItem('project_id');
    }
  }, [dispatch, localId]);

  useEffect(() => {
    if (projectData) {
      LocalStorage.set('project_id', projectData.id);
    }
  }, [projectData]);

  useEffect(() => {
    if (pathname.includes(LINK_PROJECTS_EDIT) && !editData) {
      dispatch(editProjectRequest(id));
    } else if (!editData && LocalStorage.get('project_id')) {
      dispatch(editProjectRequest(+LocalStorage.get('project_id')));
    }
  }, [dispatch, editData, id, pathname]);

  const renderBlockHTML = () => {
    switch (step) {
      case 1:
        return <ProjectAddManager />;
      case 2:
        return <ProjectAddEquipment />;
      case 3:
        return <ProjectAddInfo />;
      default:
        return <ProjectAddMain />;
    }
  };

  useEffect(() => {
    dispatch(setStep(+LocalStorage.get('project_step')));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetStore());
      dispatch(projectAddResetStore());
      dispatch(setStep(0));
      LocalStorage.set('project_step', 0);
    };
  }, [dispatch]);

  return (
    <>
      {/* {step !== 3 && <UIProjectAddStep />} */}
      <UILoaderWrap loading={loading}>{renderBlockHTML()}</UILoaderWrap>
    </>
  );
};

export default ProjectAdd;
