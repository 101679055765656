import { useAuth } from '@hooks';
import { AuthLogin } from '@modules/Auth';
import { LINK_CALENDARS } from '@utils/link';
import { Navigate, Route, Routes } from 'react-router-dom';

const ViewLogin = () => {
  const { accessToken } = useAuth();

  if (accessToken) {
    return <Navigate to={{ pathname: LINK_CALENDARS }} />;
  }

  return (
    <Routes>
      <Route path="/" element={<AuthLogin />} />
    </Routes>
  );
};

export default ViewLogin;
