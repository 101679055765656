import cn from 'classnames';

const { useMemo } = require('react');

const UITableRow = ({
  classTd,
  columns,
  onClickRows,
  onClickCol,
  tdSpan,
  index,
}) => {
  return useMemo(
    () => (
      <tr
        className={cn({ cursor_pointer: onClickRows })}
        {...(onClickRows && { onClick: () => onClickRows(columns, index) })}
      >
        {columns?.map((element, index) => (
          <td
            className={cn(classTd?.[index], {
              cursor_pointer: onClickRows,
              tdSpan: tdSpan === index,
            })}
            {...(onClickCol && { onClick: () => onClickCol(element, index) })}
            key={index}
          >
            {tdSpan === index ? (
              <span>{element || '-'}</span>
            ) : (
              <>{element || '-'}</>
            )}
          </td>
        ))}
      </tr>
    ),
    [classTd, columns, index, onClickCol, onClickRows, tdSpan],
  );
};

export default UITableRow;
