/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';

import { useResponsive } from '@hooks';
import { useAppDispatch } from '@store/';
import { getCategoriesRequest, getGroupsRequest } from '@store/Categories';
import { getOfficeEventRequest } from '@store/ProjectAdd';
import { UIButton } from '@ui';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TransferFormAdd from './FormAdd';
import TransferFormList from './FormList';

import './styles.scss';

const Transfer = () => {
  const dispatch = useAppDispatch();
  const { isIpad, isTablet, isMobile } = useResponsive();
  const { t } = useTranslation();

  const { officeEvents, loading: officeLoading } = useSelector(
    (state) => state.projectAdd,
  );

  const { loading, categories, groups } = useSelector(
    (state) => state.categories,
  );

  const {
    dataAll,
    lastPageAll,
    loadingFilterAll,
    data,
    lastPage,
    loadingNew,
    loadingFilter,
    loadingChange,
  } = useSelector((state) => state.transfer);

  const [tab, setTab] = useState(1);

  useEffect(() => {
    dispatch(getOfficeEventRequest());
    dispatch(getCategoriesRequest());
    dispatch(getGroupsRequest());
  }, [dispatch]);

  const renderContent = (tab) => {
    switch (+tab) {
      case 1:
      case 3:
        return (
          <div className="transfer-wrap">
            <TransferFormList
              tab={tab}
              officeEvents={officeEvents}
              officeLoading={officeLoading}
              loading={loading}
              categories={categories}
              groups={groups}
              data={data}
              lastPage={lastPage}
              loadingFilter={loadingFilter}
              isIpad={isIpad}
              isTabletMobile={isTablet || isMobile}
              loadingChange={loadingChange}
              setTab={setTab}
            />
          </div>
        );
      case 2:
        return (
          <div className="transfer-addWrap">
            <TransferFormAdd
              loadingNew={loadingNew}
              data={dataAll}
              officeEvents={officeEvents}
              officeLoading={officeLoading}
              loading={loading}
              categories={categories}
              groups={groups}
              lastPage={lastPageAll}
              loadingFilter={loadingFilterAll}
            />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div className="transfer-tabs tabs">
        <UIButton
          defaultClassName=""
          handler={() => setTab(1)}
          className={cn({ active: tab === 1 })}
        >
          {t('TRANSFER.LIST_TRANSFERS')}
        </UIButton>

        <UIButton
          defaultClassName=""
          handler={() => setTab(2)}
          className={cn({ active: tab === 2 })}
        >
          {t('TRANSFER.ADD_TRANSFERS')}
        </UIButton>

        <UIButton
          defaultClassName=""
          handler={() => setTab(3)}
          className={cn({ active: tab === 3 })}
        >
          {t('TRANSFER.ARCHIVE_TRANSFERS')}
        </UIButton>
      </div>

      {renderContent(tab)}
    </>
  );
};

export default Transfer;
