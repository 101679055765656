// import { colors } from '@utils/utility-property';

export const INITIAL_VALUES = {
  email: '',
  first_name: '',
  last_name: '',
  phone: '',
  role: null,
  password: '',
  // color: colors.green,
  active: '1',
};
