import { LayoutAccount } from '@layouts';
import Categories from '@modules/Categories';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

const ViewCategories = () => {
  const { t } = useTranslation();

  return (
    <LayoutAccount
      title={t('CATEGORIES.CATEGORIES')}
      className="page__categories categories"
    >
      <Routes>
        <Route path="/" element={<Categories />} />
      </Routes>
    </LayoutAccount>
  );
};

export default ViewCategories;
