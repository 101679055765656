export const GET_CATEGORIES_REQUEST = '@categories/GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_FAILURE = '@categories/GET_CATEGORIES_FAILURE';
export const GET_CATEGORIES_SUCCESS = '@categories/GET_CATEGORIES_SUCCESS';

export const ADD_CATEGORIES_REQUEST = '@categories/ADD_CATEGORIES_REQUEST';
export const ADD_CATEGORIES_FAILURE = '@categories/ADD_CATEGORIES_FAILURE';
export const ADD_CATEGORIES_SUCCESS = '@categories/ADD_CATEGORIES_SUCCESS';

export const SET_CATEGORIES_REQUEST = '@categories/SET_CATEGORIES_REQUEST';
export const SET_CATEGORIES_FAILURE = '@categories/SET_CATEGORIES_FAILURE';
export const SET_CATEGORIES_SUCCESS = '@categories/SET_CATEGORIES_SUCCESS';

export const DELETE_CATEGORIES_REQUEST =
  '@categories/DELETE_CATEGORIES_REQUEST';
export const DELETE_CATEGORIES_FAILURE =
  '@categories/DELETE_CATEGORIES_FAILURE';
export const DELETE_CATEGORIES_SUCCESS =
  '@categories/DELETE_CATEGORIES_SUCCESS';

export const GET_PACKAGES_REQUEST = '@categories/GET_PACKAGES_REQUEST';
export const GET_PACKAGES_FAILURE = '@categories/GET_PACKAGES_FAILURE';
export const GET_PACKAGES_SUCCESS = '@categories/GET_PACKAGES_SUCCESS';

export const ADD_PACKAGES_REQUEST = '@categories/ADD_PACKAGES_REQUEST';
export const ADD_PACKAGES_FAILURE = '@categories/ADD_PACKAGES_FAILURE';
export const ADD_PACKAGES_SUCCESS = '@categories/ADD_PACKAGES_SUCCESS';

export const SET_PACKAGES_REQUEST = '@categories/SET_PACKAGES_REQUEST';
export const SET_PACKAGES_FAILURE = '@categories/SET_PACKAGES_FAILURE';
export const SET_PACKAGES_SUCCESS = '@categories/SET_PACKAGES_SUCCESS';

export const DELETE_PACKAGES_REQUEST = '@categories/DELETE_PACKAGES_REQUEST';
export const DELETE_PACKAGES_FAILURE = '@categories/DELETE_PACKAGES_FAILURE';
export const DELETE_PACKAGES_SUCCESS = '@categories/DELETE_PACKAGES_SUCCESS';

export const GET_GROUPS_REQUEST = '@categories/GET_GROUPS_REQUEST';
export const GET_GROUPS_FAILURE = '@categories/GET_GROUPS_FAILURE';
export const GET_GROUPS_SUCCESS = '@categories/GET_GROUPS_SUCCESS';

export const ADD_GROUPS_REQUEST = '@categories/ADD_GROUPS_REQUEST';
export const ADD_GROUPS_FAILURE = '@categories/ADD_GROUPS_FAILURE';
export const ADD_GROUPS_SUCCESS = '@categories/ADD_GROUPS_SUCCESS';

export const SET_GROUPS_REQUEST = '@categories/SET_GROUPS_REQUEST';
export const SET_GROUPS_FAILURE = '@categories/SET_GROUPS_FAILURE';
export const SET_GROUPS_SUCCESS = '@categories/SET_GROUPS_SUCCESS';

export const DELETE_GROUPS_REQUEST = '@categories/DELETE_GROUPS_REQUEST';
export const DELETE_GROUPS_FAILURE = '@categories/DELETE_GROUPS_FAILURE';
export const DELETE_GROUPS_SUCCESS = '@categories/DELETE_GROUPS_SUCCESS';
