import i18n from '@i18n';
import { EventsHttp } from '@services/http';
import { alertError, alertErrorWrap, alertSuccess } from '@store/Alert';
import { put, takeLatest, fork, call } from 'redux-saga/effects';

import { searchEventsFailure, searchEventsSuccess } from './Events.action';
import { SEARCH_EVENTS_REQUEST } from './Events.constant';

function* workerSearchEvents({ payload }) {
  try {
    const data = yield call(EventsHttp.searchEvents, payload);

    yield put(searchEventsSuccess(data));
    if (data.length) {
      yield put(
        alertSuccess({
          message: i18n.t('ALERT.EQUIPMENT_FOUND_AT_EVENTS'),
          isTitleDefault: false,
        }),
      );
    } else {
      yield put(alertErrorWrap(i18n.t('ALERT.EQUIPMENT__NOT_FOUND_AT_EVENTS')));
    }
  } catch (error) {
    yield put(searchEventsFailure());
    yield put(alertError());
  }
}
function* watchSearchEvents() {
  yield takeLatest(SEARCH_EVENTS_REQUEST, workerSearchEvents);
}
export const eventsWatchers = [fork(watchSearchEvents)];
