/* eslint-disable indent */
/* eslint-disable import/order */
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import './styles.scss';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getEventsRequest, getOfficesRequest } from '@store/Calendar';
import { useAppDispatch } from '@store/';
import { DateHelper, StringHelper } from '@utils/helpers';
import {
  CALENDAR_TYPE_DAY,
  CALENDAR_TYPE_WEEK,
  FORMAT_DATE_QUERY,
  FORMAT_DAY_OF_WEEK,
  UNIX_DAY,
} from '@utils/constants';
import { UILoaderWrap } from '@ui';
import { useResponsive } from '@hooks';
import CalendarsCommon from './Common';
import CalendarsHeader from './Header';
import CalendarsYear from './Year';
import { ModalType, showModal } from '@store/Modal';
import { useTranslation } from 'react-i18next';
import { LocalStorage } from '@services/storage';

const Calendars = () => {
  const dispatch = useAppDispatch();
  const { isDesktopXL, isIpad, isTablet, isMobile, isDesktop } =
    useResponsive();
  const mainCalendarRef = useRef();
  const { t } = useTranslation();
  const tz = new Date().getTimezoneOffset();

  const { events, eventsYear, eventsInfo, loading, offices, loadingOffices } =
    useSelector((state) => state.calendar);
  const [range, setRange] = useState(null);

  useEffect(() => {
    dispatch(getOfficesRequest());
  }, [dispatch]);

  const renderEventContent = (eventInfo) => <div>{eventInfo.event.title}</div>;

  const handleDates = (rangeInfo) => {
    dispatch(
      getEventsRequest({
        query: `${DateHelper.toFormat(
          rangeInfo.start,
          FORMAT_DATE_QUERY,
          false,
        )},${DateHelper.toFormat(rangeInfo.end, FORMAT_DATE_QUERY, false)}`,
        type: rangeInfo.view.type,
      }),
    );
  };

  const handleDayHeaderMonth = (e) => (
    <div>{isDesktopXL ? e.text : StringHelper.longWeekToShort(e.text)}</div>
  );

  function handleDateClick(dateClick, view = 'day') {
    mainCalendarRef.current.getApi().gotoDate(dateClick);
    mainCalendarRef.current.getApi().changeView(view);
  }

  useEffect(() => {
    if (range) {
      mainCalendarRef.current.getApi().gotoDate(range.startDate);
    }
  }, [range]);

  const startDateUnix =
    new Date(
      DateHelper.toFormat(range?.startDate, FORMAT_DATE_QUERY, tz < 0),
    ).valueOf() -
    UNIX_DAY *
      (+DateHelper.toFormat(range?.startDate, FORMAT_DAY_OF_WEEK, tz < 0) - 1);
  const endDateUnix =
    new Date(
      DateHelper.toFormat(range?.endDate, FORMAT_DATE_QUERY, tz < 0),
    ).valueOf() +
    (7 - +DateHelper.toFormat(range?.endDate, FORMAT_DAY_OF_WEEK, tz < 0)) *
      UNIX_DAY;

  return (
    <>
      <CalendarsHeader
        mainCalendarRef={mainCalendarRef}
        offices={offices}
        range={range || {}}
        setRange={setRange}
        loadingOffices={loadingOffices}
      />

      <UILoaderWrap className="fullCalendar" loading={loading}>
        <FullCalendar
          ref={mainCalendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: null,
            center: null,
            right: null,
          }}
          views={{
            week: {
              type: 'timeGrid',
              dateAlignment: 'week',
              duration: {
                days: range
                  ? Math.ceil(
                      ((endDateUnix - startDateUnix) / UNIX_DAY + 1) / 7,
                    ) * 7
                  : 7,
              },
              buttonText: 'timeGrid',
              component: (e) => (
                <CalendarsCommon
                  e={e}
                  setRange={setRange}
                  range={range}
                  type={CALENDAR_TYPE_WEEK}
                  handleDateClick={handleDateClick}
                  className="calendar__week"
                />
              ),
            },
            day: {
              type: 'timeGrid',
              duration: { day: 1 },
              buttonText: 'timeGrid',
              component: (e) => (
                <CalendarsCommon
                  eventsInfo={eventsInfo}
                  e={e}
                  handleDateClick={handleDateClick}
                  type={CALENDAR_TYPE_DAY}
                  className="calendar__day"
                />
              ),
            },
            dayGridMonth: {
              viewClassNames: 'main__month',
              dayHeaderContent: handleDayHeaderMonth,
              dayHeaderFormat: {
                weekday:
                  isIpad || isTablet || isMobile || isDesktop
                    ? 'short'
                    : 'long',
              },
            },
            year: {
              type: 'dayGrid',
              duration: { year: 1 },
              buttonText: t('COMMON.YEAR'),
              component: (e) => (
                <CalendarsYear
                  e={e}
                  events={eventsYear}
                  handleDateClick={handleDateClick}
                />
              ),
            },
          }}
          firstDay={1}
          locale={LocalStorage.get('language')}
          initialView="week"
          navLinks={true}
          selectMirror={true}
          dayMaxEvents={null}
          datesSet={handleDates}
          events={events}
          eventContent={renderEventContent}
          eventClick={(e) =>
            dispatch(
              showModal({
                modalType: ModalType.CALENDAR_EVENT,
                modalProps: {
                  event: e.event,
                  pageX: e.jsEvent.pageX,
                  clientY: e.jsEvent.clientY,
                  pageY: e.jsEvent.pageY,
                },
              }),
            )
          }
          height="auto"
          eventOrder="sortId"
        />
      </UILoaderWrap>
    </>
  );
};

export default Calendars;
