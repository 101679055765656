/* eslint-disable indent */
import { useEffect, useState, useRef } from 'react';
import './style.scss';

import { AddCircleSVG } from '@assets/img/icons';
import { useAppDispatch } from '@store/';
// import { alertError } from '@store/Alert';
import { ModalType, showModal } from '@store/Modal';
import {
  createManagerRequest,
  getLegacyEventRequest,
  getOfficeEventRequest,
  getTransportListRequest,
  getUserListRequest,
  setStep2Form,
} from '@store/ProjectAdd';
import { UIButton, UICheckBox, UIInput, UISelect, UITextarea } from '@ui';
import {
  FORMAT_DATE_QUERY,
  FORMAT_DATE_QUERY_WITH_TIME,
  FORMAT_TIME_SHORT_ALT,
} from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ProjectAddManagerBooking from './Booking';
import { validationSchema } from './schema';
import { UIProjectAddStep } from '../ui';

const ProjectAddManager = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const placeRef = useRef(null);
  const bookingRef = useRef(null);
  const [step, setStep] = useState(2);

  const {
    officeEvents,
    legacyEvents,
    loading,
    transportList,
    userList,
    loadingTransport,
    loadingUser,
    projectData,
    bookingList,
    step2Form,
  } = useSelector((state) => state.projectAdd);
  const { editData } = useSelector((state) => state.project);

  const tagCollection = [
    {
      name: 'traffic_lights',
      label: t('COMMON.LIGHT'),
      value: '1',
      id: '1',
    },
    {
      name: 'traffic_lights',
      label: t('COMMON.SCENE'),
      value: '2',
      id: '2',
    },
    {
      name: 'traffic_lights',
      label: t('COMMON.VIDEO'),
      value: '3',
      id: '3',
    },
  ];

  const INITIAL_VALUES = {
    place: '',
    office: '',
    place_phone: '',
    legacy_id: '',
    comment_light: '',
    comment: '',
    traffic_lights: [],
    reservations: [
      {
        name: i18n.t('COMMON.BOOKING', 1),
        start: '',
        finish: '',
        loading: '',
        time: '',
        dateBooking: '',
      },
    ],
    step: '2',
  };

  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const [idxBookingMenu, setIdxBookingMenu] = useState(0);

  useEffect(() => {
    dispatch(getOfficeEventRequest());
    dispatch(getLegacyEventRequest());
    dispatch(getUserListRequest());
    dispatch(getTransportListRequest());
  }, [dispatch]);

  const handlerSubmit = (values) => {
    const { reservations, ...newValues } = values;

    const newReservations = values.reservations.map((i, index) => {
      const { time, dateBooking, personals, transports, loading, ...val } = i;
      const newPersonals = personals?.map((item) => item.id);
      const newTransports = transports?.map((item) => item.id);

      return {
        ...val,
        ...(bookingList?.reservations?.[index]?.id && {
          id: bookingList?.reservations?.[index]?.id,
        }),
        personals: newPersonals,
        transports: newTransports,
        loading: `${loading} ${time}:00`,
      };
    });

    const valueToRequest = {
      ...newValues,
      reservations: newReservations,
      id: `${projectData?.id || editData?.id}`,
    };

    dispatch(createManagerRequest(valueToRequest, step));
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
    isSubmitting: true,
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  const handlerSave = () => {
    dispatch(setStep2Form(form.values));

    const placeError = !!Object.keys(form.errors).includes('place', 'office');
    const bookingError = !!Object.keys(form.errors).includes('reservations');

    if (form.errors && placeError) {
      placeRef.current.scrollIntoView();
    } else if (form.errors && bookingError) {
      form.errors.reservations.forEach((item, index) => {
        if (item) {
          setIdxBookingMenu(index);
          bookingRef.current.scrollIntoView();
        }
      });
    }
  };

  const checkboxChange = (e) => {
    const { checked, value } = e.target;

    if (checked) {
      form.setFieldValue('traffic_lights', [
        ...form.values.traffic_lights,
        value,
      ]);
    } else {
      form.setFieldValue(
        'traffic_lights',
        form.values.traffic_lights.filter((v) => +v !== +value),
      );
    }
  };

  useEffect(() => {
    if (step2Form && step2Form !== INITIAL_VALUES) {
      setInitialValues(step2Form);
    } else if (editData) {
      const dataToSet = {
        place: editData?.place || '',
        office: +editData.office || '',
        place_phone: editData.place_phone || '',
        legacy_id: editData.legacy_id?.id,
        traffic_lights: editData.traffic_lights.map((i) => i.id),
        comment_light: editData.comment_light || '',
        comment: editData.comment || '',
        reservations: editData.reservations.length
          ? editData.reservations.map((i) => {
              const start = DateHelper.toFormat(
                i.start * 1000,
                FORMAT_DATE_QUERY_WITH_TIME,
              );
              const finish = DateHelper.toFormat(
                i.finish * 1000,
                FORMAT_DATE_QUERY_WITH_TIME,
              );

              const time = DateHelper.toFormat(
                i.loading * 1000,
                FORMAT_TIME_SHORT_ALT,
              );
              const loading = DateHelper.toFormat(
                i.loading * 1000,
                FORMAT_DATE_QUERY,
              );

              return {
                ...i,
                time,
                loading,
                start,
                finish,
                dateBooking: `${start} - ${finish}`,
              };
            })
          : initialValues.reservations,
        step: '2',
      };

      setInitialValues(dataToSet);
    } else {
      setInitialValues(INITIAL_VALUES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, step2Form]);

  return (
    <>
      <UIProjectAddStep
        handleSave={(type) => {
          setStep(type);
          handlerSave();
          form.handleSubmit();
        }}
      />
      <form onSubmit={form.handleSubmit} className="addEvent-wrap__step2">
        <div className="mb-30 h3 fw600">{t('COMMON.MANAGER')}</div>

        <div className="addEvent-wrap" ref={placeRef}>
          <div className="addEvent-wrap__left">
            <UIInput
              name="place"
              placeholder={t('COMMON.PLATFORM')}
              label={t('COMMON.PLATFORM')}
              classLabel="fw700 icon_necessarily"
              className="input input__row input-wrap"
              islabelRow
              value={form.values?.place}
              onChange={form.handleChange}
              isValid={!(form.errors.place && form.touched.place)}
              validateText={form.errors.place}
            />

            <UISelect
              label={t('COMMON.OFFICE')}
              classLabel="icon_necessarily"
              className="custom-select-row"
              options={officeEvents}
              name="office"
              value={officeEvents.find((i) => +i.id === +form.values.office)}
              onChange={(value) => {
                form.setFieldValue('office', +value?.id || '');
              }}
              isLoading={loading}
              placeholder={t('COMMON.OFFICE')}
              isValid={!(form.errors.office && form.touched.office)}
              validateText={form.errors.office}
            />

            <UIInput
              name="place_phone"
              placeholder={t('COMMON.PHONE')}
              label={t('COMMON.PHONE')}
              classLabel="fw700"
              className="input input__row input-wrap"
              islabelRow
              value={form.values?.place_phone}
              onChange={form.handleChange}
              isValid={!(form.errors.place_phone && form.touched.place_phone)}
              validateText={form.errors.place_phone}
            />

            <UISelect
              label={t('PROJECT.RESPONSIBLE')}
              className="custom-select-row"
              options={legacyEvents}
              value={legacyEvents.find((i) => i.id === form.values.legacy_id)}
              name="legacy_id"
              onChange={(value) => {
                form.setFieldValue('legacy_id', value?.id || '');
              }}
              isLoading={loading}
              placeholder={t('PROJECT.RESPONSIBLE')}
            />

            <UIButton
              className="addElement"
              handler={() =>
                dispatch(
                  showModal({
                    modalType: ModalType.ADD_RESPONSIBLE,
                  }),
                )
              }
            >
              <span className="h6 color_darkBlue fw600">
                {t('PROJECT.ADD_RESPONSIBLE_PERSON')}
              </span>
              <AddCircleSVG />
            </UIButton>
          </div>

          <div className="addEvent-wrap__right">
            <div className="">
              <span className="h6 fw600">
                {t('PROJECT.PARTICIPATION_THIRD_CONTRACTORS')}
              </span>

              <div className="addEvent-right__checkboxes">
                {tagCollection.map((item, index) => (
                  <UICheckBox
                    key={index}
                    id={item.id}
                    name={item.name}
                    value={+item.value}
                    label={item.label}
                    checked={
                      !!form.values.traffic_lights.find(
                        (i) => +i === +item.value,
                      )
                    }
                    onChange={checkboxChange}
                  />
                ))}
              </div>
            </div>

            <div className="addEvent-right__textarea">
              <span className="h6 fw600">
                {t('PROJECT.SVETOVIKOV_COMMENT')}
              </span>

              <UITextarea
                placeholder={t('PROJECT.SVETOVIKOV_COMMENT')}
                name="comment_light"
                value={form.values.comment_light}
                onChange={form.handleChange}
                required={false}
              />
            </div>

            <div className="addEvent-right__textarea mb-0">
              <span className="h6 fw600">{t('PROJECT.GENERAL_COMMENT')}</span>

              <UITextarea
                placeholder={t('PROJECT.GENERAL_COMMENT')}
                name="comment"
                value={form.values.comment}
                onChange={form.handleChange}
                required={false}
              />
            </div>
          </div>
        </div>

        <div className="addEvent-booking" ref={bookingRef}>
          <ProjectAddManagerBooking
            transportList={transportList}
            userList={userList}
            loadingTransport={loadingTransport}
            loadingUser={loadingUser}
            form={form}
            editProject={!!editData}
            start={editData?.date_start_event || projectData?.date_start_event}
            finish={
              editData?.date_finish_event || projectData?.date_finish_event
            }
            setIdxBookingMenu={setIdxBookingMenu}
            idxBookingMenu={idxBookingMenu}
          />
        </div>

        <UIButton
          className="outline__blue btn_submit"
          type={Button.SUBMIT}
          loading={loading}
          handler={handlerSave}
        >
          {t('COMMON.NEXT')}
        </UIButton>
      </form>
    </>
  );
};

export default ProjectAddManager;
