export const PlusWhiteSVG = ({ className }) => {
  return (
    <svg
      className={className}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 13.75V8.75H1.25C0.918479 8.75 0.600555 8.61829 0.366135 8.38387C0.131714 8.14944 0 7.83152 0 7.5C0 7.16848 0.131714 6.85055 0.366135 6.61613C0.600555 6.38171 0.918479 6.25 1.25 6.25H6.25V1.25C6.25 0.918479 6.38171 0.600555 6.61613 0.366135C6.85055 0.131714 7.16848 0 7.5 0C7.83152 0 8.14944 0.131714 8.38387 0.366135C8.61829 0.600555 8.75 0.918479 8.75 1.25V6.25H13.75C14.0815 6.25 14.3994 6.38171 14.6339 6.61613C14.8683 6.85055 15 7.16848 15 7.5C15 7.83152 14.8683 8.14944 14.6339 8.38387C14.3994 8.61829 14.0815 8.75 13.75 8.75H8.75V13.75C8.75 14.0815 8.61829 14.3994 8.38387 14.6339C8.14944 14.8683 7.83152 15 7.5 15C7.16848 15 6.85055 14.8683 6.61613 14.6339C6.38171 14.3994 6.25 14.0815 6.25 13.75Z"
        fill="white"
      />
    </svg>
  );
};
