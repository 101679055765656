import { useEffect, useState } from 'react';

import { DeleteSVG } from '@assets/img/icons';
import { LayoutModal } from '@layouts';
import { useAppDispatch } from '@store/';
import { hideModal } from '@store/Modal';
import { setOfficesRequest } from '@store/Users';
import { UIButton, UIColor, UIInput } from '@ui';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { validationSchema } from '../../../modules/Users/FormOffices/schema';

const WidgetsModalSettingsOffices = ({ id, name, color }) => {
  const { loading } = useSelector((state) => state.users);

  const [isOpenColor, setIsOpenColor] = useState(false);

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const handlerSubmit = (values) => {
    dispatch(setOfficesRequest({ ...values, id }));
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      color,
      name,
    },
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  return (
    <LayoutModal title={t('COMMON.SETTINGS')} className="modal__users">
      <form onSubmit={form.handleSubmit} className="modal__added__items">
        <UIColor
          className="users-color h6 fw700"
          setColor={(color) => form.setFieldValue('color', color)}
          color={form.values.color}
          setIsOpenColor={setIsOpenColor}
          isOpenColor={isOpenColor}
        />
        <div className="modal-users__items">
          <UIInput
            label={t('COMMON.TITLE')}
            name="name"
            placeholder={t('COMMON.TITLE')}
            className="input input__icon"
            classLabel="fw600"
            value={form.values.name}
            onChange={form.handleChange}
            isValid={!(form.errors.name && form.touched.name)}
            validateText={form.errors.name}
          >
            <span className="input-icon__btn btn__close">
              <DeleteSVG fill="#000" />
            </span>
          </UIInput>
          <div className="users-btn">
            <UIButton
              loading={loading}
              className="btn outline__blue"
              type={Button.SUBMIT}
            >
              <span>{t('COMMON.SAVE')}</span>
            </UIButton>
            <UIButton
              handler={() => dispatch(hideModal())}
              className="btn btn__gray"
            >
              {t('COMMON.CANCEL')}
            </UIButton>
          </div>
        </div>
      </form>
    </LayoutModal>
  );
};

export default WidgetsModalSettingsOffices;
