import { useState, useEffect, useRef } from 'react';

import { LoaderColorType } from '@utils';
import cn from 'classnames';

import styles from './styles.module.scss';

const UILoaderSmall = ({
  size,
  stroke = 3,
  colorType = LoaderColorType.PRIMARY,
}) => {
  const block = useRef(null);
  const [currentSize, setCurrentSize] = useState(size || 0);

  useEffect(() => {
    setCurrentSize(
      size ||
        (block.current?.parentElement
          ? block.current?.parentElement.offsetHeight
          : 5),
    );
  }, [size]);

  return (
    <div className={styles['small-loader']}>
      <div className={styles['small-loader__wrap']}>
        <div
          id="loading-indicator"
          role="progressbar"
          className={cn(styles['small-loader__container'], {
            [styles['small-loader__color--primary']]:
              colorType === LoaderColorType.PRIMARY,
            [styles['small-loader__color--secondary']]:
              colorType === LoaderColorType.SECONDARY,
          })}
          ref={block}
        >
          <svg
            className={styles['small-loader__svg']}
            width={currentSize}
            height={currentSize}
          >
            <circle
              cx={currentSize / 2}
              cy={currentSize / 2}
              r={Math.abs(currentSize - stroke) / 2}
              fill="none"
              strokeWidth={stroke}
              className={styles['small-loader__circle']}
            ></circle>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default UILoaderSmall;
