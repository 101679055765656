import {
  GET_GENERAL_FAILURE,
  GET_GENERAL_REQUEST,
  GET_GENERAL_SUCCESS,
  GET_RESPONSIBLE_FAILURE,
  GET_RESPONSIBLE_REQUEST,
  GET_RESPONSIBLE_SUCCESS,
} from './Reports.constant';

const initialState = {
  loading: false,
  generalReport: null,
  responsibleReport: null,
};

export default function ReportsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GENERAL_REQUEST:
    case GET_RESPONSIBLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_GENERAL_FAILURE:
    case GET_RESPONSIBLE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_GENERAL_SUCCESS:
      return {
        ...state,
        loading: false,
        generalReport: action.payload,
      };
    case GET_RESPONSIBLE_SUCCESS:
      return {
        ...state,
        loading: false,
        responsibleReport: action.payload,
      };
    default:
      return state;
  }
}
