export const SaveSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 12 11"
      fill="none"
    >
      <path
        d="M1.33325 4.5L4.83325 8L10.6666 1"
        stroke="#00BA34"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
