import { LayoutModal } from '@layouts';
import { useAppDispatch } from '@store/';
import { hideModal } from '@store/Modal';
import { UIButton } from '@ui';
import { useTranslation } from 'react-i18next';
import './style.scss';

const WidgetsModalConfirm = (modalProps) => {
  const { handler, title } = modalProps;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const closeModal = () => {
    dispatch(hideModal());
  };

  const handlerOk = () => {
    handler();
  };

  return (
    <LayoutModal className="modal__confirm">
      <div className="modal-header">
        <span> {title}</span>
      </div>
      <div className="confirm_btn">
        <UIButton className="btn__gray" handler={closeModal}>
          {t('COMMON.CANCEL')}
        </UIButton>
        <UIButton handler={handlerOk}>{t('COMMON.DELETE')}</UIButton>
      </div>
    </LayoutModal>
  );
};

export default WidgetsModalConfirm;
