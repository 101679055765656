export const PlusLightSVG = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 12.5V6.5M7 6.5V0.5M7 6.5H13M7 6.5H1"
        stroke="#809FB8"
        strokeLinecap="round"
      />
    </svg>
  );
};
