import { SHOW_MODAL, HIDE_MODAL } from './Modal.constant';

export function showModal(payload) {
  return {
    type: SHOW_MODAL,
    payload,
  };
}

export function hideModal() {
  return { type: HIDE_MODAL };
}
