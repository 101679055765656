import i18n from '@i18n';
import * as yup from 'yup';

export const validationSchema = () =>
  yup.object({
    email: yup
      .string()
      .email(i18n.t('VALIDATION.EMAIL.VALID'))
      .required(i18n.t('VALIDATION.EMAIL.REQUIRED')),
    password: yup
      .string()
      .min(6, i18n.t('VALIDATION.PASSWORD.MIN'))
      .required(i18n.t('VALIDATION.PASSWORD.REQUIRED')),
  });
