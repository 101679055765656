import i18n from '@i18n';
import * as yup from 'yup';

export const validationSchema = () =>
  yup.object({
    date_start: yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    date_finish: yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    user_id: yup
      .object()
      .required(i18n.t('VALIDATION.REQUIRED'))
      .nullable(true),
  });
