/* eslint-disable no-nested-ternary */
import { useEffect, useMemo, useState } from 'react';

import { AddCircleSVG, SearchSVG, SettingsSVG } from '@assets/img/icons';
import { useDebounce, useEndLessScroll } from '@hooks';
import { useAppDispatch } from '@store/';
import { ModalType, showModal } from '@store/Modal';
import {
  clearAllUsers,
  clearRefresh,
  getOfficesRequest,
  getUsersRequest,
} from '@store/Users';
import './styles.scss';
import { UIButton, UIInput, UILoaderWrap } from '@ui';
import { colors } from '@utils/utility-property';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import UsersFormOffices from './FormOffices';
import UsersFormUsers from './FormUsers';

const Users = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { users, offices, lastPage, loading, loadingItems, refresh } =
    useSelector((state) => state.users);

  const [tab, setTab] = useState(true);
  const [value, setValue] = useState('');
  const [page, setPage] = useState(1);

  const debouncedValue = useDebounce(value, 500);

  const [onScroll, containerRef] = useEndLessScroll({
    onIntersection: () => {
      if (
        page < lastPage &&
        ((tab && users.length) || (!tab && offices.length))
      ) {
        if (tab) {
          dispatch(
            getUsersRequest({
              page: +page + 1,
              ...(value && {
                filter: { search_name: value, search_email: value },
              }),
            }),
          );
        } else {
          dispatch(
            getOfficesRequest({
              page: +page + 1,
              ...(value && {
                filter: { search_name: value },
              }),
            }),
          );
        }
        setPage(+page + 1);
      }
    },
  });

  useEffect(() => {
    if (refresh) {
      setPage(1);
      setValue('');
      dispatch(
        tab ? getUsersRequest({ page: 1 }) : getOfficesRequest({ page: 1 }),
      );
      dispatch(clearRefresh());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refresh]);

  const handlerSearch = (e) => {
    const { value } = e.target;

    setValue(value);
  };

  useEffect(() => {
    setPage(1);
    dispatch(clearAllUsers());
    dispatch(
      tab
        ? getUsersRequest({
            page: 1,
            ...(debouncedValue && {
              filter: {
                search_name: debouncedValue.trim(),
                search_email: debouncedValue.trim(),
              },
            }),
          })
        : getOfficesRequest({
            page: 1,
            filter: { search_name: debouncedValue.trim() },
          }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, dispatch]);

  const renderHtmlBlock = useMemo(
    () =>
      (tab ? users : offices).map((item, index) => (
        <div key={index} className="users-search__item">
          <div className="users-searchItem__wrap">
            {!tab && (
              <span
                style={{ background: item.color || colors.green }}
                className="statue"
              ></span>
            )}
            <div className="h6 fw700">{item.name}</div>
            {item.role ? (
              <div className="users-searchItem__role h8">{item.role}</div>
            ) : null}
            <button
              onClick={() =>
                dispatch(
                  showModal({
                    modalType: tab
                      ? ModalType.SETTINGS_USERS
                      : ModalType.SETTINGS_OFFICES,
                    modalProps: item,
                  }),
                )
              }
            >
              <SettingsSVG />
            </button>
          </div>
          {item.email ? (
            <div className="users-searchItem__email">{item.email}</div>
          ) : null}
          {item.phone ? (
            <div className="users-searchItem__phone">{item.phone}</div>
          ) : null}
        </div>
      )),
    [dispatch, offices, tab, users],
  );

  return (
    <div className="users-wrap">
      <div className="users-left">
        <div className="tabs">
          <UIButton
            defaultClassName=""
            handler={() => {
              setPage(1);
              dispatch(getUsersRequest({ page: 1 }));
              setTab(true);
              setValue('');
            }}
            className={cn({ active: tab })}
          >
            {t('USERS.USERS')}
          </UIButton>

          <UIButton
            defaultClassName=""
            handler={() => {
              setPage(1);
              dispatch(getOfficesRequest({ page: 1 }));
              setTab(false);
              setValue('');
            }}
            className={cn({ active: !tab })}
          >
            {t('COMMON.OFFICES')}
          </UIButton>
        </div>

        <div className="users-search active">
          <div className="input input__icon">
            <span className="input-icon__btn">
              <SearchSVG />
            </span>

            <UIInput
              value={value}
              placeholder={t('COMMON.SEARCH')}
              onChange={handlerSearch}
            />
          </div>

          <div
            onClick={() =>
              dispatch(
                showModal({
                  modalType: tab
                    ? ModalType.MOBILE_USERS
                    : ModalType.MOBILE_OFFICES,
                }),
              )
            }
            className="add-user__btn hide-desktop show-mobile"
          >
            <span className="color_darkBlue">
              {tab ? t('USERS.ADD_USER') : t('USERS.ADD_OFFICE')}
            </span>
            <AddCircleSVG />
          </div>

          <UILoaderWrap loading={loadingItems}>
            <div
              ref={containerRef}
              onScroll={onScroll}
              className={`users-search__items users-search__${
                tab ? 'users' : 'offices'
              }`}
            >
              {renderHtmlBlock}
            </div>
          </UILoaderWrap>
        </div>
      </div>
      {tab ? (
        <UsersFormUsers refresh={refresh} loading={loading} />
      ) : (
        <UsersFormOffices refresh={refresh} loading={loading} />
      )}
    </div>
  );
};

export default Users;
