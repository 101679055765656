import { useMemo } from 'react';

import {
  FORMAT_DATE_QUERY,
  FORMAT_DATE_QUERY_WITH_TIME_FORMAT,
} from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { useTranslation } from 'react-i18next';

const ProjectAddInfoBooking = ({ equipmentData }) => {
  const { t } = useTranslation();

  const renderBooking = useMemo(
    () =>
      equipmentData?.reservations?.map((item, index) => (
        <div className="project_info-booking" key={index}>
          <div className="booking-header">
            <div className="booking-header-title">{item.name}</div>
            <div className="booking-header-date">
              <div>
                <span>{t('PROJECT.BOOKING_DATE')}</span>{' '}
                {DateHelper.toFormat(item.start * 1000, FORMAT_DATE_QUERY)} -{' '}
                {DateHelper.toFormat(item.finish * 1000, FORMAT_DATE_QUERY)}
              </div>
              <div>
                <span>{t('PROJECT.LOADING_DATE_TIME')}</span>{' '}
                {DateHelper.toFormat(
                  item.loading * 1000,
                  FORMAT_DATE_QUERY_WITH_TIME_FORMAT,
                )}
              </div>
            </div>
          </div>
          <div className="wrapper_table">
            <table className="custom-table">
              <tbody>
                <tr>
                  <th>{t('COMMON.TYPE')}</th>
                  <th>{t('COMMON.EQUIPMENT')}</th>
                  <th>{t('COMMON.QUANTITY')}</th>
                </tr>
                {item.equipments.map((i, index) => (
                  <tr key={index}>
                    <td>{i.category.name}</td>
                    <td>{i.name}</td>
                    <td>{i.reserved}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )),
    [equipmentData?.reservations, t],
  );

  return <>{renderBooking}</>;
};

export default ProjectAddInfoBooking;
