import { useTranslation } from 'react-i18next';

import ProjectAddMainClientForm from './Form';

const ProjectAddMainClient = ({ handler }) => {
  const { t } = useTranslation();

  return (
    <div className="addEvent-wrap__add ">
      <div className="fw600 h3">{t('PROJECT.ADD_CUSTOMER')}</div>

      <ProjectAddMainClientForm handler={handler} />
    </div>
  );
};

export default ProjectAddMainClient;
