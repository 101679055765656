export const HttpExceptionType = {
  REFRESH_TOKEN_EXPIRED: 'REFRESH_TOKEN_EXPIRED',
  REFRESH_TOKEN_VERIFY: 'REFRESH_TOKEN_VERIFY',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  TOKEN_MALFORMED: 'TOKEN_MALFORMED',
  TOKEN_NOT_PROVIDED: 'TOKEN_NOT_PROVIDED',
  TOKEN_VERIFY: 'TOKEN_VERIFY',
};

export const HttpStatus = {
  OK: 200,
  Created: 201,
  NoContent: 204,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  Conflict: 409,
  UnprocessableEntity: 422,
  ServerError: 499,
  InternalServerError: 500,
};

export const Button = {
  BUTTON: 'button',
  LINK: 'link',
  SUBMIT: 'submit',
};

export const LoaderColorType = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const ResponsiveWidth = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
};

export const colors = {
  green: '#00ba34',
  blue: '#0084ff',
  red: '#e92c2c',
  gray: '#585757',
};

export const FileName = {
  AUDIO: 'audio',
  DOCS: 'docs',
  IMAGE: 'image',
  VIDEO: 'video',
};

export const typeCarouselCalendar = {
  CALENDAR: 'calendar',
  RANGE: 'range',
  MONTH: 'month',
};
