import i18n from '@i18n';
import * as yup from 'yup';

export const validationSchema = () =>
  yup.object({
    place: yup.string().required(i18n.t('VALIDATION.REQUIRED')).nullable(),
    place_phone: yup
      .string()
      .matches(/^(\+|)(\d[0-9]{7,16})$/, i18n.t('VALIDATION.PHONE.FORMAT'))
      .nullable(),
    office: yup
      .number()
      .min(1, i18n.t('VALIDATION.REQUIRED'))
      .required(i18n.t('VALIDATION.REQUIRED')),
    reservations: yup.array().of(
      yup.object().shape({
        loading: yup
          .string()
          .required(`loading.${i18n.t('VALIDATION.REQUIRED')}`),
        time: yup
          .string()
          .required(`time.${i18n.t('VALIDATION.REQUIRED')}`)
          .nullable(true),
        dateBooking: yup
          .string()
          .required(`dateBooking.${i18n.t('VALIDATION.REQUIRED')}`),
        name: yup.string().required(`name.${i18n.t('VALIDATION.REQUIRED')}`),
      }),
    ),
  });
