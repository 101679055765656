import i18n from '@i18n';

export default (() => {
  const lowerCase = (name) => {
    if (name) {
      return name.toLowerCase();
    }

    return name;
  };

  const capitalize = (s) => {
    if (typeof s !== 'string') {
      return '';
    }

    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const longWeekToShort = (str) => {
    switch (str.toLowerCase()) {
      case 'понедельник':
        return i18n.t('CALENDAR.MONDAY_MIN');
      case 'вторник':
        return i18n.t('CALENDAR.TUESDAY_MIN');
      case 'среда':
        return i18n.t('CALENDAR.WEDNESDAY_MIN');
      case 'четверг':
        return i18n.t('CALENDAR.THURSDAY_MIN');
      case 'пятница':
        return i18n.t('CALENDAR.FRIDAY_MIN');
      case 'суббота':
        return i18n.t('CALENDAR.SATURDAY_MIN');
      case 'воскресенье':
        return i18n.t('CALENDAR.SUNDAY_MIN');
      default:
        return str;
    }
  };

  const replaceSymbol = (str, searchValue, replaceValue) => {
    return str && typeof str === 'string' && searchValue && replaceValue
      ? str.replaceAll(searchValue, replaceValue)
      : '';
  };

  return {
    longWeekToShort,
    lowerCase,
    capitalize,
    replaceSymbol,
  };
})();
