import { useEffect } from 'react';

import { useAppDispatch } from '@store/';
import { authLoginRequest } from '@store/Auth/Auth.action';
import { UIButton, UICheckBox, UIInput } from '@ui';
import { LINK_FORGOT_PASSWORD } from '@utils/link';
import { Button } from '@utils/utility-property';
import cn from 'classnames';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';
import style from '../styles.module.scss';

const AuthLoginForm = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const loadingLogin = useSelector((state) => state.auth.loadingLogin);

  const handlerSubmit = (values) => {
    dispatch(authLoginRequest(values));
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  return (
    <form onSubmit={form.handleSubmit}>
      <div className={cn(style['form-inputs'])}>
        <UIInput
          name="email"
          id="email"
          label={t('COMMON.LOGIN')}
          classLabel="fw700"
          classInput="fw600"
          placeholder={t('COMMON.LOGIN')}
          value={form.values.email}
          onChange={form.handleChange}
          isValid={!(form.errors.email && form.touched.email)}
          validateText={form.errors.email}
        />

        <UIInput
          name="password"
          id="password"
          label={t('COMMON.PASSWORD')}
          classLabel="fw700"
          classInput="fw600"
          placeholder={t('COMMON.PASSWORD')}
          value={form.values.password}
          onChange={form.handleChange}
          isValid={!(form.errors.password && form.touched.password)}
          type="password"
          validateText={form.errors.password}
        />
      </div>

      <Link className="lost__password fw600" to={LINK_FORGOT_PASSWORD}>
        {t('COMMON.FORGOT_PASSWORD')}?
      </Link>

      <UICheckBox
        id="remember"
        value={form.values.remember}
        onChange={form.handleChange}
        name="remember"
        label={t('COMMON.REMEMBER')}
        classLabel="fw700"
      />

      <UIButton type={Button.SUBMIT} loading={loadingLogin}>
        {t('COMMON.ENTER')}
      </UIButton>
    </form>
  );
};

export default AuthLoginForm;
