export const SearchSVG = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.91278 10.8263C8.62623 10.8263 10.8259 8.6266 10.8259 5.91314C10.8259 3.19969 8.62623 1 5.91278 1C3.19932 1 0.999634 3.19969 0.999634 5.91314C0.999634 8.6266 3.19932 10.8263 5.91278 10.8263Z"
          stroke="#809FB8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.71889 9.7191L12.807 12.8072"
          stroke="#809FB8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
