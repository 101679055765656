import { useEffect, useRef, useState } from 'react';

import { ArrTopSVG } from '@assets/img/icons';
import { useHidePopupOutside } from '@hooks';
import i18n from '@i18n';
import cn from 'classnames';

import UIButton from '../Button';
import UIInput from '../Input';
import './style.scss';

const UITimePicker = ({
  label = i18n.t('COMMON.TIME'),
  placeholder = i18n.t('COMMON.TIME'),
  className = 'input input__row input-wrap input__icon',
  name = 'time',
  isValid,
  validateText,
  value,
  disabled,
  onChange,
  handlerDisabled = null,
  ...prop
}) => {
  const [isOpen, setOpen] = useState(false);
  const [min, setMin] = useState('');
  const [hour, setHour] = useState('');
  const ref = useRef(null);
  const refHour = useRef(null);
  const refMin = useRef(null);

  useHidePopupOutside(ref, () => setOpen(false));

  const arrayHour = [];
  const arrayMin = [];

  for (let i = 0; i < 24; i++) {
    arrayHour.push(`0${i}`.slice(-2));
  }
  for (let i = 0; i < 60; i++) {
    arrayMin.push(`0${i}`.slice(-2));
  }
  useEffect(() => {
    if (onChange) {
      onChange(`${hour}:${min}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hour, min]);

  useEffect(() => {
    if (isOpen && hour) {
      refHour?.current?.scrollTo(
        0,
        [...refHour?.current?.children].find((i) =>
          i.classList?.contains('active'),
        ).offsetTop,
      );
    }
    if (isOpen && min) {
      refMin?.current?.scrollTo(
        0,
        [...refMin?.current?.children].find((i) =>
          i.classList?.contains('active'),
        ).offsetTop,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div className="timePicker">
      <UIInput
        label={label}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        className={className}
        value={value}
        islabelRow
        isValid={isValid}
        validateText={validateText}
        onFocus={() => (disabled ? handlerDisabled : setOpen(true))}
        wrapRef={ref}
        {...prop}
        childrenInput={
          isOpen && (
            <div className="timePickerPopup">
              <div ref={refHour}>
                {arrayHour.map((item, index) => (
                  <UIButton
                    className={cn({ active: item === hour })}
                    defaultClassName=""
                    handler={() => setHour(item)}
                    key={index}
                  >
                    {item}
                  </UIButton>
                ))}
              </div>
              <div ref={refMin}>
                {arrayMin.map((item, index) => (
                  <UIButton
                    className={cn({ active: item === min })}
                    defaultClassName=""
                    handler={() => setMin(item)}
                    key={index}
                  >
                    {item}
                  </UIButton>
                ))}
              </div>
            </div>
          )
        }
      >
        <span
          className="input-icon__btn input-icon__calendar"
          onMouseDown={() => (disabled ? handlerDisabled : setOpen(true))}
        >
          <ArrTopSVG />
        </span>
      </UIInput>
    </div>
  );
};

export default UITimePicker;
