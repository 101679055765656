export const PrinterSVG = () => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25 0C6.65326 0 6.08097 0.237053 5.65901 0.65901C5.23705 1.08097 5 1.65326 5 2.25V3H3.5C2.70435 3 1.94129 3.31607 1.37868 3.87868C0.816071 4.44129 0.5 5.20435 0.5 6V12.75C0.5 13.3467 0.737053 13.919 1.15901 14.341C1.58097 14.7629 2.15326 15 2.75 15H5V15.75C5 16.3467 5.23705 16.919 5.65901 17.341C6.08097 17.7629 6.65326 18 7.25 18H14.9C15.4967 18 16.069 17.7629 16.491 17.341C16.9129 16.919 17.15 16.3467 17.15 15.75V15H19.25C19.8467 15 20.419 14.7629 20.841 14.341C21.2629 13.919 21.5 13.3467 21.5 12.75V6C21.5 5.20435 21.1839 4.44129 20.6213 3.87868C20.0587 3.31607 19.2956 3 18.5 3H17V2.25C17 1.65326 16.7629 1.08097 16.341 0.65901C15.919 0.237053 15.3467 0 14.75 0H7.25ZM15.5 3H6.5V2.25C6.5 2.05109 6.57902 1.86032 6.71967 1.71967C6.86032 1.57902 7.05109 1.5 7.25 1.5H14.75C14.9489 1.5 15.1397 1.57902 15.2803 1.71967C15.421 1.86032 15.5 2.05109 15.5 2.25V3ZM7.25 12H14.9C15.0989 12 15.2897 12.079 15.4303 12.2197C15.571 12.3603 15.65 12.5511 15.65 12.75V15.75C15.65 15.9489 15.571 16.1397 15.4303 16.2803C15.2897 16.421 15.0989 16.5 14.9 16.5H7.25C7.05109 16.5 6.86032 16.421 6.71967 16.2803C6.57902 16.1397 6.5 15.9489 6.5 15.75V12.75C6.5 12.5511 6.57902 12.3603 6.71967 12.2197C6.86032 12.079 7.05109 12 7.25 12Z"
        fill="#3A36DB"
      />
    </svg>
  );
};
