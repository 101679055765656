import {
  ADD_EQUIPMENTS_FAILURE,
  ADD_EQUIPMENTS_REQUEST,
  ADD_EQUIPMENTS_SUCCESS,
  CLEAR_EQUIPMENTS_DATA,
  DELETE_EQUIPMENTS_FAILURE,
  DELETE_EQUIPMENTS_REQUEST,
  DELETE_EQUIPMENTS_SUCCESS,
  SEARCH_EQUIPMENTS_FAILURE,
  SEARCH_EQUIPMENTS_REQUEST,
  SEARCH_EQUIPMENTS_SUCCESS,
  SET_EQUIPMENTS_FAILURE,
  SET_EQUIPMENTS_REQUEST,
  SET_EQUIPMENTS_SUCCESS,
  TRANSFER_EQUIPMENTS_FAILURE,
  TRANSFER_EQUIPMENTS_REQUEST,
  TRANSFER_EQUIPMENTS_SUCCESS,
} from './Equipments.constant';

const initialState = {
  loadingFilter: false,
  loadingChange: false,
  lastPage: null,
  data: [],
};

export default function EquipmentsReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_EQUIPMENTS_DATA:
      return {
        ...state,
        data: [],
      };
    case SET_EQUIPMENTS_REQUEST:
    case ADD_EQUIPMENTS_REQUEST:
    case DELETE_EQUIPMENTS_REQUEST:
    case TRANSFER_EQUIPMENTS_REQUEST:
      return {
        ...state,
        loadingChange: true,
      };
    case SET_EQUIPMENTS_FAILURE:
    case ADD_EQUIPMENTS_FAILURE:
    case DELETE_EQUIPMENTS_FAILURE:
    case TRANSFER_EQUIPMENTS_FAILURE:
      return {
        ...state,
        loadingChange: false,
      };
    case SET_EQUIPMENTS_SUCCESS:
    case TRANSFER_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        data: state.data.map((item) => {
          return item.id === action.payload.id ? action.payload : item;
        }),
        loadingChange: false,
      };
    case ADD_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        data: [action.payload, ...state.data],
        loadingChange: false,
      };
    case DELETE_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload.id),
        loadingChange: false,
      };
    case SEARCH_EQUIPMENTS_REQUEST:
      return {
        ...state,
        loadingFilter: true,
      };
    case SEARCH_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
        lastPage: action.payload.meta.last_page,
        loadingFilter: false,
      };
    case SEARCH_EQUIPMENTS_FAILURE:
      return {
        ...state,
        loadingFilter: false,
      };
    default:
      return state;
  }
}
