import { useEffect } from 'react';

import { PlusSVG } from '@assets/img/icons';
import { LayoutModal } from '@layouts';
import { useAppDispatch } from '@store/';
import { hideModal } from '@store/Modal';
import { createTransportRequest } from '@store/ProjectAdd';
import { UIButton, UIInput } from '@ui';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';
import './style.scss';

const WidgetsModalAddTransport = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const handlerSubmit = (values) => {
    dispatch(createTransportRequest(values));
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  return (
    <LayoutModal title={t('PROJECT.ADD_TRANSPORT')} className="modal__added">
      <form onSubmit={form.handleSubmit}>
        <div className="modal__added__items">
          <UIInput
            label={t('COMMON.NAME')}
            name="name"
            value={form.values.name}
            className="input"
            placeholder={t('COMMON.NAME')}
            classLabel="fw700"
            onChange={form.handleChange}
            isValid={!(form.errors.name && form.touched.name)}
            validateText={form.errors.name}
          />
          <UIInput
            label={t('COMMON.PHONE')}
            name="phone"
            value={form.values.phone}
            className="input"
            placeholder={t('COMMON.PHONE')}
            classLabel="fw700"
            onChange={form.handleChange}
            isValid={!(form.errors.phone && form.touched.phone)}
            validateText={form.errors.phone}
          />
          <UIInput
            label={t('PROJECT.TRANSPORT')}
            name="transport"
            value={form.values.transport}
            className="input"
            placeholder={t('PROJECT.TRANSPORT')}
            classLabel="fw700"
            onChange={form.handleChange}
            isValid={!(form.errors.transport && form.touched.transport)}
            validateText={form.errors.transport}
          />
          <div className="added-btn">
            <UIButton type={Button.SUBMIT} className="outline__blue">
              <PlusSVG />
              <span>{t('COMMON.ADD')}</span>
            </UIButton>
            <UIButton
              className="btn__gray"
              handler={() => dispatch(hideModal())}
            >
              {t('COMMON.CANCEL')}
            </UIButton>
          </div>
        </div>
      </form>
    </LayoutModal>
  );
};

export default WidgetsModalAddTransport;
