export const MinusLightSVG = () => {
  return (
    <svg
      width="12"
      height="1"
      viewBox="0 0 12 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 0.5H1" stroke="#809FB8" strokeLinecap="round" />
    </svg>
  );
};
