/* eslint-disable no-nested-ternary */
import { MinusLightSVG, PlusLightSVG } from '@assets/img/icons';
import cn from 'classnames';

import UIButton from '../Button';
import UIError from '../Error';

const UIInputNumber = ({ forwardRef, wrapRef, ...props }) => {
  const {
    label,
    name,
    onChange,
    onKeyPress,
    placeholder,
    max = 9999999999,
    min = 0,
    value = +min || 0,
    validateText,
    prop,
    classLabel,
    classInput,
    className = 'input__number',
    type = 'number',
    disabled = false,
    required = false,
    isValid = true,
    validationTextPosition = 'bottom',
    onFocus,
    onBlur,
  } = props;

  const handlerChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const handlerKeyPress = (e) => {
    if (onKeyPress && e.key === 'Enter') {
      onKeyPress(+value);
    }
  };

  const handlerSet = (val) => {
    if (onChange && val >= min && val <= max) {
      onChange(val);
    }
  };

  return (
    <div
      ref={wrapRef}
      className={cn({ 'validate-error': !isValid, disabled }, className)}
    >
      {label && <span className={classLabel}>{label}</span>}
      <div>
        <UIButton
          defaultClassName=""
          handler={() => handlerSet(+value - 1)}
          className="input-number__prev"
        >
          <MinusLightSVG />
        </UIButton>
        <input
          name={name}
          type={type}
          value={
            type === 'string'
              ? value === 0 || value === '0'
                ? 0
                : value || ''
              : +value || ''
          }
          placeholder={placeholder}
          onChange={handlerChange}
          onKeyPress={handlerKeyPress}
          disabled={disabled}
          required={required}
          max={+max}
          min={+min}
          ref={forwardRef}
          className={classInput}
          onFocus={onFocus}
          onBlur={onBlur}
          {...prop}
        />
        <UIButton
          defaultClassName=""
          handler={() => handlerSet(+value + 1)}
          className="input-number__next"
        >
          <PlusLightSVG />
        </UIButton>
        <UIError
          isValid={isValid}
          textPosition={validationTextPosition}
          text={validateText}
        />
      </div>
    </div>
  );
};

export default UIInputNumber;
