export const CircleCloseSVG = ({ className }) => {
  return (
    <svg
      className={className}
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7841 17L22.3896 12.3945C22.4806 12.2882 22.5282 12.1515 22.5228 12.0117C22.5174 11.8718 22.4594 11.7392 22.3605 11.6403C22.2616 11.5413 22.1289 11.4834 21.9891 11.478C21.8493 11.4726 21.7126 11.5201 21.6063 11.6111L17.0007 16.2167L12.3952 11.6056C12.2906 11.501 12.1487 11.4422 12.0007 11.4422C11.8528 11.4422 11.7109 11.501 11.6063 11.6056C11.5017 11.7102 11.4429 11.8521 11.4429 12C11.4429 12.148 11.5017 12.2899 11.6063 12.3945L16.2174 17L11.6063 21.6056C11.5481 21.6554 11.5009 21.7167 11.4676 21.7856C11.4342 21.8545 11.4155 21.9296 11.4125 22.0061C11.4096 22.0826 11.4225 22.1589 11.4504 22.2302C11.4783 22.3015 11.5207 22.3662 11.5748 22.4204C11.629 22.4745 11.6937 22.5169 11.765 22.5448C11.8363 22.5728 11.9126 22.5856 11.9891 22.5827C12.0656 22.5797 12.1407 22.561 12.2096 22.5277C12.2785 22.4943 12.3398 22.4471 12.3896 22.3889L17.0007 17.7834L21.6063 22.3889C21.7126 22.4799 21.8493 22.5275 21.9891 22.5221C22.1289 22.5167 22.2616 22.4587 22.3605 22.3598C22.4594 22.2608 22.5174 22.1282 22.5228 21.9884C22.5282 21.8486 22.4806 21.7119 22.3896 21.6056L17.7841 17Z"
        fill="#3A36DB"
      />
      <rect
        x="0.5"
        y="0.5"
        width="33"
        height="33"
        rx="16.5"
        stroke="#3A36DB"
        strokeOpacity="0.25"
      />
    </svg>
  );
};
