import { useEffect } from 'react';

import { PlusSVG } from '@assets/img/icons';
import { useAppDispatch } from '@store/';
import {
  addGroupsRequest,
  // deleteGroupsRequest,
  setGroupsRequest,
} from '@store/Categories';
import { UIButton, UIInput } from '@ui';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const CategoriesFormGroups = ({ edit, resetEdit, resetCol, loading }) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const handlerSubmit = (values, { resetForm }) => {
    if (edit) {
      dispatch(
        setGroupsRequest({
          ...values,
          active: edit.active,
          id: edit.id,
        }),
      );
    } else {
      dispatch(addGroupsRequest({ ...values, active: true }));
      resetForm();
    }
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  useEffect(() => {
    if (edit && edit.name !== form.values.name) {
      form.setFieldValue('name', edit.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  // const handlerDelete = () => {
  //   dispatch(deleteGroupsRequest(edit.id));
  //   form.resetForm();
  //   resetEdit();
  //   resetCol();
  // };

  return (
    <form onSubmit={form.handleSubmit} className="categories-new">
      <div className="categories-new__title h3 fw600">
        {edit ? t('CATEGORIES.WAREHOUSE_EDIT') : t('CATEGORIES.WAREHOUSE_ADD')}
      </div>

      <div className="categories-new__items">
        <UIButton
          defaultClassName="categories__clear color_grey h6 fw600"
          type={Button.BUTTON}
          handler={() => {
            form.resetForm();
            resetEdit();
            resetCol();
          }}
        >
          {t('COMMON.CLEAR')}
        </UIButton>

        <UIInput
          name="name"
          id="name"
          label={t('COMMON.TITLE')}
          classLabel="fw600"
          className="input input__row"
          placeholder={t('COMMON.TITLE')}
          value={form.values.name}
          onChange={form.handleChange}
          isValid={!(form.errors.name && form.touched.name)}
          validateText={form.errors.name}
        />

        <div className="categories-new__btn">
          <UIButton
            loading={loading}
            className="btn outline__blue"
            type={Button.SUBMIT}
          >
            {edit ? (
              <>{t('COMMON.UPDATE')}</>
            ) : (
              <>
                <PlusSVG />
                <span>{t('COMMON.ADD')}</span>
              </>
            )}
          </UIButton>
          {/* {edit ? (
            <UIButton
              loading={loading}
              className="btn btn__red"
              handler={handlerDelete}
              type={Button.BUTTON}
            >
              Удалить
            </UIButton>
          ) : null} */}
        </div>
      </div>
    </form>
  );
};

export default CategoriesFormGroups;
