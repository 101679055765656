import { useEffect, useState } from 'react';

import { useAppDispatch } from '@store/';
import {
  getCategoriesRequest,
  getGroupsRequest,
  getPackagesRequest,
  setCategoriesRequest,
  setGroupsRequest,
  // setPackagesRequest,
} from '@store/Categories';
import { ModalType, showModal } from '@store/Modal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CategoriesFormCategories from './FormCategories';
import CategoriesFormGroups from './FormGroups';
// import CategoriesFormPackages from './FormPackages';
import CategoriesItem from './Item';

import './styles.scss';

const Categories = () => {
  const dispatch = useAppDispatch();
  const [editCategories, setEditCategories] = useState(null);
  const [colCategories, setColCategories] = useState(null);
  // const [editPackages, setEditPackages] = useState(null);
  // const [colPackages, setColPackages] = useState(null);
  const [editGroups, setEditGroups] = useState(null);
  const [colGroups, setColGroups] = useState(null);
  const {
    loading,
    categories,
    // packages,
    groups,
    loadingCategories,
    // loadingPackages,
    loadingGroups,
  } = useSelector((state) => state.categories);

  const { t } = useTranslation();

  const setActive = (col, setCol, index, edit, fn) => {
    if (col && col === index) {
      dispatch(
        fn({
          ...edit,
          active: !edit.active,
        }),
      );
      setCol(null);
    }
  };

  const handlerOpenCategoriesModal = () => {
    dispatch(
      showModal({
        modalType: ModalType.MOBILE_FORM_CATEGORIES,
        modalProps: {
          loading: loadingCategories,
          resetEdit: () => setEditCategories(null),
          resetCol: () => setColCategories(null),
          col: colCategories,
          edit: editCategories,
        },
      }),
    );
  };

  const handlerOpenGroupModal = () => {
    dispatch(
      showModal({
        modalType: ModalType.MOBILE_FORM_GROUPS,
        modalProps: {
          loading: loadingGroups,
          resetEdit: () => setEditGroups(null),
          resetCol: () => setColGroups(null),
          edit: editGroups,
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(getCategoriesRequest());
    dispatch(getPackagesRequest());
    dispatch(getGroupsRequest());
  }, [dispatch]);

  useEffect(() => {
    setActive(
      colCategories,
      setColCategories,
      2,
      editCategories,
      setCategoriesRequest,
    );
    // setActive(colPackages, setColPackages, 1, editPackages, setPackagesRequest);
    if (editGroups) {
      const { count, ...valEditGroups } = editGroups;

      setActive(colGroups, setColGroups, 1, valEditGroups, setGroupsRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    colGroups,
    // colPackages,
    colCategories,
  ]);

  return (
    <div className="categories-items">
      <CategoriesItem
        onClickRows={(e, index) => setEditCategories(categories[index])}
        onClickCol={(e, index) => setColCategories(index)}
        loading={loading}
        mobileModal={handlerOpenCategoriesModal}
        tdSpan={2}
        title={t('CATEGORIES.CATEGORIES')}
        titleBtnMobile={
          editCategories
            ? t('CATEGORIES.CATEGORY_EDIT')
            : t('CATEGORIES.CATEGORY_ADD')
        }
        data={{
          header: [
            t('COMMON.TITLE'),
            // 'Номер',
            // 'Активен',
          ],
          content: categories.map((item) => [
            item.name,
            // item.order_by,
            // item.active ? 'Да' : 'Нет',
          ]),
        }}
      >
        <CategoriesFormCategories
          loading={loadingCategories}
          resetEdit={() => setEditCategories(null)}
          resetCol={() => setColCategories(null)}
          col={colCategories}
          edit={editCategories}
        />
      </CategoriesItem>
      {/* <CategoriesItem
        onClickRows={(e, index) => setEditPackages(packages[index])}
        onClickCol={(e, index) => setColPackages(index)}
        title="Комплекты"
        loading={loading}
        mobileModal={() =>
          dispatch(
            showModal({
              modalType: ModalType.MOBILE_FORM_PACKAGES,
              modalProps: {
                loading: loadingPackages,
                resetEdit: () => setEditPackages(null),
                resetCol: () => setColPackages(null),
                edit: editPackages,
              },
            }),
          )
        }
        tdSpan={1}
        titleBtnMobile="Добавить комплект"
        data={{
          header: ['Название', 'Активен'],
          content: packages.map((item) => [
            item.name,
            item.active ? 'Да' : 'Нет',
          ]),
        }}
      >
        <CategoriesFormPackages
          loading={loadingPackages}
          resetEdit={() => setEditPackages(null)}
          resetCol={() => setColPackages(null)}
          col={colPackages}
          edit={editPackages}
        />
      </CategoriesItem> */}
      <CategoriesItem
        onClickRows={(e, index) => setEditGroups(groups[index])}
        onClickCol={(e, index) => setColGroups(index)}
        title={t('COMMON.WAREHOUSE')}
        loading={loading}
        mobileModal={handlerOpenGroupModal}
        tdSpan={1}
        titleBtnMobile={
          editGroups
            ? t('CATEGORIES.WAREHOUSE_EDIT')
            : t('CATEGORIES.WAREHOUSE_ADD')
        }
        data={{
          header: [
            t('COMMON.TITLE'),
            // 'Активен'
          ],
          content: groups.map((item) => [
            item.name,
            // item.active ? 'Да' : 'Нет',
          ]),
        }}
      >
        <CategoriesFormGroups
          loading={loadingGroups}
          resetEdit={() => setEditGroups(null)}
          resetCol={() => setColGroups(null)}
          col={colGroups}
          edit={editGroups}
        />
      </CategoriesItem>
    </div>
  );
};

export default Categories;
