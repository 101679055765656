import i18n from '@i18n';
import { LocalStorage } from '@services/storage';
import Axios from 'axios';

import { API_URL } from './constants';
import { LINK_LOGIN } from './link';
import { HttpStatus } from './utility-property';

const defaultConfig = {
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
};
// const refreshTokenUrl = '/api/auth/refresh-token';

const http = Axios.create(defaultConfig);

// let refreshTokenPromise;
// let isRefreshFinished = true;

http.interceptors.request.use(({ auth = true, ...config }) => {
  const AccessToken = LocalStorage.getAccessToken();

  const newConfig = {
    ...config,
    headers: {
      ...config.headers,
      ...(AccessToken && auth && { Authorization: `Bearer ${AccessToken}` }),
    },
  };

  return newConfig;
});

http.interceptors.response.use(undefined, (error) => {
  if (
    error.response?.status === HttpStatus.Unauthorized
    // && LocalStorage.getRefreshToken()
    // && Date.now() >= LocalStorage.getRefreshTokenData()
  ) {
    window.location.href = LINK_LOGIN;
    LocalStorage.clearToken();
    // const originalRequest = error.config;
    // if (isRefreshFinished) {
    //   isRefreshFinished = false;
    //   refreshTokenPromise = http({
    //     method: 'POST',
    //     url: refreshTokenUrl,
    //   })
    //     .then(() => {
    //       isRefreshFinished = true;
    //       return http(originalRequest);
    //     })
    //     .catch(() => {
    //       isRefreshFinished = true;
    //       window.location.href = LINK_LOGIN;
    //     });
    //   return refreshTokenPromise.then(() => http(originalRequest));
    // }
  } else if (error.response?.status === HttpStatus.Unauthorized) {
    // window.location.href = LINK_LOGIN;
    window.location.href = LINK_LOGIN;

    return Promise.reject();
  }

  if (
    error.response?.status === HttpStatus.UnprocessableEntity &&
    error.response?.data?.errors
  ) {
    let message = '';

    for (const prop in error.response.data.errors) {
      if (
        Object.prototype.hasOwnProperty.call(error.response.data.errors, prop)
      ) {
        message = error.response.data.errors;
      }
    }

    return Promise.reject(message);
  }

  if (error?.response.status === HttpStatus.Forbidden) {
    localStorage.clear();
    window.location.reload();
  }
  if (error?.response.status > HttpStatus.ServerError) {
    throw Error(i18n.t('ALERT.SERVER_ERROR'));
  }

  return Promise.reject(error.response?.data.message || error.toString());
});

export default http;
