import http from '@utils/http';
import qs from 'qs';

const basePath = '/api';

export const search = async (body) => {
  const data = await http.get(`${basePath}/equipments`, {
    params: body,
    paramsSerializer: (...data) => {
      return qs.stringify(...data, {
        encodeValuesOnly: true,
        skipNulls: true,
      });
    },
  });

  return data.data;
};

export const addEquipments = async (body) => {
  const data = await http.post(`${basePath}/equipments`, body);

  return data.data.data;
};

export const setEquipments = async ({ id, ...val }) => {
  const data = await http.put(`${basePath}/equipments/${id}`, val);

  return data.data.data;
};

export const deleteEquipments = async (body) => {
  const data = await http.delete(`${basePath}/equipments/${body}`);

  return data.data.data;
};

export const transferEquipments = async (body) => {
  const data = await http.post(`${basePath}/equipments-transfer`, body);

  return data.data.data;
};
