import {
  GET_GENERAL_FAILURE,
  GET_GENERAL_REQUEST,
  GET_GENERAL_SUCCESS,
  GET_RESPONSIBLE_FAILURE,
  GET_RESPONSIBLE_REQUEST,
  GET_RESPONSIBLE_SUCCESS,
} from './Reports.constant';

export function getGeneralRequest(payload) {
  return {
    type: GET_GENERAL_REQUEST,
    payload,
  };
}
export function getGeneralFailure(payload) {
  return {
    type: GET_GENERAL_FAILURE,
    payload,
  };
}
export function getGeneralSuccess(payload) {
  return {
    type: GET_GENERAL_SUCCESS,
    payload,
  };
}

export function getResponsibleRequest(payload) {
  return {
    type: GET_RESPONSIBLE_REQUEST,
    payload,
  };
}
export function getResponsibleFailure(payload) {
  return {
    type: GET_RESPONSIBLE_FAILURE,
    payload,
  };
}
export function getResponsibleSuccess(payload) {
  return {
    type: GET_RESPONSIBLE_SUCCESS,
    payload,
  };
}
