import {
  GET_USER_INFO_FAILURE,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  SET_USER_INFO_FAILURE,
  SET_USER_INFO_REQUEST,
  SET_USER_INFO_SUCCESS,
} from './Profile.constant';

export function getUserInfoRequest(payload) {
  return {
    type: GET_USER_INFO_REQUEST,
    payload,
  };
}
export function getUserInfoSuccess(payload) {
  return {
    type: GET_USER_INFO_SUCCESS,
    payload,
  };
}
export function getUserInfoFailure() {
  return {
    type: GET_USER_INFO_FAILURE,
  };
}

export function setUserInfoRequest(payload) {
  return {
    type: SET_USER_INFO_REQUEST,
    payload,
  };
}
export function setUserInfoSuccess(payload) {
  return {
    type: SET_USER_INFO_SUCCESS,
    payload,
  };
}
export function setUserInfoFailure() {
  return {
    type: SET_USER_INFO_FAILURE,
  };
}
