import {
  GET_EVENTS_REQUEST,
  GET_EVENTS_FAILURE,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_SUCCESS_YEAR,
  GET_ONE_EVENT_REQUEST,
  GET_ONE_EVENT_FAILURE,
  GET_ONE_EVENT_SUCCESS,
  ADD_ONE_EVENT_INFO,
  GET_OFFICES_REQUEST,
  GET_OFFICES_FAILURE,
  GET_OFFICES_SUCCESS,
  CHANGE_PARAM,
} from './Calendar.constant';

const initialState = {
  eventsInfo: [],
  events: [],
  eventsYear: [],
  offices: [],
  oneEvent: {},
  eventGuid: 0,
  loading: false,
  loadingModal: false,
  loadingOffices: false,
};

export default function calendarReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PARAM:
      return {
        ...state,
        ...action.payload,
      };
    case GET_OFFICES_REQUEST:
      return {
        ...state,
        loadingOffices: true,
      };
    case GET_OFFICES_SUCCESS:
      return {
        ...state,
        offices: action.payload,
        loadingOffices: false,
      };
    case GET_OFFICES_FAILURE:
      return {
        ...state,
        loadingOffices: false,
      };
    case GET_ONE_EVENT_REQUEST:
      return {
        ...state,
        loadingModal: true,
      };
    case GET_ONE_EVENT_SUCCESS:
      return {
        ...state,
        oneEvent: {
          ...state.oneEvent,
          ...action.payload,
        },
        loadingModal: false,
      };
    case GET_ONE_EVENT_FAILURE:
      return {
        ...state,
        loadingModal: false,
      };
    case ADD_ONE_EVENT_INFO:
      return {
        ...state,
        oneEvent: action.payload,
      };
    case GET_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_EVENTS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_EVENTS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        eventsYear: [],
        loading: false,
      };
    }
    case GET_EVENTS_SUCCESS_YEAR: {
      return {
        ...state,
        eventsYear: action.payload.events,
        loading: false,
      };
    }
    default:
      return state;
  }
}
