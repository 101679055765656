import cn from 'classnames';
import Select from 'react-select';

import UIError from '../Error';

function UISelect(props) {
  const {
    id,
    name,
    label,
    options,
    value,
    onChange,
    classLabel,
    disabled = false,
    isLoading = false,
    isValid = true,
    validateText,
    validationTextPosition = 'bottom',
    classNamePrefix = 'ui-select',
    placeholder,
    className,
    isSearchable,
    isClearable = true,
  } = props;

  const handlerChange = (item) => {
    if (item || item === null) {
      onChange(item);
    }
  };

  const colourStyles = {
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        color: isSelected ? '#3a36db' : '#000',
        cursor: 'pointer',
      };
    },
  };

  return (
    <div
      className={cn('custom-select', { 'validate-error': !isValid }, className)}
    >
      {label && (
        <div>
          <span className={cn('select-label', classLabel)}>{label}</span>
        </div>
      )}
      <div>
        <Select
          id={id}
          instanceId={id}
          name={name}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isLoading={isLoading}
          className={cn('ui-select-container')}
          classNamePrefix={classNamePrefix}
          value={value}
          isDisabled={disabled}
          onChange={handlerChange}
          options={options}
          placeholder={placeholder}
          styles={colourStyles}
          isSearchable={isSearchable}
          isClearable={isClearable}
        />
        <UIError
          isValid={isValid}
          text={validateText}
          textPosition={validationTextPosition}
        />
      </div>
    </div>
  );
}

export default UISelect;
