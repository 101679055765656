import { UIButton } from '@ui';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const ProjectTab = ({ tab, setTab }) => {
  const { t } = useTranslation();

  return (
    <div className="tabs wall-wrap__tabs">
      <UIButton
        defaultClassName=""
        className={cn({ active: tab })}
        handler={() => setTab(true)}
      >
        {t('PROJECT.MY_PROJECT')}
      </UIButton>

      <UIButton
        defaultClassName=""
        className={cn({ active: !tab })}
        handler={() => setTab(false)}
      >
        {t('PROJECT.PROJECT_ALL')}
      </UIButton>
    </div>
  );
};

export default ProjectTab;
