/* eslint-disable camelcase */
/* eslint-disable object-shorthand */

import { useEffect } from 'react';

import { DeleteSVG } from '@assets/img/icons';
import { LayoutModal } from '@layouts';
import { useAppDispatch } from '@store/';
import { hideModal } from '@store/Modal';
import { setUsersRequest } from '@store/Users';
import { UIButton, UIInput, UISelect } from '@ui';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './style.scss';

import { validationSchema } from '../../../modules/Users/FormUsers/schema';

const WidgetsModalSettingsUsers = ({
  id,
  email,
  first_name,
  last_name,
  phone,
  role,
  password,
  color,
  active,
}) => {
  // const [isOpenColor, setIsOpenColor] = useState(false);
  const { loading } = useSelector((state) => state.users);

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const handlerSubmit = (values) => {
    dispatch(setUsersRequest({ ...values, id, role: values.role.id }));
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      email,
      first_name,
      last_name,
      phone,
      password,
      color,
      active,
      role:
        role === 'admin'
          ? { id: 'admin', name: t('COMMON.ADMINISTRATOR') }
          : '',
    },
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  return (
    <LayoutModal title={t('COMMON.SETTINGS')} className="modal__users">
      <form onSubmit={form.handleSubmit} className="modal__added__items">
        {/* <UIColor
          className="users-color h6 fw700"
          setColor={(color) => form.setFieldValue('color', color)}
          color={form.values.color}
          setIsOpenColor={setIsOpenColor}
          isOpenColor={isOpenColor}
        /> */}
        <div className="modal-users__items">
          <UIInput
            label={t('COMMON.EMAIL')}
            name="email"
            placeholder={t('COMMON.EMAIL')}
            className="input input__icon"
            classLabel="fw600"
            value={form.values.email}
            onChange={form.handleChange}
            isValid={!(form.errors.email && form.touched.email)}
            validateText={form.errors.email}
          >
            <span className="input-icon__btn btn__close">
              <DeleteSVG fill="#000" />
            </span>
          </UIInput>
          <UIInput
            label={t('COMMON.NAME')}
            name="first_name"
            placeholder={t('COMMON.NAME')}
            classLabel="fw600"
            className="input input__icon"
            value={form.values.first_name}
            onChange={form.handleChange}
            isValid={!(form.errors.first_name && form.touched.first_name)}
            validateText={form.errors.first_name}
          >
            <span className="input-icon__btn btn__close">
              <DeleteSVG fill="#000" />
            </span>
          </UIInput>
          <UIInput
            label={t('COMMON.SURNAME')}
            name="last_name"
            placeholder={t('COMMON.SURNAME')}
            classLabel="fw600"
            className="input input__icon"
            value={form.values.last_name}
            onChange={form.handleChange}
            isValid={!(form.errors.last_name && form.touched.last_name)}
            validateText={form.errors.last_name}
          >
            <span className="input-icon__btn btn__close">
              <DeleteSVG fill="#000" />
            </span>
          </UIInput>
          <UIInput
            label={t('COMMON.PHONE')}
            name="phone"
            placeholder={t('COMMON.PHONE')}
            classLabel="fw600"
            className="input input__icon"
            value={form.values.phone}
            onChange={form.handleChange}
            isValid={!(form.errors.phone && form.touched.phone)}
            validateText={form.errors.phone}
          >
            <span className="input-icon__btn btn__close">
              <DeleteSVG fill="#000" />
            </span>
          </UIInput>
          <div className="select-column">
            <UISelect
              id="role"
              label={t('COMMON.ROLE')}
              classLabel="h6 fw600"
              isSearchable={false}
              options={[{ id: 'admin', name: t('COMMON.ADMINISTRATOR') }]}
              name="role"
              onChange={(value) => {
                form.setFieldValue('role', value);
              }}
              value={form.values.role}
              placeholder={t('COMMON.ROLE')}
              isValid={!(form.errors.role && form.touched.role)}
              validateText={form.errors.role}
            />
          </div>
          <UIInput
            label={t('COMMON.PASSWORD')}
            name="password"
            type="password"
            className="input input__icon"
            placeholder={t('COMMON.ENTER_PASSWORD')}
            classLabel="fw600"
            value={form.values.password}
            onChange={form.handleChange}
            isValid={!(form.errors.password && form.touched.password)}
            validateText={form.errors.password}
            isShowPassword
          />
          {/* <div className="modal-status__wrap">
            <div className="radio-wrap users-radio">
              <span className="h6 fw700">Статус</span>
              <div>
                <UIRadio
                  id="active_2"
                  type="radio"
                  name="active"
                  checked={form.values.active === '1'}
                  className="custom-radio"
                  value="1"
                  onChange={form.handleChange}
                >
                  Активен
                </UIRadio>
                <UIRadio
                  id="inactive_2"
                  type="radio"
                  name="active"
                  className="custom-radio"
                  value="0"
                  checked={form.values.active === '0'}
                  onChange={form.handleChange}
                >
                  Неактивен
                </UIRadio>
              </div>
              <UIError
                isValid={!(form.errors.active && form.touched.active)}
                text={form.errors.active}
              />
            </div>
          </div> */}
          <div className="users-btn">
            <UIButton
              loading={loading}
              className="btn outline__blue"
              type={Button.SUBMIT}
            >
              <span>{t('COMMON.SAVE')}</span>
            </UIButton>
            <UIButton
              handler={() => dispatch(hideModal())}
              className="btn btn__gray"
            >
              {t('COMMON.CANCEL')}
            </UIButton>
          </div>
        </div>
      </form>
    </LayoutModal>
  );
};

export default WidgetsModalSettingsUsers;
