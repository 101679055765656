/* eslint-disable camelcase */
import { useEffect, useMemo, useRef, useState } from 'react';

import { ArrLeftLongSVG, ArrLeftSVG, CalendarSVG } from '@assets/img/icons';
import { useEndLessScroll, useHidePopupOutside } from '@hooks';
import { useAppDispatch } from '@store/';
import {
  clearFilter,
  getEventsRequest,
  getOrganizersRequest,
} from '@store/Project';
import {
  UILoaderWrap,
  UIBack,
  UISelect,
  UIInput,
  UICalendar,
  UIButton,
  UITable,
} from '@ui';
import { FORMAT_DATE_QUERY, FORMAT_DATE_TEXT_ALT } from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { LINK_PROJECTS } from '@utils/link';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './style.scss';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const ProjectAll = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const {
    loading,
    // project,
    filter,
    lastPage,
    organizers,
    loadingOrganizers,
    // loading: loadingEvents,
  } = useSelector((state) => state.project);

  const [isFilterStartOpen, setFilterStartOpen] = useState(false);
  const [isFilterFinishOpen, setFilterFinishOpen] = useState(false);
  const [page, setPage] = useState(1);

  const filterStartRef = useRef(null);
  const filterFinishRef = useRef(null);

  useHidePopupOutside(filterStartRef, () => setFilterStartOpen(false));
  useHidePopupOutside(filterFinishRef, () => setFilterFinishOpen(false));

  const renderTableContent = useMemo(() => {
    return filter.map((item, index) => {
      return [
        <UIButton
          key={index}
          link={`${LINK_PROJECTS}/${item.id}`}
          type={Button.LINK}
        >
          {item.name}
        </UIButton>,
        DateHelper.toFormat(item.date_start_event * 1000, FORMAT_DATE_TEXT_ALT),
        DateHelper.toFormat(
          item.date_finish_event * 1000,
          FORMAT_DATE_TEXT_ALT,
        ),
        item.place,
        item.organizer.name,
      ];
    });
  }, [filter]);

  const [onScroll, containerRef] = useEndLessScroll({
    onIntersection: () => {
      if (page < lastPage && renderTableContent.length) {
        setPage(+page + 1);
      }
    },
  });

  useEffect(() => {
    dispatch(getOrganizersRequest());
    dispatch(getEventsRequest({ page }));
  }, [dispatch, page]);

  const header = [
    t('COMMON.PROJECT_NAME'),
    t('COMMON.BEGINNING'),
    t('COMMON.ENDING'),
    t('COMMON.PLODASCHKA'),
    t('COMMON.CUSTOMER'),
  ];

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  useEffect(() => {
    const date_start = form.values?.date_start;
    const date_finish = form.values?.date_finish;
    const organizer = form.values?.organizer?.id;

    dispatch(clearFilter());
    dispatch(
      getEventsRequest({
        page,
        ...((date_start || date_finish || organizer) && {
          filter: {
            ...(organizer && { organizer }),
            ...(date_start && { date_start }),
            ...(date_finish && { date_finish }),
          },
        }),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, form.values]);

  const handleDateClick = (date, name) => {
    form.setFieldValue(
      name,
      DateHelper.toFormat(date, FORMAT_DATE_QUERY, false),
    );
  };

  return (
    <UILoaderWrap loading={loading || loadingOrganizers}>
      <div className="projects-naw">
        <UIBack
          link={LINK_PROJECTS}
          className="projects-naw__title h4 color_blue"
          title={t('PROJECT.EVENTS_ALL')}
        >
          <ArrLeftSVG className="hide-mobile" />
          <ArrLeftLongSVG className="hide-desktop show-mobile" />
        </UIBack>
      </div>

      <form onSubmit={form.handleSubmit} className="projectsAll-filter">
        <UIInput
          label={t('COMMON.BEGINNING')}
          name="date_start"
          placeholder={t('PROJECT.SELECT_START_DATE')}
          className="input input__row input__icon"
          value={form.values.date_start}
          islabelRow
          isValid={!(form.errors.date_start && form.touched.date_start)}
          validateText={form.errors.date_start}
          onFocus={() => setFilterStartOpen(true)}
          wrapRef={filterStartRef}
          autoComplete="off"
        >
          <span
            className="input-icon__btn input-icon__calendar"
            onMouseDown={() => setFilterStartOpen(true)}
          >
            <CalendarSVG />
            {isFilterStartOpen && (
              <div className="rangeCalendar">
                <UICalendar
                  hidePopup={() => setFilterStartOpen(false)}
                  selectedDate={form.values.date_start}
                  handleDateClick={(date) =>
                    handleDateClick(date, 'date_start')
                  }
                />
              </div>
            )}
          </span>
        </UIInput>

        <UIInput
          label={t('COMMON.ENDING')}
          name="date_finish"
          placeholder={t('PROJECT.SELECT_END_DATE')}
          className="input input__row input__icon"
          value={form.values.date_finish}
          islabelRow
          isValid={!(form.errors.date_finish && form.touched.date_finish)}
          validateText={form.errors.date_finish}
          onFocus={() => setFilterFinishOpen(true)}
          wrapRef={filterFinishRef}
          autoComplete="off"
        >
          <span
            className="input-icon__btn input-icon__calendar"
            onMouseDown={() => setFilterFinishOpen(true)}
          >
            <CalendarSVG />
            {isFilterFinishOpen && (
              <div className="rangeCalendar">
                <UICalendar
                  hidePopup={() => setFilterFinishOpen(false)}
                  selectedDate={form.values.date_finish}
                  handleDateClick={(date) =>
                    handleDateClick(date, 'date_finish')
                  }
                />
              </div>
            )}
          </span>
        </UIInput>

        <UISelect
          label={t('COMMON.CUSTOMER')}
          className="select-wrap custom-select-row"
          options={organizers}
          name="organizer"
          value={form.values.organizer}
          onChange={(value) => {
            form.setFieldValue('organizer', value);
          }}
          isLoading={loadingOrganizers}
          placeholder={t('PROJECT.SELECT_CUSTOMER')}
          isValid={!(form.errors.organizer && form.touched.organizer)}
          validateText={form.errors.organizer}
        />
      </form>

      <div ref={containerRef} onScroll={onScroll} className="projectsAll-table">
        <UITable header={header} content={renderTableContent} />
      </div>
    </UILoaderWrap>
  );
};

export default ProjectAll;
