import {
  CALENDAR_TYPE_DAY,
  CALENDAR_TYPE_WEEK,
  FORMAT_DATE_QUERY,
  FORMAT_DAY_OF_WEEK,
  FORMAT_HOUR_ALT,
  FORMAT_TIME_SHORT_ALT,
  UNIX_DAY,
} from '@utils/constants';
import { DateHelper } from '@utils/helpers';

const buildDataInfo = (item, e, start, duration) => ({
  start,
  duration,
  range: item.range,
  title: e.eventStore.defs[item.defId].title,
  style: {
    backgroundColor: e.eventStore.defs[item.defId].ui.backgroundColor,
    color: e.eventStore.defs[item.defId].ui.textColor,
  },
  id: e.eventStore.defs[item.defId].publicId,
  ...e.eventStore.defs[item.defId].extendedProps,
});

const parserData = (e, type, daysToCalendar) => {
  const data = [];

  const calendarStart = DateHelper.timezoneChange(
    e.dateProfile?.activeRange.start,
  );
  const calendarEnd = DateHelper.timezoneChange(e.dateProfile?.activeRange.end);
  const startWeekUnix = calendarStart.valueOf();
  const startWeekTime = DateHelper.toFormat(
    startWeekUnix,
    FORMAT_TIME_SHORT_ALT,
    false,
  );

  const buildData = (
    indexWeek,
    hour,
    item,
    e,
    start,
    duration,
    repeat = false,
  ) => {
    const startDay =
      repeat || type === CALENDAR_TYPE_DAY
        ? start
        : +DateHelper.toFormat(start, FORMAT_DAY_OF_WEEK, false);
    const durationNextWeek =
      duration - (repeat ? daysToCalendar : daysToCalendar + 1 - startDay);

    if (+duration > 0) {
      data[indexWeek] = {
        ...data[indexWeek],
        [hour]: [
          ...(data[indexWeek]?.[hour] || []),
          buildDataInfo(item, e, startDay, duration),
        ],
      };
      if (durationNextWeek > 0 && type === CALENDAR_TYPE_WEEK) {
        buildData(+indexWeek + 1, '00:00', item, e, 1, durationNextWeek, true);
      }
    }
  };

  Object.values(e.eventStore.instances).forEach((item) => {
    const startEvent = DateHelper.timezoneChange(
      e.eventStore.defs[item.defId].extendedProps.loading,
    );
    const deys = (startEvent.valueOf() - startWeekUnix) / UNIX_DAY;
    const hour = `${DateHelper.toFormat(
      startEvent,
      FORMAT_HOUR_ALT,
      false,
    )}:00`;
    const indexWeek =
      Math.floor(deys / daysToCalendar) <= 0
        ? '0'
        : Math.floor(deys / daysToCalendar);
    const endRange = new Date(
      DateHelper.timezoneChange(item.range.end).valueOf() - UNIX_DAY,
    );
    const start = calendarStart > startEvent ? calendarStart : startEvent;
    const end =
      calendarEnd <= endRange
        ? new Date(calendarEnd.valueOf() - UNIX_DAY)
        : endRange;
    const duration =
      (new Date(DateHelper.toFormat(end, FORMAT_DATE_QUERY, false)).valueOf() -
        new Date(
          DateHelper.toFormat(start, FORMAT_DATE_QUERY, false),
        ).valueOf()) /
        UNIX_DAY +
      1;

    buildData(
      indexWeek,
      startEvent.valueOf() <= startWeekUnix ? startWeekTime : hour,
      item,
      e,
      type === CALENDAR_TYPE_DAY ? 1 : start,
      duration,
    );
  });

  data.forEach((item, index) => {
    const ordered = {};

    Object.keys(item)
      .sort()
      .forEach((key) => {
        ordered[key] = item[key];
      });
    data[index] = ordered;
  });

  return data;
};

export default parserData;
