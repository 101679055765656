import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEng from './locales/en/index';
import translationRu from './locales/ru/index';

const resources = {
  en: {
    translation: translationEng,
  },

  ru: {
    translation: translationRu,
  },
};

// in future get from localStorage
const language = 'ru';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,
    fallbackLng: 'ru', // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
