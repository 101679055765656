import { useEffect } from 'react';

import { PlusSVG } from '@assets/img/icons';
import { LayoutModal } from '@layouts';
import { useAppDispatch } from '@store/';
import { hideModal } from '@store/Modal';
import { createClientRequest } from '@store/ProjectAdd';
import { UIButton, UIInput } from '@ui';
import { Button } from '@utils/utility-property';
// eslint-disable-next-line import/order
import { useFormik } from 'formik';

import './style.scss';
import { useTranslation } from 'react-i18next';

import { INITIAL_VALUES } from '../../../modules/ProjectAdd/Main/Сlient/Form/constant';
import { validationSchema } from '../../../modules/ProjectAdd/Main/Сlient/Form/schema';

const WidgetsModalAddClient = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const handlerSubmit = (values) => {
    dispatch(createClientRequest(values));
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  return (
    <LayoutModal title={t('PROJECT.ADD_CUSTOMER')} className="modal__added">
      <form onSubmit={form.handleSubmit}>
        <div className="modal__added__items">
          <div className="input">
            <UIInput
              label={t('COMMON.MAIL')}
              name="email"
              placeholder={t('COMMON.MAIL')}
              classLabel="fw600"
              value={form.values.email}
              onChange={form.handleChange}
              isValid={!(form.errors.email && form.touched.email)}
              validateText={form.errors.email}
            />
          </div>
          <div className="input">
            <UIInput
              label={t('COMMON.FULL_NAME_MIN')}
              name="name"
              placeholder={t('COMMON.FULL_NAME_MIN')}
              classLabel="fw600"
              value={form.values.name}
              onChange={form.handleChange}
              isValid={!(form.errors.name && form.touched.name)}
              validateText={form.errors.name}
            />
          </div>
          <div className="input">
            <UIInput
              label={t('COMMON.PHONE')}
              name="phone"
              placeholder={t('COMMON.PHONE')}
              classLabel="fw600"
              value={form.values.phone}
              onChange={form.handleChange}
              isValid={!(form.errors.phone && form.touched.phone)}
              validateText={form.errors.phone}
            />
          </div>
          <div className="added-btn">
            <UIButton className="outline__blue" type={Button.SUBMIT}>
              <PlusSVG />
              <span>{t('COMMON.ADD')}</span>
            </UIButton>
            <UIButton
              className="btn__gray"
              handler={() => dispatch(hideModal())}
            >
              {t('COMMON.CANCEL')}
            </UIButton>
          </div>
        </div>
      </form>
    </LayoutModal>
  );
};

export default WidgetsModalAddClient;
