export default (() => {
  const hourToMinute = (hour) => {
    return Math.round(hour * 60);
  };

  const msToMin = (ms) => {
    return Math.floor(ms / 60000);
  };

  const roundingNearest = (num, round, limit) => {
    const col = (num / round).toFixed();
    const number = col * round;

    if (limit && number > limit) {
      return limit;
    }

    return number;
  };

  return {
    roundingNearest,
    hourToMinute,
    msToMin,
  };
})();
