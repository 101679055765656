export const CalendarSVG = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6 2.2002H3.4C2.07452 2.2002 1 3.27471 1 4.6002V10.6002C1 11.9257 2.07452 13.0002 3.4 13.0002H10.6C11.9255 13.0002 13 11.9257 13 10.6002V4.6002C13 3.27471 11.9255 2.2002 10.6 2.2002Z"
      stroke="#B3B3B3"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 5.8H13M4.6 1V3.4V1ZM9.4 1V3.4V1Z"
      stroke="#B3B3B3"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
