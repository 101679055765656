import logoName from '@assets/img/icons/logoName.svg';
import cn from 'classnames';

const WidgetHeader = ({ setIsMenu, isMenuMobile }) => {
  return (
    <div className="header-mobile">
      <div className="logo-mobile">
        <img src={logoName} alt="icon" />
      </div>
      <div
        className={cn('mobile-burger', { active: isMenuMobile })}
        onClick={() => setIsMenu(!isMenuMobile)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default WidgetHeader;
