import http from '@utils/http';

const basePath = '/api';

export const getUser = async (body) => {
  const data = await http.get(`${basePath}/users/${body}`);

  return data.data.data;
};

export const setUser = async (body) => {
  const { id, ...val } = body;

  const data = await http.put(`${basePath}/users/${id}`, val);

  return data.data.data;
};
