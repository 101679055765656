import { Button } from '@utils';
import { LoaderColorType } from '@utils/utility-property';
import cn from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import { UILoaderSmall } from '../Loader';

const UIButton = (props) => {
  const {
    link,
    handler,
    children,
    activeClassName,
    className = '',
    type = Button.BUTTON,
    disabled = false,
    loading = false,
    defaultClassName = 'btn',
    target = false,
  } = props;
  const history = useLocation();

  const renderLink = () => {
    if (link && !disabled) {
      return (
        <NavLink
          to={link}
          disabled={disabled || loading}
          className={({ isActive }) =>
            cn(className, activeClassName && { [activeClassName]: isActive })
          }
          onClick={handler}
          {...(target && { target: '_blank' })}
        >
          {loading ? <UILoaderSmall /> : children}
        </NavLink>
      );
    }

    return (
      <div className={cn('disabled', className)}>
        {loading ? <UILoaderSmall /> : children}
      </div>
    );
  };

  const renderButton = () => {
    if (type === Button.BUTTON || type === Button.SUBMIT) {
      return (
        <button
          type={type}
          className={cn(defaultClassName, className, {
            btn_loading: loading,
          })}
          disabled={disabled || loading}
          onClick={link ? () => history.push(link) : handler}
        >
          {children}
          {loading && <UILoaderSmall colorType={LoaderColorType.SECONDARY} />}
        </button>
      );
    }

    return null;
  };

  switch (type) {
    case Button.LINK:
      return renderLink();
    default:
      return renderButton();
  }
};

export default UIButton;
