import { useEffect, useState } from 'react';

import { ResponsiveWidth } from '@utils/utility-property';

const responsiveConfig = {
  xs: ResponsiveWidth.XS,
  sm: ResponsiveWidth.SM,
  md: ResponsiveWidth.MD,
  lg: ResponsiveWidth.LG,
  xl: ResponsiveWidth.XL,
};

const getCurrentViewportSize = () => {
  const width = typeof window !== 'undefined' ? window.innerWidth : 0;
  const breakpoints = Object.values(responsiveConfig).sort((a, b) => a - b);
  const index = breakpoints.findIndex((i) => width <= i);

  const currentBreakpoint =
    +breakpoints[index === -1 ? breakpoints.length - 1 : index - 1];

  const currentViewportSize = Object.keys(responsiveConfig)
    .filter((key) => responsiveConfig[key] === currentBreakpoint)
    .join();

  return currentViewportSize;
};

const useResponsive = () => {
  const [currentSize, setCurrentSize] = useState(getCurrentViewportSize());

  const resizeHandler = () => {
    const currentViewportSize = getCurrentViewportSize();

    if (!currentViewportSize) throw new Error();
    setCurrentSize(currentViewportSize);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return {
    currentSize,
    isMobile: currentSize === 'xs',
    isTablet: currentSize === 'sm',
    isIpad: currentSize === 'md',
    isDesktop: currentSize === 'lg',
    isDesktopXL: currentSize === 'xl',
  };
};

export default useResponsive;
