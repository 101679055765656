import React from 'react';

import cn from 'classnames';

import style from './style.module.scss';

const UIError = ({ isValid = true, textPosition = 'top', text }) =>
  !isValid ? (
    <span
      className={cn(style['validate-text'], {
        [style['validate-top']]: textPosition === 'top',
        [style['validate-bottom']]: textPosition === 'bottom',
      })}
    >
      {text}
    </span>
  ) : null;

export default UIError;
