import { LayoutModal } from '@layouts';
import { useAppDispatch } from '@store/';
import { hideModal } from '@store/Modal';
import { UIButton } from '@ui';
import { useTranslation } from 'react-i18next';
import './style.scss';

const WidgetsModalDeleteBooking = (modalProps) => {
  const { handler, title } = modalProps;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const closeModal = () => {
    dispatch(hideModal());
  };

  const onDelete = () => {
    handler();
    closeModal();
  };

  return (
    <LayoutModal className="modal__deleteBooking">
      <div className="modal-header">
        {t('ALERT.DO_YOU_REALLY_WANT_TO_DELETE')}
        <span className="color_darkBlue"> {title}</span>?
      </div>
      <div className="deleteBooking_btn">
        <UIButton className="btn__gray" handler={closeModal}>
          {t('COMMON.CANCEL')}
        </UIButton>
        <UIButton handler={() => onDelete()}>{t('COMMON.DELETE')}</UIButton>
      </div>
    </LayoutModal>
  );
};

export default WidgetsModalDeleteBooking;
