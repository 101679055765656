import {
  ADD_EQUIPMENTS_FAILURE,
  ADD_EQUIPMENTS_REQUEST,
  ADD_EQUIPMENTS_SUCCESS,
  CLEAR_EQUIPMENTS_DATA,
  DELETE_EQUIPMENTS_FAILURE,
  DELETE_EQUIPMENTS_REQUEST,
  DELETE_EQUIPMENTS_SUCCESS,
  SEARCH_EQUIPMENTS_FAILURE,
  SEARCH_EQUIPMENTS_REQUEST,
  SEARCH_EQUIPMENTS_SUCCESS,
  SET_EQUIPMENTS_FAILURE,
  SET_EQUIPMENTS_REQUEST,
  SET_EQUIPMENTS_SUCCESS,
  TRANSFER_EQUIPMENTS_FAILURE,
  TRANSFER_EQUIPMENTS_REQUEST,
  TRANSFER_EQUIPMENTS_SUCCESS,
} from './Equipments.constant';

export function searchEquipmentsRequest(payload) {
  return {
    type: SEARCH_EQUIPMENTS_REQUEST,
    payload,
  };
}
export function searchEquipmentsFailure(payload) {
  return {
    type: SEARCH_EQUIPMENTS_FAILURE,
    payload,
  };
}
export function searchEquipmentsSuccess(payload) {
  return {
    type: SEARCH_EQUIPMENTS_SUCCESS,
    payload,
  };
}

export function setEquipmentsRequest(payload) {
  return {
    type: SET_EQUIPMENTS_REQUEST,
    payload,
  };
}
export function setEquipmentsFailure(payload) {
  return {
    type: SET_EQUIPMENTS_FAILURE,
    payload,
  };
}
export function setEquipmentsSuccess(payload) {
  return {
    type: SET_EQUIPMENTS_SUCCESS,
    payload,
  };
}

export function addEquipmentsRequest(payload) {
  return {
    type: ADD_EQUIPMENTS_REQUEST,
    payload,
  };
}
export function addEquipmentsFailure(payload) {
  return {
    type: ADD_EQUIPMENTS_FAILURE,
    payload,
  };
}
export function addEquipmentsSuccess(payload) {
  return {
    type: ADD_EQUIPMENTS_SUCCESS,
    payload,
  };
}

export function deleteEquipmentsRequest(payload) {
  return {
    type: DELETE_EQUIPMENTS_REQUEST,
    payload,
  };
}
export function deleteEquipmentsFailure(payload) {
  return {
    type: DELETE_EQUIPMENTS_FAILURE,
    payload,
  };
}
export function deleteEquipmentsSuccess(payload) {
  return {
    type: DELETE_EQUIPMENTS_SUCCESS,
    payload,
  };
}

export function transferEquipmentsRequest(payload) {
  return {
    type: TRANSFER_EQUIPMENTS_REQUEST,
    payload,
  };
}
export function transferEquipmentsFailure(payload) {
  return {
    type: TRANSFER_EQUIPMENTS_FAILURE,
    payload,
  };
}
export function transferEquipmentsSuccess(payload) {
  return {
    type: TRANSFER_EQUIPMENTS_SUCCESS,
    payload,
  };
}

export function clearData(payload) {
  return {
    type: CLEAR_EQUIPMENTS_DATA,
    payload,
  };
}
