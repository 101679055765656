import { LayoutAccount } from '@layouts';
import Transfer from '@modules/Transfer';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

const ViewTransfer = () => {
  const { t } = useTranslation();

  return (
    <LayoutAccount title={t('TRANSFER.TRANSFER')} className="transfer-page">
      <Routes>
        <Route path="/" element={<Transfer />} />
      </Routes>
    </LayoutAccount>
  );
};

export default ViewTransfer;
