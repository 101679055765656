/* eslint-disable indent */
import { Fragment, useEffect, useState } from 'react';

import { AddCircleSVG } from '@assets/img/icons';
import { useAppDispatch } from '@store/';
import {
  getCategoriesRequest,
  getGroupsRequest,
  getPackagesRequest,
} from '@store/Categories';
import { ModalType, showModal } from '@store/Modal';
import { UIButton } from '@ui';
import { Button } from '@utils/utility-property';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import EquipmentsAddEquipments from './AddEquipments';
import EquipmentsFormFilter from './FormFilter';
import EquipmentsTable from './Table';

import './styles.scss';
// import EquipmentsTransferEquipments from './TransferEquipments';

const Equipments = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { loading, categories, packages, groups } = useSelector(
    (state) => state.categories,
  );
  const { loadingFilter, loadingChange, lastPage, data } = useSelector(
    (state) => state.equipments,
  );

  const [page, setPage] = useState(1);
  const [edit, setEdit] = useState(null);
  const [isModalTransfer, setModalTransfer] = useState(false);

  const handlerOpenEquipment = () => {
    dispatch(
      showModal({
        modalType: ModalType.ADD_EQUIPMENT,
        modalProps: {
          loadingChange,
          edit,
          resetEdit: () => setEdit(null),
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(getCategoriesRequest());
    dispatch(getPackagesRequest());
    dispatch(getGroupsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (edit && isModalTransfer) {
      dispatch(
        showModal({
          modalType: ModalType.TRANSFER_EQUIPMENT,
          modalProps: {
            loadingChange,
            edit,
            resetEdit: () => setEdit(null),
          },
        }),
      );
      setModalTransfer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, edit, isModalTransfer]);

  return (
    <>
      <div className="equipment-left">
        <div className="content__title">{t('COMMON.EQUIPMENT')}</div>

        <EquipmentsFormFilter
          loading={loading}
          categories={categories}
          packages={packages}
          groups={groups}
          page={page}
          clearPage={() => setPage(1)}
          setEdit={setEdit}
        />

        <UIButton
          type={Button.BUTTON}
          handler={handlerOpenEquipment}
          className="addElement equipment-add hide-desktop show-mobile"
        >
          <AddCircleSVG />
          <span className="h6 color_darkBlue">
            {edit
              ? t('EQUIPMENTS.EDIT_EQUIPMENT')
              : t('EQUIPMENTS.ADD_EQUIPMENT')}
          </span>
        </UIButton>

        <EquipmentsTable
          loading={loading}
          loadingFilter={loadingFilter}
          setEdit={setEdit}
          data={data}
          setModalTransfer={setModalTransfer}
          lastPage={lastPage}
          setPage={setPage}
          page={page}
        />
      </div>

      <div className="equipment-new">
        <div className="equipment-new__title h3 fw600">
          {t('EQUIPMENTS.ADD_EQUIPMENT')}
        </div>

        <EquipmentsAddEquipments
          loading={loading || loadingChange}
          categories={categories}
          packages={packages}
          groups={groups}
          edit={edit}
          resetEdit={() => setEdit(null)}
        />

        {/* <div className="stripe"></div> */}

        {/* <div className="equipment-new__title h3 fw600">
          t('TRANSFER.TRANSFER_TO_ANOTHER_WAREHOUSE')
        </div>
        
        <EquipmentsTransferEquipments
          loading={loading || loadingChange}
          groups={groups}
          edit={edit}
        /> */}
      </div>
    </>
  );
};

export default Equipments;
