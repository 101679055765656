/* eslint-disable camelcase */
import { useCallback, useMemo, useEffect, useRef, useState } from 'react';

import { CalendarSVG, MoveToSVG } from '@assets/img/icons';
import { useEndLessScroll, useHidePopupOutside } from '@hooks';
import { useAppDispatch } from '@store/';
import { ModalType, showModal } from '@store/Modal';
import { clearTransferData, transferAllRequest } from '@store/Transfer';
import { UICalendar, UIInput, UISelect, UITable } from '@ui';
import { FORMAT_DATE_QUERY } from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const TransferFormAdd = ({
  officeLoading,
  officeEvents,
  categories,
  groups,
  loading,
  loadingFilter,
  loadingNew,
  data,
  lastPage,
}) => {
  const [page, setPage] = useState(1);
  const [isGroupStartOpen, setGroupStartOpen] = useState(false);
  const [isGroupFinishOpen, setGroupFinishOpen] = useState(false);

  const groupStartRef = useRef(null);
  const groupFinishRef = useRef(null);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  useHidePopupOutside(groupStartRef, () => setGroupStartOpen(false));
  useHidePopupOutside(groupFinishRef, () => setGroupFinishOpen(false));

  const handlerSubmit = useCallback(
    (values, page) => {
      if (
        values &&
        values.group_at &&
        values.date_start &&
        values.date_finish
      ) {
        const {
          date_start,
          date_finish,
          category_id,
          office,
          group_for,
          ...val
        } = values;

        dispatch(
          transferAllRequest({
            page: page || 1,
            filter: {
              ...val,
              ...(date_start && { date_start }),
              ...(date_finish && { date_finish }),
              ...(category_id && { category_id }),
            },
          }),
        );
      } else {
        dispatch(clearTransferData());
      }
    },
    [dispatch],
  );

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  useEffect(() => {
    if (+page > 1) {
      handlerSubmit(form.values, page);
    }
  }, [form.values, handlerSubmit, page]);

  useEffect(() => {
    if (!loadingNew) {
      setPage(1);
      dispatch(clearTransferData());
      handlerSubmit(form.values, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlerSubmit, loadingNew]);

  const handleDateClick = (date, name) => {
    setPage(1);
    dispatch(clearTransferData());
    form.setFieldValue(
      name,
      DateHelper.toFormat(date, FORMAT_DATE_QUERY, false),
    );
    handlerSubmit({
      ...form.values,
      [name]: DateHelper.toFormat(date, FORMAT_DATE_QUERY, false),
    });
  };

  const renderTableContent = useMemo(
    () =>
      data?.map((i, index) => {
        const count =
          form.values?.group_at &&
          i.group?.find((i) => i.id === form.values.group_at).count;
        const modalProps = {
          ...form.values,
          groups: groups.find((i) => i.id === form.values?.group_for),
          officeEvents: officeEvents.find((i) => i.id === form.values.office),
          count:
            form.values?.group_at &&
            i.group?.find((i) => i.id === form.values.group_at).count,
          data: i,
        };

        return [
          <>
            {i?.name}
            <div
              onClick={() =>
                dispatch(
                  showModal({
                    modalType: ModalType.TRANSFER__ADD,
                    modalProps,
                  }),
                )
              }
              className="mobile-icon_transfer hide-desktop show-mobile"
            >
              <MoveToSVG />
            </div>
          </>,
          i?.category?.name,
          form.values?.group_at &&
            i.group?.find((i) => i.id === form.values.group_at).name,
          count,
          <span
            className="cursor_pointer hide-mobile"
            onClick={() =>
              dispatch(
                showModal({
                  modalType: ModalType.TRANSFER__ADD,
                  modalProps,
                }),
              )
            }
            key={index}
          >
            <MoveToSVG />
          </span>,
        ];
      }),
    [data, dispatch, form.values, groups, officeEvents],
  );

  const [onScroll, containerRef] = useEndLessScroll({
    onIntersection: () => {
      if (page < lastPage && renderTableContent.length) {
        setPage(+page + 1);
      }
    },
  });

  return (
    <>
      <form onSubmit={form.handleSubmit}>
        <div>
          <UIInput
            label={t('COMMON.BEGINNING')}
            name="date_start"
            placeholder={t('TRANSFER.SELECT_START_DATE')}
            className="input input__row input__icon"
            classLabel="h6 fw600"
            value={form.values.date_start}
            islabelRow
            isValid={!(form.errors.date_start && form.touched.date_start)}
            validateText={form.errors.date_start}
            onFocus={() => setGroupStartOpen(true)}
            wrapRef={groupStartRef}
          >
            <span
              className="input-icon__btn input-icon__calendar"
              onMouseDown={() => setGroupStartOpen(true)}
            >
              <CalendarSVG />
              {isGroupStartOpen && (
                <div className="rangeCalendar">
                  <UICalendar
                    minDate={new Date()}
                    maxDate={form.values?.date_finish}
                    hidePopup={() => setGroupStartOpen(false)}
                    selectedDate={form.values.date_start}
                    handleDateClick={(date) =>
                      handleDateClick(date, 'date_start')
                    }
                  />
                </div>
              )}
            </span>
          </UIInput>

          <UIInput
            label={t('COMMON.ENDING')}
            name="date_finish"
            placeholder={t('TRANSFER.SELECT_END_DATE')}
            className="input input__row input__icon"
            classLabel="h6 fw600"
            value={form.values.date_finish}
            islabelRow
            isValid={!(form.errors.date_finish && form.touched.date_finish)}
            validateText={form.errors.date_finish}
            onFocus={() => setGroupFinishOpen(true)}
            wrapRef={groupFinishRef}
          >
            <span
              className="input-icon__btn input-icon__calendar"
              onMouseDown={() => setGroupFinishOpen(true)}
            >
              <CalendarSVG />
              {isGroupFinishOpen && (
                <div style={{ right: '-50px' }} className="rangeCalendar">
                  <UICalendar
                    hidePopup={() => setGroupFinishOpen(false)}
                    minDate={
                      form.values?.date_start &&
                      new Date(form.values?.date_start).valueOf() >
                        new Date().valueOf()
                        ? form.values?.date_start
                        : new Date()
                    }
                    selectedDate={form.values.date_finish}
                    handleDateClick={(date) =>
                      handleDateClick(date, 'date_finish')
                    }
                  />
                </div>
              )}
            </span>
          </UIInput>
        </div>

        <div>
          <UISelect
            name="group_at"
            loading={loading}
            className="select-wrap custom-select-row"
            label={t('COMMON.FROM_WAREHOUSE')}
            classLabel="h6 fw600"
            options={groups}
            placeholder={t('TRANSFER.SELECT_WAREHOUSE')}
            value={groups.find((i) => i.id === form.values.group_at)}
            onChange={(value) => {
              setPage(1);
              dispatch(clearTransferData());
              form.setFieldValue('group_at', value?.id || '');
              handlerSubmit({ ...form.values, group_at: value?.id || '' });
            }}
            isValid={!(form.errors.group_at && form.touched.group_at)}
            validateText={form.errors.group_at}
          />

          <UISelect
            label={t('COMMON.CATEGORY')}
            className="select-wrap custom-select-row"
            options={categories}
            name="category_id"
            loading={loading}
            classLabel="h6 fw600"
            placeholder={t('TRANSFER.SELECT_CATEGORY')}
            value={categories.find((i) => i.id === form.values.category_id)}
            onChange={(value) => {
              setPage(1);
              dispatch(clearTransferData());
              form.setFieldValue('category_id', value?.id || '');
              handlerSubmit({ ...form.values, category_id: value?.id || '' });
            }}
            isValid={!(form.errors.category_id && form.touched.category_id)}
            validateText={form.errors.category_id}
          />
        </div>

        <div>
          <UISelect
            name="group_for"
            loading={loading}
            className="select-wrap custom-select-row"
            label={t('COMMON.TO_WAREHOUSE')}
            classLabel="h6 fw600"
            options={groups.filter((i) => i.id !== form.values?.group_at)}
            placeholder={t('TRANSFER.SELECT_WAREHOUSE')}
            value={groups.find((i) => i.id === form.values?.group_for)}
            onChange={(value) =>
              form.setFieldValue('group_for', value?.id || '')
            }
            isValid={!(form.errors.group_for && form.touched.group_for)}
            validateText={form.errors.group_for}
          />

          <UISelect
            label={t('COMMON.OFFICE')}
            className="select-wrap custom-select-row"
            options={officeEvents}
            name="office"
            loading={officeLoading}
            classLabel="h6 fw600"
            placeholder={t('COMMON.OFFICE')}
            value={officeEvents.find((i) => i.id === form.values.office)}
            onChange={(value) => {
              form.setFieldValue('office', value?.id || '');
            }}
            isValid={!(form.errors.office && form.touched.office)}
            validateText={form.errors.office}
          />
        </div>
      </form>
      <div
        ref={containerRef}
        onScroll={onScroll}
        className="transfer-addWrap__table"
      >
        <UITable
          header={[
            t('COMMON.TITLE'),
            t('COMMON.CATEGORY'),
            t('COMMON.WAREHOUSE'),
            t('COMMON.QUANTITY'),
            '',
          ]}
          {...(!(
            form.values?.group_at &&
            form.values.date_start &&
            form.values.date_finish
          ) && {
            textError: t('TRANSFER.SELECT_WAREHOUSE_AND__DATE_TRANSFER'),
          })}
          loading={loading || loadingFilter || loadingNew}
          content={renderTableContent}
        />
      </div>
    </>
  );
};

export default TransferFormAdd;
