/* eslint-disable indent */
import React, { useRef, useState } from 'react';

import { ArrLeftSVG, ArrRightSVG } from '@assets/img/icons';
import calendarActive from '@assets/img/icons/calendar_active.svg';
import { useHidePopupOutside, useResponsive } from '@hooks';
import { UICarouselCalendar, UILoaderWrap } from '@ui';
import { typeCarouselCalendar } from '@utils/utility-property';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const CalendarsHeader = ({
  loadingOffices,
  offices,
  mainCalendarRef,
  range,
  setRange,
}) => {
  const [isNavigatePopup, setIsNavigatePopup] = useState(false);
  const [isAllOffices, setIsAllOffices] = useState(false);

  const popupRef = useRef();
  const { t } = useTranslation();

  const currentType =
    mainCalendarRef.current?.getApi().currentDataManager.state.currentViewType;
  const buttonTab = [
    { id: 'day', name: t('COMMON.DAY') },
    { id: 'week', name: t('COMMON.WEEK') },
    { id: 'dayGridMonth', name: t('COMMON.MONTH') },
    { id: 'year', name: t('COMMON.YEAR') },
  ];
  // const [activeSelect, setActiveSelect] = useState(buttonTab[1]);
  const { isIpad, isTablet, isMobile } = useResponsive();

  useHidePopupOutside(popupRef, () => setIsNavigatePopup(false));

  return (
    <div className="calendar-setting">
      <UILoaderWrap
        className="calendar-setting__loader"
        bgTransparent={true}
        loading={loadingOffices}
      >
        <ul
          className={cn('calendar-setting__regions h6', {
            isAllOffices,
          })}
        >
          {offices.map((item, index) => {
            if (
              (isIpad || isTablet || isMobile) &&
              index > 5 &&
              !isAllOffices
            ) {
              return null;
            }

            return (
              <li key={item.id}>
                <span style={{ background: item.color }}></span>
                {item.name}
              </li>
            );
          })}
          {!isAllOffices ? (
            <div
              onClick={() => setIsAllOffices(!isAllOffices)}
              className="calendar-setting__more hide-desktop show-mobile"
            >
              {t('COMMON.SHOW_ALL')}
            </div>
          ) : null}
        </ul>
      </UILoaderWrap>
      <div className="calendar_navigate">
        <div ref={popupRef} className="calendar-setting__navigate h3 fw600">
          <button onClick={() => mainCalendarRef.current.getApi().prev()}>
            <ArrLeftSVG />
          </button>

          <div onClick={() => setIsNavigatePopup(true)}>
            <img src={calendarActive} alt="icon" />

            <span className="color_darkBlue">
              {currentType === 'year' && 'Год '}
              {mainCalendarRef.current
                ?.getApi()
                .getCurrentData()
                .viewTitle.replace(' г.', '')}
            </span>
          </div>

          {currentType === 'week' ? (
            <UICarouselCalendar
              className={cn('calendar-navigate__popup range__small', {
                active__popup: isNavigatePopup,
              })}
              type={typeCarouselCalendar.RANGE}
              range={range}
              setRange={setRange}
            />
          ) : null}

          {currentType === 'day' ? (
            <UICarouselCalendar
              className={cn('calendar-navigate__popup range__small', {
                active__popup: isNavigatePopup,
              })}
              type={typeCarouselCalendar.CALENDAR}
              range={
                mainCalendarRef.current?.getApi()?.getCurrentData()?.currentDate
              }
              setRange={(e) => mainCalendarRef.current?.getApi()?.gotoDate(e)}
            />
          ) : null}

          {currentType === 'dayGridMonth' ? (
            <UICarouselCalendar
              className={cn('calendar-navigate__popup range__small', {
                active__popup: isNavigatePopup,
              })}
              type={typeCarouselCalendar.MONTH}
              range={
                mainCalendarRef.current?.getApi()?.getCurrentData()?.currentDate
              }
              setRange={(e) => mainCalendarRef.current?.getApi()?.gotoDate(e)}
            />
          ) : null}

          <button onClick={() => mainCalendarRef.current.getApi().next()}>
            <ArrRightSVG />
          </button>
        </div>

        <div className="calendar-setting__tabs h7">
          {buttonTab.map((item) => (
            <button
              key={item.id}
              onClick={() =>
                mainCalendarRef.current?.getApi().changeView(item.id)
              }
              className={cn({ active: currentType === item.id })}
            >
              {item.name}
            </button>
          ))}
        </div>

        {/* <UISelect
          options={buttonTab}
          className="hide-desktop show-mobile"
          value={activeSelect}
          onChange={(value) => {
            mainCalendarRef.current?.getApi().changeView(value.id);
            setActiveSelect(value);
          }}
          isSearchable={false}
        /> */}
      </div>
    </div>
  );
};

export default CalendarsHeader;
