import i18n from '@i18n';
import { ProjectHttp } from '@services/http';
import { LocalStorage } from '@services/storage';
import { alertError, alertSuccess } from '@store/Alert';
import { setStep } from '@store/ProjectAdd';
import { call, fork, put, takeLatest } from 'redux-saga/effects';

import {
  getProjectSuccess,
  getProjectFailure,
  getOneProjectSuccess,
  getOneProjectFailure,
  editProjectSuccess,
  editProjectFailure,
  updateEditProjectSuccess,
  updateEditProjectFailure,
  getEventsFailure,
  getEventsSuccess,
  getOrganizersFailure,
  getOrganizersSuccess,
} from './Project.action';
import {
  GET_PROJECT_REQUEST,
  GET_ONE_PROJECT_REQUEST,
  EDIT_PROJECT_REQUEST,
  UPDATE_EDIT_PROJECT_REQUEST,
  GET_EVENTS_REQUEST,
  GET_ORGANIZERS_REQUEST,
} from './Project.constant';

function* workerGetEvents({ payload }) {
  try {
    const data = yield call(ProjectHttp.getEvents, payload);

    yield put(getEventsSuccess(data));
  } catch (error) {
    yield put(getEventsFailure());
    yield put(alertError());
  }
}
function* watchGetEvents() {
  yield takeLatest(GET_EVENTS_REQUEST, workerGetEvents);
}

function* workerGetOrganizers({ payload }) {
  try {
    const data = yield call(ProjectHttp.getOrganizers, payload);

    yield put(getOrganizersSuccess(data));
  } catch (error) {
    yield put(getOrganizersFailure());
    yield put(alertError());
  }
}
function* watchGetOrganizers() {
  yield takeLatest(GET_ORGANIZERS_REQUEST, workerGetOrganizers);
}

function* workerGetProject({ payload }) {
  try {
    const data = yield call(ProjectHttp.getProgect, payload);

    yield put(getProjectSuccess(data));
  } catch (error) {
    yield put(getProjectFailure());
    yield put(alertError());
  }
}
function* watchGetProject() {
  yield takeLatest(GET_PROJECT_REQUEST, workerGetProject);
}

function* workerGetOneProject({ payload }) {
  try {
    const data = yield call(ProjectHttp.getOneProgect, payload);

    yield put(getOneProjectSuccess(data));
  } catch (error) {
    yield put(getOneProjectFailure());
    yield put(alertError());
  }
}
function* watchGetOneProject() {
  yield takeLatest(GET_ONE_PROJECT_REQUEST, workerGetOneProject);
}

function* workerEditProject({ payload }) {
  try {
    const data = yield call(ProjectHttp.editProgect, payload);

    yield put(editProjectSuccess(data));
  } catch (error) {
    yield put(editProjectFailure());
    yield put(alertError());
  }
}
function* watchEditProject() {
  yield takeLatest(EDIT_PROJECT_REQUEST, workerEditProject);
}

function* workerUpdateEditProject({ payload, step = 1 }) {
  try {
    const data = yield call(ProjectHttp.updateEditProgect, payload);

    yield put(updateEditProjectSuccess(data));
    yield put(
      alertSuccess({
        message: i18n.t('ALERT.YOU_SUCCESSFULLY_UPDATED_PROJECT'),
      }),
    );

    yield put(setStep(step));
    LocalStorage.set('project_step', step);
  } catch (error) {
    yield put(updateEditProjectFailure());
    yield put(alertError());
  }
}
function* watchUpdateEditProject() {
  yield takeLatest(UPDATE_EDIT_PROJECT_REQUEST, workerUpdateEditProject);
}

export const projectWatchers = [
  fork(watchGetEvents),
  fork(watchGetProject),
  fork(watchGetOneProject),
  fork(watchEditProject),
  fork(watchUpdateEditProject),
  fork(watchGetOrganizers),
];
