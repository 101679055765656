export const ArrTopSVG = () => (
  <svg
    width="18"
    height="10"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 8.5L9 1.5L1.5 8.5"
      stroke="#3A36DB"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
