import React from 'react';

import cn from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

import UIError from '../Error';

const UITextarea = ({
  label,
  name,
  value,
  className,
  placeholder,
  disabled = false,
  required = true,
  onChange,
  onBlur,
  onKeyPress,
  isValid = true,
  validateText,
  validationTextPosition = 'bottom',
  rows,
  prop,
}) => {
  const handlerChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handlerKeyPress = (event) => {
    if (onKeyPress && event.key === 'Enter') {
      onKeyPress(value);
    }
  };

  const handlerBlur = () => {
    if (onBlur) {
      onBlur(value);
    }
  };

  return (
    <div className={cn({ 'validate-error': !isValid }, className)}>
      <TextareaAutosize
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handlerChange}
        onBlur={handlerBlur}
        onKeyPress={handlerKeyPress}
        disabled={disabled}
        required={required}
        rows={rows}
        {...prop}
      />
      {label && <label>{label}</label>}
      <UIError
        isValid={isValid}
        textPosition={validationTextPosition}
        text={validateText}
      />
    </div>
  );
};

export default UITextarea;
