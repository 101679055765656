import {
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS,
  UPLOAD_FAILURE,
} from './Upload.constant';

const initialState = {
  data: null,
  loading: false,
};

export default function uploadReducer(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPLOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case UPLOAD_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
