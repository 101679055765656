import {
  CHANGE_TRANSFER_FAILURE,
  CHANGE_TRANSFER_REQUEST,
  CHANGE_TRANSFER_SUCCESS,
  CLEAR_TRANSFER_DATA,
  DELETE_TRANSFER_FAILURE,
  DELETE_TRANSFER_REQUEST,
  DELETE_TRANSFER_SUCCESS,
  NEW_TRANSFER_FAILURE,
  NEW_TRANSFER_REQUEST,
  NEW_TRANSFER_SUCCESS,
  SEARCH_TRANSFER_FAILURE,
  SEARCH_TRANSFER_REQUEST,
  SEARCH_TRANSFER_SUCCESS,
  TRANSFER_ALL_FAILURE,
  TRANSFER_ALL_REQUEST,
  TRANSFER_ALL_SUCCESS,
  VALIDATION_TRANSFER_FAILURE,
  VALIDATION_TRANSFER_REQUEST,
  VALIDATION_TRANSFER_SUCCESS,
} from './Transfer.constant';

export function validationTransferRequest(payload) {
  return {
    type: VALIDATION_TRANSFER_REQUEST,
    payload,
  };
}
export function validationTransferFailure(payload) {
  return {
    type: VALIDATION_TRANSFER_FAILURE,
    payload,
  };
}
export function validationTransferSuccess(payload) {
  return {
    type: VALIDATION_TRANSFER_SUCCESS,
    payload,
  };
}

export function changeTransferRequest(payload) {
  return {
    type: CHANGE_TRANSFER_REQUEST,
    payload,
  };
}
export function changeTransferFailure(payload) {
  return {
    type: CHANGE_TRANSFER_FAILURE,
    payload,
  };
}
export function changeTransferSuccess(payload) {
  return {
    type: CHANGE_TRANSFER_SUCCESS,
    payload,
  };
}

export function deleteTransferRequest(payload) {
  return {
    type: DELETE_TRANSFER_REQUEST,
    payload,
  };
}
export function deleteTransferFailure(payload) {
  return {
    type: DELETE_TRANSFER_FAILURE,
    payload,
  };
}
export function deleteTransferSuccess(payload) {
  return {
    type: DELETE_TRANSFER_SUCCESS,
    payload,
  };
}

export function searchTransferRequest(payload) {
  return {
    type: SEARCH_TRANSFER_REQUEST,
    payload,
  };
}
export function searchTransferFailure(payload) {
  return {
    type: SEARCH_TRANSFER_FAILURE,
    payload,
  };
}
export function searchTransferSuccess(payload) {
  return {
    type: SEARCH_TRANSFER_SUCCESS,
    payload,
  };
}

export function transferAllRequest(payload) {
  return {
    type: TRANSFER_ALL_REQUEST,
    payload,
  };
}
export function transferAllFailure(payload) {
  return {
    type: TRANSFER_ALL_FAILURE,
    payload,
  };
}
export function transferAllSuccess(payload) {
  return {
    type: TRANSFER_ALL_SUCCESS,
    payload,
  };
}

export function newTransferRequest(payload) {
  return {
    type: NEW_TRANSFER_REQUEST,
    payload,
  };
}
export function newTransferFailure(payload) {
  return {
    type: NEW_TRANSFER_FAILURE,
    payload,
  };
}
export function newTransferSuccess(payload) {
  return {
    type: NEW_TRANSFER_SUCCESS,
    payload,
  };
}

export function clearTransferData(payload) {
  return {
    type: CLEAR_TRANSFER_DATA,
    payload,
  };
}
