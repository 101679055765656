import { PrivateRoute } from '@routes';
import { LINK_LOGIN, LINK_PROFILE } from '@utils';
import {
  LINK_CALENDARS,
  LINK_CATEGORIES,
  LINK_EQUIPMENTS,
  LINK_EVENTS,
  LINK_PROJECTS,
  LINK_PROJECTS_ADD,
  LINK_PROJECTS_EDIT,
  LINK_REPORTS,
  LINK_TRANSFER,
  LINK_USERS,
  LINK_WALL,
} from '@utils/link';
import { Navigate, Route, Routes } from 'react-router-dom';

import ViewCalendars from './Calendars';
import ViewCategories from './Categories';
import ViewEquipments from './Equipments';
import ViewEvents from './Events';
import ViewLogin from './Login';
import ViewProfile from './Profile';
import ViewProject from './Project';
import ViewProjectAdd from './ProjectAdd';
import ViewReports from './Reports';
import ViewTransfer from './Transfer';
import ViewUsers from './Users';
import ViewWall from './Wall';

const View = () => {
  return (
    <Routes>
      <Route path={`${LINK_LOGIN}/*`} element={<ViewLogin />} />
      <Route path={'/*'} element={<Navigate to={`${LINK_LOGIN}/`} />} />
      <Route
        path={`${LINK_PROFILE}/*`}
        element={
          <PrivateRoute>
            <ViewProfile />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_CALENDARS}/*`}
        element={
          <PrivateRoute>
            <ViewCalendars />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_USERS}/*`}
        element={
          <PrivateRoute>
            <ViewUsers />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_REPORTS}/*`}
        element={
          <PrivateRoute>
            <ViewReports />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_PROJECTS_ADD}/*`}
        element={
          <PrivateRoute>
            <ViewProjectAdd />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_EVENTS}/*`}
        element={
          <PrivateRoute>
            <ViewEvents />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_WALL}/*`}
        element={
          <PrivateRoute>
            <ViewWall />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_CATEGORIES}/*`}
        element={
          <PrivateRoute>
            <ViewCategories />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_EQUIPMENTS}/*`}
        element={
          <PrivateRoute>
            <ViewEquipments />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_PROJECTS}/*`}
        element={
          <PrivateRoute>
            <ViewProject />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_TRANSFER}/*`}
        element={
          <PrivateRoute>
            <ViewTransfer />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_PROJECTS_EDIT}/*`}
        element={
          <PrivateRoute>
            <ViewProjectAdd />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default View;
