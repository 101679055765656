import { useState } from 'react';

import { AddFileSVG } from '@assets/img/icons';
import { useAppDispatch } from '@store/';
import { alertError } from '@store/Alert';
// eslint-disable-next-line import/order
import { LoaderColorType } from '@utils/utility-property';

import './style.scss';
import { useTranslation } from 'react-i18next';

import { UILoaderSmall } from '../Loader';

const UIUpload = ({ handlerUpload, loading, label, sizeLimit }) => {
  const [fileName, setFileName] = useState(null);
  const disabled = useAppDispatch();
  const { t } = useTranslation();

  const handleFileChange = (event) => {
    if (event.target?.files?.length) {
      const file = event.target.files[0];

      if (file.size < sizeLimit || !sizeLimit) {
        setFileName(file.name);
        handlerUpload(file);
      } else {
        disabled(alertError(t('VALIDATION.DOWNLOAD_LIMIT_IN', '5 Mb')));
      }
    }
  };

  return (
    <div className="input-file__wrapper">
      <input
        onChange={handleFileChange}
        name="file"
        type="file"
        id="input__file"
        className="input__file"
        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.jpg,.jpeg,.png"
        multiple
      />

      <label htmlFor="input__file" className="input__file-button">
        <span className="input__file-icon-wrapper">
          <AddFileSVG />
        </span>
        <span className="input__file-button-text">
          {loading ? (
            <UILoaderSmall colorType={LoaderColorType.SECONDARY} />
          ) : (
            fileName || label
          )}
        </span>
      </label>
    </div>
  );
};

export default UIUpload;
