/* eslint-disable indent */
import {
  CLERA_STORE,
  CREATE_CLIENT_FAILURE,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  CREATE_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_REQUEST,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EQUIPMENT_FAILURE,
  CREATE_EQUIPMENT_REQUEST,
  CREATE_EQUIPMENT_SUCCESS,
  CREATE_MANAGER_FAILURE,
  CREATE_MANAGER_REQUEST,
  CREATE_MANAGER_SUCCESS,
  CREATE_PROJECT_FAILURE,
  CREATE_PROJECT_FINISH_FAILURE,
  CREATE_PROJECT_FINISH_REQUEST,
  CREATE_PROJECT_FINISH_SUCCESS,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_RESPONSIBLE_FAILURE,
  CREATE_RESPONSIBLE_REQUEST,
  CREATE_RESPONSIBLE_SUCCESS,
  CREATE_STAFF_FAILURE,
  CREATE_STAFF_REQUEST,
  CREATE_STAFF_SUCCESS,
  CREATE_TRANSPORT_FAILURE,
  CREATE_TRANSPORT_REQUEST,
  CREATE_TRANSPORT_SUCCESS,
  GET_EQUIPMENT_EVENT_FAILURE,
  GET_EQUIPMENT_EVENT_REQUEST,
  GET_EQUIPMENT_EVENT_SUCCESS,
  GET_LEGACY_EVENT_FAILURE,
  GET_LEGACY_EVENT_REQUEST,
  GET_LEGACY_EVENT_SUCCESS,
  GET_LIST_BOOKING_FAILURE,
  GET_LIST_BOOKING_REQUEST,
  GET_LIST_BOOKING_SUCCESS,
  GET_LIST_EQUIPMENT_SELECT_FAILURE,
  GET_LIST_EQUIPMENT_SELECT_REQUEST,
  GET_LIST_EQUIPMENT_SELECT_SUCCESS,
  GET_OFFICE_EVENT_FAILURE,
  GET_OFFICE_EVENT_REQUEST,
  GET_OFFICE_EVENT_SUCCESS,
  GET_ORGANIZERS_EVENT_FAILURE,
  GET_ORGANIZERS_EVENT_REQUEST,
  GET_ORGANIZERS_EVENT_SUCCESS,
  GET_TRANSPORT_LIST_FAILURE,
  GET_TRANSPORT_LIST_REQUEST,
  GET_TRANSPORT_LIST_SUCCESS,
  GET_USER_EVENT_FAILURE,
  GET_USER_EVENT_REQUEST,
  GET_USER_EVENT_SUCCESS,
  GET_USER_LIST_FAILURE,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_WAREHOUSE_EVENT_FAILURE,
  GET_WAREHOUSE_EVENT_REQUEST,
  GET_WAREHOUSE_EVENT_SUCCESS,
  IS_LOADED_EQUIPMENTS_EVENT,
  PROJECT_DELETE_FAILURE,
  PROJECT_DELETE_REQUEST,
  PROJECT_DELETE_SUCCESS,
  PROJECT_VALIDATION_FAILURE,
  PROJECT_VALIDATION_REQUEST,
  PROJECT_VALIDATION_SUCCESS,
  RESET_STORE,
  SET_STEP,
  SET_STEP1_FORM,
  SET_STEP2_FORM,
  SET_STEP3_FORM,
  RESET_ACTION,
} from './ProjectAdd.constant';

const initialState = {
  step1Form: null,
  step2Form: null,
  step3Form: null,
  organizerEvents: [],
  userEvents: [],
  officeEvents: [],
  legacyEvents: [],
  categoriesEvent: [],
  werehouseEvent: [],
  transportList: [],
  userList: [],
  bookingList: [],
  projectData: null,
  managerData: null,
  finishData: null,
  equipmentSelectData: null,
  validation: [],
  loading: false,
  loadingProject: false,
  loadingTransport: false,
  loadingUser: false,
  loadingCategories: false,
  loadingWerehouse: false,
  loadingEquipment: false,
  isLoadedEquipments: false,
  isProjectAction: false,
  step: 0,
};

export default function projectAddReducer(state = initialState, action) {
  switch (action.type) {
    case PROJECT_DELETE_REQUEST:
    case PROJECT_VALIDATION_REQUEST:
    case GET_ORGANIZERS_EVENT_REQUEST:
    case CREATE_CLIENT_REQUEST:
    case CREATE_EMPLOYEE_REQUEST:
    case GET_OFFICE_EVENT_REQUEST:
    case GET_LEGACY_EVENT_REQUEST:
    case CREATE_RESPONSIBLE_REQUEST:
    case CREATE_TRANSPORT_REQUEST:
    case CREATE_STAFF_REQUEST:
    case CREATE_MANAGER_REQUEST:
    case GET_LIST_BOOKING_REQUEST:
    case CREATE_EQUIPMENT_REQUEST:
    case CREATE_PROJECT_FINISH_REQUEST:
      return {
        ...state,
        loading: true,
        isProjectAction: false,
      };
    case IS_LOADED_EQUIPMENTS_EVENT:
      return {
        ...state,
        isLoadedEquipments: action.payload,
      };
    case GET_TRANSPORT_LIST_REQUEST:
      return {
        ...state,
        loadingTransport: true,
      };
    case GET_USER_LIST_REQUEST:
      return {
        ...state,
        loadingUser: true,
      };
    case CREATE_PROJECT_REQUEST:
      return {
        ...state,
        loadingProject: true,
      };
    case GET_ORGANIZERS_EVENT_SUCCESS:
      return {
        ...state,
        organizerEvents: action.payload,
        loading: false,
      };
    case GET_USER_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_EVENT_SUCCESS:
      return {
        ...state,
        userEvents: action.payload,
        loading: false,
      };
    case PROJECT_DELETE_FAILURE:
    case PROJECT_VALIDATION_FAILURE:
    case GET_ORGANIZERS_EVENT_FAILURE:
    case GET_USER_EVENT_FAILURE:
    case GET_OFFICE_EVENT_FAILURE:
    case GET_LEGACY_EVENT_FAILURE:
    case CREATE_RESPONSIBLE_FAILURE:
    case CREATE_TRANSPORT_FAILURE:
    case CREATE_STAFF_FAILURE:
    case CREATE_MANAGER_FAILURE:
    case GET_LIST_BOOKING_FAILURE:
    case CREATE_EQUIPMENT_FAILURE:
    case CREATE_PROJECT_FINISH_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PROJECT_VALIDATION_SUCCESS:
      return {
        ...state,
        validation: action.payload,
        loading: false,
      };
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        organizerEvents: [action.payload, ...state.organizerEvents],
        loading: false,
        isProjectAction: true,
      };
    case CREATE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        isProjectAction: false,
      };
    case CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        userEvents: [action.payload, ...state.userEvents],
        loading: false,
        isProjectAction: true,
      };
    case CREATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: false,
        isProjectAction: false,
      };
    case PROJECT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        projectData: action.payload,
        loadingProject: false,
      };
    case CREATE_PROJECT_FAILURE:
      return {
        ...state,
        loadingProject: false,
      };
    case GET_OFFICE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        officeEvents: action.payload,
      };
    case GET_LEGACY_EVENT_SUCCESS: {
      const newLegacy = action.payload.map((i) => ({
        id: i.id,
        name: i.person,
      }));

      return {
        ...state,
        loading: false,
        legacyEvents: newLegacy,
      };
    }

    case GET_TRANSPORT_LIST_SUCCESS:
      return {
        ...state,
        loadingTransport: false,
        transportList: action.payload,
      };
    case GET_TRANSPORT_LIST_FAILURE:
      return {
        ...state,
        loadingTransport: false,
      };
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        loadingUser: false,
        userList: action.payload,
      };
    case GET_USER_LIST_FAILURE:
      return {
        ...state,
        loadingUser: false,
      };
    case CREATE_RESPONSIBLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        legacyEvents: [
          { id: action.payload.id, name: action.payload.person },
          ...state.legacyEvents,
        ],
      };
    }

    case CREATE_TRANSPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        transportList: [action.payload, ...state.transportList],
      };
    case CREATE_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: [action.payload, ...state.userList],
      };
    case CREATE_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        managerData: action.payload,
      };
    case GET_EQUIPMENT_EVENT_REQUEST:
      return {
        ...state,
        loadingCategories: true,
      };
    case GET_EQUIPMENT_EVENT_SUCCESS:
      return {
        ...state,
        loadingCategories: false,
        categoriesEvent: action.payload,
      };
    case GET_EQUIPMENT_EVENT_FAILURE:
      return {
        ...state,
        loadingCategories: false,
      };
    case GET_WAREHOUSE_EVENT_REQUEST:
      return {
        ...state,
        loadingWerehouse: true,
      };
    case GET_WAREHOUSE_EVENT_SUCCESS:
      return {
        ...state,
        loadingWerehouse: false,
        werehouseEvent: action.payload,
      };
    case GET_WAREHOUSE_EVENT_FAILURE:
      return {
        ...state,
        loadingWerehouse: false,
      };
    case GET_LIST_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        bookingList: action.payload,
      };
    case GET_LIST_EQUIPMENT_SELECT_REQUEST:
      return {
        ...state,
        loadingEquipment: true,
      };
    case GET_LIST_EQUIPMENT_SELECT_SUCCESS: {
      return {
        ...state,
        loadingEquipment: false,
        equipmentSelectData: action.payload
          .map((item) =>
            item.count !== 0
              ? {
                  ...item,
                  reserved: +item?.group?.[0]?.count,
                }
              : null,
          )
          .filter((i) => i),
      };
    }
    case GET_LIST_EQUIPMENT_SELECT_FAILURE:
      return {
        ...state,
        loadingEquipment: false,
      };
    case CREATE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        equipmentData: action.payload,
      };
    case CREATE_PROJECT_FINISH_SUCCESS:
      return {
        ...state,
        loading: false,
        finishData: action.payload,
      };
    case CLERA_STORE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case SET_STEP1_FORM: {
      return {
        ...state,
        step1Form: action.payload,
      };
    }
    case SET_STEP2_FORM: {
      return {
        ...state,
        step2Form: action.payload,
      };
    }
    case SET_STEP3_FORM: {
      return {
        ...state,
        step3Form: action.payload,
      };
    }
    case RESET_ACTION:
      return {
        ...state,
        isProjectAction: false,
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
