import {
  GET_USER_INFO_FAILURE,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  SET_USER_INFO_FAILURE,
  SET_USER_INFO_REQUEST,
  SET_USER_INFO_SUCCESS,
} from './Profile.constant';

const initialState = {
  user: null,
  loading: false,
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_INFO_REQUEST:
    case GET_USER_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_USER_INFO_SUCCESS:
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case SET_USER_INFO_FAILURE:
    case GET_USER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
