import { LayoutAccount } from '@layouts';
import Equipments from '@modules/Equipments';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

const ViewEquipments = () => {
  const { t } = useTranslation();

  return (
    <LayoutAccount
      idNotTitle
      title={t('COMMON.EQUIPMENT')}
      className="page__equipments equipment"
    >
      <Routes>
        <Route path="/" element={<Equipments />} />
      </Routes>
    </LayoutAccount>
  );
};

export default ViewEquipments;
