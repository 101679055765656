import { UIButton } from '@ui';
import { FORMAT_DAY_MONTH, FORMAT_DATE_TEXT_ALT_FULL } from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { LINK_PROJECTS } from '@utils/link';
import { Button } from '@utils/utility-property';
import { useTranslation } from 'react-i18next';

const ProjectItem = ({ project }) => {
  const startDate = DateHelper.toFormat(
    project.date_start_event * 1000,
    FORMAT_DAY_MONTH,
  );

  const endDate = DateHelper.toFormat(
    project.date_finish_event * 1000,
    FORMAT_DAY_MONTH,
  );
  const { t } = useTranslation();

  // const startTime = DateHelper.toFormat(
  //   project.date_start_event * 1000,
  //   FORMAT_TIME_SHORT_ALT,
  // );

  // const endTime = DateHelper.toFormat(
  //   project.date_start_event * 1000,
  //   FORMAT_TIME_SHORT_ALT,
  // );

  return (
    <div className="projects-item">
      <div className="projects-item__top color_blue h4 fw600">
        <div>{/* {startTime} - {endTime} */}</div>

        <div>
          {startDate} - {endDate}
        </div>
      </div>
      <div className="projects-item__bottom">
        <UIButton
          defaultClassName=""
          className="h3"
          type={Button.LINK}
          link={`${LINK_PROJECTS}/${project.id}`}
        >
          <span>{project.name}</span>
          <span className="color_grey h5 fw600">
            {t('PROJECT.LOADING_DATE')}:{' '}
            {DateHelper.toFormat(
              project.loading * 1000,
              FORMAT_DATE_TEXT_ALT_FULL,
            )}
          </span>
        </UIButton>
      </div>
    </div>
  );
};

export default ProjectItem;
