import { LocalStorage } from '@services/storage';
import Axios from 'axios';

const basePath = '/api';

const defaultConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'blob',
};

const http = Axios.create(defaultConfig);

http.interceptors.request.use(({ auth = true, ...config }) => {
  const AccessToken = LocalStorage.getAccessToken();

  const newConfig = {
    ...config,
    headers: {
      ...config.headers,
      ...(AccessToken && auth && { Authorization: `Bearer ${AccessToken}` }),
    },
  };

  return newConfig;
});

export const downloadFile = (file, type) => {
  const fileName = 'contacts';
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(
    new Blob([file], {
      type,
    }),
  );
  link.download = fileName;
  link.click();
};

export const getResponsible = async (body) => {
  const data = await http.get(
    `${basePath}/export-user?date_start=${body.date_start}&date_finish=${body.date_finish}&user_id=${body.user_id}`,
    { responseType: 'arraybuffer' },
  );

  downloadFile(
    data?.data || '',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  );
  // downloadFile(data?.data || '', data?.headers['content-type'] || '');
};

export const getGeneral = async (body) => {
  const data = await http.get(
    `${basePath}/export-events?date_start=${body.date_start}&date_finish=${body.date_finish}`,
    { responseType: 'arraybuffer' },
  );

  downloadFile(
    data?.data || '',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  );
  // downloadFile(data?.data || '', data?.headers['content-type'] || '');
};
