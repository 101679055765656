import { useEffect, useState } from 'react';

import { PlusSVG } from '@assets/img/icons';
import { LayoutModal } from '@layouts';
import { useAppDispatch } from '@store/';
import { hideModal } from '@store/Modal';
import { createEmployeeRequest } from '@store/ProjectAdd';
import { UIButton, UIColor, UIInput, UIRadio, UISelect } from '@ui';
import { Button } from '@utils/utility-property';
// eslint-disable-next-line import/order
import { useFormik } from 'formik';
import './style.scss';

import { useTranslation } from 'react-i18next';

import { INITIAL_VALUES } from '../../../modules/ProjectAdd/Main/Employee/Form/constant';
import { validationSchema } from '../../../modules/ProjectAdd/Main/Employee/Form/schema';

const WidgetModalAddEmployee = () => {
  const [isOpenColor, setIsOpenColor] = useState(false);

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const handlerSubmit = (values) => {
    dispatch(createEmployeeRequest(values));
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  const radioInput = [
    { id: 'inactive', value: '1', label: i18n.t('COMMON.ACTIVE') },
    { id: 'active', value: '0', label: i18n.t('COMMON.INACTIVE') },
  ];

  return (
    <LayoutModal title={t('PROJECT.ADD_EMPLOYEE')} className="modal__added">
      <form onSubmit={form.handleSubmit}>
        <UIColor
          className="addEvent-title__color"
          setColor={(color) => form.setFieldValue('color', color)}
          color={form.values.color}
          setIsOpenColor={setIsOpenColor}
          isOpenColor={isOpenColor}
        />
        <div className="modal__added__items">
          <div className="input">
            <UIInput
              label={t('COMMON.EMAIL')}
              name="email"
              placeholder={t('COMMON.EMAIL')}
              classLabel="fw600"
              value={form.values.email}
              onChange={form.handleChange}
              isValid={!(form.errors.email && form.touched.email)}
              validateText={form.errors.email}
            />
          </div>
          <div className="input">
            <UIInput
              label={t('COMMON.NAME')}
              name="first_name"
              placeholder={t('COMMON.NAME')}
              classLabel="fw600"
              value={form.values.first_name}
              onChange={form.handleChange}
              isValid={!(form.errors.first_name && form.touched.first_name)}
              validateText={form.errors.first_name}
            />
          </div>
          <div className="input">
            <UIInput
              label={t('COMMON.SURNAME')}
              name="last_name"
              placeholder={t('COMMON.SURNAME')}
              classLabel="fw600"
              value={form.values.last_name}
              onChange={form.handleChange}
              isValid={!(form.errors.last_name && form.touched.last_name)}
              validateText={form.errors.last_name}
            />
          </div>
          <div className="input">
            <UIInput
              label={t('COMMON.PHONE')}
              name="phone"
              placeholder={t('COMMON.PHONE')}
              classLabel="fw600"
              value={form.values.phone}
              onChange={form.handleChange}
              isValid={!(form.errors.phone && form.touched.phone)}
              validateText={form.errors.phone}
            />
          </div>
          <div className="select-column">
            <UISelect
              id="role"
              label={t('COMMON.ROLE')}
              classLabel="h6 fw600"
              options={[{ id: 'admin', name: t('COMMON.ADMINISTRATOR') }]}
              name="role"
              onChange={(value) => {
                form.setFieldValue('role', value?.id || '');
              }}
              placeholder={t('COMMON.ROLE')}
              isValid={!(form.errors.role && form.touched.role)}
              validateText={form.errors.role}
            />
          </div>
          <div className="input input__icon">
            <UIInput
              label={t('COMMON.PASSWORD')}
              name="password"
              type="password"
              placeholder={t('COMMON.ENTER_PASSWORD')}
              classLabel="fw600"
              value={form.values.password}
              onChange={form.handleChange}
              isValid={!(form.errors.password && form.touched.password)}
              validateText={form.errors.password}
              isShowPassword
            />
          </div>
          <div className="modal-status__wrap">
            <div className="radio-wrap users-radio">
              <span className="h6 fw700">{t('COMMON.STATUS')}</span>
              <div>
                {radioInput?.map((item, index) => (
                  <UIRadio
                    key={index}
                    id={item.id}
                    type="radio"
                    name="active"
                    className="custom-radio"
                    value={item.value}
                    onChange={form.handleChange}
                  >
                    {item.label}
                  </UIRadio>
                ))}
              </div>
            </div>
          </div>
          <div className="added-btn">
            <UIButton className="outline__blue" type={Button.SUBMIT}>
              <PlusSVG />
              <span>{t('COMMON.ADD')}</span>
            </UIButton>
            <UIButton
              className=" btn__gray"
              handler={() => dispatch(hideModal())}
            >
              {t('COMMON.CANCEL')}
            </UIButton>
          </div>
        </div>
      </form>
    </LayoutModal>
  );
};

export default WidgetModalAddEmployee;
