import { useEffect, useMemo, useRef } from 'react';

import { UILoaderWrap } from '@ui';

import ProjectItem from '../Item';

const ProjectList = ({ projectList, loading }) => {
  const refItems = useRef();

  const renderItemHTML = useMemo(
    () =>
      projectList?.map((project, index) => (
        <ProjectItem key={index} project={project} />
      )),
    [projectList],
  );

  useEffect(() => {
    refItems.current.scrollTo(0, 0);
  }, [projectList]);

  return (
    <UILoaderWrap loading={loading}>
      <div ref={refItems} className="projects-items">
        {renderItemHTML}
      </div>
    </UILoaderWrap>
  );
};

export default ProjectList;
