import { LayoutAccount } from '@layouts';
import Calendars from '@modules/Calendars';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

const ViewLogin = () => {
  const { t } = useTranslation();

  return (
    <LayoutAccount title={t('CALENDAR.CALENDAR')} className="calendar__page">
      <Routes>
        <Route path="/" element={<Calendars />} />
      </Routes>
    </LayoutAccount>
  );
};

export default ViewLogin;
