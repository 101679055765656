import http from '@utils/http';

const basePath = '/api';

export const getCategories = async () => {
  const data = await http.get(`${basePath}/categories`);

  return data.data.data;
};

export const addCategories = async (body) => {
  const data = await http.post(`${basePath}/categories`, body);

  return data.data.data;
};

export const setCategories = async (body) => {
  const { id, ...val } = body;

  const data = await http.put(`${basePath}/categories/${id}`, val);

  return data.data.data;
};

export const deleteCategories = async (body) => {
  const data = await http.delete(`${basePath}/categories/${body}`);

  return data.data.data;
};

export const getPackages = async () => {
  const data = await http.get(`${basePath}/packages`);

  return data.data.data;
};

export const addPackages = async (body) => {
  const data = await http.post(`${basePath}/packages`, body);

  return data.data.data;
};

export const setPackages = async (body) => {
  const { id, ...val } = body;

  const data = await http.put(`${basePath}/packages/${id}`, val);

  return data.data.data;
};

export const deletePackages = async (body) => {
  const data = await http.delete(`${basePath}/packages/${body}`);

  return data.data.data;
};

export const getGroups = async () => {
  const data = await http.get(`${basePath}/groups`);

  return data.data.data;
};

export const addGroups = async (body) => {
  const data = await http.post(`${basePath}/groups`, body);

  return data.data.data;
};

export const setGroups = async (body) => {
  const { id, ...val } = body;

  const data = await http.put(`${basePath}/groups/${id}`, val);

  return data.data.data;
};

export const deleteGroups = async (body) => {
  const data = await http.delete(`${basePath}/groups/${body}`);

  return data.data.data;
};
