export const SentSVG = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.30107 0.683931L14.0936 6.80211C14.2229 6.86385 14.3321 6.96093 14.4085 7.08213C14.4849 7.20333 14.5255 7.34368 14.5255 7.48697C14.5255 7.63025 14.4849 7.77061 14.4085 7.8918C14.3321 8.013 14.2229 8.11008 14.0936 8.17182L1.30107 14.29C1.16993 14.3528 1.02351 14.3766 0.879219 14.3586C0.73493 14.3406 0.598854 14.2815 0.487179 14.1884C0.375505 14.0953 0.292935 13.972 0.249292 13.8333C0.205649 13.6946 0.202772 13.5463 0.241002 13.406L1.52086 8.714C1.53653 8.6565 1.56877 8.60488 1.61358 8.56558C1.65839 8.52628 1.71377 8.50104 1.77282 8.493L7.97175 7.6509C7.99766 7.64721 8.02218 7.63689 8.04293 7.62093C8.06367 7.60497 8.07994 7.58392 8.09015 7.55982L8.10107 7.52218C8.10586 7.48828 8.09905 7.45376 8.08175 7.42422C8.06444 7.39469 8.03766 7.37186 8.00575 7.35947L7.97236 7.35036L1.7795 6.50825C1.72056 6.50011 1.66531 6.47481 1.62062 6.43552C1.57593 6.39623 1.54377 6.34467 1.52815 6.28725L0.241002 1.56854C0.202617 1.42821 0.205377 1.2798 0.248954 1.14099C0.292531 1.00218 0.375088 0.878822 0.486792 0.785607C0.598496 0.692393 0.734639 0.63325 0.879006 0.615224C1.02337 0.597197 1.16988 0.621046 1.30107 0.683931Z"
        fill="#3A36DB"
      />
    </svg>
  );
};
