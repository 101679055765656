import { useEffect, useState, useMemo } from 'react';

import {
  AddCircleSVG,
  MoveBackSVG,
  MoveToSVG,
  SearchSVG,
} from '@assets/img/icons';
import { useAppDispatch } from '@store/';
import { alertError } from '@store/Alert';
import { ModalType, showModal } from '@store/Modal';
import { UIButton, UIInput, UILoaderWrap } from '@ui';
import { ArrayHelper } from '@utils/helpers';
import { LoaderColorType } from '@utils/utility-property';
import { useTranslation } from 'react-i18next';

const ProjectAddManagerBookingTable = ({
  title,
  list,
  loading,
  form,
  index,
  isTransport,
  disabled,
  disabledText,
}) => {
  const [selected, setSelected] = useState([]);
  const [option, setOption] = useState(list);
  const [searchList, setSearchList] = useState(null);
  const [searchValue, setSearchValue] = useState(null);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => setOption(list), [list]);

  const rightArray = isTransport
    ? form.values.reservations[index]?.transports
    : form.values.reservations[index]?.personals;

  useEffect(() => {
    if (rightArray?.length && rightArray.length !== selected.length) {
      const newOption = [...option];

      rightArray.forEach((item) => {
        newOption.forEach((e, index) => {
          if (e.id === item.id) {
            newOption.splice(index, 1);
          }
        });
      });

      setOption(newOption);

      setSelected([...selected, ...rightArray]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, isTransport, rightArray]);

  useEffect(() => {
    form.setFieldValue(
      'reservations',
      ArrayHelper.overwriteArray(
        form.values.reservations,
        index,
        selected,
        isTransport ? 'transports' : 'personals',
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, selected]);

  const renderList = useMemo(
    () =>
      (searchList || option).map((item, index) => (
        <div key={index}>
          <span>{item.name}</span>
          <UIButton
            defaultClassName=""
            handler={() => {
              if (disabled) {
                dispatch(alertError(disabledText));
              } else {
                setSearchList(searchList?.filter((e) => e.id !== item.id));
                setOption(option.filter((e) => e.id !== item.id));
                setSelected([...selected, item]);
              }
            }}
            className="d-flex"
          >
            <MoveToSVG />
          </UIButton>
        </div>
      )),
    [disabled, disabledText, dispatch, option, searchList, selected],
  );

  const renderPickedList = useMemo(
    () =>
      selected.map((item, index) => (
        <div
          onClick={() => {
            if (disabled) {
              dispatch(alertError(disabledText));
            } else {
              if (
                searchValue &&
                !!selected.find((i) =>
                  i.name.toLowerCase().includes(searchValue.toLowerCase()),
                )
              ) {
                setSearchList([...(searchList || []), item]);
              } else {
                setOption([...(option || []), item]);
              }
              setSelected(selected.filter((e) => e.id !== item.id));
            }
          }}
          key={index}
        >
          <span>{item.name}</span>
          <UIButton defaultClassName="" className="d-flex">
            <MoveBackSVG />
          </UIButton>
        </div>
      )),
    [
      disabled,
      disabledText,
      dispatch,
      option,
      searchList,
      searchValue,
      selected,
    ],
  );

  const clearPickerList = () => {
    if (
      searchValue &&
      !!selected.find((i) =>
        i.name.toLowerCase().includes(searchValue.toLowerCase()),
      )
    ) {
      setSearchList([...(searchList || []), ...selected.map((item) => item)]);
    } else {
      setOption((prev) => [...prev, ...selected]);
    }
    setSelected([]);
  };

  const openModal = () => {
    dispatch(
      showModal({
        modalType: isTransport ? ModalType.ADD_TRANSPORT : ModalType.ADD_STAFF,
      }),
    );
  };

  const handlerChange = (e) => {
    const { value } = e.target;

    setSearchValue(value);
    setSearchList(
      option.filter((i) => i.name.toLowerCase().includes(value.toLowerCase())),
    );
  };

  return (
    <div className="addingElement-wrap mb-30">
      <span className="h6 fw600">{title}</span>
      <div className="adding-element h6">
        <div className="adding-element__left">
          <div className="adding-elementLeft__add">
            <div className="cursor_pointer" onClick={() => openModal()}>
              <span className="color_darkBlue">{t('COMMON.ADD')}</span>
              <AddCircleSVG />
            </div>
            <UIInput
              type="text"
              disabled={disabled}
              placeholder={t('COMMON.SEARCH')}
              onChange={handlerChange}
              className="input input__icon"
            >
              <span className="input-icon__btn">
                <SearchSVG />
              </span>
            </UIInput>
          </div>
          <UILoaderWrap
            loading={loading}
            colorType={LoaderColorType.SECONDARY}
            bgTransparent
          >
            <div className="adding-elementLeft__items">{renderList}</div>
          </UILoaderWrap>
        </div>
        <div className="adding-element__right">
          <div
            className="adding-elementRight__clear color_grey"
            onClick={() =>
              disabled ? dispatch(alertError(disabledText)) : clearPickerList()
            }
          >
            {t('COMMON.CLEAR')}
          </div>
          <div className="adding-elementRight__items">{renderPickedList}</div>
        </div>
      </div>
    </div>
  );
};

export default ProjectAddManagerBookingTable;
