import { LayoutAccount } from '@layouts';
import Events from '@modules/Events';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

const ViewEvents = () => {
  const { t } = useTranslation();

  return (
    <LayoutAccount
      title={t('EQUIPMENTS.EQUIPMENT_AT_EVENTS')}
      className="page__event"
    >
      <Routes>
        <Route path="/" element={<Events />} />
      </Routes>
    </LayoutAccount>
  );
};

export default ViewEvents;
