import i18n from '@i18n';
import * as yup from 'yup';

export const validationSchema = () =>
  yup.object({
    groupAt: yup.object().nullable().required(i18n.t('VALIDATION.REQUIRED')),
    groupFor: yup.object().nullable().required(i18n.t('VALIDATION.REQUIRED')),
    date_start: yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    date_finish: yup.string().required(i18n.t('VALIDATION.REQUIRED')),
    isTransfered: yup
      .number()
      .min(1, i18n.t('VALIDATION.COUNT.MIN'))
      .nullable(true)
      .required(i18n.t('VALIDATION.REQUIRED')),
  });
