import { LocalStorage } from '@services/storage';
import {
  LOCAL_ACCESS_TOKEN,
  LOCAL_REFRESH_TOKEN,
  LOCAL_REFRESH_TOKEN_DATA,
} from '@utils/constants';

import {
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
} from './Auth.constant';

const initialState = {
  data: {
    accessToken: LocalStorage.get(LOCAL_ACCESS_TOKEN),
    refreshToken: LocalStorage.get(LOCAL_REFRESH_TOKEN),
    refreshTokenData: LocalStorage.get(LOCAL_REFRESH_TOKEN_DATA),
  },
  loadingLogin: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        loadingLogin: true,
      };
    case AUTH_LOGIN_SUCCESS: {
      const refreshTokenData =
        new Date().getTime() + action.payload.expires_in * 1000;

      LocalStorage.set(LOCAL_ACCESS_TOKEN, action.payload.access_token);
      LocalStorage.set(LOCAL_REFRESH_TOKEN, action.payload.refresh_token);
      LocalStorage.set(LOCAL_REFRESH_TOKEN_DATA, refreshTokenData);

      return {
        ...state,
        data: {
          accessToken: action.payload.access_token,
          refreshToken: action.payload.refresh_token,
          refreshTokenTata: refreshTokenData,
        },
        loadingLogin: false,
      };
    }
    case AUTH_LOGIN_FAILURE:
      return {
        ...state,
        loadingLogin: false,
      };
    default:
      return state;
  }
}
