/* eslint-disable prefer-template */
import { useEffect, useState } from 'react';

import { PlusSVG } from '@assets/img/icons';
import { useAppDispatch } from '@store/';
import {
  addEquipmentsRequest,
  // deleteEquipmentsRequest,
  setEquipmentsRequest,
} from '@store/Equipments';
import { UIButton, UIInput, UIInputNumber, UISelect } from '@ui';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const EquipmentsAddEquipments = ({
  edit,
  resetEdit,
  categories,
  // packages,
  groups,
  loading,
}) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);

  const startInitialValues = {
    ...INITIAL_VALUES,
    group: [...(groups || [])],
  };

  const handlerSubmit = (
    {
      category,
      group,
      // package: packageNew,
      ...val
    },
    { resetForm },
  ) => {
    const parsPayload = {
      ...val,
      category_id: category.id,
      // package_id: packageNew?.id,
      groups: group.map((item) => ({ id: item.id, count: item.count || 0 })),
    };

    if (edit) {
      dispatch(setEquipmentsRequest(parsPayload));
    } else {
      dispatch(
        addEquipmentsRequest({
          ...parsPayload,
          id: null,
        }),
      );
      resetForm();
    }
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  useEffect(() => {
    if (groups) {
      setInitialValues({
        ...INITIAL_VALUES,
        group: groups,
      });
    }
  }, [groups]);

  // const handlerDelete = () => {
  //   dispatch(deleteEquipmentsRequest(edit.id));
  //   form.resetForm({
  //     values: startInitialValues,
  //   });
  //   resetEdit();
  // };

  useEffect(() => {
    if (edit) {
      setInitialValues({
        active: edit?.active,
        category: edit?.category,
        group: groups.map((item) => {
          const groupId = edit?.group.find((item2) => item2.id === item.id);

          if (groupId) {
            return {
              ...item,
              count: +groupId.count || 0,
            };
          }

          return item;
        }),
        id: edit?.id,
        name: edit?.name,
        // package: edit?.package,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  return (
    <form onSubmit={form.handleSubmit} className="equipment-new__items">
      <UIButton
        defaultClassName="equipment__clear h6 fw600"
        type={Button.BUTTON}
        handler={() => {
          form.resetForm({
            values: startInitialValues,
          });
          resetEdit();
        }}
      >
        {t('COMMON.CLEAR')}
      </UIButton>
      <UIInput
        name="name"
        islabelRow
        id="name"
        label={t('COMMON.TITLE')}
        classLabel="fw600 icon_necessarily"
        className="input input__row"
        placeholder={t('COMMON.TITLE')}
        value={form.values?.name}
        onChange={form.handleChange}
        isValid={!(form.errors.name && form.touched.name)}
        validateText={form.errors.name}
      />
      <UISelect
        name="category"
        loading={loading}
        className="select-wrap custom-select-row"
        label={t('COMMON.CATEGORY')}
        classLabel="h6 icon_necessarily fw600"
        options={categories}
        placeholder={t('COMMON.CATEGORY')}
        value={form.values?.category}
        onChange={(value) => form.setFieldValue('category', value)}
        isValid={!(form.errors.category && form.touched.category)}
        validateText={form.errors.category}
      />
      {/* <UISelect
        name="package"
        loading={loading}
        className="select-wrap custom-select-row"
        label="Комплект"
        classLabel="h6 fw600"
        options={packages}
        placeholder="Комплект"
        value={form.values?.package}
        onChange={(value) => form.setFieldValue('package', value)}
      /> */}
      <div className="equipment-new__numbers">
        {form?.values?.group?.map((item, index) => (
          <UIInputNumber
            key={index}
            type="string"
            min={0}
            name={`group.${index}.count`}
            label={item.name}
            classLabel="h6 fw600"
            value={form.values?.group?.[index]?.count}
            onChange={(value) =>
              form.setFieldValue(`group.${index}.count`, value)
            }
          />
        ))}
      </div>
      {/* <div className="equipment-radio radio-wrap">
        <span className="h6 fw600">Статус</span>
        <div>
          <UIRadio
            id="active"
            name="active"
            checked={form.values?.active}
            onChange={() => form.setFieldValue('active', true)}
          >
            Активен
          </UIRadio>
          <UIRadio
            id="inactive"
            name="active"
            checked={!form.values?.active}
            onChange={() => form.setFieldValue('active', false)}
          >
            Неактивен
          </UIRadio>
        </div>
        <UIError
          isValid={!(form.errors.active && form.touched.active)}
          text={form.errors.active}
        />
      </div> */}
      <div className="equipment-new__btn">
        <UIButton
          loading={loading}
          className="btn outline__blue"
          type={Button.SUBMIT}
        >
          {edit ? (
            <>{t('COMMON.UPDATE')}</>
          ) : (
            <>
              <PlusSVG />
              <span>{t('COMMON.ADD')}</span>
            </>
          )}
        </UIButton>
        {/* {edit ? (
          <UIButton
            loading={loading}
            className="btn btn__red"
            handler={handlerDelete}
            type={Button.BUTTON}
          >
            Удалить
          </UIButton>
        ) : null} */}
      </div>
    </form>
  );
};

export default EquipmentsAddEquipments;
