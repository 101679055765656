import {
  CHANGE_TRANSFER_FAILURE,
  CHANGE_TRANSFER_REQUEST,
  CHANGE_TRANSFER_SUCCESS,
  CLEAR_TRANSFER_DATA,
  DELETE_TRANSFER_FAILURE,
  DELETE_TRANSFER_REQUEST,
  DELETE_TRANSFER_SUCCESS,
  NEW_TRANSFER_FAILURE,
  NEW_TRANSFER_REQUEST,
  NEW_TRANSFER_SUCCESS,
  SEARCH_TRANSFER_FAILURE,
  SEARCH_TRANSFER_REQUEST,
  SEARCH_TRANSFER_SUCCESS,
  TRANSFER_ALL_FAILURE,
  TRANSFER_ALL_REQUEST,
  TRANSFER_ALL_SUCCESS,
} from './Transfer.constant';

const initialState = {
  loadingNew: false,
  loadingChange: false,
  loadingFilter: false,
  loadingFilterAll: false,
  lastPage: null,
  lastPageAll: null,
  data: [],
  dataAll: [],
};

export default function TransferReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_TRANSFER_DATA:
      return {
        ...state,
        data: [],
        dataAll: [],
      };
    case NEW_TRANSFER_REQUEST:
      return {
        ...state,
        loadingNew: true,
      };
    case DELETE_TRANSFER_REQUEST:
    case CHANGE_TRANSFER_REQUEST:
      return {
        ...state,
        loadingChange: true,
      };
    case DELETE_TRANSFER_FAILURE:
    case CHANGE_TRANSFER_FAILURE:
      return {
        ...state,
        loadingChange: false,
      };
    case CHANGE_TRANSFER_SUCCESS:
      return {
        ...state,
        loadingChange: false,
        data: state.data.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }

          return item;
        }),
      };
    case DELETE_TRANSFER_SUCCESS:
      return {
        ...state,
        loadingChange: false,
        data: state.data.filter((item) => item.id !== action.payload.id),
      };
    case NEW_TRANSFER_FAILURE:
    case NEW_TRANSFER_SUCCESS:
      return {
        ...state,
        loadingNew: false,
      };
    case SEARCH_TRANSFER_REQUEST:
      return {
        ...state,
        loadingFilter: true,
      };
    case SEARCH_TRANSFER_SUCCESS:
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
        lastPage: action.payload.meta.last_page,
        loadingFilter: false,
      };
    case SEARCH_TRANSFER_FAILURE:
      return {
        ...state,
        loadingFilter: false,
      };
    case TRANSFER_ALL_REQUEST:
      return {
        ...state,
        loadingFilterAll: true,
      };
    case TRANSFER_ALL_SUCCESS:
      return {
        ...state,
        dataAll: [...state.dataAll, ...action.payload.data],
        lastPageAll: action.payload.meta.last_page,
        loadingFilterAll: false,
      };
    case TRANSFER_ALL_FAILURE:
      return {
        ...state,
        loadingFilterAll: false,
      };
    default:
      return state;
  }
}
