export const ArrLeftSVG = ({ className }) => (
  <svg
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.87677 7.00101L8.06781 1.772C8.25272 1.58596 8.35651 1.33433 8.35651 1.07202C8.35651 0.809717 8.25272 0.558055 8.06781 0.372009C7.97706 0.280367 7.86903 0.207606 7.75 0.157959C7.63097 0.108312 7.50324 0.0827637 7.37427 0.0827637C7.24529 0.0827637 7.11763 0.108312 6.9986 0.157959C6.87956 0.207606 6.77154 0.280367 6.68079 0.372009L0.795776 6.30002C0.610869 6.48606 0.50708 6.7377 0.50708 7C0.50708 7.2623 0.610869 7.51397 0.795776 7.70001L6.68079 13.628C6.77154 13.7197 6.87956 13.7924 6.9986 13.842C7.11763 13.8917 7.24529 13.9173 7.37427 13.9173C7.50324 13.9173 7.63097 13.8917 7.75 13.842C7.86903 13.7924 7.97706 13.7197 8.06781 13.628C8.25272 13.442 8.35651 13.1903 8.35651 12.928C8.35651 12.6657 8.25272 12.414 8.06781 12.228L2.87677 7.00101Z"
      fill="#809FB8"
    />
  </svg>
);
