import http from '@utils/http';

const basePath = '/api';

export const getEvents = async (body) => {
  const data = await http.get(
    `${basePath}/events-calendar?filter[date_between]=${body}`,
  );

  return data.data.data;
};

export const getEventsYear = async (body) => {
  const data = await http.get(
    `${basePath}/events-year-calendar?filter[date_between]=${body}`,
  );

  return data.data.data;
};

export const getOneEvent = async (body) => {
  const data = await http.get(`${basePath}/events/${body}`);

  return data.data.data;
};

export const getOffices = async () => {
  const data = await http.get(`${basePath}/offices-calendar`);

  return data.data.data;
};
