export const EdditSVG = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.762494 11.8617C0.675781 11.8615 0.590049 11.8433 0.510748 11.8082C0.431447 11.7732 0.360313 11.722 0.301869 11.6579C0.242368 11.5944 0.196964 11.519 0.168636 11.4367C0.140308 11.3544 0.129696 11.2671 0.137494 11.1804L0.290619 9.49666L7.36437 2.42541L9.57499 4.63541L2.50312 11.706L0.819369 11.8592C0.800461 11.8609 0.781481 11.8617 0.762494 11.8617ZM10.0162 4.19354L7.80624 1.98354L9.13187 0.657914C9.18991 0.599803 9.25884 0.553704 9.33472 0.522251C9.41059 0.490799 9.49192 0.474609 9.57406 0.474609C9.65619 0.474609 9.73752 0.490799 9.81339 0.522251C9.88927 0.553704 9.9582 0.599803 10.0162 0.657914L11.3419 1.98354C11.4 2.04158 11.4461 2.11051 11.4775 2.18639C11.509 2.26226 11.5252 2.34359 11.5252 2.42573C11.5252 2.50786 11.509 2.58919 11.4775 2.66506C11.4461 2.74094 11.4 2.80987 11.3419 2.86791L10.0169 4.19291L10.0162 4.19354Z"
        fill="#809FB8"
      />
    </svg>
  );
};
