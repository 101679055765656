import { useEffect, useState } from 'react';

import { ArrLeftSVG, ArrRightSVG, CalendarSVG } from '@assets/img/icons';
import { LocalStorage } from '@services/storage';
import { useAppDispatch } from '@store/';
import { ModalType, showModal } from '@store/Modal';
import { getProjectRequest } from '@store/Project';
import { UIButton, UICalendar } from '@ui';
import {
  FORMAT_DATE_SLASH,
  FORMAT_DAY_MONTH_FORMAT,
  FORMAT_DAY_MONTH_WEEK,
  FORMAT_WEEK,
  LOCAL_ACCESS_TOKEN,
  UNIX_DAY,
} from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { LINK_PROJECTS } from '@utils/link';
import { Button } from '@utils/utility-property';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ProjectList from './List';
import ProjectTab from './Tab';

import './style.scss';

const Project = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { projectList, loading } = useSelector((state) => state.project);

  const [activeDate, setActiveDate] = useState(new Date());
  const [tab, setTab] = useState(false);

  useEffect(() => {
    const todayDate = DateHelper.toFormat(
      activeDate || new Date(),
      FORMAT_DATE_SLASH,
      false,
    );

    dispatch(
      getProjectRequest({
        filter: {
          date_between: `${todayDate},${todayDate}`,
          ...(tab && {
            user: jwtDecode(LocalStorage.get(LOCAL_ACCESS_TOKEN)).sub,
          }),
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, dispatch]);

  const handleDateClick = (date) => {
    const newDate = DateHelper.toFormat(date, FORMAT_DATE_SLASH, false);

    setActiveDate(date);
    dispatch(
      getProjectRequest({
        filter: {
          date_between: `${newDate},${newDate}`,
          ...(tab && {
            user: jwtDecode(LocalStorage.get(LOCAL_ACCESS_TOKEN)).sub,
          }),
        },
      }),
    );
  };

  const handlerOpenMobileDateModal = () => {
    dispatch(
      showModal({
        modalType: ModalType.MOBILE__DATE,
        modalProps: {
          range: activeDate,
          setRange: setActiveDate,
          type: 'calendar',
          handlerDateClick: handleDateClick,
        },
      }),
    );
  };

  return (
    <>
      <div className="projects">
        <div className="projects-left">
          <ProjectTab tab={tab} setTab={setTab} />

          <div className="projects-calendar_mobile hide-desktop show-mobile">
            <div>
              <UIButton
                handler={() =>
                  handleDateClick(new Date(activeDate.valueOf() - UNIX_DAY))
                }
                defaultClassName=""
              >
                <ArrLeftSVG />
              </UIButton>

              <CalendarSVG />
              <span onClick={handlerOpenMobileDateModal}>
                {DateHelper.toFormat(activeDate, FORMAT_DAY_MONTH_WEEK, false)}
              </span>

              <UIButton
                handler={() =>
                  handleDateClick(new Date(activeDate.valueOf() + UNIX_DAY))
                }
                defaultClassName=""
              >
                <ArrRightSVG />
              </UIButton>
            </div>
            <UIButton
              className="btn outline__blue"
              type={Button.LINK}
              link={`${LINK_PROJECTS}/all`}
            >
              {t('PROJECT.EVENTS_ALL')}
            </UIButton>
          </div>

          <div className="projects-left__title h3 fw700">
            {DateHelper.toFormat(activeDate, FORMAT_DAY_MONTH_FORMAT, false)}
            <span className="h4 fw600">
              {DateHelper.toFormat(activeDate, FORMAT_WEEK, false)}
            </span>
          </div>

          <ProjectList projectList={projectList} loading={loading} />
        </div>

        <div className="projects-right">
          <UICalendar
            selectedDate={activeDate}
            handleDateClick={handleDateClick}
          >
            <UIButton
              className="btn outline__blue"
              type={Button.LINK}
              link={`${LINK_PROJECTS}/all`}
            >
              {t('PROJECT.EVENTS_ALL')}
            </UIButton>
          </UICalendar>
        </div>
      </div>
    </>
  );
};

export default Project;
