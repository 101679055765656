import {
  LOCAL_ACCESS_TOKEN,
  LOCAL_REFRESH_TOKEN,
  LOCAL_REFRESH_TOKEN_DATA,
} from '@utils/constants';

export default (() => {
  const set = (key, data) => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, data);
    }
  };

  const get = (key) => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(key);
    }

    return null;
  };

  const removeItem = (key) => {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(key);
    }
  };

  const clearToken = () => {
    localStorage.removeItem(LOCAL_ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_REFRESH_TOKEN);
    localStorage.removeItem(LOCAL_REFRESH_TOKEN_DATA);
  };

  const getAccessToken = () => {
    return localStorage.getItem(LOCAL_ACCESS_TOKEN);
  };

  const getRefreshToken = () => {
    return localStorage.getItem(LOCAL_REFRESH_TOKEN);
  };

  const getRefreshTokenData = () => {
    return localStorage.getItem(LOCAL_REFRESH_TOKEN_DATA);
  };

  return {
    set,
    get,
    clearToken,
    removeItem,
    getAccessToken,
    getRefreshToken,
    getRefreshTokenData,
  };
})();
