import http from '@utils/http';
import qs from 'qs';

const basePath = '/api';

export const getTransfer = async (body) => {
  const data = await http.get(`${basePath}/transfers`, {
    params: body,
    paramsSerializer: (...data) => {
      return qs.stringify(...data, {
        encodeValuesOnly: true,
        skipNulls: true,
      });
    },
  });

  return data.data;
};

export const putTransfer = async (body) => {
  const data = await http.put(`${basePath}/transfers/${body.id}`, body);

  return data.data.data;
};

export const getTransferAll = async (body) => {
  const data = await http.get(`${basePath}/transfers/equipments`, {
    params: body,
    paramsSerializer: (...data) => {
      return qs.stringify(...data, {
        encodeValuesOnly: true,
        skipNulls: true,
      });
    },
  });

  return data.data;
};

export const newTransfer = async (body) => {
  const data = await http.post(`${basePath}/transfers`, body);

  return data.data.data;
};

export const deleteTransfer = async ({ transferId }) => {
  const data = await http.delete(`${basePath}/transfers/${transferId}`);

  return data.data.data;
};

export const validationTransfer = async (body) => {
  const data = await http.post(`${basePath}/transfers/validation`, body);

  return data.data.data;
};
