import { all } from 'redux-saga/effects';

import { authWatchers } from './Auth/Auth.saga';
import { calendarWatchers } from './Calendar/Calendar.saga';
import { categoriesWatchers } from './Categories/Categories.saga';
import { equipmentsWatchers } from './Equipments/Equipments.saga';
import { eventsWatchers } from './Events/Events.saga';
import { profileWatchers } from './Profile/Profile.saga';
import { projectWatchers } from './Project/Project.saga';
import { projectAddWatchers } from './ProjectAdd/ProjectAdd.saga';
import { reportsWatchers } from './Reports/Reports.saga';
import { transferWatchers } from './Transfer/Transfer.saga';
import { uploadWatchers } from './Upload/Upload.saga';
import { usersWatchers } from './Users/Users.saga';
import { wallWatchers } from './Wall/Wall.saga';

export function* rootSagas() {
  const watchers = [
    ...authWatchers,
    ...calendarWatchers,
    ...projectAddWatchers,
    ...usersWatchers,
    ...uploadWatchers,
    ...reportsWatchers,
    ...eventsWatchers,
    ...wallWatchers,
    ...categoriesWatchers,
    ...projectWatchers,
    ...equipmentsWatchers,
    ...profileWatchers,
    ...transferWatchers,
  ];

  yield all(watchers);
}
