import { ErrorSearchSVG } from '@assets/img/icons';

const UITableError = ({ header, textError }) => {
  return (
    <tr>
      <td colSpan={header.length}>
        <div className="errorTable">
          <ErrorSearchSVG />
          <span className="h4 fw400 color_blue">{textError}</span>
        </div>
      </td>
    </tr>
  );
};

export default UITableError;
