import { useEffect } from 'react';

import { PlusSVG } from '@assets/img/icons';
import { useAppDispatch } from '@store/';
import { createClientRequest, resetAction } from '@store/ProjectAdd';
import { UIButton, UIInput } from '@ui';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const ProjectAddMainClientForm = ({ handler }) => {
  const { loading, isProjectAction } = useSelector((state) => state.projectAdd);

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const handlerSubmit = (values) => {
    dispatch(createClientRequest(values));
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  useEffect(() => {
    if (isProjectAction) {
      form.resetForm();
      dispatch(resetAction());
    }
  }, [dispatch, form, isProjectAction]);

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  return (
    <form onSubmit={form.handleSubmit}>
      <div className="addEvent-add__items">
        <UIButton
          defaultClassName="ml-auto d-flex color_grey h6 fw600"
          type={Button.BUTTON}
          handler={() => form.resetForm()}
        >
          {t('COMMON.CLEAR')}
        </UIButton>

        <UIInput
          label={t('COMMON.MAIL')}
          name="email"
          placeholder={t('COMMON.MAIL')}
          classLabel="fw600"
          value={form.values.email}
          onChange={form.handleChange}
          isValid={!(form.errors.email && form.touched.email)}
          validateText={form.errors.email}
        />

        <UIInput
          label={t('COMMON.FULL_NAME_MIN')}
          name="name"
          placeholder={t('COMMON.FULL_NAME_MIN')}
          classLabel="fw600"
          value={form.values.name.trimStart()}
          onChange={form.handleChange}
          isValid={!(form.errors.name && form.touched.name)}
          validateText={form.errors.name}
        />
        <UIInput
          label={t('COMMON.PHONE')}
          name="phone"
          placeholder={t('COMMON.PHONE')}
          classLabel="fw600"
          value={form.values.phone.trimStart()}
          onChange={form.handleChange}
          isValid={!(form.errors.phone && form.touched.phone)}
          validateText={form.errors.phone}
        />
      </div>

      <div className="addEvent-add__btn">
        <UIButton
          className="outline__blue"
          type={Button.SUBMIT}
          loading={loading}
        >
          <PlusSVG />
          <span>{t('COMMON.ADD')}</span>
        </UIButton>

        <UIButton
          className="btn__gray addEvent-remove__client"
          handler={handler}
        >
          {t('COMMON.CANCEL')}
        </UIButton>
      </div>
    </form>
  );
};

export default ProjectAddMainClientForm;
