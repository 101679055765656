/* eslint-disable no-underscore-dangle */
import { ENV_DEVELOPMENT } from '@utils';
import { useDispatch } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { rootReducer } from './Root.reducer';
import { rootSagas } from './Root.saga';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  typeof window !== 'undefined' &&
  process.env.NODE_ENV === ENV_DEVELOPMENT &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const bindMiddleware = composeEnhancers(applyMiddleware(sagaMiddleware));

const initStore = createStore(rootReducer, undefined, bindMiddleware);

export const useAppDispatch = () => useDispatch();

sagaMiddleware.run(rootSagas);

export default initStore;
