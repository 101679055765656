import { LayoutAccount } from '@layouts';
import Profile from '@modules/Profile';
import { Route, Routes } from 'react-router-dom';

const ViewProfile = () => (
  <LayoutAccount idNotTitle className="page__profile">
    <Routes>
      <Route path="/" element={<Profile />} />
    </Routes>
  </LayoutAccount>
);

export default ViewProfile;
