import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_REQUEST,
} from './Auth.constant';

export function authLoginRequest(payload) {
  return {
    type: AUTH_LOGIN_REQUEST,
    payload,
  };
}

export function authLoginSuccess(payload) {
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload,
  };
}

export function authLoginFailure() {
  return {
    type: AUTH_LOGIN_FAILURE,
  };
}
