import { useEffect } from 'react';

import { PlusSVG } from '@assets/img/icons';
import { LayoutModal } from '@layouts';
import { useAppDispatch } from '@store/';
import { hideModal } from '@store/Modal';
import { createResponsibleRequest } from '@store/ProjectAdd';
import { UIButton, UIInput } from '@ui';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';
import './style.scss';

const WidgetsModalAddResponsible = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const handlerSubmit = (values) => {
    dispatch(createResponsibleRequest(values));
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  return (
    <LayoutModal
      title={t('PROJECT.ADD_RESPONSIBLE_PERSON')}
      className="modal__added"
    >
      <form onSubmit={form.handleSubmit}>
        <div className="modal__added__items">
          <UIInput
            label={t('PROJECT.CONTACT_PERSON')}
            name="person"
            value={form.values.person}
            className="input"
            placeholder={t('PROJECT.CONTACT_PERSON')}
            classLabel="fw700"
            onChange={form.handleChange}
            isValid={!(form.errors.person && form.touched.person)}
            validateText={form.errors.person}
          />
          <UIInput
            label={t('COMMON.EMAIL')}
            name="email"
            value={form.values.email}
            className="input"
            placeholder={t('COMMON.EMAIL')}
            classLabel="fw700"
            onChange={form.handleChange}
            isValid={!(form.errors.email && form.touched.email)}
            validateText={form.errors.email}
          />
          <UIInput
            label={t('COMMON.PHONE')}
            name="phone"
            value={form.values.phone}
            className="input"
            placeholder={t('COMMON.PHONE')}
            classLabel="fw700"
            onChange={form.handleChange}
            isValid={!(form.errors.phone && form.touched.phone)}
            validateText={form.errors.phone}
          />
          <div className="added-btn">
            <UIButton type={Button.SUBMIT} className="btn outline__blue">
              <PlusSVG />
              <span>{t('COMMON.ADD')}</span>
            </UIButton>
            <UIButton
              className="btn btn__gray"
              handler={() => {
                dispatch(hideModal());
              }}
            >
              {t('COMMON.CANCEL')}
            </UIButton>
          </div>
        </div>
      </form>
    </LayoutModal>
  );
};

export default WidgetsModalAddResponsible;
