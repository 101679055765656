import {
  ADD_CATEGORIES_FAILURE,
  ADD_CATEGORIES_REQUEST,
  ADD_CATEGORIES_SUCCESS,
  ADD_GROUPS_FAILURE,
  ADD_GROUPS_REQUEST,
  ADD_GROUPS_SUCCESS,
  ADD_PACKAGES_FAILURE,
  ADD_PACKAGES_REQUEST,
  ADD_PACKAGES_SUCCESS,
  DELETE_CATEGORIES_FAILURE,
  DELETE_CATEGORIES_REQUEST,
  DELETE_CATEGORIES_SUCCESS,
  DELETE_GROUPS_FAILURE,
  DELETE_GROUPS_REQUEST,
  DELETE_GROUPS_SUCCESS,
  DELETE_PACKAGES_FAILURE,
  DELETE_PACKAGES_REQUEST,
  DELETE_PACKAGES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_GROUPS_FAILURE,
  GET_GROUPS_REQUEST,
  GET_GROUPS_SUCCESS,
  GET_PACKAGES_FAILURE,
  GET_PACKAGES_REQUEST,
  GET_PACKAGES_SUCCESS,
  SET_CATEGORIES_FAILURE,
  SET_CATEGORIES_REQUEST,
  SET_CATEGORIES_SUCCESS,
  SET_GROUPS_FAILURE,
  SET_GROUPS_REQUEST,
  SET_GROUPS_SUCCESS,
  SET_PACKAGES_FAILURE,
  SET_PACKAGES_REQUEST,
  SET_PACKAGES_SUCCESS,
} from './Categories.constant';

const initialState = {
  loading: false,
  loadingCategories: false,
  loadingPackages: false,
  loadingGroups: false,
  categories: [],
  packages: [],
  groups: [],
};

export default function CategoriesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: state.categories.map((item) => {
          return item.id === action.payload.id ? action.payload : item;
        }),
        loadingCategories: false,
      };
    case DELETE_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: state.categories.filter(
          (item) => item.id !== action.payload.id,
        ),
        loadingCategories: false,
      };
    case DELETE_CATEGORIES_REQUEST:
    case SET_CATEGORIES_REQUEST:
    case ADD_CATEGORIES_REQUEST:
      return {
        ...state,
        loadingCategories: true,
      };
    case DELETE_CATEGORIES_FAILURE:
    case SET_CATEGORIES_FAILURE:
    case ADD_CATEGORIES_FAILURE:
      return {
        ...state,
        loadingCategories: false,
      };
    case ADD_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, action.payload],
        loadingCategories: false,
      };
    case SET_PACKAGES_SUCCESS:
      return {
        ...state,
        packages: state.packages.map((item) => {
          return item.id === action.payload.id ? action.payload : item;
        }),
        loadingPackages: false,
      };
    case DELETE_PACKAGES_SUCCESS:
      return {
        ...state,
        packages: state.packages.filter(
          (item) => item.id !== action.payload.id,
        ),
        loadingPackages: false,
      };
    case DELETE_PACKAGES_REQUEST:
    case SET_PACKAGES_REQUEST:
    case ADD_PACKAGES_REQUEST:
      return {
        ...state,
        loadingPackages: true,
      };
    case DELETE_PACKAGES_FAILURE:
    case SET_PACKAGES_FAILURE:
    case ADD_PACKAGES_FAILURE:
      return {
        ...state,
        loadingPackages: false,
      };
    case ADD_PACKAGES_SUCCESS:
      return {
        ...state,
        packages: [...state.packages, action.payload],
        loadingPackages: false,
      };
    case SET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: state.groups.map((item) => {
          return item.id === action.payload.id ? action.payload : item;
        }),
        loadingGroups: false,
      };
    case DELETE_GROUPS_SUCCESS:
      return {
        ...state,
        groups: state.groups.filter((item) => item.id !== action.payload.id),
        loadingGroups: false,
      };
    case DELETE_GROUPS_REQUEST:
    case SET_GROUPS_REQUEST:
    case ADD_GROUPS_REQUEST:
      return {
        ...state,
        loadingGroups: true,
      };
    case DELETE_GROUPS_FAILURE:
    case SET_GROUPS_FAILURE:
    case ADD_GROUPS_FAILURE:
      return {
        ...state,
        loadingGroups: false,
      };
    case ADD_GROUPS_SUCCESS:
      return {
        ...state,
        groups: [...state.groups, action.payload],
        loadingGroups: false,
      };
    case GET_CATEGORIES_FAILURE:
    case GET_PACKAGES_FAILURE:
    case GET_GROUPS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_CATEGORIES_REQUEST:
    case GET_PACKAGES_REQUEST:
    case GET_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };
    case GET_PACKAGES_SUCCESS:
      return {
        ...state,
        packages: action.payload,
        loading: false,
      };
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
