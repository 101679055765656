import { useRef, useCallback } from 'react';

function useLazyLoading({ onIntersection, marginFromBottom = 0 }) {
  const containerRef = useRef(null);

  const onScroll = useCallback(() => {
    const containerScrollTop = containerRef.current.scrollTop;
    const containerHeight = containerRef.current.clientHeight;
    const { scrollHeight } = containerRef.current;

    if (
      scrollHeight - containerScrollTop - containerHeight - marginFromBottom <=
      0
    ) {
      onIntersection();
    }
  }, [marginFromBottom, onIntersection]);

  return [onScroll, containerRef];
}

export default useLazyLoading;
