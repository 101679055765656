/* eslint-disable quotes */
export const ENV_DEVELOPMENT = 'development';
export const ENV_PRODUCTION = 'production';

export const API_URL = process.env.REACT_APP_API_URL;

export const FORMAT_TIME = 'DD MMM YYYY hh:mm A';
export const FORMAT_DATE_WITHOUT_TIME = 'DD MMM YYYY';
export const FORMAT_DATE_WITH_TIME = 'dd LLL yyyy hh:mm a';
export const FORMAT_TIME_SHORT = 'h:mm';
export const FORMAT_TIME_SHORT_ALT = 'HH:mm';
export const FORMAT_TIME_SHORT_WITH_TIME_FORMAT_ALT = 'h:mm a';
export const FORMAT_TIME_WITH_YEAR = 'HH:mm, yyyy.MM.dd';
export const FORMAT_DATE = 'PP';
export const FORMAT_DATE_TEXT = 'yyyy.MM.dd';
export const FORMAT_DATE_TEXT_ALT = 'dd.MM.yyyy';
export const FORMAT_DATE_TEXT_ALT_FULL = `dd.MM.yyyy HH:mm`;
export const FORMAT_DATE_QUERY = 'yyyy-MM-dd';
export const FORMAT_DATE_SLASH = 'yyyy/MM/dd';
export const FORMAT_DATE_QUERY_FULL = `yyyy-MM-dd'T'HH:mm:ssxxx`;
export const FORMAT_DATE_QUERY_WITH_TIME = `yyyy-MM-dd HH:mm:ss`;
export const FORMAT_DATE_QUERY_WITH_TIME_FORMAT = `yyyy-MM-dd / h:mm`;
export const FORMAT_DATE_FULL = `yyyy-MM-dd'T'hh:mm.ss.XXX`;
export const FORMAT_DAY = 'd';
export const FORMAT_HOUR_ALT = 'HH';
export const FORMAT_DAY_ALT = 'dd';
export const FORMAT_DAY_WITH_MONTH = `d.MM`;
export const FORMAT_WEEKDAY = 'E';
export const FORMAT_DAY_OF_WEEK = 'c';
export const FORMAT_WEEK_WITH_DAY_WITH_MONTH = 'EEEE, d.MM';
export const FORMAT_WEEK_WITH_DAY_WITH_MONTH__ALT = 'EEEE, d MMMM';
export const FORMAT_DAY_WITH_MONTH_ALT = 'd MMMM';
export const FORMAT_MONTH = 'LLLL';
export const FORMAT_DAY_MONTH_WEEK = 'd LLLL EEEE';
export const FORMAT_DAY_MONTH_FORMAT = 'd LLLL';
export const FORMAT_DAY_MONTH = 'dd MMMM';
export const FORMAT_DAY_MONTH_YEAR = 'dd LLLL yyyy';
export const FORMAT_YEAR = 'yyyy';
export const FORMAT_WEEK = 'EEEE';
export const FORMAT_WEEK_WITH_DAY_WITH_MONTH_MOB = 'E, d.MM';
export const FORMAT_CALENDAR_DAY = 'E, LLL d';

export const UNIX_HOUR = 3600000;
export const UNIX_DAY = 86400000;
export const UNIX_WEEK = 604800000;

export const CALENDAR_TYPE_WEEK = 'week';
export const CALENDAR_TYPE_DAY = 'day';

export const LIMIT_DEFAULT = 10;
export const PAGE_DEFAULT = 1;

export const COOKIE_ACCESS_TOKEN = 'accessToken';
export const LOCAL_FRIEND_KEY = 'friendId';

export const LOCAL_ACCESS_TOKEN = 'access_token';
export const LOCAL_REFRESH_TOKEN = 'refresh_token';
export const LOCAL_REFRESH_TOKEN_DATA = 'refresh_token_data';

export const STANDART_DATE = 'Jan 1 2000';
