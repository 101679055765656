import { useState } from 'react';

import { AddCircleSVG, ArrTopSVG } from '@assets/img/icons';
import { UIButton, UILoaderWrap, UITable } from '@ui';
import cn from 'classnames';

const CategoriesItem = ({
  data,
  title,
  titleBtnMobile,
  loading,
  onClickRows,
  onClickCol,
  tdSpan,
  mobileModal,
  children,
}) => {
  const [tab, setTab] = useState(true);

  return (
    <div className={cn('categories-item', { categoriesTab__active: !tab })}>
      <div className="categories-item__title h3 fw700">
        <span>{title}</span>

        <UIButton handler={() => setTab(!tab)} defaultClassName="">
          <ArrTopSVG />
        </UIButton>
      </div>

      <div onClick={mobileModal} className="add__btn hide-desktop show-mobile">
        <span className="color_darkBlue">{titleBtnMobile}</span>
        <AddCircleSVG />
      </div>

      <div className="categories-item__wrap">
        <UILoaderWrap
          loading={loading}
          className="categories-wrap__left oneTd42p"
        >
          <UITable
            {...(onClickRows && {
              onClickRows: (item, index) => onClickRows(item, index),
            })}
            {...(onClickCol && {
              onClickCol: (item, index) => onClickCol(item, index),
            })}
            tdSpan={tdSpan}
            {...data}
          />
        </UILoaderWrap>
        {children}
      </div>
    </div>
  );
};

export default CategoriesItem;
