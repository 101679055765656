import { LayoutAccount } from '@layouts';
import Wall from '@modules/Wall';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

const ViewWall = () => {
  const { t } = useTranslation();

  return (
    <LayoutAccount title={t('WALL.WALL')} className="wall-wrap">
      <Routes>
        <Route path="/" element={<Wall />} />
      </Routes>
    </LayoutAccount>
  );
};

export default ViewWall;
