import { useEffect, useState } from 'react';

import { PlusSVG } from '@assets/img/icons';
import { LayoutModal } from '@layouts';
import { useAppDispatch } from '@store/';
import {
  addPackagesRequest,
  deletePackagesRequest,
  setPackagesRequest,
} from '@store/Categories';
import { UIButton, UIInput } from '@ui';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { INITIAL_VALUES } from '../../../modules/Categories/FormPackages/constant';
import { validationSchema } from '../../../modules/Categories/FormPackages/schema';

const WidgetsModalMobileFormPackages = ({
  edit,
  resetEdit,
  resetCol,
  loading,
}) => {
  const [isEdit, setIsEdit] = useState(!!edit);

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const handlerSubmit = (values, { resetForm }) => {
    if (isEdit) {
      dispatch(
        setPackagesRequest({
          ...values,
          active: edit.active,
          id: edit.id,
        }),
      );
    } else {
      dispatch(addPackagesRequest({ ...values, active: false }));
      resetForm();
    }
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  useEffect(() => {
    if (isEdit && edit.name !== form.values.name) {
      form.setFieldValue('name', edit.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, isEdit]);

  const handlerDelete = () => {
    dispatch(deletePackagesRequest(edit.id));
    form.resetForm();
    resetEdit();
    resetCol();
    setIsEdit(false);
  };

  return (
    <LayoutModal
      title={`${isEdit ? t('COMMON.CHANGE') : t('COMMON.ADD')} ${t(
        'COMMON.SET',
      )}`}
      className="modal__categories"
    >
      <form onSubmit={form.handleSubmit} className="modal_added__items">
        <UIButton
          defaultClassName="categories__clear color_grey h6 fw600"
          type={Button.BUTTON}
          handler={() => {
            form.resetForm();
            resetEdit();
            resetCol();
            setIsEdit(false);
          }}
        >
          {t('COMMON.CLEAR')}
        </UIButton>
        <UIInput
          name="name"
          id="name"
          label={t('COMMON.TITLE')}
          classLabel="fw600"
          className="input input__row"
          placeholder={t('COMMON.TITLE')}
          value={form.values.name}
          onChange={form.handleChange}
          isValid={!(form.errors.name && form.touched.name)}
          validateText={form.errors.name}
        />
        <div className="categories-new__btn">
          <UIButton
            loading={loading}
            className="btn outline__blue"
            type={Button.SUBMIT}
          >
            {isEdit ? (
              <>{t('COMMON.UPDATE')}</>
            ) : (
              <>
                <PlusSVG />
                <span>{t('COMMON.ADD')}</span>
              </>
            )}
          </UIButton>
          {isEdit ? (
            <UIButton
              loading={loading}
              className="btn btn__red"
              handler={handlerDelete}
              type={Button.BUTTON}
            >
              {t('COMMON.DELETE')}
            </UIButton>
          ) : null}
        </div>
      </form>
    </LayoutModal>
  );
};

export default WidgetsModalMobileFormPackages;
