export const SHOW_MODAL = '@modal/SHOW_MODAL';
export const HIDE_MODAL = '@modal/HIDE_MODAL';

export const ModalType = {
  CALENDAR_EVENT: 'CALENDAR_EVENT',
  ADD_CLIENT: 'ADD_CLIENT',
  ADD_EMPLOYEE: 'ADD_EMPLOYEE',
  ADD_RESPONSIBLE: 'ADD_RESPONSIBLE',
  ADD_STAFF: 'ADD_STAFF',
  ADD_TRANSPORT: 'ADD_TRANSPORT',
  DELETE_BOOKING: 'DELETE_BOOKING',
  SETTINGS_USERS: 'SETTINGS_USERS',
  SETTINGS_OFFICES: 'SETTINGS_OFFICES',
  MOBILE_USERS: 'MOBILE_USERS',
  MOBILE_OFFICES: 'MOBILE_OFFICES',
  MOBILE_FORM_CATEGORIES: 'MOBILE_FORM_CATEGORIES',
  MOBILE_FORM_PACKAGES: 'MOBILE_FORM_PACKAGES',
  MOBILE_FORM_GROUPS: 'MOBILE_FORM_GROUPS',
  ADD_EQUIPMENT: 'ADD_EQUIPMENT',
  TRANSFER_EQUIPMENT: 'TRANSFER_EQUIPMENT',
  INTERSECTIONS: 'INTERSECTIONS',
  PROJECT_PRINT: 'PROJECT_PRINT',
  TRANSFER__SETTINGS: 'TRANSFER__SETTINGS',
  TRANSFER__ADD: 'TRANSFER__ADD',
  MOBILE__DATE: 'MOBILE__DATE',
  CONFIRM: 'CONFIRM',
};
