import { useAppDispatch } from '@store/';
import { createProjectFinishRequest } from '@store/ProjectAdd';
import { uploadRequest } from '@store/Upload';
import { UIButton, UIUpload } from '@ui';
import { LINK_PROJECTS } from '@utils/link';
import { FileName } from '@utils/utility-property';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ProjectAddInfoBooking from './Booking';
import './style.scss';
import ProjectAddInfoCard from './Сard';

const ProjectAddInfo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { equipmentData, loading: loadingFinish } = useSelector(
    (state) => state.projectAdd,
  );
  const { editData } = useSelector((state) => state.project);
  const { loading, data } = useSelector((state) => state.upload);

  const initialData = equipmentData || editData;

  const uploadHandler = (file) => {
    const dataToRequest = {
      file,
      fileName: /^(image)\//.test(file.type) ? FileName.IMAGE : FileName.DOCS,
    };

    dispatch(uploadRequest(dataToRequest));
  };

  const onSubmit = () => {
    const dataToRequest = {
      id: initialData.id,
      mediaId: data?.id || null,
      step: 4,
    };

    dispatch(createProjectFinishRequest(dataToRequest));
    navigate(`${LINK_PROJECTS}/${initialData.id}`);
  };

  return (
    <div className="project_info">
      <div className="project_info__title">Soundcafe.by</div>

      <div className="project_info-table_wrap">
        <ProjectAddInfoCard
          equipmentData={initialData}
          title={t('PROJECT.EQUIPMENT_PROJECT_CREATION_CARD')}
        />

        <ProjectAddInfoCard
          equipmentData={initialData}
          isProject
          title={t('PROJECT.PROJECT_INFORMATION')}
        />
      </div>

      <div className="project_info-booking_title">
        {t('PROJECT.ALL_BOOKINGS')}
      </div>

      <ProjectAddInfoBooking equipmentData={initialData} />

      <UIUpload
        loading={loading}
        label={t('COMMON.ATTACH_FILE')}
        sizeLimit={4926438}
        handlerUpload={uploadHandler}
      />

      <div className="btn_submit">
        <UIButton loading={loadingFinish} handler={onSubmit}>
          {t('COMMON.SAVE')}
        </UIButton>
      </div>
    </div>
  );
};

export default ProjectAddInfo;
