import { useEffect, useState } from 'react';

import { PlusSVG } from '@assets/img/icons';
import { LayoutModal } from '@layouts';
import { useAppDispatch } from '@store/';
import {
  addEquipmentsRequest,
  deleteEquipmentsRequest,
  setEquipmentsRequest,
} from '@store/Equipments';
import { hideModal } from '@store/Modal';
import { UIButton, UIInput, UIInputNumber, UISelect } from '@ui';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './style.scss';

import { INITIAL_VALUES } from '../../../modules/Equipments/AddEquipments/constant';
import { validationSchema } from '../../../modules/Equipments/AddEquipments/schema';

const AddEquipment = ({ edit, resetEdit, loadingChange }) => {
  const {
    loading,
    categories,
    // packages,
    groups,
  } = useSelector((state) => state.categories);

  const startInitialValues = {
    ...INITIAL_VALUES,
    name: '',
    group: [...(groups || [])],
  };

  const [initialValues, setInitialValues] = useState(startInitialValues);
  const [isEdit, setIsEdit] = useState(!!edit);

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const handlerSubmit = (
    {
      category,
      group,
      // package: packageNew,
      ...val
    },
    { resetForm },
  ) => {
    const parsPayload = {
      ...val,
      category_id: category.id,
      // package_id: packageNew?.id,
      groups: group.map((item) => ({ id: item.id, count: item.count || 0 })),
    };

    if (isEdit) {
      dispatch(setEquipmentsRequest(parsPayload));
    } else {
      dispatch(
        addEquipmentsRequest({
          ...parsPayload,
          id: null,
        }),
      );
      resetForm();
    }
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  const handlerDelete = () => {
    dispatch(deleteEquipmentsRequest(edit.id));
    form.resetForm({
      values: startInitialValues,
    });
    resetEdit();
    setIsEdit(false);
    dispatch(hideModal());
  };

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  useEffect(() => {
    if (groups) {
      setInitialValues({
        ...INITIAL_VALUES,
        group: groups,
      });
    }
  }, [groups]);

  useEffect(() => {
    if (isEdit) {
      setInitialValues({
        active: edit?.active,
        category: edit?.category,
        group: groups.map((item) => {
          const groupId = edit?.group.find((item2) => item2.id === item.id);

          if (groupId) {
            return {
              ...item,
              count: +groupId.count || 0,
            };
          }

          return item;
        }),
        id: edit?.id,
        name: edit?.name,
        // package: edit?.package,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  return (
    <LayoutModal
      title={
        isEdit ? t('EQUIPMENTS.EDIT_EQUIPMENT') : t('EQUIPMENTS.ADD_EQUIPMENT')
      }
      className="modal_added_equipment modal__equipment"
    >
      <UIButton
        defaultClassName="modal_transfer__clear"
        type={Button.BUTTON}
        handler={() => {
          form.resetForm({
            values: startInitialValues,
          });
          resetEdit();
          setIsEdit(false);
        }}
      >
        {t('COMMON.CLEAR')}
      </UIButton>
      <form
        className="modal_added_equipment__items"
        onSubmit={form.handleSubmit}
      >
        <UIInput
          name="name"
          islabelRow
          id="name_modal"
          label={t('COMMON.TITLE')}
          classLabel="fw600 icon_necessarily"
          placeholder={t('COMMON.TITLE')}
          value={form.values?.name}
          onChange={form.handleChange}
          isValid={!(form.errors.name && form.touched.name)}
          validateText={form.errors.name}
        />
        <UISelect
          name="category"
          loading={loading || loadingChange}
          className="custom-select-row"
          label={t('COMMON.CATEGORY')}
          classLabel="fw600 icon_necessarily"
          options={categories}
          placeholder={t('COMMON.CATEGORY')}
          value={form.values?.category}
          onChange={(value) => form.setFieldValue('category', value)}
          isValid={!(form.errors.category && form.touched.category)}
          validateText={form.errors.category}
        />
        {/* <UISelect
          name="package"
          loading={loading || loadingChange}
          className="custom-select-row"
          label="Комплект"
          classLabel="h6 fw600"
          options={packages}
          placeholder="Комплект"
          value={form.values?.package}
          onChange={(value) => form.setFieldValue('package', value)}
        /> */}
        {form?.values?.group.map((item, index) => (
          <UIInputNumber
            key={index}
            type="string"
            min={0}
            name={`group.${index}.count`}
            label={item.name}
            classLabel="h6 fw600"
            value={form.values?.group?.[index]?.count}
            onChange={(value) =>
              form.setFieldValue(`group.${index}.count`, value)
            }
          />
        ))}
        {/* <div className="modal-status__wrap radio-wrap">
          <span className="h6 fw600">Статус</span>
          <div>
            <UIRadio
              id="active_modal"
              name="active"
              checked={form.values?.active}
              onChange={() => form.setFieldValue('active', true)}
            >
              Активен
            </UIRadio>
            <UIRadio
              id="inactive_modal"
              name="active"
              checked={!form.values?.active}
              onChange={() => form.setFieldValue('active', false)}
            >
              Неактивен
            </UIRadio>
          </div>
          <UIError
            isValid={!(form.errors.active && form.touched.active)}
            text={form.errors.active}
          />
        </div> */}
        <div className="added-btn">
          <UIButton
            loading={loading || loadingChange}
            className="btn outline__blue"
            type={Button.SUBMIT}
          >
            {isEdit ? (
              <>{t('COMMON.UPDATE')}</>
            ) : (
              <>
                <PlusSVG />
                <span>{t('COMMON.ADD')}</span>
              </>
            )}
          </UIButton>
          {isEdit ? (
            <UIButton
              loading={loading || loadingChange}
              className="btn btn__red"
              handler={handlerDelete}
              type={Button.BUTTON}
            >
              {t('COMMON.DELETE')}
            </UIButton>
          ) : null}
        </div>
      </form>
    </LayoutModal>
  );
};

export default AddEquipment;
