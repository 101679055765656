import { useRef } from 'react';

import iconClose from '@assets/img/icons/close.svg';
import { useHidePopupOutside } from '@hooks';
import { useAppDispatch } from '@store/';
import { hideModal } from '@store/Modal';
import cn from 'classnames';

const LayoutModal = ({ className, children, title }) => {
  const dispatch = useAppDispatch();

  const modalRef = useRef(null);

  const handlerClose = () => {
    dispatch(hideModal());
  };

  useHidePopupOutside(modalRef, () => handlerClose());

  return (
    <div className="modal">
      <div ref={modalRef} className={cn('modal-content', className)}>
        <div className="modal-close">
          <img src={iconClose} alt="icon" onClick={handlerClose} />
        </div>
        {title && <div className="modal-header fw700">{title}</div>}
        {children}
      </div>
    </div>
  );
};

export default LayoutModal;
