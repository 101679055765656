export const GET_EVENTS_REQUEST = '@calendar/GET_EVENTS_REQUEST';
export const GET_EVENTS_FAILURE = '@calendar/GET_EVENTS_FAILURE';
export const GET_EVENTS_SUCCESS = '@calendar/GET_EVENTS_SUCCESS';
export const GET_EVENTS_SUCCESS_YEAR = '@calendar/GET_EVENTS_SUCCESS_YEAR';

export const GET_OFFICES_REQUEST = '@calendar/GET_OFFICES_REQUEST';
export const GET_OFFICES_FAILURE = '@calendar/GET_OFFICES_FAILURE';
export const GET_OFFICES_SUCCESS = '@calendar/GET_OFFICES_SUCCESS';

export const GET_ONE_EVENT_REQUEST = '@calendar/GET_ONE_EVENT_REQUEST';
export const GET_ONE_EVENT_FAILURE = '@calendar/GET_ONE_EVENT_FAILURE';
export const GET_ONE_EVENT_SUCCESS = '@calendar/GET_ONE_EVENT_SUCCESS';

export const UPDATE_EVENT = '@calendar/UPDATE_EVENT';

export const DELETE_EVENT = '@calendar/DELETE_EVENT';

export const ADD_ONE_EVENT_INFO = '@calendar/ADD_ONE_EVENT_INFO';

export const CHANGE_PARAM = '@calendar/CHANGE_PARAM';
