import { useEffect, useCallback } from 'react';

function isDescendant(parent, child) {
  let node = child.parentNode;

  if (child === parent) {
    return true;
  }

  while (node != null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }

  return false;
}

const useHidePopupOutside = (ref, handler) => {
  const onOutsideClickHandler = useCallback(
    (e) => {
      if (
        !isDescendant(ref?.current, e.target) &&
        !e.target?.classList?.contains('ui-select__option') &&
        e.pointerType !== ''
      ) {
        handler();
      }
    },
    [handler, ref],
  );

  useEffect(() => {
    document.addEventListener('mousedown', onOutsideClickHandler);

    return () => {
      document.removeEventListener('mousedown', onOutsideClickHandler);
    };
  }, [onOutsideClickHandler]);
};

export default useHidePopupOutside;
