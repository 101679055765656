import { FORMAT_DATE_TEXT } from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { useTranslation } from 'react-i18next';

const ProjectAddEquipmentHeader = ({ name, dateStart, dateEnd }) => {
  const { t } = useTranslation();

  return (
    <div className="addEvent-description">
      <div>
        <span className="h6 fw700">{t('COMMON.TITLE')}</span>
        <span className="h6 color_grey">{name}</span>
      </div>

      <div>
        <span className="h6 fw700">{t('COMMON.BEGINNING')}</span>
        <span className="h6 color_grey">
          {DateHelper.toFormat(dateStart * 1000, FORMAT_DATE_TEXT)}
        </span>
      </div>

      <div>
        <span className="h6 fw700">{t('COMMON.ENDING')}</span>
        <span className="h6 color_grey">
          {DateHelper.toFormat(dateEnd * 1000, FORMAT_DATE_TEXT)}
        </span>
      </div>
    </div>
  );
};

export default ProjectAddEquipmentHeader;
