import { useState } from 'react';

import { ShowPassSVG } from '@assets/img/icons';
import cn from 'classnames';

import UIError from '../Error';

const UIInput = ({ forwardRef, wrapRef, ...props }) => {
  const {
    id,
    label,
    name,
    onChange,
    onKeyPress,
    value,
    placeholder,
    maxLength,
    validateText,
    children,
    prop,
    defaultValue,
    isShowPassword,
    classLabel,
    classInput,
    className = 'input',
    islabelRow,
    type = 'text',
    disabled = false,
    required = false,
    isValid = true,
    validationTextPosition = 'bottom',
    onFocus,
    onBlur,
    childrenInput,
    autoComplete,
  } = props;

  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  const handlerChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handlerKeyPress = (event) => {
    if (onKeyPress && event.key === 'Enter') {
      onKeyPress(value);
    }
  };

  return (
    <div
      ref={wrapRef}
      className={cn(
        { 'validate-error': !isValid, wrapPopup: !!wrapRef },
        className,
      )}
    >
      {label &&
        (islabelRow ? (
          <span>
            <label className={classLabel} htmlFor={id}>
              {label}
            </label>
          </span>
        ) : (
          <label className={classLabel} htmlFor={id}>
            {label}
          </label>
        ))}
      <div className="inputValid-wrap">
        <input
          defaultValue={defaultValue}
          id={id}
          name={name}
          type={isVisiblePassword ? 'text' : type}
          value={value}
          placeholder={placeholder}
          onChange={handlerChange}
          onKeyPress={handlerKeyPress}
          disabled={disabled}
          required={required}
          maxLength={maxLength}
          ref={forwardRef}
          className={classInput}
          onFocus={onFocus}
          onBlur={onBlur}
          {...(autoComplete && { autoComplete })}
          {...prop}
        />
        <UIError
          isValid={isValid}
          textPosition={validationTextPosition}
          text={validateText}
        />
        {childrenInput && childrenInput}
      </div>
      {children && children}
      {isShowPassword && (
        <span
          onClick={() => setIsVisiblePassword(!isVisiblePassword)}
          className="input-icon__btn"
        >
          <ShowPassSVG />
        </span>
      )}
    </div>
  );
};

export default UIInput;
