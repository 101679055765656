import { useState } from 'react';

import { PlusWhiteSVG } from '@assets/img/icons';
import calendar from '@assets/img/icons/calendar.svg';
import calendarActive from '@assets/img/icons/calendar_active.svg';
import categories from '@assets/img/icons/categories.svg';
import categoriesActive from '@assets/img/icons/categories_active.svg';
import equipment from '@assets/img/icons/equipment.svg';
import equipmentActive from '@assets/img/icons/equipment_active.svg';
import event from '@assets/img/icons/event.svg';
import eventActive from '@assets/img/icons/event_active.svg';
import logo from '@assets/img/icons/logo.svg';
import logoName from '@assets/img/icons/logoName.svg';
import menu from '@assets/img/icons/menu.svg';
import projects from '@assets/img/icons/projects.svg';
import projectsActive from '@assets/img/icons/projects_active.svg';
import reports from '@assets/img/icons/reports.svg';
import reportsActive from '@assets/img/icons/reports_active.svg';
import transfer from '@assets/img/icons/transfer.svg';
import transferActive from '@assets/img/icons/transfer_active.svg';
import user from '@assets/img/icons/user.svg';
import userActive from '@assets/img/icons/user_active.svg';
import wall from '@assets/img/icons/wall.svg';
import wallActive from '@assets/img/icons/wall_active.svg';
import { LocalStorage } from '@services/storage';
import { useAppDispatch } from '@store/';
import { resetStore as resetStoreProject } from '@store/Project';
import { resetStore, setStep } from '@store/ProjectAdd';
import { clearTransferData } from '@store/Transfer';
import { UIButton } from '@ui';
import {
  LINK_CALENDARS,
  LINK_CATEGORIES,
  LINK_EQUIPMENTS,
  LINK_EVENTS,
  LINK_PROFILE,
  LINK_PROJECTS,
  LINK_PROJECTS_ADD,
  LINK_REPORTS,
  LINK_TRANSFER,
  LINK_USERS,
  LINK_WALL,
} from '@utils/link';
import { Button } from '@utils/utility-property';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const WidgetMenu = ({ isMenuMobile, setIsMenuMobile }) => {
  const [isMenu, setIsMenu] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handlerCloseMenu = () => {
    setIsMenuMobile(false);
  };

  return (
    <div
      className={cn(isMenu ? 'menu hide' : 'menu', {
        open: isMenuMobile,
      })}
      id="menu"
    >
      <UIButton
        defaultClassName=""
        className="logo"
        type={Button.LINK}
        link={'/'}
      >
        <span>
          <img src={logoName} alt="icon" />
        </span>
        <img src={logo} alt="icon" />
      </UIButton>
      <span className="btn__menu" onClick={() => setIsMenu(!isMenu)}>
        <img src={menu} alt="menu" />
      </span>
      <UIButton
        type={Button.BUTTON}
        handler={() => {
          navigate(LINK_PROJECTS_ADD);
          dispatch(resetStoreProject());
          dispatch(resetStore());
          dispatch(setStep(0));
          dispatch(clearTransferData());
          LocalStorage.set('project_step', 0);
          LocalStorage.removeItem('project_id');
        }}
        className="btn addEvent"
      >
        <span className="addEvent__desktop">{t('MENU.ADD_AN_EVENT')}</span>
        <PlusWhiteSVG className="addEvent__mobile" />
      </UIButton>
      <ul className="menu-items">
        <li>
          <UIButton
            activeClassName="active"
            type={Button.LINK}
            link={LINK_CALENDARS}
            handler={handlerCloseMenu}
          >
            <img className="icons__stock" src={calendar} alt="icons" />
            <img className="icons__active" src={calendarActive} alt="icons" />
            <span className="menu-items__name">{t('MENU.CALENDAR')}</span>
            {/* <span className="menu-items__actions">2</span> */}
          </UIButton>
        </li>
        <li>
          <UIButton
            activeClassName="active"
            type={Button.LINK}
            link={LINK_PROJECTS}
            handler={handlerCloseMenu}
          >
            <img className="icons__stock" src={projects} alt="icons" />
            <img className="icons__active" src={projectsActive} alt="icons" />
            <span className="menu-items__name">{t('MENU.PROJECTS')}</span>
          </UIButton>
        </li>
        <li>
          <UIButton
            activeClassName="active"
            type={Button.LINK}
            link={LINK_TRANSFER}
            handler={handlerCloseMenu}
          >
            <img className="icons__stock" src={transfer} alt="icons" />
            <img className="icons__active" src={transferActive} alt="icons" />
            <span className="menu-items__name">{t('MENU.TRANSFER')}</span>
          </UIButton>
        </li>
        <li>
          <UIButton
            activeClassName="active"
            type={Button.LINK}
            link={LINK_WALL}
            handler={handlerCloseMenu}
          >
            <img className="icons__stock" src={wall} alt="icons" />
            <img className="icons__active" src={wallActive} alt="icons" />
            <span className="menu-items__name">{t('MENU.WALL')}</span>
          </UIButton>
        </li>
        <li>
          <UIButton
            activeClassName="active"
            type={Button.LINK}
            link={LINK_USERS}
            handler={handlerCloseMenu}
          >
            <img className="icons__stock" src={user} alt="icons" />
            <img className="icons__active" src={userActive} alt="icons" />
            <span className="menu-items__name">{t('MENU.USERS')}</span>
          </UIButton>
        </li>
        <li>
          <UIButton
            activeClassName="active"
            type={Button.LINK}
            link={LINK_EQUIPMENTS}
            handler={handlerCloseMenu}
          >
            <img className="icons__stock" src={equipment} alt="icons" />
            <img className="icons__active" src={equipmentActive} alt="icons" />
            <span className="menu-items__name">{t('MENU.EQUIPMENT')}</span>
          </UIButton>
        </li>
        <li>
          <UIButton
            activeClassName="active"
            type={Button.LINK}
            link={LINK_EVENTS}
            handler={handlerCloseMenu}
          >
            <img className="icons__stock" src={event} alt="icons" />
            <img className="icons__active" src={eventActive} alt="icons" />
            <span className="menu-items__name">{t('MENU.AT_EVENTS')}</span>
          </UIButton>
        </li>
        <li>
          <UIButton
            activeClassName="active"
            type={Button.LINK}
            link={LINK_CATEGORIES}
            handler={handlerCloseMenu}
          >
            <img className="icons__stock" src={categories} alt="icons" />
            <img className="icons__active" src={categoriesActive} alt="icons" />
            <span className="menu-items__name">{t('MENU.CATEGORIES')}</span>
          </UIButton>
        </li>
        <li>
          <UIButton
            activeClassName="active"
            type={Button.LINK}
            link={LINK_REPORTS}
            handler={handlerCloseMenu}
          >
            <img className="icons__stock" src={reports} alt="icons" />
            <img className="icons__active" src={reportsActive} alt="icons" />
            <span className="menu-items__name">{t('MENU.REPORTS')}</span>
          </UIButton>
        </li>
      </ul>
      <UIButton
        className="menu-user"
        activeClassName="active"
        type={Button.LINK}
        link={LINK_PROFILE}
        handler={handlerCloseMenu}
      >
        <span>{t('MENU.PROFILE')}</span>
      </UIButton>
    </div>
  );
};

export default WidgetMenu;
