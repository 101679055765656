export const SEARCH_TRANSFER_REQUEST = '@transfer/SEARCH_TRANSFER_REQUEST';
export const SEARCH_TRANSFER_FAILURE = '@transfer/SEARCH_TRANSFER_FAILURE';
export const SEARCH_TRANSFER_SUCCESS = '@transfer/SEARCH_TRANSFER_SUCCESS';

export const TRANSFER_ALL_REQUEST = '@transfer/TRANSFER_ALL_REQUEST';
export const TRANSFER_ALL_FAILURE = '@transfer/TRANSFER_ALL_FAILURE';
export const TRANSFER_ALL_SUCCESS = '@transfer/TRANSFER_ALL_SUCCESS';

export const NEW_TRANSFER_REQUEST = '@transfer/NEW_TRANSFER_REQUEST';
export const NEW_TRANSFER_FAILURE = '@transfer/NEW_TRANSFER_FAILURE';
export const NEW_TRANSFER_SUCCESS = '@transfer/NEW_TRANSFER_SUCCESS';

export const CHANGE_TRANSFER_REQUEST = '@transfer/CHANGE_TRANSFER_REQUEST';
export const CHANGE_TRANSFER_FAILURE = '@transfer/CHANGE_TRANSFER_FAILURE';
export const CHANGE_TRANSFER_SUCCESS = '@transfer/CHANGE_TRANSFER_SUCCESS';

export const DELETE_TRANSFER_REQUEST = '@transfer/DELETE_TRANSFER_REQUEST';
export const DELETE_TRANSFER_FAILURE = '@transfer/DELETE_TRANSFER_FAILURE';
export const DELETE_TRANSFER_SUCCESS = '@transfer/DELETE_TRANSFER_SUCCESS';

export const VALIDATION_TRANSFER_REQUEST =
  '@transfer/VALIDATION_TRANSFER_REQUEST';
export const VALIDATION_TRANSFER_FAILURE =
  '@transfer/VALIDATION_TRANSFER_FAILURE';
export const VALIDATION_TRANSFER_SUCCESS =
  '@transfer/VALIDATION_TRANSFER_SUCCESS';

export const CLEAR_TRANSFER_DATA = '@transfer/CLEAR_TRANSFER_DATA';
