/* eslint-disable prefer-template */
import { UITable } from '@ui';
import {
  FORMAT_DATE_TEXT_ALT,
  FORMAT_DATE_TEXT_ALT_FULL,
} from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { useTranslation } from 'react-i18next';

const ProjectDetailBooking = ({ project }) => {
  const { t } = useTranslation();

  const header = [
    t('COMMON.TYPE'),
    t('COMMON.EQUIPMENT'),
    t('COMMON.WAREHOUSE'),
    t('COMMON.QUANTITY'),
  ];

  return (
    <>
      <div className="projects__all h4 fw600">{t('PROJECT.ALL_BOOKINGS')}</div>
      <div className="projects-tables">
        {project?.reservations?.map((item, index) => {
          return (
            <div
              key={index}
              className="projects-tables__item projects__equipment"
            >
              <div className="projects-tables__info">
                <div className="projects-tableInfo__title h4 fw600 color_blue">
                  {item?.name}
                </div>
                <div className="projects-tableInfo__date">
                  <div className="h6">
                    <span className="fw600">{t('PROJECT.BOOKING_DATE')}</span>
                    <span>
                      {DateHelper.toFormat(
                        item?.start * 1000,
                        FORMAT_DATE_TEXT_ALT,
                      ) +
                        ' - ' +
                        DateHelper.toFormat(
                          item?.finish * 1000,
                          FORMAT_DATE_TEXT_ALT,
                        )}
                    </span>
                  </div>
                  <div className="h6">
                    <span className="fw600">
                      {t('PROJECT.LOADING_DATE_TIME')}
                    </span>
                    <span>
                      {DateHelper.toFormat(
                        item?.loading * 1000,
                        FORMAT_DATE_TEXT_ALT_FULL,
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="wrap-big-table">
                <UITable
                  className="custom-table oneTdBold big-table"
                  header={header}
                  content={item?.equipments.map((item2) => [
                    item2?.category?.name,
                    item2?.name,
                    item2?.group?.find((i) => +i.id === +item2?.group_id)?.name,
                    item2?.reserved,
                  ])}
                />
                <div className="projects-additionally">
                  {item?.transports.length ? (
                    <div>
                      <span>{t('PROJECT.TRANSPORT')}</span>
                      {item?.transports?.map(
                        (item2, index) =>
                          item2?.name +
                          (index !== item?.transports?.length - 1 ? ', ' : '.'),
                      )}
                    </div>
                  ) : null}

                  {item?.personals.length ? (
                    <div>
                      <span>{t('PROJECT.STAFF')}</span>
                      {item?.personals?.map(
                        (item2, index) =>
                          item2?.name +
                          (index !== item?.personals?.length - 1 ? ', ' : '.'),
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ProjectDetailBooking;
