/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react';

import { CircleCloseSVG, EdditSVG } from '@assets/img/icons';
import { useResponsive } from '@hooks';
import { LocalStorage } from '@services/storage';
import { useAppDispatch } from '@store/';
import { getUserInfoRequest, setUserInfoRequest } from '@store/Profile';
import { UIButton, UIColor, UIInput, UILoaderWrap, UISelect } from '@ui';
import { LOCAL_ACCESS_TOKEN } from '@utils/constants';
import { LINK_LOGIN } from '@utils/link';
import cn from 'classnames';
import { useFormik } from 'formik';
import jwt from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

import './styles.scss';

const Profile = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const { loading, user } = useSelector((state) => state.profile);

  const [isOpenColor, setIsOpenColor] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const { isIpad, isTablet, isMobile } = useResponsive();

  const handlerSubmit = (values) => {
    const { password, role, ...val } = values;

    dispatch(
      setUserInfoRequest({
        ...user,
        ...val,
        role: role.id,
        ...(password && { password }),
      }),
    );
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  const resetForm = () => {
    if (user) {
      form.resetForm();
      form.setFieldValue('first_name', user.first_name);
      form.setFieldValue('last_name', user.last_name);
      form.setFieldValue('color', user.color);
      user.email && form.setFieldValue('email', user.email);
      user.phone && form.setFieldValue('phone', user.phone);
      if (user.role) {
        form.setFieldValue('role', {
          id: user.role,
          name: t('COMMON.ADMINISTRATOR'),
        });
      }
    }
  };

  const handlerSave = () => {
    if (isEdit) {
      form.submitForm();
    } else {
      setEdit(true);
    }
  };

  const handlerClose = () => {
    setEdit(!isEdit);
    if (isEdit) {
      resetForm();
    } else {
      LocalStorage.clearToken();
      window.location.href = LINK_LOGIN;
    }
  };

  useEffect(() => {
    dispatch(getUserInfoRequest(jwt(LocalStorage.get(LOCAL_ACCESS_TOKEN)).sub));
  }, [dispatch]);

  useEffect(() => {
    if (!loading) {
      setEdit(false);
    }
  }, [loading]);

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  return (
    <UILoaderWrap loading={loading}>
      <div className="profile-naw">
        <div className="h4 color_grey">{t('COMMON.PRIFILE')}</div>
        <UIButton
          defaultClassName=""
          className={cn('btn__gray', {
            btn: !((isIpad || isTablet || isMobile) && isEdit),
          })}
          handler={handlerClose}
        >
          {isEdit ? (
            isIpad || isTablet || isMobile ? (
              <CircleCloseSVG className="hide-desktop show-mobile" />
            ) : (
              t('COMMON.CANCEL')
            )
          ) : (
            <span className="h6 fw400">{t('COMMON.EXIT')}</span>
          )}
        </UIButton>

        <UIButton
          className={cn('outline__blue btn_eddit', { 'hide-mobile': isEdit })}
          handler={handlerSave}
          loading={loading}
        >
          <span className="hide-mobile">
            {isEdit ? t('COMMON.SAVE_CHANGES') : t('COMMON.EDIT')}
          </span>

          <EdditSVG className="hide-desktop show-mobile" />
        </UIButton>
      </div>

      <UIColor
        setColor={(color) => form.setFieldValue('color', color)}
        color={form.values.color}
        setIsOpenColor={setIsOpenColor}
        isOpenColor={isOpenColor}
        title={`${t('COMMON.PROFILE_COLOR')}:`}
      />

      <form onSubmit={form.handleSubmit}>
        <div className="profile-inputs__wrap">
          <div>
            <UIInput
              islabelRow={false}
              disabled={!isEdit}
              label={t('COMMON.NAME')}
              name="first_name"
              placeholder={t('COMMON.NAME')}
              classLabel="fw600"
              value={form.values.first_name}
              onChange={form.handleChange}
              isValid={!(form.errors.first_name && form.touched.first_name)}
              validateText={form.errors.first_name}
            />

            <UIInput
              disabled={!isEdit}
              islabelRow={false}
              label={t('COMMON.SURNAME')}
              name="last_name"
              placeholder={t('COMMON.SURNAME')}
              classLabel="fw600"
              value={form.values.last_name}
              onChange={form.handleChange}
              isValid={!(form.errors.last_name && form.touched.last_name)}
              validateText={form.errors.last_name}
            />

            <UIInput
              label={t('COMMON.PASSWORD')}
              name="password"
              type="password"
              placeholder={t('COMMON.PASSWORD')}
              className="input input__icon"
              classLabel="fw600"
              value={form.values.password}
              onChange={form.handleChange}
              isValid={!(form.errors.password && form.touched.password)}
              validateText={form.errors.password}
              isShowPassword
            />
          </div>

          <div>
            <UIInput
              islabelRow={false}
              disabled={!isEdit}
              label={t('COMMON.MAIL')}
              name="email"
              placeholder={t('COMMON.MAIL')}
              classLabel="fw600"
              value={form.values.email}
              onChange={form.handleChange}
              isValid={!(form.errors.email && form.touched.email)}
              validateText={form.errors.email}
            />

            <UIInput
              islabelRow={false}
              disabled={!isEdit}
              label={t('COMMON.PHONE')}
              name="phone"
              placeholder={t('COMMON.PHONE')}
              classLabel="fw600"
              value={form.values.phone}
              onChange={form.handleChange}
              isValid={!(form.errors.phone && form.touched.phone)}
              validateText={form.errors.phone}
            />

            <UISelect
              disabled={!isEdit}
              id="role"
              label={t('COMMON.ROLE')}
              classLabel="h6 fw600"
              options={[{ id: 'admin', name: t('COMMON.ADMINISTRATOR') }]}
              name="role"
              value={form.values.role}
              onChange={(value) => {
                form.setFieldValue('role', value);
              }}
              placeholder={t('COMMON.ROLE')}
              isValid={!(form.errors.role && form.touched.role)}
              validateText={form.errors.role}
            />
          </div>
        </div>

        {isEdit ? (
          <UIButton
            loading={loading}
            className="hide-desktop show-mobile profile-submit"
            handler={handlerSave}
          >
            {t('COMMON.SAVE')}
          </UIButton>
        ) : null}
      </form>
    </UILoaderWrap>
  );
};

export default Profile;
