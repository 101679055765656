export const INITIAL_VALUES = {
  email: '',
  first_name: '',
  last_name: '',
  phone: '',
  role: '',
  password: '',
  color: '',
  active: '',
};
