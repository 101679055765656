/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { useEffect, useRef, useState } from 'react';

import { AddCircleSVG, CalendarSVG } from '@assets/img/icons';
import { useHidePopupOutside, useResponsive } from '@hooks';
import { UIProjectAddStep } from '@modules/ProjectAdd/ui';
import { useAppDispatch } from '@store/';
import { ModalType, showModal } from '@store/Modal';
import { updateEditProjectRequest } from '@store/Project';
import { createProjectRequest, setStep1Form } from '@store/ProjectAdd';
import { UIButton, UICheckBox, UIInput, UIRangeCalendar, UISelect } from '@ui';
import { Button } from '@utils';
import {
  FORMAT_DATE_FULL,
  FORMAT_DATE_QUERY,
  FORMAT_DATE_TEXT,
} from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { checkDisableDate, INITIAL_RANGE, INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const ProjectAddMainForm = ({ setShowClient, setShowEmployee }) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const calendarRef = useRef(null);
  const { isMobile, isDesktop, isIpad, isTablet } = useResponsive();

  const {
    organizerEvents,
    userEvents,
    loading,
    loadingProject,
    step1Form,
    projectData,
  } = useSelector((state) => state.projectAdd);
  const { editData } = useSelector((state) => state.project);

  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [projectDate, setProjectDate] = useState(null);
  const [isFirstDate, isSetFirstDate] = useState(false);
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const [range, setRange] = useState(INITIAL_RANGE);
  const [step, setStep] = useState(1);

  useHidePopupOutside(calendarRef, () => setCalendarOpen(false));

  const handlerSubmit = (values) => {
    if (editData) {
      const startDate = DateHelper.toFormat(
        new Date(values.date_start_event),
        FORMAT_DATE_QUERY,
        false,
      );
      const endDate = DateHelper.toFormat(
        new Date(values.date_finish_event),
        FORMAT_DATE_QUERY,
        false,
      );

      const objToRequest = {
        ...values,
        dateEvent: [startDate, endDate],
        id: editData.id,
        date_start_event: `${DateHelper.toFormat(
          values.date_start_event,
          FORMAT_DATE_QUERY,
          false,
        )} 00:00:00`,
        date_finish_event: `${DateHelper.toFormat(
          values.date_finish_event,
          FORMAT_DATE_QUERY,
          false,
        )} 00:00:00`,
      };

      dispatch(updateEditProjectRequest(objToRequest, step));
    } else if (projectData) {
      dispatch(
        updateEditProjectRequest({ ...values, id: projectData.id }, step),
      );
    } else {
      dispatch(createProjectRequest(values, step));
    }
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  const handlerSelect = (ranges) => {
    const { startDate, endDate } = ranges?.selection;

    if (new Date(startDate).valueOf() === new Date(endDate).valueOf()) {
      isSetFirstDate(true);
    } else {
      isSetFirstDate(false);
    }

    if (startDate && endDate) {
      form.setFieldValue(
        'date_start_event',
        `${DateHelper.toFormat(startDate, FORMAT_DATE_QUERY, false)} 00:00:00`,
      );
      form.setFieldValue(
        'date_finish_event',
        `${DateHelper.toFormat(endDate, FORMAT_DATE_QUERY, false)} 00:00:00`,
      );
      form.setFieldValue('dateEvent', [
        DateHelper.toFormat(startDate, FORMAT_DATE_FULL, false),
        DateHelper.toFormat(endDate, FORMAT_DATE_FULL, false),
      ]);
      setProjectDate({
        startDate: DateHelper.toFormat(startDate, FORMAT_DATE_TEXT, false),
        endDate: DateHelper.toFormat(endDate, FORMAT_DATE_TEXT, false),
      });
      setRange({
        startDate,
        endDate,
        key: 'selection',
      });
    }
  };

  const openModal = (modalType) => {
    if (isMobile || isIpad || isTablet || isDesktop) {
      dispatch(
        showModal({
          modalType,
        }),
      );
    }
  };

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  useEffect(() => {
    if (step1Form && step1Form !== INITIAL_VALUES) {
      setProjectDate({
        startDate: DateHelper.toFormat(
          step1Form.date_start_event,
          FORMAT_DATE_QUERY,
        ),
        endDate: DateHelper.toFormat(
          step1Form.date_finish_event,
          FORMAT_DATE_QUERY,
        ),
      });

      setInitialValues(step1Form);
    } else if (editData) {
      const startDate = DateHelper.toFormat(
        editData.date_start_event * 1000,
        FORMAT_DATE_QUERY,
      );
      const endDate = DateHelper.toFormat(
        editData.date_finish_event * 1000,
        FORMAT_DATE_QUERY,
      );

      setProjectDate({
        startDate,
        endDate,
      });

      const dataToSet = {
        name: editData.name,
        date_start_event: startDate,
        date_finish_event: endDate,
        edit: !editData.edit_disabled,
        organizer_id: editData.organizer.id,
        person_charge_id: editData.person_charge.id,
        dateEvent: [startDate, endDate],
        step: '1',
      };

      setInitialValues(dataToSet);
    } else {
      setInitialValues(INITIAL_VALUES);
      setProjectDate(null);
      isSetFirstDate(false);
      setRange(INITIAL_RANGE);
    }
  }, [editData, step1Form]);

  return (
    <>
      <div className="addEvent-wrap__left">
        <UIProjectAddStep
          handleSave={(type) => {
            setStep(type);
            dispatch(setStep1Form(form.values));
            form.handleSubmit(type);
          }}
        />
        <div className="h3 fw600 mb-30">{t('COMMON.MAIN')}</div>
        <form onSubmit={form.handleSubmit}>
          <div className="input mb-30">
            <UIInput
              name="name"
              placeholder={t('COMMON.TITLE')}
              label={t('COMMON.TITLE')}
              classLabel="fw700 icon_necessarily"
              value={form.values.name}
              onChange={form.handleChange}
              isValid={!(form.errors.name && form.touched.name)}
              validateText={form.errors.name}
            />
          </div>

          <div className="addEvent-left__select">
            <UISelect
              label={t('PROJECT.PROJECT_CLIENT')}
              classLabel="icon_necessarily"
              options={organizerEvents}
              name="organizer_id"
              value={organizerEvents?.find(
                (i) => i.id === form.values.organizer_id,
              )}
              onChange={(value) => {
                form.setFieldValue('organizer_id', value?.id || null);
              }}
              isLoading={loading}
              placeholder={t('PROJECT.PROJECT_CLIENT')}
              isValid={!(form.errors.organizer_id && form.touched.organizer_id)}
              validateText={form.errors.organizer_id}
            />

            <UIButton
              className="addElement"
              type={Button.BUTTON}
              handler={() => {
                setShowClient(true);
                setShowEmployee(false);
                openModal(ModalType.ADD_CLIENT);
              }}
            >
              <span className="h6 fw600 color_darkBlue">
                {t('PROJECT.ADD_CUSTOMER')}
              </span>
              <AddCircleSVG />
            </UIButton>
          </div>

          <div className="addEvent-left__select">
            <UISelect
              label={t('PROJECT.PROJECT_MANAGER')}
              classLabel="icon_necessarily"
              name="person_charge_id"
              options={userEvents}
              isLoading={loading}
              value={userEvents.find(
                (i) => i.id === form.values?.person_charge_id,
              )}
              onChange={(value) => {
                form.setFieldValue('person_charge_id', value?.id || null);
              }}
              placeholder={t('PROJECT.PROJECT_MANAGER')}
              isValid={
                !(form.errors.person_charge_id && form.touched.person_charge_id)
              }
              validateText={form.errors.person_charge_id}
            />

            <UIButton
              className="addElement"
              type={Button.BUTTON}
              handler={() => {
                setShowClient(false);
                setShowEmployee(true);
                openModal(ModalType.ADD_EMPLOYEE);
              }}
            >
              <span className="h6 fw600 color_darkBlue">
                {t('PROJECT.ADD_EMPLOYEE')}
              </span>
              <AddCircleSVG />
            </UIButton>
          </div>

          <UIInput
            className="input input__icon mb-32"
            name="dateEvent"
            placeholder={t('PROJECT.PROJECT_DATE')}
            label={t('PROJECT.PROJECT_DATE')}
            classLabel="fw700 icon_necessarily"
            value={
              projectDate
                ? `${projectDate.startDate} - ${projectDate.endDate}`
                : ''
            }
            isValid={
              !(
                form.errors.date_start_event &&
                form.touched.date_start_event &&
                form.errors.date_finish_event &&
                form.touched.date_finish_event
              )
            }
            validateText={
              form.errors.date_start_event || form.errors.date_finish_event
            }
            onFocus={() => setCalendarOpen(true)}
            wrapRef={calendarRef}
            autoComplete="off"
          >
            <span
              className="input-icon__btn input-icon__calendar"
              onClick={() => setCalendarOpen(true)}
            >
              <CalendarSVG />
              {isCalendarOpen && (
                <div
                  style={isMobile || isIpad || isTablet ? { right: 0 } : {}}
                  className="rangeCalendar"
                >
                  <UIRangeCalendar
                    {...(editData &&
                      editData.reservations.length && {
                        className: 'disable-blur',
                        minDate: isFirstDate && checkDisableDate(editData).max,
                        maxDate: !isFirstDate && checkDisableDate(editData).min,
                      })}
                    range={range}
                    hidePopup={() => setCalendarOpen(false)}
                    handlerSelect={handlerSelect}
                    showMonthAndYearPickers={false}
                  />
                </div>
              )}
            </span>
          </UIInput>

          <div className="checkbox addEvent-left__checkbox">
            <UICheckBox
              label={t('PROJECT.ONLY_I_HAVE_EDITING_RIGHTS')}
              name="edit"
              id="edit"
              onClick={() => form.setFieldValue('edit', !form.values.edit)}
              checked={form.values.edit}
              isValid={!(form.errors.edit && form.touched.edit)}
              validateText={form.errors.edit}
            />
          </div>

          <UIButton
            className="outline__blue"
            type={Button.SUBMIT}
            loading={loadingProject}
            handler={() => dispatch(setStep1Form(form.values))}
          >
            {t('COMMON.NEXT')}
          </UIButton>
        </form>
      </div>
    </>
  );
};

export default ProjectAddMainForm;
