import { Fragment, memo, useMemo } from 'react';

// import { MoveToSVG } from '@assets/img/icons';
import { useEndLessScroll } from '@hooks';
import { UITable } from '@ui';
import { useTranslation } from 'react-i18next';

const EquipmentsTable = ({
  data,
  // setModalTransfer,
  lastPage,
  loading,
  loadingFilter,
  setEdit,
  setPage,
  page,
}) => {
  const { t } = useTranslation();

  const header = [
    t('COMMON.TITLE'),
    t('COMMON.CATEGORY'),
    t('COMMON.WAREHOUSE'),
    // 'Статус',
    t('COMMON.QUANTITY'),
  ];

  const renderTableContent = useMemo(() => {
    return data.map((item, index) => {
      const transferObj = {};

      item.transfer?.forEach((i) => {
        transferObj[i.group_id] = [...(transferObj?.[i.group_id] || []), i];
      });

      return [
        <>
          {item.name}
          {/* <div
            onClick={() => setModalTransfer(true)}
            className="mobile-icon_equipment hide-desktop"
          >
            <MoveToSVG />
          </div> */}
        </>,
        item.category?.name,
        <Fragment key={index}>
          {item.group
            .filter((i) => +i.count)
            .map((item2, index) => {
              return (
                <div key={index}>
                  <div>{item2.name}</div>
                  <div>
                    <span>{item2.count}</span>
                    {/* {transferObj[item2.id]
                          ? transferObj?.[item2.id]?.map((item3, index) => (
                              <span key={index}>{item3.count}</span>
                            ))
                          : null} */}
                  </div>
                </div>
              );
            })}
        </Fragment>,
        // item.active ? 'Активен' : 'Неактивен',
        item.count,
      ];
    });
  }, [data]);

  const [onScroll, containerRef] = useEndLessScroll({
    onIntersection: () => {
      if (page < lastPage && renderTableContent.length) {
        setPage(+page + 1);
      }
    },
  });

  return (
    <div ref={containerRef} onScroll={onScroll} className="equipment-items">
      <UITable
        classTd={['', 'color_grey', 'equipment-items__storage']}
        header={header}
        content={renderTableContent}
        loading={loading || loadingFilter}
        onClickRows={(e, index) => setEdit(data[index])}
      />
    </div>
  );
};

export default memo(EquipmentsTable);
