import { useRef, useState } from 'react';

import { CloseSVG, EdditSVG, SaveSVG } from '@assets/img/icons';
import { useHidePopupOutside } from '@hooks';
import { useAppDispatch } from '@store/';
import { deleteMassageRequest, updateMassageRequest } from '@store/Wall';
import { UITextarea } from '@ui';
import {
  FORMAT_DATE_QUERY_WITH_TIME,
  FORMAT_TIME_WITH_YEAR,
} from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import cn from 'classnames';

const WallItem = ({ massage }) => {
  const dispatch = useAppDispatch();
  const messageRef = useRef();

  const [value, setValue] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [massageIdx, setMassageIdx] = useState();

  useHidePopupOutside(messageRef, () => setIsEdit(false));

  const updateMassage = (id) => {
    const objToRequest = {
      content: value,
      date: DateHelper.toFormat(new Date(), FORMAT_DATE_QUERY_WITH_TIME, false),
      id,
      vacation: false,
    };

    dispatch(updateMassageRequest(objToRequest));
    setValue('');
  };

  const deleteMassage = (id) => {
    dispatch(deleteMassageRequest(id));
  };

  const handlerSave = () => {
    updateMassage(massage.id);
    setIsEdit(false);
  };

  const handlerEdit = () => {
    setValue(massage.content);
    setMassageIdx(massage.id);
    setIsEdit(true);
  };

  const renderBtn = () => {
    return (
      <>
        {isEdit ? (
          <span onClick={handlerSave}>
            <SaveSVG />
          </span>
        ) : (
          <span onClick={handlerEdit}>
            <EdditSVG />
          </span>
        )}

        <span onClick={() => deleteMassage(massage.id)}>
          <CloseSVG />
        </span>
      </>
    );
  };

  return (
    <>
      <div className="wall-chat__item">
        <div className="h6 fw600 mb-15">{massage.user_id.name}</div>
        <div className="h6">
          {DateHelper.toFormat(massage.date, FORMAT_TIME_WITH_YEAR, false)}
        </div>
        <div
          className={cn('wall-chatItem__text', {
            'wall-chatItem__active': isEdit && massageIdx === massage.id,
          })}
          ref={messageRef}
        >
          {isEdit ? (
            <UITextarea
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="wall-chatItem_texarea"
            />
          ) : (
            <div className="h6 color_grey">{massage.content}</div>
          )}
          {renderBtn()}
        </div>
      </div>
    </>
  );
};

export default WallItem;
