import * as yup from 'yup';

export const validationSchema = () =>
  yup.object({
    date_start: yup.string(),
    date_finish: yup.string(),
    office: yup.string(),
    category_id: yup.string(),
    group_at: yup.object().nullable(),
    group_for: yup.object().nullable(),
  });
