import {
  ALERT_CLEAR,
  ALERT_ERROR,
  ALERT_ERROR_WRAP,
  ALERT_SUCCESS,
} from './Alert.constant';

const initialState = {
  message: '',
  type: null,
  isTitleDefault: true,
};

export default function alertReducer(state = initialState, action) {
  switch (action.type) {
    case ALERT_SUCCESS:
      return {
        type: 'success',
        message: action.payload.message,
        isTitleDefault: action.payload.isTitleDefault,
      };
    case ALERT_ERROR:
      return {
        type: 'error',
        message: action.payload.message,
      };
    case ALERT_ERROR_WRAP:
      return {
        type: 'errorWrap',
        message: action.payload.message,
      };
    case ALERT_CLEAR:
      return {
        message: '',
        type: null,
      };
    default:
      return state;
  }
}
