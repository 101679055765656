import i18n from '@i18n';
import { ProfileHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { call, fork, put, takeLatest } from 'redux-saga/effects';

import {
  getUserInfoFailure,
  getUserInfoSuccess,
  setUserInfoFailure,
  setUserInfoSuccess,
} from './Profile.action';
import {
  GET_USER_INFO_REQUEST,
  SET_USER_INFO_REQUEST,
} from './Profile.constant';

function* workerGetUserInfo({ payload }) {
  try {
    const data = yield call(ProfileHttp.getUser, payload);

    yield put(getUserInfoSuccess(data));
  } catch (error) {
    yield put(getUserInfoFailure());
    yield put(alertError());
  }
}
function* watchGetUserInfo() {
  yield takeLatest(GET_USER_INFO_REQUEST, workerGetUserInfo);
}

function* workerSetUserInfo({ payload }) {
  try {
    const data = yield call(ProfileHttp.setUser, payload);

    yield put(
      alertSuccess({
        message: i18n.t('ALERT.YOU_SUCCESSFULLY_EDITED_PROFILE'),
      }),
    );
    yield put(setUserInfoSuccess(data));
  } catch (error) {
    yield put(setUserInfoFailure());
    yield put(alertError());
  }
}
function* watchSetUserInfo() {
  yield takeLatest(SET_USER_INFO_REQUEST, workerSetUserInfo);
}

export const profileWatchers = [fork(watchGetUserInfo), fork(watchSetUserInfo)];
