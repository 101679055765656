export const LINK_LOGIN = '/login';
export const LINK_FORGOT_PASSWORD = '/forgot-password';

export const LINK_PROFILE = '/profile';
export const LINK_CALENDARS = '/calendars';
export const LINK_PROJECTS = '/projects';
export const LINK_PROJECTS_ALL = '/projects_all';
export const LINK_PROJECTS_ADD = '/project_add';
export const LINK_PROJECTS_EDIT = '/project_edit';
export const LINK_WALL = '/wall';
export const LINK_USERS = '/users';
export const LINK_EQUIPMENTS = '/equipments';
export const LINK_EVENTS = '/events';
export const LINK_CATEGORIES = '/categories';
export const LINK_REPORTS = '/reports';
export const LINK_TRANSFER = '/transfer';
