export const AddFileSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        d="M1.86458 8.49446L7.03395 3.32396C7.50866 2.84926 8.1525 2.58257 8.82383 2.58257C9.49516 2.58257 10.139 2.84926 10.6137 3.32396C11.0884 3.79867 11.3551 4.4425 11.3551 5.11384C11.3551 5.78517 11.0884 6.42901 10.6137 6.90371L4.64896 12.8696C4.57116 12.9502 4.47809 13.0145 4.37517 13.0588C4.27225 13.1031 4.16154 13.1264 4.04951 13.1274C3.93748 13.1284 3.82636 13.1072 3.72265 13.0648C3.61894 13.0224 3.5247 12.9598 3.44544 12.8806C3.36618 12.8014 3.30349 12.7072 3.26102 12.6036C3.21854 12.4999 3.19714 12.3888 3.19806 12.2768C3.19898 12.1647 3.22221 12.054 3.26638 11.9511C3.31055 11.8481 3.37478 11.755 3.45533 11.6771L9.42121 5.71009C9.57025 5.55014 9.65138 5.33859 9.64753 5.12C9.64367 4.90141 9.55512 4.69285 9.40053 4.53826C9.24594 4.38367 9.03738 4.29512 8.81879 4.29127C8.6002 4.28741 8.38865 4.36855 8.2287 4.51759L2.26171 10.4835C1.787 10.9582 1.52031 11.602 1.52031 12.2733C1.52031 12.9447 1.787 13.5885 2.26171 14.0632C2.73641 14.5379 3.38025 14.8046 4.05158 14.8046C4.72291 14.8046 5.36675 14.5379 5.84145 14.0632L11.8085 8.09734C12.5997 7.30606 13.0443 6.23287 13.0443 5.11384C13.0443 3.99481 12.5997 2.92161 11.8085 2.13034C11.0172 1.33906 9.94398 0.894531 8.82495 0.894531C7.70592 0.894531 6.63273 1.33906 5.84145 2.13034L0.670955 7.30084C0.590333 7.37863 0.526011 7.47171 0.481743 7.57463C0.437475 7.67755 0.414147 7.78825 0.413121 7.90028C0.412095 8.01231 0.433392 8.12343 0.475767 8.22714C0.518143 8.33085 0.580749 8.42509 0.659933 8.50435C0.739118 8.58361 0.833294 8.6463 0.936967 8.68878C1.04064 8.73125 1.15173 8.75265 1.26377 8.75173C1.3758 8.75081 1.48653 8.72759 1.58949 8.68342C1.69245 8.63925 1.78558 8.57501 1.86346 8.49446H1.86458Z"
        fill="#3A36DB"
      />
    </svg>
  );
};
