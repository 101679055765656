import { Button } from '@utils/utility-property';

import UIButton from '../Button';

const UIBack = ({ children, title, className, link }) => {
  return (
    <UIButton type={Button.LINK} link={link} className={className}>
      {children}
      <span>{title}</span>
    </UIButton>
  );
};

export default UIBack;
