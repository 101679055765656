export const BasketSVG = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 2.16H9.5V0.96C9.5 0.4305 9.05156 0 8.5 0H3.5C2.94844 0 2.5 0.4305 2.5 0.96V2.16H0.5C0.223437 2.16 0 2.3745 0 2.64V3.12C0 3.186 0.05625 3.24 0.125 3.24H1.06875L1.45469 11.085C1.47969 11.5965 1.92031 12 2.45312 12H9.54688C10.0813 12 10.5203 11.598 10.5453 11.085L10.9312 3.24H11.875C11.9438 3.24 12 3.186 12 3.12V2.64C12 2.3745 11.7766 2.16 11.5 2.16ZM8.375 2.16H3.625V1.08H8.375V2.16Z"
        fill="#FF4D4F"
      />
    </svg>
  );
};
