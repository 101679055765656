export const GET_USERS_REQUEST = '@users/GET_USERS_REQUEST';
export const GET_USERS_FAILURE = '@users/GET_USERS_FAILURE';
export const GET_USERS_SUCCESS = '@users/GET_USERS_SUCCESS';

export const CREATE_USERS_REQUEST = '@users/CREATE_USERS_REQUEST';
export const CREATE_USERS_FAILURE = '@users/CREATE_USERS_FAILURE';
export const CREATE_USERS_SUCCESS = '@users/CREATE_USERS_SUCCESS';

export const SET_USERS_REQUEST = '@users/SET_USERS_REQUEST';
export const SET_USERS_FAILURE = '@users/SET_USERS_FAILURE';
export const SET_USERS_SUCCESS = '@users/SET_USERS_SUCCESS';

export const GET_OFFICES_REQUEST = '@users/GET_OFFICES_REQUEST';
export const GET_OFFICES_FAILURE = '@users/GET_OFFICES_FAILURE';
export const GET_OFFICES_SUCCESS = '@users/GET_OFFICES_SUCCESS';

export const CREATE_OFFICES_REQUEST = '@users/CREATE_OFFICES_REQUEST';
export const CREATE_OFFICES_FAILURE = '@users/CREATE_OFFICES_FAILURE';
export const CREATE_OFFICES_SUCCESS = '@users/CREATE_OFFICES_SUCCESS';

export const SET_OFFICES_REQUEST = '@users/SET_OFFICES_REQUEST';
export const SET_OFFICES_FAILURE = '@users/SET_OFFICES_FAILURE';
export const SET_OFFICES_SUCCESS = '@users/SET_OFFICES_SUCCESS';

export const CLEAR_REFRESH = '@users/CLEAR_REFRESH';
export const CLEAR_ALL_USERS = '@users/CLEAR_ALL_USERS';
