import { Fragment, useCallback, useMemo } from 'react';

import { useAppDispatch } from '@store/';
import { alertError } from '@store/Alert';
import { setStep3Fom } from '@store/ProjectAdd';
import { UIButton, UILoaderWrap } from '@ui';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const ProjectAddEquipmentTable = ({
  icon,
  loadingEquipment,
  step3Form,
  isSelected,
  setTableData,
  tableData,
  bookIdx,
  disabled,
  disabledText,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onSelect = useCallback(
    (item) => {
      setTableData(
        tableData.map((i) => {
          if (i.id === item.id) {
            return { ...i, reserved: +i.reserved - (+i.reserved ? 1 : 0) };
          }

          return i;
        }),
      );

      const newArray = [...(step3Form || [])];

      if (
        !step3Form?.[bookIdx]?.data?.find(
          (i) =>
            `${i.id}_${i?.group_id || i.group[0].id}` ===
            `${item.id}_${item?.group_id || item.group[0].id}`,
        )
      ) {
        newArray[bookIdx] = {
          data: [
            ...step3Form?.[bookIdx]?.data,
            ...tableData
              .map((i) => {
                if (i.id === item.id) {
                  return { ...i, reserved: 1 };
                }

                return null;
              })
              .filter((i) => i),
          ],
        };
      } else {
        newArray[bookIdx] = {
          data: [
            ...step3Form?.[bookIdx]?.data
              .map((i) => {
                if (
                  `${i.id}_${i?.group_id || i.group[0].id}` ===
                  `${item.id}_${item?.group_id || item.group[0].id}`
                ) {
                  return {
                    ...i,
                    reserved: +i.reserved + (+item.reserved ? 1 : 0),
                  };
                }

                return i;
              })
              .filter((i) => i),
          ],
        };
      }
      dispatch(setStep3Fom(newArray));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bookIdx, dispatch, setTableData, tableData],
  );

  const onDelete = useCallback(
    (item) => {
      setTableData(
        tableData?.map((i) => {
          if (i.id === item.id) {
            return { ...i, reserved: +i.reserved + 1 };
          }

          return i;
        }),
      );

      const newArray = [...step3Form];

      newArray[bookIdx] = {
        ...step3Form[bookIdx],
        data: step3Form?.[bookIdx]?.data
          ?.map((i) => {
            if (
              i.id === item.id &&
              (i?.group_id || i.group[0].id) ===
                (item?.group_id || item.group[0].id)
            ) {
              if (+i.reserved <= 1) {
                return null;
              }

              return { ...i, reserved: +i.reserved - 1 };
            }

            return i;
          })
          .filter((i) => i),
      };

      dispatch(setStep3Fom(newArray));
    },
    [step3Form, bookIdx, dispatch, setTableData, tableData],
  );

  const renderTable = useMemo(
    () =>
      (isSelected ? step3Form?.[bookIdx]?.data : tableData)?.map(
        (item, index) => (
          <Fragment key={index}>
            <tr>
              <td>{item?.name}</td>
              <td className="hide-mobile">{item?.category?.name}</td>
              <td className="hide-mobile">
                {item?.group?.find((item2) => +item2.id === +item?.group_id)
                  ?.name || item?.group?.[0]?.name}
              </td>
              <td>{+item.reserved}</td>
              <td>
                <UIButton
                  defaultClassName=""
                  handler={() => {
                    if (disabled) {
                      dispatch(alertError(disabledText));
                    } else if (isSelected) {
                      onDelete(item);
                    } else {
                      onSelect(item, index);
                    }
                  }}
                  className={cn('addEvent-table__btn', {
                    'table-btn__opacity': +item.reserved === 0,
                  })}
                >
                  {icon}
                </UIButton>
              </td>
            </tr>
            <tr>
              <td
                colSpan="5"
                className="addEvent-table__unification hide-desktop show-table-mobile"
              >
                <div>
                  <span className="addEvent-table__category">
                    <span>{t('COMMON.CATEGORY')} </span> -{' '}
                    {item?.category?.name}
                  </span>

                  <span className="addEvent-table__category">
                    <span>{t('COMMON.WAREHOUSE')}</span> -{' '}
                    {item?.group.find((item2) => +item2?.id === +item?.group_id)
                      ?.name || item?.group?.[0]?.name}
                  </span>
                </div>
              </td>
            </tr>
          </Fragment>
        ),
      ),
    [
      isSelected,
      step3Form,
      bookIdx,
      tableData,
      icon,
      t,
      disabled,
      dispatch,
      disabledText,
      onDelete,
      onSelect,
    ],
  );

  return (
    <UILoaderWrap loading={loadingEquipment}>
      <div className="addEvent-table__wrap">
        <table className="custom-table">
          <tbody>
            <tr>
              <th>{t('COMMON.TITLE')}</th>
              <th className="hide-mobile">{t('COMMON.CATEGORY')}</th>
              <th className="hide-mobile">{t('COMMON.WAREHOUSE')}</th>
              <th>
                <span className="hide-mobile">{t('COMMON.QUANTITY')}</span>
                <span className="hide-desktop show-mobile">
                  {t('COMMON.QUANTITY_MIN')}
                </span>
              </th>
              <th></th>
            </tr>
            {renderTable}
          </tbody>
        </table>
      </div>
    </UILoaderWrap>
  );
};

export default ProjectAddEquipmentTable;
