import { useEffect } from 'react';

import { PlusSVG } from '@assets/img/icons';
import { useAppDispatch } from '@store/';
import {
  addCategoriesRequest,
  // deleteCategoriesRequest,
  setCategoriesRequest,
} from '@store/Categories';
import { UIButton, UIInput } from '@ui';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { INITIAL_VALUES } from './constant';
import { validationSchema } from './schema';

const CategoriesFormCategories = ({ edit, resetEdit, resetCol, loading }) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const handlerSubmit = (values, { resetForm }) => {
    if (edit) {
      dispatch(
        setCategoriesRequest({
          ...values,
          active: edit.active,
          id: edit.id,
        }),
      );
    } else {
      dispatch(addCategoriesRequest({ ...values, active: true }));
      resetForm();
    }
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  const handlerClear = () => {
    form.resetForm();
    resetEdit();
    resetCol();
  };

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  useEffect(() => {
    if (
      edit &&
      edit.name !== form.values.name
      // && edit.order_by !== form.values.order_by
    ) {
      form.setFieldValue('name', edit.name);
      // form.setFieldValue('order_by', edit.order_by);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  // const handlerDelete = () => {
  //   dispatch(deleteCategoriesRequest(edit.id));
  //   form.resetForm();
  //   resetEdit();
  //   resetCol();
  // };

  return (
    <form onSubmit={form.handleSubmit} className="categories-new">
      <div className="categories-new__title h3 fw600">
        {edit ? t('CATEGORIES.CATEGORY_EDIT') : t('CATEGORIES.CATEGORY_ADD')}
      </div>
      <div className="categories-new__items">
        <UIButton
          defaultClassName="categories__clear color_grey h6 fw600"
          type={Button.BUTTON}
          handler={handlerClear}
        >
          {t('COMMON.CLEAR')}
        </UIButton>
        <UIInput
          name="name"
          id="name"
          label={t('COMMON.TITLE')}
          classLabel="fw600"
          className="input input__row"
          placeholder={t('COMMON.TITLE')}
          value={form.values.name}
          onChange={form.handleChange}
          isValid={!(form.errors.name && form.touched.name)}
          validateText={form.errors.name}
        />
        {/* <UIInput
          name="order_by"
          id="order_by"
          label="Номер"
          classLabel="fw600"
          className="input input__row"
          placeholder="Номер"
          value={form.values.order_by}
          onChange={form.handleChange}
          isValid={!(form.errors.order_by && form.touched.order_by)}
          validateText={form.errors.order_by}
        /> */}
        <div className="categories-new__btn">
          <UIButton
            loading={loading}
            className="btn outline__blue"
            type={Button.SUBMIT}
          >
            {edit ? (
              <>{t('COMMON.UPDATE')}</>
            ) : (
              <>
                <PlusSVG />
                <span>{t('COMMON.ADD')}</span>
              </>
            )}
          </UIButton>
          {/* {edit ? (
            <UIButton
              loading={loading}
              className="btn btn__red"
              handler={handlerDelete}
              type={Button.BUTTON}
            >
              Удалить
            </UIButton>
          ) : null} */}
        </div>
      </div>
    </form>
  );
};

export default CategoriesFormCategories;
