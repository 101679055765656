import calendar from './calendar.json';
import categories from './categories.json';
import equipments from './equipments.json';
import login from './login.json';
import project from './project.json';
import report from './report.json';
import transfer from './transfer.json';
import users from './users.json';
import wall from './wall.json';

export default {
  ...login,
  ...calendar,
  ...categories,
  ...equipments,
  ...project,
  ...report,
  ...transfer,
  ...users,
  ...wall,
};
