import {
  SEARCH_EVENTS_FAILURE,
  SEARCH_EVENTS_REQUEST,
  SEARCH_EVENTS_SUCCESS,
} from './Events.constant';

const initialState = {
  loading: false,
  events: [],
};

export default function EventsReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_EVENTS_REQUEST:
      return {
        ...state,
        events: [],
        loading: true,
      };
    case SEARCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
        loading: false,
      };
    case SEARCH_EVENTS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
