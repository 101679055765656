export const MoveToSVG = () => {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 13.125C12.5 13.2908 12.4342 13.4497 12.3169 13.5669C12.1997 13.6842 12.0408 13.75 11.875 13.75H1.875C1.70924 13.75 1.55027 13.6842 1.43306 13.5669C1.31585 13.4497 1.25 13.2908 1.25 13.125V1.875C1.25 1.70924 1.31585 1.55027 1.43306 1.43306C1.55027 1.31585 1.70924 1.25 1.875 1.25H11.875C12.0408 1.25 12.1997 1.31585 12.3169 1.43306C12.4342 1.55027 12.5 1.70924 12.5 1.875V4.375C12.5 4.54076 12.5658 4.69973 12.6831 4.81694C12.8003 4.93415 12.9592 5 13.125 5C13.2908 5 13.4497 4.93415 13.5669 4.81694C13.6842 4.69973 13.75 4.54076 13.75 4.375V1.875C13.75 1.37772 13.5525 0.900806 13.2008 0.549175C12.8492 0.197544 12.3723 0 11.875 0H1.875C1.37772 0 0.900805 0.197544 0.549175 0.549175C0.197544 0.900806 0 1.37772 0 1.875L0 13.125C0 13.6223 0.197544 14.0992 0.549175 14.4508C0.900805 14.8025 1.37772 15 1.875 15H11.875C12.3723 15 12.8492 14.8025 13.2008 14.4508C13.5525 14.0992 13.75 13.6223 13.75 13.125V10.625C13.75 10.4592 13.6842 10.3003 13.5669 10.1831C13.4497 10.0658 13.2908 10 13.125 10C12.9592 10 12.8003 10.0658 12.6831 10.1831C12.5658 10.3003 12.5 10.4592 12.5 10.625V13.125Z"
        fill="#809FB8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8175 7.94231C19.8757 7.88426 19.9219 7.81529 19.9534 7.73935C19.9849 7.66342 20.0011 7.58202 20.0011 7.49981C20.0011 7.4176 19.9849 7.3362 19.9534 7.26027C19.9219 7.18434 19.8757 7.11537 19.8175 7.05731L16.0675 3.30731C15.9501 3.18995 15.791 3.12402 15.625 3.12402C15.459 3.12402 15.2999 3.18995 15.1825 3.30731C15.0651 3.42467 14.9992 3.58384 14.9992 3.74981C14.9992 3.91578 15.0651 4.07496 15.1825 4.19231L17.8663 6.87481H6.875C6.70924 6.87481 6.55027 6.94066 6.43306 7.05787C6.31585 7.17508 6.25 7.33405 6.25 7.49981C6.25 7.66557 6.31585 7.82455 6.43306 7.94176C6.55027 8.05897 6.70924 8.12481 6.875 8.12481H17.8663L15.1825 10.8073C15.0651 10.9247 14.9992 11.0838 14.9992 11.2498C14.9992 11.4158 15.0651 11.575 15.1825 11.6923C15.2999 11.8097 15.459 11.8756 15.625 11.8756C15.791 11.8756 15.9501 11.8097 16.0675 11.6923L19.8175 7.94231Z"
        fill="#809FB8"
      />
    </svg>
  );
};
