import i18n from '@i18n';
import * as yup from 'yup';

export const validationSchema = () =>
  yup.object({
    email: yup.string().email(i18n.t('VALIDATION.EMAIL.VALID')),
    password: yup.string().min(6, i18n.t('VALIDATION.PASSWORD.MIN')),
    first_name: yup.string().required(i18n.t('VALIDATION.NAME.REQUIRED')),
    role: yup.object().nullable().required(i18n.t('VALIDATION.REQUIRED')),
    phone: yup
      .string()
      .matches(/^(\+|)(\d[0-9]{7,16})$/, i18n.t('VALIDATION.PHONE.FORMAT')),
  });
