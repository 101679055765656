import { LocalStorage } from '@services/storage';
import { FORMAT_DATE_QUERY } from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { typeCarouselCalendar } from '@utils/utility-property';
import { DateRangePicker, Calendar } from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';

import './style.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const UIRangeCalendar = ({
  handlerSelect,
  range,
  showMonthAndYearPickers = true,
  minDate,
  maxDate,
  hidePopup,
  type = typeCarouselCalendar.RANGE,
  handlerDateClick,
  className,
  disabledDates,
  ...props
}) => {
  const tz = new Date().getTimezoneOffset();
  const handlerSelectWrap = (e) => {
    handlerSelect(e);

    if (handlerDateClick) {
      handlerDateClick(e);
    }

    if (
      hidePopup &&
      DateHelper.toFormat(e.selection.endDate, FORMAT_DATE_QUERY) !==
        DateHelper.toFormat(e.selection.startDate, FORMAT_DATE_QUERY)
    ) {
      hidePopup();
    }
  };

  const getLocate = () => {
    switch (LocalStorage.get('language')) {
      case 'ru':
        return rdrLocales.ru;

      case 'en':
        return rdrLocales.enGB;

      case 'de':
        return rdrLocales.de;

      default:
        return rdrLocales.enGB;
    }
  };

  return type === typeCarouselCalendar.RANGE ? (
    <DateRangePicker
      oneTap
      className={className}
      showOneCalendar
      rangeColors={['#e5dfff']}
      months={1}
      {...(range && {
        ranges: [
          {
            ...range,
            endDate: DateHelper.timezoneChange(range.endDate, tz > 0),
            startDate: DateHelper.timezoneChange(range.startDate, tz > 0),
          },
        ],
      })}
      onChange={({ ...e }) => {
        handlerSelectWrap({
          ...e,
          selection: {
            ...e?.selection,
            startDate:
              tz < 0
                ? e?.selection.startDate
                : new Date(
                    DateHelper.roundingDayToUnix(e?.selection?.startDate, true),
                  ),
            endDate:
              tz < 0
                ? e?.selection.endDate
                : new Date(
                    DateHelper.roundingDayToUnix(e?.selection?.endDate, true),
                  ),
          },
        });
      }}
      staticRanges={[]}
      inputRanges={[]}
      {...(minDate && {
        minDate: DateHelper.timezoneChange(minDate, tz > 0),
      })}
      {...(maxDate && {
        maxDate: DateHelper.timezoneChange(maxDate, tz > 0),
      })}
      showDateDisplay={false}
      moveRangeOnFirstSelection={false}
      showMonthAndYearPickers={showMonthAndYearPickers}
      disabledDates={disabledDates}
      weekStartsOn={1}
      locale={getLocate()}
      weekdayDisplayFormat="EEEEEE"
      {...props}
    />
  ) : (
    <Calendar
      oneTap
      showOneCalendar
      rangeColors={['#e5dfff']}
      months={1}
      {...(range && {
        date: DateHelper.timezoneChange(range, tz > 0),
      })}
      onChange={handlerSelectWrap}
      staticRanges={[]}
      inputRanges={[]}
      {...(minDate && {
        minDate: DateHelper.timezoneChange(minDate, tz > 0),
      })}
      {...(maxDate && {
        maxDate: DateHelper.timezoneChange(maxDate, tz > 0),
      })}
      showDateDisplay={false}
      moveRangeOnFirstSelection={false}
      showMonthAndYearPickers={showMonthAndYearPickers}
      weekStartsOn={1}
      locale={getLocate()}
      weekdayDisplayFormat="EEEEEE"
      selectRange={true}
      {...props}
    />
  );
};

export default UIRangeCalendar;
