import React from 'react';

const UICheckBox = ({
  id,
  name,
  value,
  checked,
  readOnly = true,
  className = 'checkbox',
  onChange,
  label,
  classLabel,
  onClick,
}) => {
  const handlerChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className={className}>
      <input
        name={name}
        readOnly={readOnly}
        checked={checked}
        value={value}
        id={id}
        type="checkbox"
        onClick={onClick}
        onChange={handlerChange}
      />
      <label className={classLabel} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default UICheckBox;
