import { useCallback, useEffect } from 'react';

import { alertClear } from '@store/Alert';
import { useAppDispatch } from '@store/index';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import style from './style.module.scss';

const WidgetAlert = () => {
  const { message, type, isTitleDefault } = useSelector((state) => state.alert);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handlerClose = useCallback(() => {
    dispatch(alertClear());
  }, [dispatch]);

  useEffect(() => {
    if (type) {
      setTimeout(() => handlerClose(), 6000);
    }
  }, [type, handlerClose]);

  const massageObj = (obj) => {
    if (typeof message === 'object') {
      return Object.keys(obj).map((item, index) => (
        <div key={index}>{obj[item][0]}</div>
      ));
    }

    return message || t('ALERT.SOMETHING_WENT_WRONG');
  };

  const title = () => {
    switch (type) {
      case 'error':
        return t('ALERT.MISTAKE');
      case 'success':
        return isTitleDefault ? t('ALERT.THANKS') : '';
      case 'errorWrap':
      default:
        return '';
    }
  };

  return type ? (
    <div className={cn(style.container)}>
      {type === 'errorWrap' || type === 'error' ? (
        <svg
          width="53"
          height="53"
          viewBox="0 0 53 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="26.5"
            cy="26.5"
            r="25.5"
            stroke="#E92C2C"
            strokeWidth="2"
          />
          <path
            d="M28.4387 22.2703C28.4387 21.4678 27.7677 20.8154 26.9398 20.8154C26.1097 20.8154 25.4387 21.4657 25.4387 22.2682V38.0239C25.4534 38.4 25.6179 38.7559 25.8976 39.0169C26.1773 39.2779 26.5504 39.4238 26.9387 39.4238C27.327 39.4238 27.7002 39.2779 27.9799 39.0169C28.2596 38.7559 28.424 38.4 28.4387 38.0239L28.4385 30.1469L28.4387 22.2703Z"
            fill="#E92C2C"
          />
          <path
            d="M28.4142 15.5678C28.0391 15.2042 27.5304 15 27 15C26.4696 15 25.9609 15.2042 25.5858 15.5678C25.2107 15.9313 25 16.4243 25 16.9385C25 17.4526 25.2107 17.9456 25.5858 18.3092C25.9609 18.6727 26.4696 18.8769 27 18.8769C27.5304 18.8769 28.0391 18.6727 28.4142 18.3092C28.7893 17.9456 29 17.4526 29 16.9385C29 16.4243 28.7893 15.9313 28.4142 15.5678Z"
            fill="#E92C2C"
          />
          <path
            d="M28.4387 22.2703C28.4387 21.4678 27.7677 20.8154 26.9398 20.8154C26.1097 20.8154 25.4387 21.4657 25.4387 22.2682V38.0239C25.4534 38.4 25.6179 38.7559 25.8976 39.0169C26.1773 39.2779 26.5504 39.4238 26.9387 39.4238C27.327 39.4238 27.7002 39.2779 27.9799 39.0169C28.2596 38.7559 28.424 38.4 28.4387 38.0239L28.4385 30.1469L28.4387 22.2703Z"
            fill="#E92C2C"
          />
          <path
            d="M28.4142 15.5678C28.0391 15.2042 27.5304 15 27 15C26.4696 15 25.9609 15.2042 25.5858 15.5678C25.2107 15.9313 25 16.4243 25 16.9385C25 17.4526 25.2107 17.9456 25.5858 18.3092C25.9609 18.6727 26.4696 18.8769 27 18.8769C27.5304 18.8769 28.0391 18.6727 28.4142 18.3092C28.7893 17.9456 29 17.4526 29 16.9385C29 16.4243 28.7893 15.9313 28.4142 15.5678Z"
            fill="#E92C2C"
          />
        </svg>
      ) : (
        <svg
          width="53"
          height="53"
          viewBox="0 0 53 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="26.5"
            cy="26.5"
            r="25.5"
            stroke="#00BA34"
            strokeWidth="2"
          />
          <path
            d="M35.7979 21L25.6055 31.1923L19.9998 25.5865"
            stroke="#00BA34"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      )}

      <div className={cn(style['alert-wrap'])}>
        <div className="h3 fw600">{title()}</div>
        <div className="h6 color_grey">
          {type === 'error' ? massageObj(message) : message}
        </div>
      </div>
      <div className={style.alert_close} onClick={handlerClose}></div>
    </div>
  ) : null;
};

export default WidgetAlert;
