export const ArrRightSVG = () => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.63037 7.00101L0.439331 1.772C0.254423 1.58596 0.150635 1.33433 0.150635 1.07202C0.150635 0.809717 0.254423 0.558055 0.439331 0.372009C0.530082 0.280367 0.638107 0.207606 0.757141 0.157959C0.876175 0.108312 1.0039 0.0827637 1.13287 0.0827637C1.26185 0.0827637 1.38951 0.108312 1.50854 0.157959C1.62758 0.207606 1.7356 0.280367 1.82635 0.372009L7.71136 6.30002C7.89627 6.48606 8.00006 6.7377 8.00006 7C8.00006 7.2623 7.89627 7.51397 7.71136 7.70001L1.82635 13.628C1.7356 13.7197 1.62758 13.7924 1.50854 13.842C1.38951 13.8917 1.26185 13.9173 1.13287 13.9173C1.0039 13.9173 0.876175 13.8917 0.757141 13.842C0.638107 13.7924 0.530082 13.7197 0.439331 13.628C0.254423 13.442 0.150635 13.1903 0.150635 12.928C0.150635 12.6657 0.254423 12.414 0.439331 12.228L5.63037 7.00101Z"
      fill="#809FB8"
    />
  </svg>
);
