import i18n from '@i18n';
import { UploadHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { put, takeLatest, fork, call } from 'redux-saga/effects';

import { uploadFailure, uploadSuccess } from './Upload.action';
import { UPLOAD_REQUEST } from './Upload.constant';

function* workerUpload({ payload }) {
  try {
    const data = yield call(UploadHttp.upload, payload);

    yield put(uploadSuccess(data));
    yield put(
      alertSuccess({ message: i18n.t('ALERT.YOU_SUCCESSFULLY_ADDED_FILE') }),
    );
  } catch (error) {
    yield put(uploadFailure());
    yield put(alertError());
  }
}
function* watchUpload() {
  yield takeLatest(UPLOAD_REQUEST, workerUpload);
}

export const uploadWatchers = [fork(watchUpload)];
