import { useEffect, useRef, useState } from 'react';

import { CalendarSVG } from '@assets/img/icons';
import { useHidePopupOutside } from '@hooks';
import { LayoutModal } from '@layouts';
import { useAppDispatch } from '@store/';
import { transferEquipmentsRequest } from '@store/Equipments';
import { hideModal } from '@store/Modal';
import { UIButton, UICalendar, UIInput, UIInputNumber, UISelect } from '@ui';
import {
  FORMAT_DATE_QUERY,
  FORMAT_DATE_QUERY_WITH_TIME,
} from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { Button } from '@utils/utility-property';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { INITIAL_VALUES } from '../../../modules/Equipments/TransferEquipments/constant';
import { validationSchema } from '../../../modules/Equipments/TransferEquipments/schema';

const TransferEquipments = ({ edit, resetEdit, loadingChange }) => {
  const { loading, categories, groups } = useSelector(
    (state) => state.categories,
  );
  const [isGroupStartOpen, setGroupStartOpen] = useState(false);
  const [isGroupFinishOpen, setGroupFinishOpen] = useState(false);

  const groupStartRef = useRef(null);
  const groupFinishRef = useRef(null);
  const { t, i18n } = useTranslation();

  useHidePopupOutside(groupStartRef, () => setGroupStartOpen(false));
  useHidePopupOutside(groupFinishRef, () => setGroupFinishOpen(false));

  const dispatch = useAppDispatch();

  const handlerSubmit = (value) => {
    if (edit) {
      dispatch(
        transferEquipmentsRequest({
          date_start: DateHelper.toFormat(
            value.date_start,
            FORMAT_DATE_QUERY_WITH_TIME,
          ),
          date_finish: DateHelper.toFormat(
            value.date_finish,
            FORMAT_DATE_QUERY_WITH_TIME,
          ),
          groupAt: value.groupAt.id,
          groupFor: value.groupFor.id,
          equipment_id: edit.id,
          count: edit.count,
        }),
      );
    }
    resetEdit();
    dispatch(hideModal());
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...INITIAL_VALUES,
      category: edit?.category,
      name: edit?.name,
    },
    validationSchema: validationSchema(),
    onSubmit: handlerSubmit,
  });

  const handleDateClick = (date, name) => {
    form.setFieldValue(
      name,
      DateHelper.toFormat(date, FORMAT_DATE_QUERY, false),
    );
  };

  useEffect(() => {
    validationSchema();
  }, [i18n.language]);

  return (
    <LayoutModal
      title={t('TRANSFER.TRANSFER_TO_ANOTHER_WAREHOUSE')}
      className="modal_transfer_equipment modal__equipment"
    >
      <UIButton
        defaultClassName="modal_transfer__clear"
        type={Button.BUTTON}
        handler={() => {
          resetEdit();
          dispatch(hideModal());
        }}
      >
        {t('COMMON.CLEAR')}
      </UIButton>
      <form
        className="modal_added_equipment__items"
        onSubmit={form.handleSubmit}
      >
        <UIInput
          name="name"
          islabelRow
          disabled={true}
          id="name_modal2"
          label={t('COMMON.TITLE')}
          classLabel="fw600 icon_necessarily"
          placeholder={t('COMMON.TITLE')}
          value={form.values?.name}
          onChange={form.handleChange}
          isValid={!(form.errors.name && form.touched.name)}
          validateText={form.errors.name}
        />
        <UISelect
          name="category"
          disabled={true}
          loading={loading || loadingChange}
          className="custom-select-row"
          label={t('COMMON.CATEGORY')}
          classLabel="fw600 icon_necessarily"
          options={categories}
          placeholder={t('COMMON.CATEGORY')}
          value={form.values?.category}
          onChange={(value) => form.setFieldValue('category', value)}
          isValid={!(form.errors.category && form.touched.category)}
          validateText={form.errors.category}
        />

        <UISelect
          name="groupAt"
          loading={loading}
          className="select-wrap custom-select-row"
          label={t('COMMON.FROM_WAREHOUSE')}
          classLabel="h6 fw600"
          options={groups}
          placeholder={t('COMMON.WAREHOUSE')}
          value={form.values?.groupAt}
          onChange={(value) => form.setFieldValue('groupAt', value)}
          isValid={!(form.errors.groupAt && form.touched.groupAt)}
          validateText={form.errors.groupAt}
        />
        <UISelect
          name="groupFor"
          loading={loading}
          className="select-wrap custom-select-row"
          label={t('COMMON.TO_WAREHOUSE')}
          classLabel="h6 fw600"
          options={groups}
          placeholder={t('COMMON.WAREHOUSE')}
          value={form.values?.groupFor}
          onChange={(value) => form.setFieldValue('groupFor', value)}
          isValid={!(form.errors.groupFor && form.touched.groupFor)}
          validateText={form.errors.groupFor}
        />
        <UIInput
          label={t('COMMON.DATE_START')}
          name="date_start"
          placeholder={t('COMMON.DATE_START')}
          className="input input__row input__icon"
          value={form.values.date_start}
          islabelRow
          isValid={!(form.errors.date_start && form.touched.date_start)}
          validateText={form.errors.date_start}
          onFocus={() => setGroupStartOpen(true)}
          wrapRef={groupStartRef}
          autoComplete="off"
        >
          <span
            className="input-icon__btn input-icon__calendar"
            onMouseDown={() => setGroupStartOpen(true)}
          >
            <CalendarSVG />
            {isGroupStartOpen && (
              <div className="rangeCalendar">
                <UICalendar
                  hidePopup={() => setGroupStartOpen(false)}
                  selectedDate={form.values.date_start}
                  handleDateClick={(date) =>
                    handleDateClick(date, 'date_start')
                  }
                />
              </div>
            )}
          </span>
        </UIInput>
        <UIInput
          label={t('COMMON.DATE_END')}
          name="date_finish"
          placeholder={t('COMMON.DATE_END')}
          className="input input__row input__icon"
          value={form.values.date_finish}
          islabelRow
          isValid={!(form.errors.date_finish && form.touched.date_finish)}
          validateText={form.errors.date_finish}
          onFocus={() => setGroupFinishOpen(true)}
          wrapRef={groupFinishRef}
          autoComplete="off"
        >
          <span
            className="input-icon__btn input-icon__calendar"
            onMouseDown={() => setGroupFinishOpen(true)}
          >
            <CalendarSVG />
            {isGroupFinishOpen && (
              <div className="rangeCalendar">
                <UICalendar
                  hidePopup={() => setGroupFinishOpen(false)}
                  selectedDate={form.values.date_finish}
                  handleDateClick={(date) =>
                    handleDateClick(date, 'date_finish')
                  }
                />
              </div>
            )}
          </span>
        </UIInput>
        <UIInputNumber
          min={0}
          name="isTransfered"
          label={t('COMMON.QUANTITY_MIN')}
          classLabel="h6 fw600"
          value={form.values?.isTransfered}
          onChange={(value) => form.setFieldValue('isTransfered', value)}
          isValid={!(form.errors.isTransfered && form.touched.isTransfered)}
          validateText={form.errors.isTransfered}
        />
        <div className="equipment-new__btn transfer__btn">
          <UIButton
            loading={loading}
            className="btn outline__blue"
            type={Button.SUBMIT}
          >
            {t('EQUIPMENTS.MOVE')}
          </UIButton>
        </div>
      </form>
    </LayoutModal>
  );
};

export default TransferEquipments;
