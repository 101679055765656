import { colors } from '@utils/utility-property';

export const INITIAL_VALUES = {
  first_name: '',
  last_name: '',
  password: '',
  email: '',
  phone: '',
  role: null,
  color: colors.green,
};
