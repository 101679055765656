import {
  CLEAR_ALL_USERS,
  CLEAR_REFRESH,
  CREATE_OFFICES_FAILURE,
  CREATE_OFFICES_REQUEST,
  CREATE_OFFICES_SUCCESS,
  CREATE_USERS_FAILURE,
  CREATE_USERS_REQUEST,
  CREATE_USERS_SUCCESS,
  GET_OFFICES_FAILURE,
  GET_OFFICES_REQUEST,
  GET_OFFICES_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  SET_OFFICES_FAILURE,
  SET_OFFICES_REQUEST,
  SET_OFFICES_SUCCESS,
  SET_USERS_FAILURE,
  SET_USERS_REQUEST,
  SET_USERS_SUCCESS,
} from './Users.constant';

export function clearRefresh(payload) {
  return {
    type: CLEAR_REFRESH,
    payload,
  };
}

export function clearAllUsers() {
  return {
    type: CLEAR_ALL_USERS,
  };
}

export function getUsersRequest(payload) {
  return {
    type: GET_USERS_REQUEST,
    payload,
  };
}
export function getUsersFailure(payload) {
  return {
    type: GET_USERS_FAILURE,
    payload,
  };
}
export function getUsersSuccess(payload) {
  return {
    type: GET_USERS_SUCCESS,
    payload,
  };
}

export function createUsersRequest(payload) {
  return {
    type: CREATE_USERS_REQUEST,
    payload,
  };
}
export function createUsersFailure(payload) {
  return {
    type: CREATE_USERS_FAILURE,
    payload,
  };
}
export function createUsersSuccess(payload) {
  return {
    type: CREATE_USERS_SUCCESS,
    payload,
  };
}

export function setUsersRequest(payload) {
  return {
    type: SET_USERS_REQUEST,
    payload,
  };
}
export function setUsersFailure(payload) {
  return {
    type: SET_USERS_FAILURE,
    payload,
  };
}
export function setUsersSuccess(payload) {
  return {
    type: SET_USERS_SUCCESS,
    payload,
  };
}

export function getOfficesRequest(payload) {
  return {
    type: GET_OFFICES_REQUEST,
    payload,
  };
}
export function getOfficesFailure(payload) {
  return {
    type: GET_OFFICES_FAILURE,
    payload,
  };
}
export function getOfficesSuccess(payload) {
  return {
    type: GET_OFFICES_SUCCESS,
    payload,
  };
}

export function createOfficesRequest(payload) {
  return {
    type: CREATE_OFFICES_REQUEST,
    payload,
  };
}
export function createOfficesFailure(payload) {
  return {
    type: CREATE_OFFICES_FAILURE,
    payload,
  };
}
export function createOfficesSuccess(payload) {
  return {
    type: CREATE_OFFICES_SUCCESS,
    payload,
  };
}

export function setOfficesRequest(payload) {
  return {
    type: SET_OFFICES_REQUEST,
    payload,
  };
}
export function setOfficesFailure(payload) {
  return {
    type: SET_OFFICES_FAILURE,
    payload,
  };
}
export function setOfficesSuccess(payload) {
  return {
    type: SET_OFFICES_SUCCESS,
    payload,
  };
}
