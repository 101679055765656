export const MoveBackSVG = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.9168 9.5H5.5835" stroke="#809FB8" strokeLinecap="round" />
      <rect
        x="1"
        y="1.25"
        width="16.5"
        height="16.5"
        rx="8.25"
        stroke="#809FB8"
      />
    </svg>
  );
};
