import {
  GET_MASSAGES_LIST_REQUEST,
  GET_MASSAGES_LIST_SUCCESS,
  GET_MASSAGES_LIST_FAILURE,
  CREATE_MASSAGE_REQUEST,
  CREATE_MASSAGE_SUCCESS,
  CREATE_MASSAGE_FAILURE,
  UPDATE_MASSAGE_REQUEST,
  UPDATE_MASSAGE_SUCCESS,
  UPDATE_MASSAGE_FAILURE,
  DELETE_MASSAGE_REQUEST,
  DELETE_MASSAGE_SUCCESS,
  DELETE_MASSAGE_FAILURE,
  CLEAR_MASSAGES,
} from './Wall.constant';

const initialState = {
  massageList: [],
  loading: false,
  createLoading: false,
  updateLoading: false,
  deleteLoading: false,
};

export default function wallReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MASSAGES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MASSAGES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        massageList: [...state.massageList, ...action.payload.data],
        meta: action.payload.meta,
      };
    case GET_MASSAGES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_MASSAGE_REQUEST:
      return {
        ...state,
        createLoading: true,
      };
    case CREATE_MASSAGE_SUCCESS:
      return {
        ...state,
        massageList: [action.payload, ...state.massageList],
        createLoading: false,
      };
    case CREATE_MASSAGE_FAILURE:
      return {
        ...state,
        createLoading: false,
      };
    case UPDATE_MASSAGE_REQUEST:
      return {
        ...state,
        updateLoading: true,
      };
    case UPDATE_MASSAGE_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        massageList: state.massageList.map((i) => {
          if (i.id === action.payload.id) {
            return action.payload;
          }

          return i;
        }),
      };
    case UPDATE_MASSAGE_FAILURE:
      return {
        ...state,
        updateLoading: false,
      };

    case DELETE_MASSAGE_REQUEST:
      return {
        ...state,
        deleteLoading: true,
      };
    case DELETE_MASSAGE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        massageList: state.massageList.filter(
          (i) => i.id !== action.payload.id,
        ),
      };
    case DELETE_MASSAGE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
      };
    case CLEAR_MASSAGES:
      return {
        ...state,
        massageList: [],
      };
    default:
      return state;
  }
}
