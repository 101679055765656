import {
  GET_PROJECT_REQUEST,
  GET_PROJECT_FAILURE,
  GET_PROJECT_SUCCESS,
  GET_ONE_PROJECT_REQUEST,
  GET_ONE_PROJECT_FAILURE,
  GET_ONE_PROJECT_SUCCESS,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_SUCCESS,
  UPDATE_EDIT_PROJECT_REQUEST,
  UPDATE_EDIT_PROJECT_SUCCESS,
  RESET_STORE,
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  CLEAR_FILTER,
  GET_ORGANIZERS_REQUEST,
  GET_ORGANIZERS_FAILURE,
  GET_ORGANIZERS_SUCCESS,
} from './Project.constant';

const initialState = {
  loading: false,
  loadingOrganizers: false,
  projectList: null,
  project: null,
  editData: null,
  filter: [],
  lastPage: null,
  organizers: null,
};

export default function projectReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EVENTS_REQUEST:
    case GET_PROJECT_REQUEST:
    case GET_ONE_PROJECT_REQUEST:
    case EDIT_PROJECT_REQUEST:
    case UPDATE_EDIT_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ORGANIZERS_REQUEST:
      return {
        ...state,
        loadingOrganizers: true,
      };
    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        projectList: action.payload.data,
      };
    case GET_ONE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: action.payload,
      };
    case EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        editData: action.payload,
      };
    case UPDATE_EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        editData: { ...state.editData, ...action.payload },
      };
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        filter: [...state.filter, ...action.payload.data],
        lastPage: action.payload.meta.last_page,
      };
    case GET_ORGANIZERS_SUCCESS:
      return {
        ...state,
        loadingOrganizers: false,
        organizers: action.payload,
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filter: [],
        lastPage: null,
      };
    case GET_EVENTS_FAILURE:
    case GET_PROJECT_FAILURE:
    case GET_ONE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_ORGANIZERS_FAILURE:
      return {
        ...state,
        loadingOrganizers: false,
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
