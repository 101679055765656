/* eslint-disable no-return-assign */
/* eslint-disable indent */
import { Fragment, useMemo } from 'react';

import { useEndLessScroll } from '@hooks';
import { UILoaderWrap } from '@ui';
import { FORMAT_MONTH } from '@utils/constants';
import { DateHelper } from '@utils/helpers';

import WallItem from '../Item';

// eslint-disable-next-line no-unused-vars
const WallList = ({ massageList, loading, page, setPage, meta }) => {
  const renderList = useMemo(() => {
    let month = '';

    return massageList?.map((massage, index) => {
      return (
        <Fragment key={index}>
          {month !== DateHelper.toFormat(massage.date, FORMAT_MONTH, false)
            ? (month = DateHelper.toFormat(
                massage.date,
                FORMAT_MONTH,
                false,
              )) && <span className="wall-chat__month">{month}</span>
            : null}

          <WallItem massage={massage} />
        </Fragment>
      );
    });
  }, [massageList]);

  const [onScroll, containerRef] = useEndLessScroll({
    onIntersection: () => {
      if (page < meta.last_page && renderList.length) {
        setPage(+page + 1);
      }
    },
  });

  return (
    <UILoaderWrap loading={loading}>
      <div ref={containerRef} onScroll={onScroll} className="wall-chat__items">
        {renderList}
      </div>
    </UILoaderWrap>
  );
};

export default WallList;
