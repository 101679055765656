import {
  SEARCH_EVENTS_FAILURE,
  SEARCH_EVENTS_REQUEST,
  SEARCH_EVENTS_SUCCESS,
} from './Events.constant';

export function searchEventsRequest(payload) {
  return {
    type: SEARCH_EVENTS_REQUEST,
    payload,
  };
}
export function searchEventsFailure(payload) {
  return {
    type: SEARCH_EVENTS_FAILURE,
    payload,
  };
}
export function searchEventsSuccess(payload) {
  return {
    type: SEARCH_EVENTS_SUCCESS,
    payload,
  };
}
