import cn from 'classnames';

import styles from './styles.module.scss';
import UILoader from '../Item';

const UILoaderWrap = ({
  children,
  loading,
  className,
  bgTransparent = false,
}) => {
  return (
    <div className={cn(styles.loader__wrap, className)}>
      {loading && <UILoader bgTransparent={bgTransparent} primary />}
      {children}
    </div>
  );
};

export default UILoaderWrap;
