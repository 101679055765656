/* eslint-disable indent */
import { useState } from 'react';

import i18n from '@i18n';
import { STANDART_DATE } from '@utils/constants';
import { typeCarouselCalendar } from '@utils/utility-property';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';

import UIRangeCalendar from '../RangeCalendar';
import UISelect from '../Select';
import './style.scss';

const MonthRender = (focusedDate, changeShownDate, props) => {
  const upperYearLimit = props.maxDate.getFullYear();
  const newYear = new Date(STANDART_DATE).getFullYear();

  return (
    <>
      <span className="calendar__year">
        <UISelect
          isClearable={false}
          options={new Array(upperYearLimit - newYear + 1)
            .fill(upperYearLimit)
            .map((item, index) => ({ id: item - index, name: item - index }))}
          placeholder={newYear}
          value={{
            id: focusedDate.getFullYear(),
            name: focusedDate.getFullYear(),
          }}
          onChange={(value) => changeShownDate(value.id, 'setYear')}
          isSearchable={false}
          className="select_year"
        />
      </span>
      <div className="calendar__month">
        <Swiper
          direction="vertical"
          slidesPerView={9}
          spaceBetween={10}
          loop={true}
          loopedSlides={30}
          slideToClickedSlide={true}
          centeredSlides={true}
          initialSlide={new Date().getMonth()}
          onSlideChange={(e) => changeShownDate(e.realIndex, 'setMonth')}
        >
          <SwiperSlide>{i18n.t('CALENDAR.JANUARY')}</SwiperSlide>
          <SwiperSlide>{i18n.t('CALENDAR.FEBRUARY')}</SwiperSlide>
          <SwiperSlide>{i18n.t('CALENDAR.MARCH')}</SwiperSlide>
          <SwiperSlide>{i18n.t('CALENDAR.APRIL')}</SwiperSlide>
          <SwiperSlide>{i18n.t('CALENDAR.MAY')}</SwiperSlide>
          <SwiperSlide>{i18n.t('CALENDAR.JUNE')}</SwiperSlide>
          <SwiperSlide>{i18n.t('CALENDAR.JULY')}</SwiperSlide>
          <SwiperSlide>{i18n.t('CALENDAR.AUGUST')}</SwiperSlide>
          <SwiperSlide>{i18n.t('CALENDAR.SEPTEMBER')}</SwiperSlide>
          <SwiperSlide>{i18n.t('CALENDAR.OCTOBER')}</SwiperSlide>
          <SwiperSlide>{i18n.t('CALENDAR.NOVEMBER')}</SwiperSlide>
          <SwiperSlide>{i18n.t('CALENDAR.DECEMBER')}</SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

const UICarouselCalendar = ({
  type = typeCarouselCalendar.RANGE,
  range: rangeMain,
  setRange: setRangeMain,
  className,
  handlerDateClick,
}) => {
  const [range, setRange] = useState(
    rangeMain ||
      (type === typeCarouselCalendar.RANGE
        ? {
            endDate: new Date(),
            key: 'selection',
            startDate: new Date(),
          }
        : new Date()),
  );

  const handlerRange = (item) => {
    switch (type) {
      case typeCarouselCalendar.RANGE: {
        setRange(item?.selection);
        setRangeMain(item?.selection);

        break;
      }
      case typeCarouselCalendar.CALENDAR: {
        setRange(item);
        setRangeMain(item);

        break;
      }
      default:
    }
  };

  return (
    <div
      className={cn('rangeCalendar', className, {
        rangeCalendar__oneDay: type === typeCarouselCalendar.CALENDAR,
        rangeCalendar__monthDisabled: type === typeCarouselCalendar.MONTH,
      })}
    >
      <UIRangeCalendar
        type={type}
        showMonthArrow={false}
        handlerSelect={handlerRange}
        range={range}
        handlerDateClick={handlerDateClick}
        navigatorRenderer={MonthRender}
        {...(type === typeCarouselCalendar.MONTH && {
          onShownDateChange: setRangeMain,
        })}
      />
    </div>
  );
};

export default UICarouselCalendar;
