import { memo, useEffect, useState } from 'react';

import FullCalendar from '@fullcalendar/react';
// eslint-disable-next-line import/order
import dayGridPlugin from '@fullcalendar/daygrid';
import { LocalStorage } from '@services/storage';
import { FORMAT_DATE_QUERY } from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import cn from 'classnames';

import './styles.scss';

const UICalendar = ({
  children,
  className,
  handleDateClick,
  selectedDate,
  initialDate,
  maxDate,
  minDate,
  hidePopup,
}) => {
  const [dataActive, setDataActive] = useState();

  useEffect(() => {
    if (selectedDate) {
      setDataActive(
        DateHelper.roundingDayToUnix(
          DateHelper.timezoneChange(
            selectedDate,
            new Date().getTimezoneOffset() > 0,
          ),
        ),
      );
    }
  }, [selectedDate]);

  const handlerClick = (e, disabled) => {
    if (!disabled) {
      handleDateClick(e.date, 'day');
      setDataActive(DateHelper.toFormat(e.date, FORMAT_DATE_QUERY, false));
      if (hidePopup) {
        hidePopup();
      }
    }
  };

  return (
    <div className={cn('UICalendar-wrap', className)}>
      <FullCalendar
        plugins={[dayGridPlugin]}
        headerToolbar={{
          left: 'prev',
          center: 'title',
          right: 'next',
        }}
        locale={LocalStorage.get('language')}
        firstDay={1}
        titleFormat={{ month: 'long' }}
        initialView="dayGridMonth"
        showNonCurrentDates={false}
        height="auto"
        initialDate={initialDate}
        dayCellContent={(e) => {
          const disabled =
            (minDate &&
              DateHelper.roundingDayToUnix(e.date) <
                DateHelper.roundingDayToUnix(
                  DateHelper.timezoneChange(minDate),
                )) ||
            (maxDate &&
              DateHelper.roundingDayToUnix(e.date) >
                DateHelper.roundingDayToUnix(
                  DateHelper.timezoneChange(maxDate),
                ));

          return (
            <div
              className={cn('dayCellContent', {
                disabled,
                dataActive:
                  DateHelper.toFormat(
                    DateHelper.timezoneChange(e.date, true, true),
                    FORMAT_DATE_QUERY,
                    false,
                  ) ===
                    DateHelper.toFormat(dataActive, FORMAT_DATE_QUERY, false) &&
                  dataActive,
              })}
              onClick={() => handlerClick(e, disabled)}
            >
              {e.dayNumberText}
            </div>
          );
        }}
      />
      {children}
    </div>
  );
};

export default memo(UICalendar);
