import http from '@utils/http';
import qs from 'qs';

const basePath = '/api';

export const getOffices = async (body) => {
  const data = await http.get(`${basePath}/offices`, {
    params: body,
    paramsSerializer: (...data) => {
      return qs.stringify(...data, {
        encodeValuesOnly: true,
        skipNulls: true,
      });
    },
  });

  return data.data;
};

export const getUsers = async (body) => {
  const data = await http.get(`${basePath}/users`, {
    params: body,
    paramsSerializer: (...data) => {
      return qs.stringify(...data, {
        encodeValuesOnly: true,
        skipNulls: true,
      });
    },
  });

  return data.data;
};

export const createOffices = async (body) => {
  const data = await http.post(`${basePath}/offices`, body);

  return data.data.data;
};

export const setUsers = async (body) => {
  const { id, ...payload } = body;

  const data = await http.put(`${basePath}/users/${id}`, payload);

  return data.data.data;
};

export const setOffices = async (body) => {
  const { id, ...payload } = body;

  const data = await http.put(`${basePath}/offices/${id}`, payload);

  return data.data.data;
};
