import {
  ADD_CATEGORIES_FAILURE,
  ADD_CATEGORIES_REQUEST,
  ADD_CATEGORIES_SUCCESS,
  ADD_GROUPS_FAILURE,
  ADD_GROUPS_REQUEST,
  ADD_GROUPS_SUCCESS,
  ADD_PACKAGES_FAILURE,
  ADD_PACKAGES_REQUEST,
  ADD_PACKAGES_SUCCESS,
  DELETE_CATEGORIES_FAILURE,
  DELETE_CATEGORIES_REQUEST,
  DELETE_CATEGORIES_SUCCESS,
  DELETE_GROUPS_FAILURE,
  DELETE_GROUPS_REQUEST,
  DELETE_GROUPS_SUCCESS,
  DELETE_PACKAGES_FAILURE,
  DELETE_PACKAGES_REQUEST,
  DELETE_PACKAGES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_GROUPS_FAILURE,
  GET_GROUPS_REQUEST,
  GET_GROUPS_SUCCESS,
  GET_PACKAGES_FAILURE,
  GET_PACKAGES_REQUEST,
  GET_PACKAGES_SUCCESS,
  SET_CATEGORIES_FAILURE,
  SET_CATEGORIES_REQUEST,
  SET_CATEGORIES_SUCCESS,
  SET_GROUPS_FAILURE,
  SET_GROUPS_REQUEST,
  SET_GROUPS_SUCCESS,
  SET_PACKAGES_FAILURE,
  SET_PACKAGES_REQUEST,
  SET_PACKAGES_SUCCESS,
} from './Categories.constant';

export function getCategoriesRequest(payload) {
  return {
    type: GET_CATEGORIES_REQUEST,
    payload,
  };
}
export function getCategoriesFailure(payload) {
  return {
    type: GET_CATEGORIES_FAILURE,
    payload,
  };
}
export function getCategoriesSuccess(payload) {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload,
  };
}

export function addCategoriesRequest(payload) {
  return {
    type: ADD_CATEGORIES_REQUEST,
    payload,
  };
}
export function addCategoriesFailure(payload) {
  return {
    type: ADD_CATEGORIES_FAILURE,
    payload,
  };
}
export function addCategoriesSuccess(payload) {
  return {
    type: ADD_CATEGORIES_SUCCESS,
    payload,
  };
}

export function setCategoriesRequest(payload) {
  return {
    type: SET_CATEGORIES_REQUEST,
    payload,
  };
}
export function setCategoriesFailure(payload) {
  return {
    type: SET_CATEGORIES_FAILURE,
    payload,
  };
}
export function setCategoriesSuccess(payload) {
  return {
    type: SET_CATEGORIES_SUCCESS,
    payload,
  };
}

export function deleteCategoriesRequest(payload) {
  return {
    type: DELETE_CATEGORIES_REQUEST,
    payload,
  };
}
export function deleteCategoriesFailure(payload) {
  return {
    type: DELETE_CATEGORIES_FAILURE,
    payload,
  };
}
export function deleteCategoriesSuccess(payload) {
  return {
    type: DELETE_CATEGORIES_SUCCESS,
    payload,
  };
}

export function getPackagesRequest(payload) {
  return {
    type: GET_PACKAGES_REQUEST,
    payload,
  };
}
export function getPackagesFailure(payload) {
  return {
    type: GET_PACKAGES_FAILURE,
    payload,
  };
}
export function getPackagesSuccess(payload) {
  return {
    type: GET_PACKAGES_SUCCESS,
    payload,
  };
}

export function addPackagesRequest(payload) {
  return {
    type: ADD_PACKAGES_REQUEST,
    payload,
  };
}
export function addPackagesFailure(payload) {
  return {
    type: ADD_PACKAGES_FAILURE,
    payload,
  };
}
export function addPackagesSuccess(payload) {
  return {
    type: ADD_PACKAGES_SUCCESS,
    payload,
  };
}

export function setPackagesRequest(payload) {
  return {
    type: SET_PACKAGES_REQUEST,
    payload,
  };
}
export function setPackagesFailure(payload) {
  return {
    type: SET_PACKAGES_FAILURE,
    payload,
  };
}
export function setPackagesSuccess(payload) {
  return {
    type: SET_PACKAGES_SUCCESS,
    payload,
  };
}

export function deletePackagesRequest(payload) {
  return {
    type: DELETE_PACKAGES_REQUEST,
    payload,
  };
}
export function deletePackagesFailure(payload) {
  return {
    type: DELETE_PACKAGES_FAILURE,
    payload,
  };
}
export function deletePackagesSuccess(payload) {
  return {
    type: DELETE_PACKAGES_SUCCESS,
    payload,
  };
}

export function getGroupsRequest(payload) {
  return {
    type: GET_GROUPS_REQUEST,
    payload,
  };
}
export function getGroupsFailure(payload) {
  return {
    type: GET_GROUPS_FAILURE,
    payload,
  };
}
export function getGroupsSuccess(payload) {
  return {
    type: GET_GROUPS_SUCCESS,
    payload,
  };
}

export function addGroupsRequest(payload) {
  return {
    type: ADD_GROUPS_REQUEST,
    payload,
  };
}
export function addGroupsFailure(payload) {
  return {
    type: ADD_GROUPS_FAILURE,
    payload,
  };
}
export function addGroupsSuccess(payload) {
  return {
    type: ADD_GROUPS_SUCCESS,
    payload,
  };
}

export function setGroupsRequest(payload) {
  return {
    type: SET_GROUPS_REQUEST,
    payload,
  };
}
export function setGroupsFailure(payload) {
  return {
    type: SET_GROUPS_FAILURE,
    payload,
  };
}
export function setGroupsSuccess(payload) {
  return {
    type: SET_GROUPS_SUCCESS,
    payload,
  };
}

export function deleteGroupsRequest(payload) {
  return {
    type: DELETE_GROUPS_REQUEST,
    payload,
  };
}
export function deleteGroupsFailure(payload) {
  return {
    type: DELETE_GROUPS_FAILURE,
    payload,
  };
}
export function deleteGroupsSuccess(payload) {
  return {
    type: DELETE_GROUPS_SUCCESS,
    payload,
  };
}
