import { LayoutModal } from '@layouts';
import { UICarouselCalendar } from '@ui';

import './style.scss';

const WidgetsModalMobileDate = ({
  range,
  setRange,
  handlerDateClick,
  type = 'range',
}) => {
  return (
    <LayoutModal className="modal__date">
      <UICarouselCalendar
        className="range__small"
        type={type}
        range={range}
        setRange={setRange}
        handlerDateClick={handlerDateClick}
      />
    </LayoutModal>
  );
};

export default WidgetsModalMobileDate;
