import logoName from '@assets/img/icons/logoName.svg';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import AuthLoginForm from './Form';
import style from './styles.module.scss';

const AuthLogin = () => {
  const { t } = useTranslation();

  return (
    <section className={style.auth}>
      <div className={cn(style['auth-left'])}>
        <div className={cn(style['auth-left__subtitle'])}>
          <img src={logoName} alt="icon" />
        </div>
        <div className="h2 fw700">{t('LOGIN.INFO_1')}</div>

        <div className={cn(style['auth-left__text'], 'h4 fw600')}>
          {t('LOGIN.INFO_2')}
        </div>
      </div>

      <div className={cn(style['auth-right'])}>
        <div className={cn(style['auth-forms'])}>
          <div className={cn(style['auth-right__title'], 'h2 fw700')}>
            {t('COMMON.ENTER')}
          </div>

          <AuthLoginForm />
        </div>
      </div>
    </section>
  );
};

export default AuthLogin;
