export const ArrLeftLongSVG = ({ className }) => {
  return (
    <svg
      className={className}
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.60091 0.727294L0.734246 8.00002L6.60091 15.2727M0.734246 8.00002L21.2676 8.00002"
        stroke="#809FB8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
