export const ShowPassSVG = () => (
  <svg
    width="18"
    height="8"
    viewBox="0 0 18 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 1C1.5 1 4.125 4.69231 9 4.69231C13.875 4.69231 16.5 1 16.5 1M3 2.51846L1.5 4.69231M16.5 4.69231L15.003 2.52123M6.6855 4.39692L6 7M11.2973 4.40431L12 7"
      stroke="#B3B3B3"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
