import { UIButton, UITable } from '@ui';
import { FORMAT_DATE_TEXT } from '@utils/constants';
import { DateHelper } from '@utils/helpers';
import { LINK_PROJECTS } from '@utils/link';
import { Button } from '@utils/utility-property';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './styles.scss';
import EventsForm from './Form';

const Events = () => {
  const { t } = useTranslation();

  const { loading, events } = useSelector((state) => state.events);

  const content = events?.map((item, index) => [
    <UIButton
      key={index}
      type={Button.LINK}
      link={`${LINK_PROJECTS}/${item.event.id}`}
    >
      {item.event.name}
    </UIButton>,
    DateHelper.toFormat(item.event.date_start_event * 1000, FORMAT_DATE_TEXT),
    DateHelper.toFormat(item.event.date_finish_event * 1000, FORMAT_DATE_TEXT),
    item.event.place,
    item.event.organizer.name,
  ]);

  const header = [
    t('COMMON.PROJECT_NAME'),
    t('COMMON.BEGINNING'),
    t('COMMON.ENDING'),
    t('COMMON.PLODASCHKA'),
    t('COMMON.CUSTOMER'),
  ];

  return (
    <>
      <EventsForm loading={loading} />

      <div className="projectsAll-table">
        <UITable header={header} content={content} />
      </div>
    </>
  );
};

export default Events;
