import i18n from '@i18n';
import { ReportsHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { put, takeLatest, fork, call } from 'redux-saga/effects';

import {
  getGeneralFailure,
  getGeneralSuccess,
  getResponsibleFailure,
  getResponsibleSuccess,
} from './Reports.action';
import {
  GET_GENERAL_REQUEST,
  GET_RESPONSIBLE_REQUEST,
} from './Reports.constant';

function* workerGetResponsible({ payload }) {
  try {
    const data = yield call(ReportsHttp.getResponsible, payload);

    yield put(getResponsibleSuccess(data));
    yield put(
      alertSuccess({
        message: i18n.t('ALERT.REPORT_RESPONSIBLE_PERSON_HAS_BEEN_GENERATED'),
      }),
    );
  } catch (error) {
    yield put(getResponsibleFailure());
    yield put(alertError());
  }
}
function* watchGetResponsible() {
  yield takeLatest(GET_RESPONSIBLE_REQUEST, workerGetResponsible);
}

function* workerGetGeneral({ payload }) {
  try {
    const data = yield call(ReportsHttp.getGeneral, payload);

    yield put(getGeneralSuccess(data));
    yield put(
      alertSuccess({ message: i18n.t('ALERT.GENERAL_REPORT_GENERATED') }),
    );
  } catch (error) {
    yield put(getGeneralFailure());
    yield put(alertError());
  }
}
function* watchGetGeneral() {
  yield takeLatest(GET_GENERAL_REQUEST, workerGetGeneral);
}
export const reportsWatchers = [
  fork(watchGetGeneral),
  fork(watchGetResponsible),
];
