import { LayoutAccount } from '@layouts';
import ProjectAdd from '@modules/ProjectAdd';
import { LINK_PROJECTS_EDIT } from '@utils/link';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';

const ViewProjectAdd = () => {
  const { editData } = useSelector((state) => state.project);

  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <LayoutAccount
      title={`${editData ? t('COMMON.SHIFT') : t('COMMON.ADDING')} ${t(
        'PROJECT.EVENTS',
      )}`}
      className="add_event"
    >
      <Routes>
        <Route
          path={pathname.includes(LINK_PROJECTS_EDIT) ? '/:id' : '/'}
          element={<ProjectAdd />}
        />
      </Routes>
    </LayoutAccount>
  );
};

export default ViewProjectAdd;
